import { ProvisionUserHeader } from "../../api-client/investor-portal-client";

export function isProvisionUserHeader(target: any): target is ProvisionUserHeader {
  return Object.hasOwn(target, 'failedCount')
}

export function isProvisionUserHeaderList(target: any): target is ProvisionUserHeader[] {
  // Not an array then return false
  if (!Array.isArray(target)) {
    return false
  }
  // Object should contain 'failedCount' property
  return target.every(h => Object.hasOwn(h, 'failedCount'))
}