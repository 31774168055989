import { SelectBox } from 'devextreme-react'
import produce from 'immer'
import { useRecoilState } from 'recoil'
import { taxYearViewOptions } from './tax-year-models'
import { taxYearState } from './tax-year-control-state'
import './tax-year-control.scss'

export interface TaxYearProps {
  isDisabled: boolean
  className?: string
}

/** Tax Year dropdown component  */
export const TaxYearControl = ({
  isDisabled,
  className: styleName,
}: TaxYearProps) => {
  const [taxYear, setTaxYear] = useRecoilState(taxYearState)

  /** Set changed year value in state */
  const onTaxYearChanged = (value: string) => {
    setTaxYear(
      produce((draft) => {
        draft.taxyear = value
      })
    )
  }
  
  return (
    <div className='tax-year-control'>
      <div
        className={
          styleName +
          ' annotated-tax-year-select annotated-tax-year-select tax-year-spacing'
        }
      >
        <SelectBox
          items={taxYearViewOptions}
          displayExpr='label'
          valueExpr={'value'}
          value={taxYear.taxyear}
          onValueChange={onTaxYearChanged}
          disabled={isDisabled}
        ></SelectBox>
      </div>
    </div>
  )
}
