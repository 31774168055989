import { useMemo } from 'react'
import { permissionRequestAny } from '../access-manager/permission-request-creation'
import { useIsPermissionsLoaded, usePermissions } from '../access-manager/use-permission'
import { NavDataProps } from '../components/layout/leftnav/LeftNav'
import { ADMIN, INVESTOR } from '../model/permissions'
import { useNavDataAdmin } from './use-navdata-admin'
import { useNavDataInvestor } from './use-navdata-investor'

export const useMainNav = () => {
  const navDataAdmin = useNavDataAdmin()
  const navDataInvestor = useNavDataInvestor()

  const isPermissionLoaded = useIsPermissionsLoaded()
  const isAdmin = usePermissions([permissionRequestAny(ADMIN)])
  const isInvestor = usePermissions([permissionRequestAny(INVESTOR)])

  // accessing data, based on user type
  const navData: NavDataProps | undefined = useMemo(() => {
    if (!isPermissionLoaded) {
      return undefined
    } else if (isAdmin) {
      return navDataAdmin
    } else if (isInvestor) {
      return navDataInvestor
    } else {
      throw new Error("No permissions are available for this user, which are required for main menu content.")
    }
  }, [navDataAdmin, navDataInvestor, isAdmin, isInvestor, isPermissionLoaded])

  return navData
}