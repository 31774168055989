import { File } from '../../api-client/investor-portal-client'

export const NEW_FILE_SESSION_STATE_KEY = 'new-files'

export interface NewFileState {
    /** The id of the file that was marked as "new". */
    fileId: number
    /** This is the date we received this information from the server,
     *   Files should show as "new" for 24 hours after this date. */
    serverDate: Date
}

/** Gets the array of NewFileState objects for files with the "new" status from the session storage. */
export function getNewFileSessionState(): NewFileState[] {
    // Get the value from session storage.
    const sessionStorageValue = sessionStorage.getItem(NEW_FILE_SESSION_STATE_KEY)

    // Account for null/undefined, if this is the first time we're using this state.
    if (sessionStorageValue) {
        const parsedData = JSON.parse(sessionStorageValue) as NewFileState[]
        // Because JSON does parse dates back to date objects (only strings) we need to
        //  update the date values to Date objects ourselves.
        parsedData.forEach(d => {
            d.serverDate = new Date(d.serverDate)
        })

        return parsedData
    }

    return []
}

/** Sets the array of NewFileState objects for files with the "new" status in the session storage. */
export function setNewFileSessionState(newValue: NewFileState[]): void {
    if (!newValue) {
        throw new Error('newValue must be set.')
    }

    sessionStorage.setItem(NEW_FILE_SESSION_STATE_KEY, JSON.stringify(newValue))
}

/** After receiving Investor files from the server, this method will update the isNew property
 *   on incoming files, to match session storage values, and ALSO update session storage
 *   to match the incoming files' isNew properties. */
export function processNewStatusOnInvestorFiles(incomingFiles: File[]): void {
    // Get the "new files" state from session storage.
    let newFilesState = getNewFileSessionState()

    // Update the session state to include files that have isNew set from the server.
    incomingFiles
        .filter(f => f.isNew)
        .forEach(f => {
            newFilesState.push({
                fileId: f.id!,
                serverDate: new Date()
            })
        })

    // Update the isNew property on the incoming files.
    incomingFiles.forEach(f => {
        // Try to find the ID of this file in our session storage.
        const existingItem = newFilesState.find(s => s.fileId === f.id)

        if (existingItem && (Date.now() - existingItem.serverDate.valueOf() <= (24 * 60 * 60 * 1000))) {
            f.isNew = true
        }
    })

    // Clean up any statuses that are outside the 24 hour window.
    newFilesState = newFilesState.filter(s => Date.now() - s.serverDate.valueOf() <= (24 * 60 * 60 * 1000))

    // Save the values back to session storage.
    setNewFileSessionState(newFilesState)
}

/** Removes any files from the "new file" session storage that match a specified file ID. */
export function clearNewStatusOnInvestorFile(fileId: number): void {
    // Get the files from session store.
    let sessionFiles = getNewFileSessionState()

    // Remove the necessary files.
    sessionFiles = sessionFiles.filter(f => f.fileId !== fileId)

    // Save the values back to session storage.
    setNewFileSessionState(sessionFiles)
}