import { Grid, Button, IconButton } from "@mui/material";
import Masthead from "../Masthead";
import MastheadImage from "../../assets/img/icon/bargraph.png";
import { partnershipNameData } from "./Data";
import DataGrid, {
  SearchPanel,
  Column,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const Client = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#33588e",
      },
    },
    typography: {
      fontFamily: [
        "Arial",
        "Roboto",
        '"Helvetica Neue"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <>
      <style>
        {`
              :root {
                --cui-color-primary-rgb: 51, 88, 142;        
                --cui-color-bg-dark: #07224b;
                --cui-color-shadow-base: transparent;
              }

              .cui-page_wrapper {
                border-radius:0;
              }
              .cui-c_card h3 {
                  color:#161616;
              }

        `}
      </style>
              <ThemeProvider theme={theme}>
      <Masthead mastheadDx="lg" mastheadImage={MastheadImage}>
        <h1>Get Started!</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nunc mi,
          tempor id molestie ut, sagittis nec purus. Proin id nulla neque. Etiam
          mauris velit, dapibus ut pharetra eu, cursus in nulla. Vestibulum
          bibendum id arcu sit amet pretium. Maecenas pharetra imperdiet mi, ut
          ornare lorem accumsan at.
        </p>
        <Button variant="outlined">Learn More</Button>
      </Masthead>
      <Grid
        container
        justifyContent='flex-end'
        spacing={1}
        maxWidth="lg"
        style={{ margin: "0 auto", paddingLeft: "16px", paddingRight: "16px" }}
      >
        <Grid item xs={12}>
          <DataGrid
            dataSource={partnershipNameData[0].List}
            showBorders={true}
            className={"cui-c_table cui-c_table-dx"}
          >
            <SearchPanel visible={true} width="200px" />
            <Column cellRender={cellRenderInvestorDownload} width="60px" />
            <Column dataField="Document" />
            <Column dataField="TaxYear" defaultSortOrder="desc" />
            <Column dataField="Investor" groupIndex={0} />
            <Toolbar>
              <Item location="before">
                <div className="cui-c_table-heading">Documents</div>
              </Item>
              <Item location="after"></Item>
              <Item name="searchPanel" />
            </Toolbar>
          </DataGrid>
        </Grid>
      </Grid>
      </ThemeProvider>
    </>
  );
};
function cellRenderInvestorDownload() {
  return (
    <IconButton size="small">
      <span className="material-icons-outlined">file_download</span>
    </IconButton>
  );
}
export default Client;
