import {
  ProvisionUserHeader,
  ProvisionUserRecord,
} from '../../api-client/investor-portal-client'
import DataGrid, {
  Column,
  LoadPanel,
  Paging,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid'
import { dgheight, ICellData } from '../../model/file-model'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import { useEffect, useState } from 'react'

interface ProvisionUsersFailedStatusProps {
  provisionUserHeaders: ProvisionUserHeader[]
}

// Display failed provisioned users with successfully provisioned users count
export const ProvisionUsersFailedStatus = ({
  provisionUserHeaders,
}: ProvisionUsersFailedStatusProps) => {
  const [provisionRecords, setProvisionRecords] = useState<
    ProvisionUserRecord[]
  >([])
  const [successCount, setSuccessCount] = useState<number>(0)
  const [failedCount, setFailedCount] = useState<number>(0)
  // Get investor name
  const investorName = (data: ICellData<ProvisionUserRecord>) =>
    data?.data?.firstName + ' ' + data?.data?.lastName

  // Set provision records, success count and failed count
  useEffect(() => {
    const records: ProvisionUserRecord[] = []
    let succCount: number = 0
    let failCount: number = 0

    provisionUserHeaders.forEach((user) => {
      const err = user.provisionUserRecords?.filter((r) => r.status === 'ERROR')
      if (err && err.length > 0) {
        records.push(...err)
      }
      succCount = succCount + user.successCount!
      failCount = failCount + user.failedCount!
    })

    setProvisionRecords(records)
    setSuccessCount(succCount)
    setFailedCount(failCount)
  }, [provisionUserHeaders])

  return (
    <div className='ip-table'>
      <div className='provision-status-align'>
        <div>{`${successCount} users successfully added`}</div>
        <div>{`${failedCount} users failed listed below`}</div>
      </div>
      <DataGrid
        dataSource={provisionRecords}
        keyExpr='id'
        showBorders={true}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        showColumnLines={false}
        showRowLines={true}
        onCellPrepared={addToolTipOnCellPrepared}
        wordWrapEnabled={false}
      >
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={4} />
        <Sorting mode='single' />
        <LoadPanel enabled={true} />
        <Column
          cellRender={investorName}
          caption='Investor Name'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'20%'}
          defaultSortOrder='asc'
        />
        {/* For unbound column specifically required 'allowSearch = true'  */}
        <Column
          dataField='emailAddress'
          caption='Investor Email'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'25%'}
        />
        <Column
          dataField='clientName'
          caption='Allocating Entity Info'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'20%'}
        />
        <Column
          dataField='errorMessage'
          caption='Errors'
          dataType='string'
          encodeHtml={false}
          allowSearch={true}
          allowSorting={true}
          width={'35%'}
        />
      </DataGrid>
    </div>
  )
}
