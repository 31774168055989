import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'

export const VerifyUserBanner = () => {
  return (
    <BannerContainer>
      <div>
        <BannerHeader>Verify Users</BannerHeader>
      </div>
    </BannerContainer>
  )
}
