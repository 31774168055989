import { Grid } from '@mui/material'
import { Button } from 'devextreme-react'
import { InvestorElectionDetails } from './investor-election-details'
import { useRecoilValue } from 'recoil'
import {
  investorRelationsAtom,
  smGeneralElectionInfoAtom,
} from '../../../state/atom'
import './investor-submission-overview.scss'
import { useParams } from 'react-router-dom'
import { handleContactEmail } from '../investor-utils'
import { EntityType } from '../../../model/entity-types'
import { useState } from 'react'
import { PdfInvestorElection } from './print/pdf-investor-election'

export interface InvestorElectionOverviewProps {
  showElectionOverview: (isShowOverview: boolean) => void
}

/** Purpose of this component is to show the overview of submitted election */
export const InvestorElectionOverview = ({
  showElectionOverview,
}: InvestorElectionOverviewProps) => {
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)
  const { investorEntities } = useRecoilValue(investorRelationsAtom)
  const { groupId, taxYear, partnerId } = useParams() as {
    groupId: string
    taxYear: string
    partnerId: string
  }
  const [showPrintDialog, setShowPrintDialog] = useState<boolean>(false)

  // Get the group info using grouId
  const investorClientGroup = investorEntities?.clientGroups?.find(
    (i) => i.id === parseInt(groupId)
  )
  //Get the current investor info from investor entities
  const investorClientEntity = investorEntities?.clientGroupEntities?.find(
    (i) => i.id === parseInt(partnerId)
  )

  // InvestorName as Entity's LastName and FirstName or entityName
  const investorName =
    investorClientEntity?.entityType === EntityType.Business
      ? `${investorClientEntity.entityName}`
      : `${investorClientEntity?.firstName!} ${investorClientEntity?.lastName!}`

  // get the name from fiterd item
  const investorGroupName = investorClientGroup?.name

  return (
    <div className='investor-submission-overview-container'>
      {/** Page Header */}
      <Grid className='page-header-items'>
        <Grid item xs={1} md={0.4} className='back-button'>
          <Button
            onClick={() => {
              showElectionOverview(false)
            }}
            data-testId='back-button'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={11} md={11.6}>
          <p className='state-overview-text'>State Manager</p>
        </Grid>
      </Grid>
      <Grid item xs={11} md={11.6} className='sub-header-grid'>
        <span className='sub-header-text'>Submission Overview</span>
      </Grid>
      <div>
        <InvestorElectionDetails investorElectionsInfoId={electionsInfo?.id} />
        <div className='button-grid'>
          <Button
            onClick={() => {
              showElectionOverview(false)
            }}
            text='Back'
            type='normal'
            data-testid='back-button'
          ></Button>
          <div className='contect-administration-button'>
            <Button
              onClick={() => {
                handleContactEmail(investorName, investorGroupName!, taxYear)
              }}
              text='Contact Administration'
              type='normal'
              data-testid='contact-administration-button'
            ></Button>
          </div>
          <div className='contect-administration-button'>
            <Button
              onClick={async () => {
                setShowPrintDialog(true)
              }}
              text='Print'
              type='normal'
              disabled={
                investorGroupName === undefined ||
                electionsInfo === null ||
                electionsInfo?.id === 0
              }
              data-testid='print-button'
            ></Button>
          </div>
          <PdfInvestorElection
            isVisible={showPrintDialog}
            electionsInfo={electionsInfo!}
            investorElectionsInfoId={electionsInfo?.id!}
            investorId={electionsInfo?.investorId!}
            generalOptionsId={electionsInfo?.generalOptionsId!}
            taxYear={taxYear}
            investorGroupName={investorGroupName!}
            investorName={investorName!}
            onClose={() => setShowPrintDialog(false)}
          ></PdfInvestorElection>
        </div>
      </div>
    </div>
  )
}
