export const setup = {
  appTitle: {
    app: 'Investor',
    appTag: 'IP',
    appIco: 'paid',
    appIcoVariant: 'outlined',
    xp: 'Portal',
    logo: '',
  },
}
export const clientSetup = {
  appTitle: {
    app: 'Investor',
    xp: 'Portal',
    logo: 'audax.png',
  },
}

export const partnershipNameData = [
  {
    Name: 'Audax Group',
    Logo: 'audax.png',
    Available: true,
    Url: '/client',
    List: [
      {
        Investor: 'Partner One',
        TaxYear: '2020',
        Document: 'K-1',
      },
      {
        Investor: 'Partner One',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2020',
        Document: 'K-1',
      },
    ],
  },
  {
    Name: 'Silver Oak',
    Logo: 'silveroak.png',
    Available: true,
    List: [
      {
        Investor: 'Partner One',
        TaxYear: '2020',
        Document: 'K-1',
      },
      {
        Investor: 'Partner One',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2020',
        Document: 'K-1',
      },
    ],
  },
  {
    Name: 'Centre Partners',
    Logo: 'centrepartners.png',
    Available: false,
    List: [
      {
        Investor: 'Partner One',
        TaxYear: '2020',
        Document: 'K-1',
      },
      {
        Investor: 'Partner One',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2020',
        Document: 'K-1',
      },
    ],
  },
  {
    Name: 'ACME Partners',
    Logo: 'acme.svg',
    Available: false,
    List: [
      {
        Investor: 'Partner One',
        TaxYear: '2020',
        Document: 'K-1',
      },
      {
        Investor: 'Partner One',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2019',
        Document: 'K-1',
      },
      {
        Investor: 'Partner Two',
        TaxYear: '2020',
        Document: 'K-1',
      },
    ],
  },
]
