import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { SignalRWatcher } from './signalr-watcher'

/** Watches for Provision Users, and resolves/rejects when all expected users have been provisioned.*/
export class ProvisionUserWatcher extends SignalRWatcher<ProvisionUserHeader> {
  constructor(apiPromise: Promise<ProvisionUserHeader>) {
    super('SendUpdatedProvisionUserHeader', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)

    if (!apiPromise) {
      throw new Error(`apiPromise cannot be undefined.`)
    }

    // We want to keep a local copy of the ProvisionUserHeader we
    //  receive from the VerifyUser API call.
    apiPromise.then((x) => {
      this.provisionUsersHeader = x

      //avoid further process of provision when there are no records.
      //means, avoid further process of provision when investor didn't mentioned the mailId
      if (x.provisionUserRecords?.length === 0) {
        this.operationComplete(x)
      } else if (this.provisionUsersHeader?.status === 'COMPLETE') {
        // Display error message incase of investor detail is missing any
        // required information for user provisioning (email address, client id)
        this.operationFailed(this.provisionUsersHeader)
      }
    })
  }

  private provisionUsersHeader?: ProvisionUserHeader

  /** SignalR handler to process each provision received in the messages. */
  handlerAction(header: ProvisionUserHeader, uniqueOperationId: string) {
    // Replace all provision user records in our existing header, with
    //  the records in the header from the message.  This basically updates
    //  the statuses of the records we already have.
    header.provisionUserRecords!.forEach((r) => {
      const existingRecordIndex =
        this.provisionUsersHeader!.provisionUserRecords!.findIndex(
          (r2) => r2.id === r.id
        )
      if (existingRecordIndex >= 0) {
        this.provisionUsersHeader!.provisionUserRecords![existingRecordIndex] =
          r
      }
    })

    // If the status is not NEW then we will not get any
    //  further messages from SignalR, and we can complete this operation.
    if (header.status !== 'NEW') {
      this.provisionUsersHeader!.failedCount = header.failedCount
      this.provisionUsersHeader!.successCount = header.successCount
      this.provisionUsersHeader!.status = header.status

      if (this.provisionUsersHeader!.failedCount === 0) {
        this.operationComplete(this.provisionUsersHeader)
      } else {
        this.operationFailed(this.provisionUsersHeader)
      }
    }
  }
}
