import { RecoilState, useRecoilState, SetterOrUpdater } from 'recoil'

/** These are created for use with SignalR. They may be used in any place where Recoil hooks would
 *   normally be used, but in their use, would cause excessive updates through useEffects, or something
 *   like that.
 * 
 *  In the case of SignalR, it's necessary to register event handlers for SignalR, and if hooks were used
 *   it would require the handlers to be unnecessarily re-registered every time a Recoil state value was changed.
 */

/** Provides a reference object that represents a recoil state, which 
 *   can be used to access the recoil state, without requiring React components
 *   to update in order to have access to these values. */
export class RecoilPinner<T>{
    constructor(public readonly atom: RecoilState<T>, set: SetterOrUpdater<T>, value: T) {
        this.value = value
        this.set = set
    }

    /** The value of the recoil state.  When used with useRecoilPinner, this value
     *   is maintained with the current recoil value when changes occur. 
     *  NOTE: This does NOT update immediately after a call to set is made.  It will
     *  update using standard React hook mechanics. */
    value: T

    /** Setter function to set the state. */
    set: SetterOrUpdater<T>
}

/** A constant set of pinners for react state values that are maintained
 *   and reused from calls to useRecoilPinner. */
const pinners = new Array<RecoilPinner<any>>()

/** Returns a reusable instance of a Recoil State object, which will
 *   have its values maintained through hook mechanics.  The return value
 *   may be used to get/set Recoil state values without the need for
 *   definitions based on those values to be re-evaluated on every state change. */
export function useRecoilPinner<T>(atom: RecoilState<T>): RecoilPinner<T> {
    const [val, setter] = useRecoilState(atom)

    // Get the current pinner, if there is one.
    let pinner = pinners.find(p => p.atom === atom)

    // Create one, if it doesn't exist, and add it to the list.
    if (!pinner) {
        pinner = new RecoilPinner(atom, setter, val)
        pinners.push(pinner)
    }

    // Update the pinners values with the recoil values.
    pinner.value = val
    pinner.set = setter

    // Return the pinner.
    return pinner
}