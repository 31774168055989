import { entityClientApi } from '../../api-client/investor-portal-client-runtime'
import { uploadFileToAzure } from './azure-upload'

/** Uploads a new logo file to the API. */
export const uploadLogo = async (
  f: File,
  entityGroupId: number,
  progress: (x: number) => void
) => {

  // Exit if there's no file.
  if (!f) {
    return
  }

  // Get the SAS Uri, and let the API know that we're about to upload
  //  a file, so it can add the information to the database, and such.
  const result = await entityClientApi.logo_PrepareUpload(f.name)

  // Upload the file directly to Azure, using the SAS Uri.
  await uploadFileToAzure(result.uploadSasUri!, f, (p) => {
    progress(p)
  })

  // Notify the API that the upload is complete, so it can process the new file.
  await entityClientApi.logo_AddOrUpdateEntityGroupLogo(entityGroupId, result)

  return result;
}