import { signalR } from '../signalr-runtime'
import { SignalREventHandler } from '../signalr-models'
import { EmailStatusTypes } from '../../api-client/investor-portal-client'
import { useEffect } from 'react'

type UseInvestorEmailSubscriptionCallback = (
  investorId: number,
  statusType: EmailStatusTypes,
  emailType: 'Initial' | 'Reminder'
) => void
export const useSendEmailNotificationHandler = (
  generalOptionsId: number | undefined,
  emailUpdateCallback: UseInvestorEmailSubscriptionCallback
) => {
  useEffect(() => {
    if (!generalOptionsId) {
      return
    }

    /** Subscribes to updates for emails to a specified StateManager GeneralOptions on the server. */
    signalR.subscribeToGeneralOptionsEmailUpdates(generalOptionsId)

    // The handler to be registered.
    const handler: SignalREventHandler = {
      eventName: 'StateManagerEmailStatusUpdated',
      action: emailUpdateCallback,
    }

    signalR.registerHandler(handler)

    return () => {
      signalR.unSubscribeFromGeneralOptionsEmailUpdates(generalOptionsId)
      signalR.unregisterHandler(handler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalOptionsId, emailUpdateCallback])
}
