import { setApiWrapperName } from "./api-state-management"

/** Wraps the functions of an object, typically a hook with API functions, with a handler 
 *   to catch AbortErrors, and return undefined. */
export function wrapApiWithCommonErrorHandlers<T extends object>(apiFunctions: T): T {
    // Recast the functions so TypeScript won't complain when we iterate.
    const iteratorCast = apiFunctions as { [n in keyof T]: T[n] }

    // Again... Appease TypeScript, since we can't set the type in the for loop.
    let n: keyof T
    // Iterate through all functions in the API.
    for (n in iteratorCast) {
        // Get the current function of the API.
        const currentFunction = apiFunctions[n] as any

        // Make sure we actually have a function.
        if (typeof currentFunction === 'function') {
            // Update the wrapper name, so we can use the getApiCallId method with it.
            setApiWrapperName(currentFunction, n)

            // Create a wrapper function for this, so we can process
            //  the results first.
            const newFunction = async function (...args: any[]) {
                try {
                    // Call the function, and get the result.
                    const fnResult = currentFunction(...args)

                    // If this is a promise, which is probably is, then return the awaited result.
                    if (fnResult instanceof Promise) {
                        return await fnResult
                    }

                    // This isn't a promise - strange.  But who cares.  Return the result.
                    return fnResult

                } catch (err: any) {
                    // If we get an error, filter out the AbortErrors.
                    //  Most times, we are dismounting a component early, and
                    //  just don't want to continue our API call.
                    if (err?.name === 'AbortError' || (typeof err === 'string' && /^Abort:.*/i.test(err))) {
                        console.log(`${currentFunction.name} API call was aborted.`)
                        return Promise.resolve(undefined)
                    } else {
                        console.log(`API Error Occurred: ${currentFunction.name}: ${err}`)
                    }

                    // We don't know what the error was, so just rethrow it.
                    throw err
                }
            }

            setApiWrapperName(newFunction, n.toString() + '_wrapper')

            // Replace the old function definition with our new one.
            iteratorCast[n] = newFunction as any
        }
    }

    // Return the functions.
    return iteratorCast as T
}