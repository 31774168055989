import { Link } from 'react-router-dom'
import rsm from '../../assets/img/icon/rsm-logo.png'
import { getTermsOfUseRoute } from '../utility/route-creation'
const Footer = () => {
  return (
    <footer className='page-footer'>
      <div className='page-footer_meta'>
        <p>
          &copy; {new Date().getFullYear()} RSM US LLP. All rights reserved.
        </p>
        <ul className='cui-c_list cui-c_list-inline'>
          <li>
            <Link to={getTermsOfUseRoute()}>Terms of Use</Link>
            {/* <a href='/'>Terms of Use</a> */}
          </li>
          <li>
            <a
              href='https://rsmus.com/pages/rsm-us-privacy-policy.html'
              target='_blank'
              rel='noreferrer'
            >
              Privacy
            </a>
          </li>
          <li>
            <a
              href='https://rsmus.com/contact.html'
              target='_blank'
              rel='noreferrer'
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href='https://rsmus.com/pages/cookie-policy.html'
              target='_blank'
              rel='noreferrer'
            >
              Cookies
            </a>
          </li>
        </ul>
      </div>
      <div className='page-footer_vid'>
        <img className='cui-c_app-logo' src={rsm} alt='RSM' />
      </div>
    </footer>
  )
}

export default Footer
