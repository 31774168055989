import { useRecoilValue } from 'recoil'
import { errorAtom } from '../state/atom'
import { useEffect } from 'react'

/** Error Handler - When we throw error in any of the API call (ie., promise) 
 * errors are not caught in the ErrorBoundary. So we have created a recoil state to
 * set the error message, when there's any error, this hook will throw the error.
 * ErrorBoundary will handle and display the error message using the ErrorFallback (ErrorFallback.tsx) component.
*/
export default function useErrorHandler() {
  const error = useRecoilValue(errorAtom)

  /** If there's any error then trhoe the error, 
   * it will be caught in the ErrorBoundary & display error message to the user */
  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])
}
