import { OidcIdentityContext } from '@rsmus/react-auth'
import React, { ReactNode, useEffect } from 'react'
import { useUserApi } from '../../hooks/use-user-api'
import { isAccessTokenExpired } from '../utility/parse-token'
import { useSetRecoilState } from 'recoil'
import { errorAtom, userProfileFromIdmAtom } from '../../state/atom'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import produce from 'immer'

type IsAuthenticatedProps = {
  children: ReactNode
}

export const IsAuthenticated = ({ children }: IsAuthenticatedProps) => {
  const auth = React.useContext(OidcIdentityContext)
  const userApi = useUserApi()
  const setError = useSetRecoilState(errorAtom)
  const setUserProfile = useSetRecoilState(userProfileFromIdmAtom)

  /** Validate session expired or not */
  const validateAccessTokenExpiration = async () => {
    // If token expired then call login function in the auth component
    if (await isAccessTokenExpired()) {
      auth.login()
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (auth.user) {
      // Add or Update user profile information
      userApi
        .addUpdateUserProfile(auth.user!, abortController.signal)
        .then((response)=>{
          setUserProfile((userProfile) => 
             produce(userProfile, (draft) => {
              draft.userProfileResult = response
            })
          )
        })
        .catch((err) => {
          validateAccessTokenExpiration()
          if (err.response) {
            setError(err.response)
          } else if (err.message) {
            setError(err.message)
          } else {
            setError(err.toString())
          }
        })
    }
    // Abort the API Call when user moves away from this page
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user])

  if (!auth.user) {
    return null
  }

  return <>{children}</>
}
