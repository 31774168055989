import { EffectCallback, useEffect } from "react"

/** useEffect that prevents the call from triggering a second time when called
 *   in development mode, due to the way React.StrictMode works.  This should probably only
 *   be used in places that make API calls.
 */
/** @obsolete This method is now obsolete.  Use useEffect in place, and ensure that all API calls get aborted in the cleanup function of the useEffect hooks. */
export function useEffectSingleCalls(userFunction: EffectCallback, depList: Array<any>) {
    // Call the useEffect function, like normal.
    return useEffect(() => {
        // Cleanup function to call from the input function.
        let dismountFunc: void | Function

        // Put the call on a timeout without a delay.  This will cause
        //  the call to be made at the next earliest time, but after
        //  all other calls are executed.
        let id: any = setTimeout(() => {
            // Clear the timeout.  If we got this far, we want
            //  the user function to fire, and there's no cleanup
            //  of the timeout necessary.
            id = null

            // Call the user function, and collect the
            //  dismount function, if there is one.
            dismountFunc = userFunction()
        })

        // Cleanup function.
        return () => {
            // If the timeout function executed, then id will be null.
            //  If it's NOT null, then stop the execution of the function.
            //  This would indicate that our component is being disposed
            //  of before it was ever shown.
            if (id !== null) {
                clearTimeout(id)
            } else if (dismountFunc) {
                // If the function provided a cleanup function of its own, then
                //  we only need to call it if the user function was called.  Otherwise
                //  there's nothing to undo.
                dismountFunc()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, depList)
}
