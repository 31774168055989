import React from 'react'
import { useEffect, useRef, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import { AppTitle } from './Iconography'
import { useRecoilValue } from 'recoil'
import { userProfileFromIdmAtom } from '../../state/atom'
import KeyIcon from '@mui/icons-material/Key'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { OidcIdentityContext } from '@rsmus/react-auth'
import {
  REACT_APP_CEM_APP_URL,
} from '../../envVariables'
import { userIsExternal } from '../utility/parse-token'
import './header.scss'
import {
  useNavigate,
  useNavigationAction,
} from '../../hooks/useNavigationGuard'
import { useUserRoles } from '../../hooks/use-user-roles'
import { useFileClientApi } from '../../hooks/use-file-api'

interface Props {
  appTitle: AppTitle
  appSwitcher: boolean
}

const Header = ({ appTitle, appSwitcher }: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0 })
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)
  const auth = React.useContext(OidcIdentityContext)
  const isExternal = userIsExternal(auth.user!)
  const userRoles = useUserRoles()
  const hasRSMAdmin = userRoles.hasRole('RSMAdmin')
  const navigateIfAllowed = useNavigationAction()
  const { downloadPubicFile } = useFileClientApi()

  useEffect(() => {
    setDimensions({
      width: ref.current ? ref.current.offsetWidth : 0,
    })
  }, [])
  const navigate = useNavigate()

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    event.preventDefault()
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  return (
    <>
      <header className='cui-header'>
        <div ref={ref} className='page-header_app'>
          {appTitle.logo ? (
            <div className='page-header_app-name page-header_app-name_client'>
              <img
                src={
                  require(`../../assets/clientLogo/${appTitle.logo}`).default
                }
                alt={appTitle.appTag!}
              />
            </div>
          ) : (
            <>
              <div className='page-header_app-name'>
                <span>
                  <b>{appTitle.app}</b>
                  {appTitle.xp}
                </span>
              </div>
            </>
          )}
        </div>

        <div className='page-header_meta'>
          <div className='page-header_meta-actions'>
            {/* SAVED FOR FUTURE USAGE */}
            {/* <a href='/'>
              <span className='material-icons-outlined'>notifications</span>
            </a>
            <a href='/' onClick={toggleDrawer('right', true)}>
              <span className='material-icons-outlined'>settings</span>
            </a> */}
          </div>

          {appSwitcher && hasRSMAdmin && (
            <div
              id='cui-app_switcher'
              className='cui-c_dropdown header_dropdown'
            >
              <span className='material-icons-round'>apps</span>
              <ul style={{ width: dimensions.width }} className='header-ul'>
                <li>
                  <a
                    href={REACT_APP_CEM_APP_URL}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='icon-alignment'>
                      <KeyIcon />
                    </span>
                    CEM
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div></div>
          <div
            id='cui-user_dropdown'
            className='cui-c_dropdown header_dropdown'
          >
            <span>
              {userProfileResult.firstName?.substring(0, 1)}
              {userProfileResult.lastName?.substring(0, 1)}
            </span>
            <ul className='header-ul'>
              <li>
                <div className='div-link' onClick={() => navigate('/help')}>
                  <span className='icon-alignment'>
                    <HelpOutlineIcon />
                  </span>
                  Help
                </div>
              </li>
              <li>
                <div
                  className='div-link'
                  onClick={() =>
                    downloadPubicFile(
                      isExternal
                        ? 'RSMInvestorPortal-UserGuide.pdf'
                        : 'RSMInvestorPortal-RSMUserGuide.pdf'
                    )
                  }
                >
                  <span className='icon-alignment'>
                    <ArticleOutlinedIcon />
                  </span>
                  Investor Portal User Guide
                </div>
              </li>
              <li>
                <div
                  className='div-link'
                  onClick={() => navigateIfAllowed(() => auth.logout())}
                >
                  <span className='icon-alignment'>
                    <LogoutOutlinedIcon />
                  </span>
                  Log out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        title='hello'
      >
        <div style={{ width: '400px' }}>
          <h2>Settings</h2>
        </div>
      </Drawer>
    </>
  )
}

Header.defaultProps = {
  appTitle: {
    app: 'Platform',
    xp: 'UX',
    logo: null,
  },
  appSwitcher: true,
}

export default Header
