import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob'

export async function uploadFileToAzure(sasUri: string, file: File, progress: (percent: number) => void) {
    const blobClient = new BlockBlobClient(sasUri, new AnonymousCredential())

    await blobClient.uploadData(file, {
        onProgress(progressVal) {
            progress(progressVal.loadedBytes)
        },
    })
}