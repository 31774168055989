import { View, Text } from '@react-pdf/renderer'
import { styles } from './pdf-utils'

interface PdfGeneralInfoProps {
  entityType: string
  stateName: string
}

/** General election info  */
export const PdfGeneralInfo = ({ entityType, stateName }: PdfGeneralInfoProps) => (
  <View style={styles.table}>
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>General</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableLeftCol}>
        <Text
          style={[styles.tableCell, { display: 'flex', alignSelf: 'auto' }]}
        >
          Entity Type
        </Text>
      </View>
      <View style={styles.tableRightCol}>
        <Text style={styles.tableCell}>{entityType}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableLeftCol}>
        <Text style={styles.tableCell}>State of Residency</Text>
      </View>
      <View style={styles.tableRightCol}>
        <Text style={styles.tableCell}>{stateName}</Text>
      </View>
    </View>
  </View>
)
