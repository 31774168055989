import { TagBox } from 'devextreme-react'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { SelectBoxOptions } from '../../../types/types'
import { useEffect, useState } from 'react'
import { SelectionChangedEvent } from 'devextreme/ui/tag_box'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

interface AllocatingEntityProps {
  groupId: number
  selectedValue?: number[]
  placeholderText: string
  updateValues: (newItems: number[], removedItems: number[]) => void
  notifyError: (errorMessage: string) => void
}

/** Allocating Entities Component */
export const AllocatingEntities = ({
  groupId,
  selectedValue,
  placeholderText,
  updateValues,
  notifyError,
}: AllocatingEntityProps) => {
  const entityManagerApi = useEntityClientApi()
  const [entities, setEntities] = useState<SelectBoxOptions<number>[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    /** Make API call to get allocating entities based on the entity group id */
    const getAllocatingEntities = async () => {
      try {
        // Get allocating entities
        const response = await entityManagerApi.getAllocatingEntities(
          [groupId],
          abortController.signal
        )

        // Check response from API
        if (response) {
          // Create SelectBoxOptions list from the API response
          const entityData: SelectBoxOptions<number>[] = response
            .map((y) => {
              return {
                label: y.name!,
                value: y.parentEntityId!,
              }
            })
            .sort((a, b) => (a.label < b.label ? -1 : 1))

          // set allocating entity list in state
          setEntities(entityData)
        }
      } catch (err: any) {
        notifyError(err.message)
      }
    }
    // Get allocating entities by group id
    getAllocatingEntities()

    return () => {
      // Abort any API calls currently in flight.
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, notifyError])

  /** Add or Remove the allocating entity from the state when user click on remove icon */
  const onSelectionChanged = (e: SelectionChangedEvent) => {
    // Call update value to add new items & delete remove items from the parent
    updateValues(
      e.addedItems?.map((r) => r.value),
      e.removedItems?.map((r) => r.value)
    )
  }

  return (
    <TagBox
      dataSource={entities}
      valueExpr='value'
      displayExpr='label'
      multiline={true}
      value={selectedValue}
      onSelectionChanged={onSelectionChanged}
      placeholder={placeholderText}
    />
  )
}
