export type PermissionTypes = 'any' | 'legal-entity' | 'client'
export type PermissionInfo = { name: string, type: string }

export const ADMIN = { name: 'ADMIN', type: 'any' } as PermissionInfo
export const ADMIN_EDIT_CLIENTDETAILS = { name: 'ADMIN_EDIT_CLIENTDETAILS', type: 'any' } as PermissionInfo
export const ADMIN_EDIT_CLIENTIDENTITY = { name: 'ADMIN_EDIT_CLIENTIDENTITY', type: 'any' } as PermissionInfo
export const ADMIN_PROVISION_INVESTOR = { name: 'ADMIN_PROVISION_INVESTOR', type: 'any' } as PermissionInfo
export const CEM_EDIT_PROFILE = { name: 'CEM_EDIT_PROFILE', type: 'any' } as PermissionInfo
export const CLIENT_ADD_NEW = { name: 'CLIENT_ADD_NEW', type: 'any' } as PermissionInfo
export const DOC_VISIBILITY_ADMIN = { name: 'DOC_VISIBILITY_ADMIN', type: 'any' } as PermissionInfo
export const DOC_VISIBILITY_INVESTOR = { name: 'DOC_VISIBILITY_INVESTOR', type: 'any' } as PermissionInfo
export const INTERNAL = { name: 'INTERNAL', type: 'any' } as PermissionInfo
export const INVESTOR = { name: 'INVESTOR', type: 'any' } as PermissionInfo
export const MENU_VISIBILITY_CEM = { name: 'MENU_VISIBILITY_CEM', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_ADMINLANDING = { name: 'PAGE_VISIBILITY_ADMINLANDING', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_APPMENU = { name: 'PAGE_VISIBILITY_APPMENU', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_APPSETTINGS = { name: 'PAGE_VISIBILITY_APPSETTINGS', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_CLIENTSADMIN = { name: 'PAGE_VISIBILITY_CLIENTSADMIN', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_HELP_EXTERNAL = { name: 'PAGE_VISIBILITY_HELP_EXTERNAL', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_HELP_INTERNAL = { name: 'PAGE_VISIBILITY_HELP_INTERNAL', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_MANAGEXISTING = { name: 'PAGE_VISIBILITY_MANAGEXISTING', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_PROVISION = { name: 'PAGE_VISIBILITY_PROVISION', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_RULESETADMIN = { name: 'PAGE_VISIBILITY_RULESETADMIN', type: 'any' } as PermissionInfo
export const PAGE_VISIBILITY_VERIFY = { name: 'PAGE_VISIBILITY_VERIFY', type: 'any' } as PermissionInfo
export const SUBMIT_ACTION_VERIFY = { name: 'SUBMIT_ACTION_VERIFY', type: 'any' } as PermissionInfo
export const SYSTEM_VISIBILITY_DBUNFILTERED = { name: 'SYSTEM_VISIBILITY_DBUNFILTERED', type: 'any' } as PermissionInfo
export const CLIENT_EDIT_CLIENTDETAILS = { name: 'CLIENT_EDIT_CLIENTDETAILS', type: 'client' } as PermissionInfo
export const CLIENTSTATUS_EDIT_CLIENTEMAILTEMPLATE = { name: 'CLIENTSTATUS_EDIT_CLIENTEMAILTEMPLATE', type: 'client' } as PermissionInfo
export const ENTITY_ADD_ALLOCATING = { name: 'ENTITY_ADD_ALLOCATING', type: 'client' } as PermissionInfo
export const ENTITY_ADD_INVESTOR = { name: 'ENTITY_ADD_INVESTOR', type: 'client' } as PermissionInfo
export const ENTITY_DELETE_ALLOCATING = { name: 'ENTITY_DELETE_ALLOCATING', type: 'client' } as PermissionInfo
export const ENTITY_DELETE_INVESTOR = { name: 'ENTITY_DELETE_INVESTOR', type: 'client' } as PermissionInfo
export const ENTITY_EDIT_ALLOCATING = { name: 'ENTITY_EDIT_ALLOCATING', type: 'client' } as PermissionInfo
export const ENTITY_EDIT_INVESTOR = { name: 'ENTITY_EDIT_INVESTOR', type: 'client' } as PermissionInfo
export const ENTITY_REPORT_ACTIVITY = { name: 'ENTITY_REPORT_ACTIVITY', type: 'client' } as PermissionInfo
export const ENTITY_REPORT_BLANK = { name: 'ENTITY_REPORT_BLANK', type: 'client' } as PermissionInfo
export const ENTITY_REPORT_POPULATED = { name: 'ENTITY_REPORT_POPULATED', type: 'client' } as PermissionInfo
export const ENTITY_UPLOAD_ALLOCATING = { name: 'ENTITY_UPLOAD_ALLOCATING', type: 'client' } as PermissionInfo
export const ENTITY_UPLOAD_INVESTOR = { name: 'ENTITY_UPLOAD_INVESTOR', type: 'client' } as PermissionInfo
export const PAGE_VISIBILITY_CLIENTSTATUS = { name: 'PAGE_VISIBILITY_CLIENTSTATUS', type: 'client' } as PermissionInfo
export const PAGE_VISIBILITY_CLIENTVERIFY = { name: 'PAGE_VISIBILITY_CLIENTVERIFY', type: 'client' } as PermissionInfo
export const PAGE_VISIBILITY_ENTITYMAN = { name: 'PAGE_VISIBILITY_ENTITYMAN', type: 'client' } as PermissionInfo
export const PAGE_VISIBILITY_STATEMAN = { name: 'PAGE_VISIBILITY_STATEMAN', type: 'client' } as PermissionInfo
export const PROVISION_ACTION_INVESTOR = { name: 'PROVISION_ACTION_INVESTOR', type: 'client' } as PermissionInfo
export const USER_PROVISION_INVESTOR = { name: 'USER_PROVISION_INVESTOR', type: 'client' } as PermissionInfo
export const COMPOSITEDOCS_ADD_NEW = { name: 'COMPOSITEDOCS_ADD_NEW', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_DELETE_PACKAGE = { name: 'COMPOSITEDOCS_DELETE_PACKAGE', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_DOWNLOAD_MAPPING = { name: 'COMPOSITEDOCS_DOWNLOAD_MAPPING', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_DOWNLOAD_PACKAGE = { name: 'COMPOSITEDOCS_DOWNLOAD_PACKAGE', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_EDIT_ENTITYEMAILTEMPLATE = { name: 'COMPOSITEDOCS_EDIT_ENTITYEMAILTEMPLATE', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_EDIT_PACKAGE = { name: 'COMPOSITEDOCS_EDIT_PACKAGE', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_PUBLISH = { name: 'COMPOSITEDOCS_PUBLISH', type: 'legal-entity' } as PermissionInfo
export const COMPOSITEDOCS_RELINK = { name: 'COMPOSITEDOCS_RELINK', type: 'legal-entity' } as PermissionInfo
export const INVESTORDOCS_DELETE = { name: 'INVESTORDOCS_DELETE', type: 'legal-entity' } as PermissionInfo
export const INVESTORDOCS_DOWNLOAD = { name: 'INVESTORDOCS_DOWNLOAD', type: 'legal-entity' } as PermissionInfo
export const INVESTORDOCS_RESEND_EMAIL = { name: 'INVESTORDOCS_RESEND_EMAIL', type: 'legal-entity' } as PermissionInfo
export const INVESTORDOCS_SEND_EMAIL = { name: 'INVESTORDOCS_SEND_EMAIL', type: 'legal-entity' } as PermissionInfo
export const PAGE_VISIBILITY_COMPOSITEDOCS = { name: 'PAGE_VISIBILITY_COMPOSITEDOCS', type: 'legal-entity' } as PermissionInfo
export const PAGE_VISIBILITY_DOCS = { name: 'PAGE_VISIBILITY_DOCS', type: 'legal-entity' } as PermissionInfo
