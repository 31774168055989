import { SignalRWatcher } from './signalr-watcher'
import { ReportCompleteWatcher } from './report-complete-watcher'
/** report the operation error back .*/
export class OperationErrorWatcher extends SignalRWatcher {
    constructor(
        apiPromise: Promise<any>,
        private readonly uniqueOperationId: number
    ) {
        super('OperationError', apiPromise)
        this.handlerAction = this.handlerAction.bind(this)
    }
    reportWatcher?: ReportCompleteWatcher

    //calling the operation complete
    operationCompleted() {
        this.operationComplete()
    }

    /** SignalR handler to process the received in the messages. */
    handlerAction(operationId: number, operationType: string) {
        let errorMessage: string = ''
        if (this.uniqueOperationId !== operationId) {
            return
        }

        //Setting up the operation type errors 
        const operationTypeError: { [e: string]: string } =
        {
            InvestorActivityReport: 'Investor Activity Report.',
            BulkEntityTemplate_v3: 'Bulk Entity Spreadsheet.',
            ZipUploadTemplate: 'Zip Upload Template.',
            ClientReportTemplate: 'Client Report Template.',
            PartnerSightReportTemplate: 'Partner Sight Report Template.',
            DemographicReportTemplate: 'Demographic Report Template',
            ZipUploadVerificationTemplate: 'Zip Upload Verification Template'
        }

        //checking the operation type and setting custom error message
        Object.keys(operationTypeError).includes(operationType)
            ? errorMessage = 'An error occurred while generating ' + operationTypeError[operationType]
            : console.error(`Unrecognized operation type: ${operationType}`)

        this.reportWatcher!.reportCompleteOperationFailed(errorMessage)
        this.operationComplete()
    }
}
