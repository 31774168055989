import { createContext, useEffect, useState } from 'react'
import { PermissionSet } from '../api-client/entity-manager-client-v3'
import { isAccessTokenExpired } from '../components/utility/parse-token'
import { getErrorPageRoute } from '../components/utility/route-creation'
import { useEntityClientApi } from '../hooks/use-entity-api'
import { useNavigate } from '../hooks/useNavigationGuard'

export interface AccessProviderProps {
  children: React.ReactNode
}

export const AccessManagerContext = createContext<Record<
  string,
  PermissionSet[]
> | null>(null)

export const AccessProvider: React.FC<AccessProviderProps> = ({ children }) => {
  const clientApi = useEntityClientApi()
  const navigate = useNavigate()
  const [permissionData, setPermissionData] = useState<Record<
    string,
    PermissionSet[]
  > | null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    /** This method must keep checking that we have a valid auth token, and update the
     *   permission data only when we have the auth token. */
    const updatePermissionDataLocally = async () => {
      // Ensure we have a valid access token, otherwise we'll probably
      //  get a 401 from the server.
      if (await isAccessTokenExpired()) {
        // If aborted, then we need to just exit here.
        if (abortController.signal.aborted) {
          navigate(getErrorPageRoute())
          return
        }

        // Wait a few seconds and try again.
        setTimeout(updatePermissionDataLocally, 3000)
        return
      }

      // Get the permissions for the current user.
      clientApi
        .updatePermissionData(abortController.signal)
        .then((data) => {
          // Set the data on the context.
          setPermissionData(data as Record<string, PermissionSet[]>)
        })
        .catch((err) => {
          navigate(getErrorPageRoute())
        })
    }

    updatePermissionDataLocally()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <AccessManagerContext.Provider value={permissionData}>
      {permissionData ? children : null}
    </AccessManagerContext.Provider>
  )
}
