import { atom } from 'recoil'
import { PropsWithChildren } from 'react'
import { FormattedDialogProps } from '../formattedDialog'
import { ButtonFunction } from '../modalBase'

export type CommonDialogButtonFunction = ButtonFunction & {
    /** Boolean value indicating whether or not this button closes the modal. */
    isCloseAction?: boolean
}

export type CommonDialogProps = PropsWithChildren<Omit<FormattedDialogProps, 'buttonFunctions'>
    & {
        buttonFunctions?: CommonDialogButtonFunction[],
        /** Random number assigned to dialog upon creation for unique identification. */
        id: number,
        /** Boolean value indicating whether or not the default colors are assigned to "close" buttons. */
        setDefaultColors?: boolean
    }>

export const commonDialogs = atom<Array<CommonDialogProps>>({
    key: 'COMMON_DIALOG_PROPS',
    default: []
})