import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { navWidgetState } from './nav-widget-state'
import { useNavWidgetUtilities } from './nav-hooks'
import { NavWidgetOption } from './nav-widget-models'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import './navWidget.scss'
import { NavChip } from './navChip'

export const NavWidget = () => {
  const location = useLocation()
  const [currentNavItems, setCurrentNavItems] = useState<NavWidgetOption[]>([])
  const navStateUtilities = useNavWidgetUtilities()
  const [widgetItemLevels, setWidgetItemLevels] = useState<NavWidgetOption[][]>(
    []
  )
  const navWidgetStateVal = useRecoilValue(navWidgetState)

  // If the location changes, then we need to update the widget.
  useEffect(() => {
    setCurrentNavItems(navStateUtilities.generateNavWidgetStateForLocation())

    // Get the selectable options for the widgets, for our current location.
    const options = navStateUtilities.groupNavOptionsByLevel()
    setWidgetItemLevels(options.asArray)
  }, [location, navWidgetStateVal])

  return (
    <div className='nav-widget'>
      <Breadcrumbs>
        {currentNavItems.map((c, i) => (
          <NavChip
            key={`item-${i}`}
            label={c.label}
            navLocation={c.navigationPath}
            items={widgetItemLevels[i].map((x) => ({
              text: x.label,
              data: x.navigationPath,
            }))}
          ></NavChip>
        ))}
      </Breadcrumbs>
    </div>
  )
}
