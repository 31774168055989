
/** Returns a string representation of a date in the form of "1/20/2020" */
export const getShortDateString = (date?: Date, undefinedValue: string = ''): string => {
    if (!date) {
        return undefinedValue
    } 
    // some time browser behaving weared and returning the date as string then all the date functions are failing so 
    // checking the type and and converting it to date type
    else if (typeof(date) === 'string') { 
        date = new Date(date)
    }
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

/** Convert date from any timezone to UTC */
export const convertDateToUTC = (date: Date): Date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

/** default date in database*/
export const defaultDate = new Date('0001-01-01T00:00:00Z');