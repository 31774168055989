import { useClientGroupStatusNavWidget } from './useClientGroupStatusNavWidget'
import { useClientsNavWidget } from './useClientsNavWidget'
import { useParentFilesNavWidget } from './useParentFilesNavWidget'
import { usePartnershipsNavWidget } from './usePartnershipsNavWidget'
import { useStateManagerNavWidget } from './useStateManagerNavWidget'
import { useEntityManagerNavWidget } from './useEntityManagerNavWidget'

/** Consolidates all dynamic nav widget calls in one place. */
export const useDynamicNavWidgetItems = () => {
  useClientGroupStatusNavWidget() 
  useEntityManagerNavWidget()
  useStateManagerNavWidget()
  usePartnershipsNavWidget()
  useClientsNavWidget()
  useParentFilesNavWidget()
}
