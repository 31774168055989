import { useSetRecoilState } from "recoil"
import produce from "immer"
import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import { investorRelationsAtom } from "../state/atom"
import { useEffect } from "react"
import { DISMOUNT_ABORT_REASON } from "../components/utility/abort-constants"
import { usePermissions } from "../access-manager/use-permission"
import { INVESTOR, PermissionInfo } from "../model/permissions"
import { permissionRequestAny } from "../access-manager/permission-request-creation"

export const useInvestorRelation = () => {
    const setInvestorDetails = useSetRecoilState(investorRelationsAtom)
    const isInvestor = usePermissions([permissionRequestAny(INVESTOR)])

    useEffect(() => {
        const abortController = new AbortController()

        /** Get Investor Files based on the email for investor role */
        if (isInvestor) {
            setInvestorDetails(
                produce((draft) => {
                    draft.investorEntities = {}
                    draft.hasLoaded = false
                })
            )
            // Load investor Files based on the investor email
            filesClientApi.investor_GetInvestorRelationships(abortController.signal).then((response) => {
                setInvestorDetails(
                    produce((draft) => {
                        draft.investorEntities = response
                        draft.hasLoaded = true
                    })
                )
            }).catch((err) => {
                setInvestorDetails(produce((draft) => {
                    draft.investorEntities = {}
                    draft.hasLoaded = true
                }))
                throw new Error('Error occurred investor_GetInvestorRelationships ' + err)
            })
        }

        return () => {
            abortController.abort(DISMOUNT_ABORT_REASON)
        }
    }, [isInvestor])
}
