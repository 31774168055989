import { useEffect, useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../../modal/modalBase'
import { Button, DataGrid, SelectBox, TextBox } from 'devextreme-react'
import { Grid } from '@mui/material'
import { useEntityClientApi } from '../../../hooks/use-entity-api'
import { SpinnerModal } from '../../modal/spinnerModal'
import { Column, Paging, Scrolling } from 'devextreme-react/data-grid'
import { ICellData } from '../../../model/file-model'
import { useCommonDialogs } from '../../modal/commonDialog/common-dialog-operations'
import { UserEntityGroupViewModel } from '../../../api-client/entity-manager-client-v3'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import './client-group-user-access.scss'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

export interface ClientGroupUserAccessProps {
  clientGroupId: number
  clientName: string
  onModalClose: (isUpdated?: boolean) => void
}

export const ClientGroupUserAccess = ({
  clientGroupId,
  clientName,
  onModalClose,
}: ClientGroupUserAccessProps) => {
  const entityApi = useEntityClientApi()
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modelMessageInfo, setModelMessageInfo] = useState({
    inProgressTitle: '',
    inProgressMessage: '',
    errorTitle: '',
    errorMessage: '',
  })
  const selectBoxRef = useRef<SelectBox>(null)
  const dataGridRef = useRef<DataGrid>(null)
  const commonDialog = useCommonDialogs()
  const [commonPromise, setCommonPromise] = useState<Promise<any>>()
  const [showSelectBox, setShowSelectBox] = useState(false)
  const [userProfiles, setUserProfiles] = useState<UserEntityGroupViewModel[]>(
    []
  )
  const [selectedUserProfile, setSelectedUserProfile] = useState<
    UserEntityGroupViewModel | undefined
  >(undefined)

  /** custom store */
  const dataStore = useMemo(() => {
    // Abort controller so that subsequent loads can cancel
    //  previous calls to load.
    let abortController: AbortController | undefined

    // Store for the custom store... somehow, we have to implement the byKey function WITHOUT THE DATA?!!?!?
    let storeData: UserEntityGroupViewModel[] | undefined

    /** Loads the investor selection data from the server. */
    const loadUser = async (options: LoadOptions<UserEntityGroupViewModel>) => {
      // Abort the previous call, if we have a controller.
      abortController?.abort(DISMOUNT_ABORT_REASON)

      // Create a new abort controller so we can abort, if needed.
      abortController = new AbortController()

      // Get the filter value.
      const searchString = options.searchValue?.toString()

      if (!searchString) {
        return []
      }

      // We must have at least 3 characters to perform our search.
      if (searchString.length < 3) {
        return []
      }
      const responseData =
        await entityApi.searchUserByEntityGroupIdAndSearchExpr(
          clientGroupId,
          searchString,
          abortController.signal
        )

      // Filtering already added users to avoid duplicate entry
      const filteredUsers = responseData.filter(
        (e) =>
          !!dataGridRef.current?.instance
            ?.getDataSource()
            .items()
            .every((x) => x.userId !== e.userId)
      )

      storeData = filteredUsers
      return filteredUsers
    }

    return new CustomStore({
      key: 'userId',
      load: loadUser,
      byKey: (key: string) => {
        // This is required by the SelectBox, and can't be omitted, so we
        //  are tricking it with the last data set from the API, and
        //  returning whatever value it's asking for.
        return Promise.resolve(storeData?.find((x) => x.userId === key))
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientGroupId])

  /** Get the user profile by entity id to sow in datagrid */
  const getUserProfilesByEntityGroupId = async (
    clientGroupId: number,
    signal: AbortSignal
  ) => {
    setIsLoading(true)
    if (clientGroupId > 0) {
      const response = await entityApi.getUserProfilesByEntityGroupId(
        clientGroupId,
        signal
      )
      setIsLoading(false)
      setUserProfiles(response!)
    }
  }

  /**Get the User Profile from API added based on ClientGroupId */
  useEffect(() => {
    const abortController = new AbortController()

    getUserProfilesByEntityGroupId(clientGroupId, abortController.signal)
    return () => {
      // Abort any API calls currently in flight.
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientGroupId])

  /** Format display for dropdown item*/
  const userProfileOption = (data: UserEntityGroupViewModel) => {
    // The following styling is necessary, because CSS is not working
    //  with the dropdown control.
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ flex: '1 1 auto' }}>
          {`${data.lastName}, ${data.firstName}`}
        </div>

        <div>{data.userId}</div>
      </div>
    )
  }

  /** Display value Input Field when item in dropdown is selected*/
  const searchTextBox = useMemo(() => {
    let selectedUser: string | undefined = undefined
    if (selectedUserProfile) {
      selectedUser = `${selectedUserProfile.lastName}, ${
        selectedUserProfile.firstName
      } ${selectedUserProfile && selectedUserProfile.userId}`
    }

    // When the user gives focus to the search box, we want to ACTUALLY give focus
    //  to the SelectBox.  We do this because the SelectBox still has a value
    //  inside of it, and the TextBox allows us to retain our selection while
    //  removing the SelectBox, and giving the user the ability to start with
    //  a clean search.
    const onTbFocused = () => {
      setShowSelectBox(true)
      // At the time we tell React to show the SelectBox, it does not exist.  Add
      //  The call to set the SelectBox's focus after all events are complete, so
      //  the user can type into the search.
      setTimeout(() => {
        selectBoxRef.current?.instance.focus()
      }, 0)
    }

    return (
      <div className='div-select-box'>
        <TextBox
          value={selectedUser}
          className='select-user-profile'
          onFocusIn={onTbFocused}
        />
      </div>
    )
  }, [selectedUserProfile])

  /** Show the confirmation dialog to confirm delete, if yes then delete user profile from entity group */
  const deleteUserProfileFromClient = (userEntityGroupId: number) => {
    commonDialog.showDialog({
      dialogType: 'general',
      title: 'Deleting User',
      content:
        'Are you sure you would like to remove user access to this client?',
      buttonFunctions: [
        {
          label: 'Cancel',
          onClick: () => {},
          isCloseAction: true,
          buttonProps: {
            stylingMode: 'contained',
            type: 'normal',
            width: 100,
            'data-testid': 'cancel-button',
          },
        },
        {
          label: 'Proceed ',
          onClick: async () => {
            setShowApiSpinner(true)
            setModelMessageInfo({
              inProgressTitle: 'Delete user in-process',
              inProgressMessage: 'Deleting user from entity group',
              errorTitle: 'Error',
              errorMessage: 'Error deleting user from entity group',
            })

            const deleteUserPromise =
              entityApi.deleteUserProfileEntityGroup(userEntityGroupId)
            setCommonPromise(deleteUserPromise)

            await deleteUserPromise.then(() => {
              const index = userProfiles.findIndex(
                (d) => d.userEntityGroupId === userEntityGroupId
              )
              if (index >= 0) {
                userProfiles.splice(index, 1)
              }
              setUserProfiles(userProfiles)
              dataGridRef.current?.instance.refresh()
            })
          },
          isCloseAction: true,
          buttonProps: {
            useSubmitBehavior: false,
            stylingMode: 'contained',
            type: 'default',
            width: 160,
            'data-testid': 'proceed-close-button',
          },
        },
      ],
      omitDefaultButton: true,
    })
  }

  /** Set delete action for User Profile Record  */
  const setDeleteButton = (cellData: ICellData<UserEntityGroupViewModel>) => {
    const userEntityGroupId = cellData.data!.userEntityGroupId!

    return (
      <>
        <Button
          icon='trash'
          onClick={() => deleteUserProfileFromClient(userEntityGroupId)}
        ></Button>
      </>
    )
  }

  /** Model button */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Close',
      onClick: () => onModalClose(),
      isDefaultAction: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'close-button',
      },
    },
  ]

  /** Set User Name column data as 'lastName, firstName' */
  const setUserName = (cellData: ICellData<UserEntityGroupViewModel>) => {
    return cellData.data?.lastName + ', ' + cellData.data?.firstName
  }

  // Save the selected user profile data
  const handleSave = async () => {
    setShowApiSpinner(true)
    setModelMessageInfo({
      inProgressTitle: 'Adding user in-process',
      inProgressMessage: 'Adding user to entity group',
      errorTitle: '',
      errorMessage: '',
    })
    selectedUserProfile!.entityGroupId = clientGroupId
    await entityApi.addUserProfileToEntityGroup(selectedUserProfile!)
    // Once user profile saved then call the backend and refresh the grid
    const response = await entityApi.getUserProfilesByEntityGroupId(
      clientGroupId
    )
    setUserProfiles(response!)
    dataGridRef.current?.instance.refresh()
    setShowApiSpinner(false)

    // Reset the selected user
    setSelectedUserProfile(undefined)
  }

  return (
    <Modal
      visible={true}
      title={clientName + ' - Users'}
      maxHeight={800}
      maxWidth={700}
      showCloseButtonInTitle={true}
      buttonFunctions={buttonFunctions}
      preventModalClose={false}
      disableScrollbar={false}
      className='client-group-user-access'
    >
      <span>
        To add new user to client, search by Name, email or employee number,
        select user, then click Add User button. Once done user has immediate
        access to Client
      </span>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={8}>
          <>
            {showSelectBox && (
              <SelectBox
                ref={selectBoxRef}
                dataSource={dataStore}
                displayExpr='userId'
                searchEnabled={true}
                showDropDownButton={false}
                showClearButton={false}
                searchMode={'contains'}
                searchExpr={'userId'}
                searchTimeout={500}
                minSearchLength={3}
                itemRender={userProfileOption}
                // See the searchTextBox function for info on why
                //  we are hiding and showing the SelectBox at different times.
                onFocusOut={() => {
                  setShowSelectBox(false)
                }}
                onOpenedChange={(value) => {
                  if (!value) {
                    setShowSelectBox(false)
                  }
                }}
                onSelectionChanged={(e) => {
                  setSelectedUserProfile(e.selectedItem)
                }}
              ></SelectBox>
            )}
            {!showSelectBox && searchTextBox}
          </>
        </Grid>
        <Grid item xs={4} className='add-button-grid'>
          <Button
            disabled={!selectedUserProfile}
            type='default'
            text='Add User'
            className='btn-add-user'
            onClick={(e) => {
              handleSave()
            }}
          />
        </Grid>
      </Grid>
      <div className='ip-table'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          noDataText={isLoading ? 'Loading...' : ''}
          dataSource={userProfiles}
          columnAutoWidth={true}
          height='calc(73vh - 152px)'
          repaintChangesOnly={true}
          remoteOperations={true}
          showColumnLines={false}
          showRowLines={true}
          wordWrapEnabled={false}
        >
          <Paging defaultPageSize={12} />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Column visible={false} dataField='userEntityGroupId'></Column>
          <Column
            caption='Name'
            dataField='lastName'
            dataType='string'
            width={'35%'}
            cellRender={setUserName}
            alignment='left'
            allowSorting={true}
            allowFiltering={false}
            defaultSortIndex={0}
            sortOrder='asc'
          ></Column>
          <Column
            caption='Email'
            dataField='email'
            dataType='string'
            width={'35%'}
            alignment='left'
            allowFiltering={false}
          ></Column>
          <Column
            caption='Employee ID'
            dataField='userId'
            dataType='string'
            width={'20%'}
            alignment='left'
            allowFiltering={false}
          ></Column>
          <Column
            cellRender={setDeleteButton}
            width={'10%'}
            alignment='left'
            allowFiltering={false}
          ></Column>
        </DataGrid>
      </div>
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={modelMessageInfo.errorTitle}
        errorMessage=''
        inProgressTitleMessage={modelMessageInfo.inProgressTitle}
        inProgressMessage={modelMessageInfo.inProgressMessage}
        successTitleMessage=''
        successMessage=''
        closeOnSuccess={true}
        hideCloseButton={true}
        onClose={(e) => {
          setShowApiSpinner(false)
        }}
        apiAction={commonPromise}
      />
    </Modal>
  )
}
