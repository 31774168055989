import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { clientAndPartnershipStateAtom } from '../../../state/atom'
import { getClientGroupPageRoute } from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { CLIENT_GROUP_MENU_LEVEL } from '../widget-levels'

export const useClientGroupStatusNavWidget = () => {
  const clientAndPartnership = useRecoilValue(clientAndPartnershipStateAtom)

  const navPath = useMemo(
    () =>
      clientAndPartnership.clientGroupId
        ? getClientGroupPageRoute(clientAndPartnership.clientGroupId)
        : undefined,
    [clientAndPartnership.clientGroupId]
  )

  const navItems = useMemo(
    () => [
      {
        label: 'Document Status',
        level: CLIENT_GROUP_MENU_LEVEL,
        visibilityPaths: '/admin/groups/:groupId/*',
        selectionPath: '/admin/groups/:groupId/*',
        navigationPath: navPath,
      },
    ],
    [navPath]
  )

  useNavWidgetPageItems(navItems)
}
