import { useRecoilValue } from 'recoil'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { investorRelationsAtom } from '../../state/atom'
import { useParams } from 'react-router-dom'

/** Banner for investor files view */
export const InvestorPageBanner = () => {
  const investorRelation = useRecoilValue(investorRelationsAtom)

  // Get partner Id & group Id from parameter
  const { partnerId, groupId } = useParams() as {
    groupId: string
    partnerId: string
  }

  /** Set banner header name based on Client Group & Invetsor selected*/
  const getHeaderContent = () => {
    let bannerText: string = 'All Documents'

    // If Group Id & Partner Id is available then set the investor name
    if (groupId && partnerId) {
      // Get Client group based on client group id
      const clientGroup = investorRelation.investorEntities.clientGroups?.find(
        (group) => group.id === parseInt(groupId)
      )

      // Get entity based on the entity id
      const entity = investorRelation.investorEntities.clientGroupEntities?.find(
        (e) => e.id === parseInt(partnerId)
      )
      // Set banner text
      bannerText = `${clientGroup?.name!} - ${entity?.name}`
    }
    return bannerText
  }
  return (
    <BannerContainer className='state-manager-banner'>
      <div className='cui-c_card align-banner'>
        <div className='banner-header-full-width'>
          <BannerHeader>{getHeaderContent()}</BannerHeader>
        </div>
      </div>
    </BannerContainer>
  )
}
