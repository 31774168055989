//Download report progress status
export enum ReportDownloadStatus {
  inProgress = 'in-progress',
  success = 'success',
  error = 'error'
}

// Download report progress message
export enum ReportDownloadMessage {
  inProgress = 'Report Building...',
  success = 'Report Successfully Downloaded',
  error = 'Report Download Error'
}

//Promise state and message to show actual state in in-progress model
export interface PromiseState {
  status: string
  message: string
}

// report type is to decide that is checkbox checked or not?
export interface ReportType {
  partnerSight: boolean
  clientFriendly: boolean
  investorDemographicChanges: boolean
  investorTable:boolean
  includeNotSubmitted: boolean
}

/** Creates a random number*/
export const generateUniqueOperationId = (): number => {
  return Math.floor(Math.random() * 100000)
}

/** Checkbox Change event */
export interface ICheckBoxEvent {
  value?: any
  previousValue?: any
  element: any
  event?: any
}