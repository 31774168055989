import { Button, SelectBox } from 'devextreme-react'
import './investorGeneralElectionInfo.scss'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { ForeignCountryEnum, taxClassificationTypes } from './investor-utils'
import { USState, usStatesData } from '../state-manager/state-manager-utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { smGeneralElectionInfoAtom } from '../../state/atom'
import { useRecoilState } from 'recoil'
import { InvestorElectionInfo } from '../../api-client/investor-portal-client'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { ApiSpinnerContent } from '../state-manager/global-election-settings'
import produce from 'immer'
import { useParams } from 'react-router-dom'
import { SpinnerModal } from '../modal/spinnerModal'
import { InvestorEntityList } from './investor-entity-list'
import SimpleTooltip from '../reuasble-components/simpleTooltip'

export interface InvestorGeneralElectionInfoProps {
  updateCanSave: (isEditMode: boolean) => void
  generalTabDirty: (isDirty: boolean) => void
  clearData: () => void
  setParentButtons: (buttons: React.ReactNode) => void
}
/**
 * in this Component we are achive the dropdown for tax classification and state of residency
 * and updating to the backend
 */
export const InvestorGeneralElectionInfo = ({
  updateCanSave,
  generalTabDirty,
  clearData,
  setParentButtons,
}: InvestorGeneralElectionInfoProps) => {
  const commonDialog = useCommonDialogs()
  const stateManagerApi = useStateManagerApi()
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [savePromise, setSavePromise] = useState<Promise<any>>()
  const [dirtyFlag, setDirtyFlag] = useState<boolean>(false)
  const [generalElectionInfo, setGeneralElectionInfo] = useRecoilState(
    smGeneralElectionInfoAtom
  )
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )

  const { groupId, partnerId, taxYear } = useParams() as {
    groupId: string
    partnerId: string
    taxYear: string
  }

  /**Intial data arrangement for the investor Election info
   *  Going to be utilised for investorElaction info AKA General tab
   */
  const [investorInfo, setInvestorInfo] = useState<InvestorElectionInfo>({
    entityGroupId: parseInt(groupId),
    investorId: parseInt(partnerId),
    taxYear: parseInt(taxYear),
    entityType:
      generalElectionInfo.electionsInfo?.entityType ??
      generalElectionInfo.entityInfo?.entityType,
    stateOfResidency:
      generalElectionInfo.electionsInfo?.stateOfResidency ??
      generalElectionInfo.entityInfo?.stateOfResidency,
  })

  // Check the null entityType or stateOfResidency
  const isUnsavedInvestorInfo =
    !generalElectionInfo?.electionsInfo?.entityType ||
    !generalElectionInfo?.electionsInfo?.stateOfResidency ||
    !generalElectionInfo?.electionsInfo?.id

  const [isEditMode, setIsEditMode] = useState<boolean>(isUnsavedInvestorInfo)

  /**Set foreign stateas first element in dropdown */
  const allStates = useMemo(() => {
    const foreignState: USState[] = [
      {
        name: ForeignCountryEnum.Name,
        stateCode: ForeignCountryEnum.StateCode,
        isSelected: false,
      },
    ]
    return foreignState.concat(usStatesData)
  }, [])

  //check the dropdown values and set the isDirty state
  const checkForDirty = (entityType: string, stateOfResidency: string) => {
    if (
      entityType !== generalElectionInfo.electionsInfo?.entityType ||
      stateOfResidency !== generalElectionInfo.electionsInfo?.stateOfResidency
    ) {
      setDirtyFlag(true)
      generalTabDirty(true)
    } else {
      setDirtyFlag(false)
      generalTabDirty(false)
    }
  }

  // Setting the InvestorInfo state with onchange value for taxClassification dropdown
  const onTaxClassificationChanged = (changedValue: string) => {
    /** checking the changed value if not equals to exsting value
     * and update the local state value
     * */
    checkForDirty(changedValue, investorInfo.stateOfResidency!)
    setInvestorInfo(
      produce((draft) => {
        draft.entityType = changedValue
      })
    )
  }

  // Setting the InvestorInfo state with onchange value for stateOfResidency dropdown
  const onStateOfResidencyChanged = (changedValue: string) => {
    /** checking the changed value if not equals to exsting value
     * and update the value to local state
     * */
    checkForDirty(investorInfo.entityType!, changedValue)
    setInvestorInfo(
      produce((draft) => {
        draft.stateOfResidency = changedValue
      })
    )
  }

  /** Delete and then Update General Selections to the API and recoil state,reset dirty flag */
  const saveInvestorElectionInfo = useCallback(async () => {
    if (!isUnsavedInvestorInfo) {
      await stateManagerApi.deleteInvestorElections(
        generalElectionInfo.electionsInfo?.id!
      )
    }
    const response = await stateManagerApi.updateInvestorElectionInfo(
      investorInfo
    )

    // clear data only for update general information data
    // not for insert scenario
    if (!isUnsavedInvestorInfo) {
      clearData()
    }

    //Reset the GeneralElectionInfo state value
    setGeneralElectionInfo(
      produce((draft) => {
        draft.electionsInfo = {
          id: response.id,
          generalOptionsId: response.generalOptionsId,
          investorId: response.investorId,
          entityType: response.entityType,
          stateOfResidency: response.stateOfResidency,
        }
      })
    )

    //Reset the investor info local state
    setInvestorInfo(
      produce((draft) => {
        draft.entityType = response.entityType
        draft.stateOfResidency = response.stateOfResidency
      })
    )

    //Reset the isInvestorElectionInfoDirty flag and isEditable to false
    setDirtyFlag(false)
    setIsEditMode(false)
    updateCanSave(true)
    generalTabDirty(false)

    // Update Submission status if there's any change in general tab
    await stateManagerApi.getSubmissionStatus(response.id!)
  }, [
    clearData,
    generalElectionInfo.electionsInfo?.id,
    generalTabDirty,
    investorInfo,
    isUnsavedInvestorInfo,
    setGeneralElectionInfo,
    updateCanSave,
  ])

  /** Check isDirty and Set investor info promise and API Spinner */
  const handleSave = useCallback(() => {
    /** Check the selected values, it should not be null or un-selected */
    if (!investorInfo.entityType || !investorInfo.stateOfResidency) {
      return commonDialog.showDialog({
        dialogType: 'general',
        title: 'Incomplete Fields',
        content:
          'You must answer both questions in order to save. Please answer both questions and click Save again.',
        buttonFunctions: [
          {
            label: 'OK',
            onClick: () => {},
            isCloseAction: true,
            buttonProps: {
              className: 'ok-btn-modal',
              stylingMode: 'contained',
              type: 'default',
              width: 120,
            },
          },
        ],
        omitDefaultButton: true,
      })
    }

    //Check if not dirty then set fields to non-editable and show Edit button
    if (!dirtyFlag && !isUnsavedInvestorInfo) {
      setIsEditMode(false)
      return
    }

    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Save Selection'
        draft.inProgressMessage = 'Selection is being saved.'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'Selection has been saved.'
        draft.errorTitle = 'Error Saving'
      })
    )

    setShowApiSpinner(true)
    setSavePromise(saveInvestorElectionInfo())
  }, [
    dirtyFlag,
    investorInfo.entityType,
    investorInfo.stateOfResidency,
    isUnsavedInvestorInfo,
    saveInvestorElectionInfo,
  ])

  // Cancel confirmation dialog proceed button action
  const handleCancelProceed = useCallback(() => {
    setIsEditMode(isUnsavedInvestorInfo)
    setDirtyFlag(false)
    generalTabDirty(false)
    setInvestorInfo(
      produce((draft) => {
        draft.entityType =
          generalElectionInfo.electionsInfo?.entityType ??
          generalElectionInfo.entityInfo?.entityType
        draft.stateOfResidency =
          generalElectionInfo.electionsInfo?.stateOfResidency ??
          generalElectionInfo.entityInfo?.stateOfResidency
      })
    )
  }, [
    generalElectionInfo.electionsInfo?.entityType,
    generalElectionInfo.electionsInfo?.stateOfResidency,
    generalElectionInfo.entityInfo?.entityType,
    generalElectionInfo.entityInfo?.stateOfResidency,
    generalTabDirty,
    isUnsavedInvestorInfo,
  ])

  // Cancel editing confirmation dialog
  const cancelConfirmationDialog = useCallback(() => {
    return commonDialog.showDialog({
      dialogType: 'general',
      title: 'Unsaved Changes',
      content:
        'You have unsaved changes. If you continue changes will be lost. Would you like to proceed?',
      buttonFunctions: [
        {
          label: 'Cancel',
          isCloseAction: true,
          isDefaultAction: true,
          onClick: () => {},
          buttonProps: {
            stylingMode: 'contained',
            type: 'normal',
            width: 100,
            'data-testid': 'cancel-button-edit',
          },
        },
        {
          label: 'Proceed',
          onClick: () => {
            handleCancelProceed()
          },
          isCloseAction: true,
          buttonProps: {
            className: 'ok-btn-modal',
            stylingMode: 'contained',
            type: 'default',
            width: 120,
          },
        },
      ],
      omitDefaultButton: true,
    })
  }, [handleCancelProceed])

  //Reset the value if dirty or disable the controls and exit edit mode
  const cancelEditing = useCallback(() => {
    dirtyFlag ? cancelConfirmationDialog() : setIsEditMode(false)
  }, [cancelConfirmationDialog, dirtyFlag])

  /** Edit click warning dialog for confirmation*/
  const handleEditClick = useCallback(() => {
    commonDialog.showDialog({
      dialogType: 'general',
      title: 'Data Loss Warning',
      content:
        'Warning - by editing and saving new selections here, please be aware that your current (if any) selection made in Composites and Withholdings tabs will be deleted, and you will need to re-select Composite and Withholdings selections. Do you wish to proceed?',
      omitDefaultButton: true,
      buttonFunctions: [
        {
          label: 'Cancel',
          isCloseAction: true,
          isDefaultAction: true,
          onClick: () => {},
          buttonProps: {
            stylingMode: 'contained',
            type: 'normal',
            width: 100,
            'data-testid': 'cancel-button',
          },
        },
        {
          label: 'Ok',
          isCloseAction: true,
          onClick: () => {
            setIsEditMode(true)
          },
          buttonProps: {
            stylingMode: 'contained',
            type: 'default',
            width: 110,
            'data-testid': 'proceed-button',
          },
        },
      ],
    })
  }, [])

  useEffect(() => {
    setParentButtons(
      <div>
        <Button
          visible={dirtyFlag}
          onClick={() => {
            cancelEditing()
          }}
          text='Cancel'
          type='normal'
          className='cancel-btn-State'
          data-testid='investor-election-cancel-button'
        ></Button>
        <Button
          text='Edit'
          visible={!isEditMode && !isUnsavedInvestorInfo}
          type='default'
          stylingMode='contained'
          className='clear-btn-State'
          onClick={() => handleEditClick()}
          data-testid='investor-election-edit-button'
        ></Button>
        <Button
          text='Save'
          visible={isEditMode || isUnsavedInvestorInfo}
          type='default'
          stylingMode='contained'
          className='save-btn-State'
          onClick={handleSave}
          data-testid='investor-election-Save-button'
        ></Button>
      </div>
    )
  }, [
    cancelEditing,
    dirtyFlag,
    handleEditClick,
    handleSave,
    isEditMode,
    isUnsavedInvestorInfo,
    setParentButtons,
  ])

  return (
    <div className='investor-election-info'>
      <div className='content-area vertical-flex'>
        <div className='grid w-full'>
          <div className='col-6'>
            <div className='dx-field'>
              <div className='dx-field-label'>
                Select your Entity Type
                <SimpleTooltip popupMessage='Please select your appropriate tax classification for this particular tax year' />
              </div>
              <div className='dx-field-value'>
                <SelectBox
                  disabled={!isEditMode && !isUnsavedInvestorInfo}
                  dataSource={taxClassificationTypes}
                  displayExpr='name'
                  valueExpr='value'
                  data-testid='tax-classification-select-box'
                  value={investorInfo.entityType}
                  placeholder='--Select Entity Type--'
                  showClearButton={true}
                  onValueChange={onTaxClassificationChanged}
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='dx-field'>
              <div className='dx-field-label'>
                Select your State of Residency
                <SimpleTooltip popupMessage='Please select your resident state (for tax purposes) for this particular tax year' />
              </div>
              <div className='dx-field-value'>
                <SelectBox
                  disabled={!isEditMode && !isUnsavedInvestorInfo}
                  dataSource={allStates}
                  displayExpr='name'
                  valueExpr='stateCode'
                  data-testid='state-of-residency-select-box'
                  value={investorInfo.stateOfResidency}
                  placeholder='--Select State of Residency--'
                  showClearButton={true}
                  onValueChange={onStateOfResidencyChanged}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex-fill-own-height no-overflow-y w-full'>
          <InvestorEntityList
            generalOptionsId={
              generalElectionInfo?.electionsInfo?.generalOptionsId
            }
            investorId={generalElectionInfo?.entityInfo?.investorId}
          ></InvestorEntityList>
        </div>
      </div>
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowApiSpinner(false)}
        closeOnSuccess={true}
        apiAction={savePromise}
      />
    </div>
  )
}
