import React, { ReactNode } from 'react'

type IsEnableFeatureProps = {
  children: ReactNode
  isFeatureEnabled: boolean
}

/** Validate the feature enabled or not then render the component */
export const EnabledFeature = ({
  children,
  isFeatureEnabled,
}: IsEnableFeatureProps) => {
  // If feature is enabled then render the component
  if (isFeatureEnabled === true) {
    return <>{children}</>
  }
  return null
}
