
export enum PermissionRequestLevels {
    Entity = 'entity',
    Client = 'client',
    Any = 'any',
}

/** Represents a request for a specified resource. */
export interface PermissionRequest {
    ipmNumber?: number
    entityOrClientId?: number
    level: PermissionRequestLevels
    permissionName: string
}

/** A set of PermissionRequest objects to check the access for. */
export type PermissionRequestSet = PermissionRequest[]
