import { View, Text, Image } from '@react-pdf/renderer'
import { RSM_LOGO, headerStyles } from './pdf-utils'

interface PdfHeaderProps {
  taxYear: string
  title: string
}

/** PDF header - RSM log, year and title */
export const PdfHeader = ({ taxYear, title }: PdfHeaderProps) => (
  <View style={headerStyles.header}>
    <View style={headerStyles.heading}>
      <View style={headerStyles.headingLeft}>
        <Image src={RSM_LOGO} />
      </View>
      <View style={headerStyles.headingCenter}>
        <View style={headerStyles.headerText}></View>
        <Text style={[headerStyles.pageTitle]}>{title}</Text>
        <View style={headerStyles.headerText}></View>
      </View>
      <View style={headerStyles.headingRight}>
        <View style={headerStyles.headerText}></View>
        <View>
          <Text style={headerStyles.subText}>{taxYear}</Text>
        </View>
      </View>
    </View>
  </View>
)