import { useEffect, useState } from 'react'
import { useStateManagerApi } from '../../../hooks/use-state-manager-api'
import { InvestorNotificationTypes } from '../../../api-client/investor-portal-client'
import { SendEmailNotificationWatcher } from '../../../signalr/watcher/email-notification-watcher'
import { SpinnerModal } from '../../modal/spinnerModal'
import { useRecoilValue } from 'recoil'
import { smGeneralOptionsAtom } from '../../../state/atom'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

export interface SMSendEmailProps {
  onCloseModal: () => void
  isSpinnerVisible: boolean
  emailType: InvestorNotificationTypes
  sessionId: string | null | undefined
  sendIndividualMail: boolean
  investorId: number
}

/** Send Initial and Reminder Emails */
export const SMSendEmails = ({
  onCloseModal,
  emailType,
  isSpinnerVisible,
  sendIndividualMail,
  sessionId,
  investorId,
}: SMSendEmailProps) => {
  const { sendInitialReminderEmails, sendInitialReminderIndividualEmails } =
    useStateManagerApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(isSpinnerVisible)
  const [sendPromise, setSendPromise] = useState<Promise<any>>()
  const smGeneralOptions = useRecoilValue(smGeneralOptionsAtom)

  /** Sending Intial and Reminder Emails from API and Watcher */
  useEffect(() => {
    const abortController = new AbortController()

    const sendInitialReminderEmail = async () => {
      setShowSpinner(true)

      // Conditionally call sendInitialReminderEmails with or without 'investorId'
      const sendEmailResponse = sendIndividualMail
        ? sendInitialReminderIndividualEmails(
            sessionId!,
            investorId,
            emailType,
            abortController.signal
          )
        : sendInitialReminderEmails(
            sessionId!,
            emailType,
            abortController.signal
          )

      let sendEmailWatcher = new SendEmailNotificationWatcher(
        sendEmailResponse,
        smGeneralOptions?.generalOptions.id!
      )
      sendEmailWatcher.initialize()

      setSendPromise(sendEmailWatcher.promise)
    }

    if (isSpinnerVisible) {
      sendInitialReminderEmail()
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isSpinnerVisible, emailType, sessionId, investorId, sendIndividualMail])

  return (
    <div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage=' Error Sending'
        errorMessage='Unable to Send Emails. Try Again.'
        inProgressTitleMessage='Emails Sending'
        inProgressMessage='Sending emails.'
        successTitleMessage='Emails Sent'
        successMessage='Emails have successfully sent.'
        onClose={() => {
          onCloseModal()
          setShowSpinner(false)
        }}
        apiAction={sendPromise}
      />
    </div>
  )
}
