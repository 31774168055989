import { atom } from 'recoil'

export type ClientGroupDetailsViewTypes = 'entity' | 'investor'

export interface ClientGroupDetailsState {
  /** Gets or sets the current view to show on the ClientGroupDetails page. */
  currentView: ClientGroupDetailsViewTypes
}

/** Returns a new ClientGroupDetailsState with all values reset. */
export const getDefaultClientGroupDetailsState = () =>
  ({
    currentView: 'entity',
  } as ClientGroupDetailsState)

export const clientGroupDetailsState = atom<ClientGroupDetailsState>({
  key: 'CLIENT_GROUP_DETAILS',
  default: getDefaultClientGroupDetailsState(),
})
