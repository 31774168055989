import { SelectBoxOptions } from '../../../../types/types'
import { getTaxYearValues } from '../../../components/investor-files/fileUtilities'

export const taxYearViewOptions: SelectBoxOptions[] = [
    {
        label: 'All',
        value: undefined
    },
]


// Get the tax years that are valid for the dropdown list.
var taxYears = getTaxYearValues().reverse()

// Add these to the client view options.
taxYearViewOptions.push(...taxYears.map(y => ({ label: y, value: y })))
