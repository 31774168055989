import { useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { RequiredRule } from 'devextreme-react/form'
import {
  DataGrid,
  Column,
  HeaderFilter,
  Selection,
  Scrolling,
  SearchPanel,
  LoadPanel,
  Sorting,
  PatternRule,
} from 'devextreme-react/data-grid'
import './manageExistingInvestors.scss'
import { useUserApi } from '../../hooks/use-user-api'
import { dgheight, ICellData, MenuProps } from '../../model/file-model'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import { multipleEmailWithoutWildcardPattern } from '../../utilities/email-validation'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { Button, ContextMenu, TextBox, Validator } from 'devextreme-react'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { ExistingInvestors } from '../../api-client/investor-portal-client'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { CemInfoModal } from './cemInfoModal'
import * as Permissions from '../../model/permissions'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import { permissionRequestAny } from '../../access-manager/permission-request-creation'
import { EventInfo } from 'devextreme/events'
import dxDataGrid from 'devextreme/ui/data_grid'

// create a new type as the response data does not have any value that is suitable to assign as a key to datagrid
// assigning any value  from response would lead to flickeringf issue
// in prder to avoid that we create a unique key that is rowId
type ExistingInvestorWithUniqueId = {
  existingInvestor: ExistingInvestors
  uniqueId: number
}

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  return (
    <>
      <span className='material-icons-outlined'>{e.icon}</span>
      {e.items ? <span className='dx-icon-spinright' /> : null}
      {e.text}
    </>
  )
}

/** Manage Existing Inverstors Componenet */
export const ManageExistingInvestors = () => {
  const [emailAddress, setEmailAddress] = useState<string>()
  const validatorRef = useRef<Validator>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [existingInvestorWithRowId, setExistingInvestorWithRowId] = useState<
    ExistingInvestorWithUniqueId[]
  >([])
  const [rowEmail, setRowEmail] = useState<string>()
  const searchRef = useRef<TextBox>(null)
  const [searchText, setSearchText] = useState('')
  const dataGridRef = useRef<DataGrid>(null)
  const [documentCount, setDocumentCount] = useState<number>(0)
  const [showCemInfo, setShowCemInfo] = useState<boolean>(false)
  const userApi = useUserApi()

  /** Create Request to check whether the Page it authorized when retrieved 
   Redirect the Page if there is no permission */
  useAuthorizedPermission([
    permissionRequestAny(Permissions.PAGE_VISIBILITY_MANAGEXISTING),
  ])

  /** Validate  email and call API */
  const submitInvestorEmail = () => {
    // Validate form
    if (!validatorRef.current!.instance.validate().isValid) {
      return
    }

    // Exit if there's no email address.
    if (!emailAddress) {
      return
    }

    let investorWithRowId: ExistingInvestorWithUniqueId[] = []

    setLoading(true)
    setExistingInvestorWithRowId([])

    userApi
      .existingInvestorSource(emailAddress)
      .then((response: ExistingInvestors[]) => {
        response.forEach((investor, i) => {
          let investorRow: ExistingInvestorWithUniqueId = {
            existingInvestor: investor,
            uniqueId: i,
          }
          investorWithRowId.push(investorRow)
        })
        setExistingInvestorWithRowId(investorWithRowId)
        setDocumentCount(response.length)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /** Email text box and submit */
  const toolbarContent = (
    <Grid container alignItems='center' className='spaced-content'>
      <Grid item>
        <TextBox
          id='email-textbox'
          className='email-textbox'
          value={emailAddress}
          onValueChanged={(data: ValueChangedEvent) =>
            setEmailAddress(data.value)
          }
          onEnterKey={submitInvestorEmail}
        >
          <Validator ref={validatorRef}>
            <RequiredRule message='Email is invalid' />
            <PatternRule
              ignoreEmptyValue={false}
              message='Email is invalid'
              pattern={multipleEmailWithoutWildcardPattern}
            />
          </Validator>
        </TextBox>
      </Grid>

      <Grid item>
        <Button
          elementAttr={{
            id: 'submit-button',
          }}
          text='Submit'
          type='default'
          useSubmitBehavior={true}
          onClick={() => submitInvestorEmail()}
        />
      </Grid>
    </Grid>
  )

  /** Full toolbar content ie all components except the datagrid */
  const fullToolbarContent = (
    <Grid container alignItems='flex-end' className='padded-sides'>
      {/** this is grid contains textbox, searchbar and submit button, provision button */}
      <Grid item xs={12} md={8}>
        <Grid container alignItems='flex-end'>
          <Grid item>
            <Grid item xs={12}>
              <div className='instruction-list'>
                Email address must be added to Entity in Entity Manager before
                the email can be provisioned in RSM CEM.
              </div>
            </Grid>
            <Grid item xs={12}>
              {toolbarContent}
            </Grid>
            <Grid item xs={12} className='provision-button'>
              <Button
                text='Provision'
                type='default'
                useSubmitBehavior={false}
                onClick={() => {
                  // placeholder to handle provision button
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/** this is grid contains datagrid searchbar and clear filter button */}
      <Grid
        item
        xs={12}
        md={4}
        alignItems='center'
        className='search-container'
      >
        <ClearFiltersButton className='clear-button' gridRef={dataGridRef} />
        <TextBox
          className='search-box'
          ref={searchRef}
          onValueChanged={(e) => setSearchText(e.value)}
          valueChangeEvent='keyup'
          placeholder={'Search...'}
          value={searchText}
          showClearButton={true}
        >
          <Button
            icon='search'
            type='normal'
            className='icon-button'
            onClick={() => {
              searchRef.current!.instance.focus()
            }}
          />
        </TextBox>
      </Grid>
    </Grid>
  )

  /** set action column of datagrid */
  const setActionColumn = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    // Create the ID for the button, so we can attach our context menu to it.
    // make this Id unique
    const buttonId = `btnMenuAction-${cellData.data?.uniqueId}`
    const contextMenu: MenuProps[] = [
      {
        text: 'Edit',
        icon: 'create',
        action: () => {
          // placeholder to write further code for onEdit Click,
        },
      },
      {
        text: 'CEM Info',
        icon: 'portrait',
        action: () => {
          setRowEmail(cellData.data?.existingInvestor.emailAddress)
          setShowCemInfo(true)
        },
      },
    ]
    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        ></Button>
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Set Provisioned column value for sorting  */
  const setProvisionSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'Provisioned')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set Documents column value for sorting  */
  const setDocumentSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'Document')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set StateManager column value for sorting  */
  const setStateManagerSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'StateManager')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set Client Name column value for sorting  */
  const setClientNameSortData = (cellData: ExistingInvestorWithUniqueId) => {
    if (cellData.existingInvestor.clientName) {
      return cellData.existingInvestor.clientName
    }
    return undefined
  }

  /** set provision checkbox column of datagrid */
  const setProvisionCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isProvisioned: boolean =
      cellData.data?.existingInvestor.status?.some(
        (i) => i === 'Provisioned'
      ) ?? false
    return (
      <div>
        {isProvisioned && <CheckCircleOutlineOutlinedIcon color='primary' />}
        {!isProvisioned && <RadioButtonUncheckedOutlinedIcon color='primary' />}
      </div>
    )
  }

  /** set document checkbox column of datagrid */
  const setDocumentsCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isDocumentsChecked: boolean =
      cellData.data?.existingInvestor.status?.some((i) => i === 'Document') ??
      false
    return (
      <div>
        {isDocumentsChecked && (
          <CheckCircleOutlineOutlinedIcon color='primary' />
        )}
        {!isDocumentsChecked && (
          <RadioButtonUncheckedOutlinedIcon color='primary' />
        )}
      </div>
    )
  }

  /** set State Manager column of datagrid */
  const setStateManagerCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isStateManagerChecked: boolean =
      cellData.data?.existingInvestor.status?.some(
        (i) => i === 'StateManager'
      ) ?? false
    return (
      <div>
        {isStateManagerChecked && (
          <CheckCircleOutlineOutlinedIcon color='primary' />
        )}
        {!isStateManagerChecked && (
          <RadioButtonUncheckedOutlinedIcon color='primary' />
        )}
      </div>
    )
  }

  /** Get the list of records displayed in the child datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
  }

  // Creates a unique key for a cell in the data grid, to prevent flickering.
  // two rows with same key leads to flickering issue, in order to make it unique
  // we take row ID from response and attach column name in order to make it unique
  const getId =
    (prefix: string) => (data: ICellData<ExistingInvestorWithUniqueId>) =>
      `${prefix}-${data.data?.uniqueId}`

  return (
    <div className='manage-existing-investors'>
      <div>{fullToolbarContent}</div>
      <div className='ip-table'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          keyExpr='uniqueId'
          noDataText={
            isLoading &&
            existingInvestorWithRowId.length === 0 &&
            documentCount === 0
              ? 'Loading...'
              : !isLoading &&
                (existingInvestorWithRowId.length === 0 || documentCount === 0)
              ? 'No Data Found.'
              : ''
          }
          dataSource={existingInvestorWithRowId}
          columnAutoWidth={true}
          height={dgheight}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={true}
          wordWrapEnabled={false}
          onCellPrepared={addToolTipOnCellPrepared}
          onContentReady={onContentReady}
        >
          <SearchPanel
            visible={false}
            text={searchText}
            width={350}
            placeholder='Search...'
          />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Sorting mode='single' />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <LoadPanel enabled={false} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column
            dataField='existingInvestor.emailAddress'
            caption='Email Address'
            allowSearch={true}
            allowSorting={true}
            defaultSortOrder='desc'
            width={'13%'}
            cellKeyFn={getId('EmailAddress')}
          ></Column>
          <Column
            dataField='existingInvestor.entityId'
            caption='Entity Id'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'8%'}
            cellKeyFn={getId('entityId')}
          />
          <Column
            dataField='existingInvestor.investorName'
            caption='Investor Name'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'16%'}
            cellKeyFn={getId('InvestorName')}
          />
          <Column
            dataField='existingInvestor.clientName'
            caption='Client Name'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'15%'}
            calculateSortValue={setClientNameSortData}
            cellKeyFn={getId('ClientName')}
          />
          <Column
            dataField='existingInvestor.ipmNumber'
            caption='IPM Number'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'10%'}
            cellKeyFn={getId('IpmNumber')}
          />
          <Column
            dataField='existingInvestor.allocatingEntityName'
            caption='Allocating Entity Name'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'15%'}
            cellKeyFn={getId('AllocatingEntityName')}
          />
          <Column
            caption='Provisioned'
            alignment='center'
            allowSearch={false}
            allowSorting={true}
            allowFiltering={false}
            cellRender={setProvisionCheckBox}
            calculateSortValue={setProvisionSortData}
            width={'6%'}
            cellKeyFn={getId('ProvisionCheckbox')}
          />
          <Column
            caption='Documents'
            alignment='center'
            allowSearch={false}
            allowSorting={true}
            allowFiltering={false}
            cellRender={setDocumentsCheckBox}
            calculateSortValue={setDocumentSortData}
            width={'6%'}
            cellKeyFn={getId('DocumentManagerCheckbox')}
          />
          <Column
            caption='State Manager'
            alignment='center'
            allowSearch={false}
            allowSorting={true}
            allowFiltering={false}
            cellRender={setStateManagerCheckBox}
            calculateSortValue={setStateManagerSortData}
            width={'7%'}
            cellKeyFn={getId('StateManagerCheckbox')}
          />
          <Column
            caption='Action'
            cellRender={setActionColumn}
            allowSearch={false}
            allowSorting={false}
            allowFiltering={false}
            width={'5%'}
            cellKeyFn={getId('Action')}
          />
        </DataGrid>
      </div>
      <CemInfoModal
        onCancel={() => {
          setShowCemInfo(false)
          setRowEmail(undefined)
        }}
        email={rowEmail!}
        isVisible={showCemInfo}
      />
    </div>
  )
}
