import { SelectBox, TextBox } from 'devextreme-react'
import './state-manager-tax-year.scss'
import {
  generatePath,
  matchRoutes,
  useLocation,
  useParams,
} from 'react-router-dom'
import { routes } from '../../../routes'
import { useNavigate } from '../../../hooks/useNavigationGuard'
import LockIcon from '@mui/icons-material/Lock'
import { SelectBoxOptions } from '../../../../types/types'
import { useCallback, useEffect } from 'react'

export interface TaxYearStateProps {
  selectYearWhenUnset: boolean
  className?: string
  viewOptions: SelectBoxOptions[]
  selectYear?: string
}

/**  This function generates the template for a custom Select Box field.
 It takes a SelectBoxOption object as input and renders a customized item.*/
const selectBoxFieldTemplate = (data: SelectBoxOptions) => {
  return (
    <div className='custom-item'>
      <div className='di-tax-year-selectbox-field'>
        {/* Display a lock icon if the option has 'lock' icon type */}
        {data?.icon === 'lock' && <LockIcon className='di-lock' />}

        {/* Render a read-only TextBox with the year label */}
        <TextBox
          className='year-name .dx-texteditor-container'
          defaultValue={data && data.label}
          readOnly={true}
          width={120}
        />
      </div>
    </div>
  )
}

/** Tax Year dropdown component  */
export const TaxYearControlStateManager = ({
  selectYearWhenUnset,
  viewOptions,
  selectYear,
  className: styleName,
}: TaxYearStateProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const matchedRoute = matchRoutes(routes, location.pathname)
  // We need all of the parameters from the current URL location
  //  so we can reconstruct the navigation path, if needed.F
  const allParams = useParams()
  const taxYear: string | undefined = allParams.taxYear

  //Set default value when TaxYear Mode is true
  const defaultValue: string | undefined = viewOptions
    ? viewOptions[0]?.value
    : undefined

  /** Method to update the tax year in the URL. */
  const setTaxYear = useCallback(
    (newYear: string | undefined) => {
      // Create the new parameters to pass to the url, with the new tax year.
      const newParams = {
        ...allParams,
        taxYear: newYear?.toString(),
      }

      // Generate the new URL path to navigate to.
      let newPath = generatePath(matchedRoute![0]!.route.path, newParams)

      // If there's no tax year in the URL path, then we need to assume
      //  the tax year gets added to the end of the new path.  Append it.
      if (!matchedRoute![0]!.route.path.includes(':taxYear')) {
        if (newPath.endsWith('/')) {
          newPath += newYear
        } else {
          newPath += '/' + newYear
        }
      }

      // Navigate to the new URL.
      navigate('/' + newPath)
    },
    [allParams, matchedRoute, navigate]
  )

  // This will handle initialization of the component
  //  when navigated to without a tax year, and the default
  //  mode requires picking the current year or first year in list by default.
  useEffect(() => {
    if (selectYearWhenUnset && !taxYear && defaultValue) {
      setTaxYear(defaultValue)
    }
    /** Please do not add the 'setTaxYear' as a dependency, it's causing the infinite loop issue */
  }, [taxYear, defaultValue, selectYearWhenUnset])

  /** Set added year to the dropdown and update the URL */
  useEffect(() => {
    if (selectYear) {
      setTaxYear(selectYear)
    }
    /** Please do not add the 'setTaxYear' as a dependency, it's causing the infinite loop issue */
  }, [selectYear])

  /** Set changed year value in state */
  const onTaxYearChanged = (value: string) => {
    setTaxYear(value)
  }

  return (
    <div className='sm-tax-year-control'>
      <div
        data-testid='taxyear-selectbox'
        className={styleName + ' annotated-tax-year-select tax-year-spacing'}
      >
        <SelectBox
          items={viewOptions}
          fieldRender={selectBoxFieldTemplate}
          displayExpr='label'
          valueExpr={'value'}
          value={taxYear}
          onValueChange={onTaxYearChanged}
        ></SelectBox>
      </div>
    </div>
  )
}
