import { SelectBox } from 'devextreme-react'
import { useRecoilState } from 'recoil'
import { appSettingsAtom } from '../../state/atom'
import produce from 'immer'
import { SelectBoxOptions } from '../../../types/types'

interface ViewSelectorProps {
  viewOptions: SelectBoxOptions[]
  defaultView: string
  appStateKey: string
}

/** ViewSelector component to display dropdown */
export const ViewSelector = ({
  viewOptions,
  defaultView,
  appStateKey,
}: ViewSelectorProps) => {
  const [appState, setAppState] = useRecoilState(appSettingsAtom)
  const selectedValue = appState.viewOptions[appStateKey] ?? defaultView

  /** Handle view value changed
   * if key exists then update the value
   * otherwise add new entry with the key
   */
  const onViewValueChanged = (value: string) => {
    // Update the view option with the new values
    setAppState(
      produce((draft) => {
        draft.viewOptions[appStateKey] = value
      })
    )
  }
  return (
    <div className='view-selector-control'>
      {appStateKey && (
        <SelectBox
          items={viewOptions}
          displayExpr='label'
          valueExpr='value'
          value={selectedValue}
          onValueChange={onViewValueChanged}
        />
      )}
    </div>
  )
}
