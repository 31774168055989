import produce from 'immer'
import { SMInvestorStateElection } from '../../../api-client/investor-portal-client'
import { usStatesData } from '../../state-manager/state-manager-utils'
import { useEffect, useState } from 'react'
import { ApiSpinnerContent } from '../../state-manager/global-election-settings'
import { SpinnerModal } from '../../modal/spinnerModal'
import { CircularProgress } from '@mui/material'
import './investor-election-card.scss'
import { useStateManagerApi } from '../../../hooks/use-state-manager-api'
import { useRecoilValue } from 'recoil'
import { smGeneralElectionInfoAtom } from '../../../state/atom'
import { useFileClientApi } from '../../../hooks/use-file-api'

export interface InvestorWithholdingReviewProps {
  investorElectionsInfoId: number | undefined
  entityOptionsId: number | undefined
  entityName: string | undefined
  isComposite: boolean
}

/** This component is responsible to create the card for the composite and
 * withholding in expected design
 */
export const InvestorElectionsCard = ({
  isComposite,
  investorElectionsInfoId,
  entityOptionsId,
  entityName,
}: InvestorWithholdingReviewProps) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()
  const [stateNames, setStateNames] = useState<string>()
  const [entitiesList, setEntitiesList] = useState<string>()
  const stateManagerApi = useStateManagerApi()
  const apiClient = useFileClientApi()
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)

  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )

  /** load the state elections */
  useEffect(() => {
    const abortController = new AbortController()

    setApiSpinnerContent(
      produce((draft) => {
        draft.errorTitle = 'Error loading'
      })
    )

    const newSpinnerPromise = loadElectionData(abortController.signal)

    newSpinnerPromise.catch((err) => {
      setShowApiSpinner(true)
    })

    // Set API call promise to display spinner modal while error
    setSpinnerPromise(newSpinnerPromise)
  }, [investorElectionsInfoId])

  /**Function to fetch and process investor election entities */
  const loadingEntites = async (abortSignal?: AbortSignal) => {
    // Show spinner to indicate loading
    setShowSpinner(true)

    // Fetch investor election entities using the API client
    const entityListResult = await apiClient.getInvestorElectionEntities(
      electionsInfo?.investorId!,
      electionsInfo?.generalOptionsId!,
      abortSignal
    )

    // Create an intermediate list to store the composite entities
    let newCompositeList: string[] = []

    // Fill the entity lists with the results
    entityListResult.allocatingEntities!.forEach((entity) => {
      // Get the entity references for this allocating entity
      let references = entityListResult.investorSelections!.filter(
        (x) =>
          x.allocatingEntityReference!.entityOptions!.allocatingEntityId ===
          entity.id
      )

      // Add the list of allocating entity to composites
      if (
        references.some((r) => r.allocatingEntityReference!.enableComposites)
      ) {
        newCompositeList.push(entity.name!)
      }
    })

    // Sort the lists
    newCompositeList = newCompositeList
      .map((x) => ({ sortName: x.toLocaleLowerCase(), name: x }))
      .sort((a, b) => a.sortName.localeCompare(b.sortName))
      .map((x) => x.name)

    // Set the sorted and joined composite entity list to the state
    if (newCompositeList.length === 0) {
      setEntitiesList('None')
    } else {
      setEntitiesList(newCompositeList.join(', '))
    }
  }

  /** Load composites/withholding data based on the investorElectionsInfoId & entityOptionsId */
  const loadElectionData = async (abortSignal?: AbortSignal) => {
    setShowSpinner(true)
    /** Load the investor state elections data from the API */
    const investorStateElections: SMInvestorStateElection[] =
      await stateManagerApi.getInvestorElections(
        investorElectionsInfoId!,
        entityOptionsId,
        abortSignal
      )

    if (investorStateElections.length === 0) {
      setStateNames('None Available')
    } else if (investorStateElections.length === 1) {
      const stateCode = investorStateElections
        .map((x) => x.stateCode)
        .toString()

      // check the state codes if we get only one result
      if (stateCode === 'NONE') {
        setStateNames('None Selected')
      } else {
        setStateNames(usStatesData.find((x) => x.stateCode === stateCode)?.name)
      }
    } else {
      // get the names for all the state codes
      const investerStateNames = investorStateElections
        .map((x) => x.stateCode)
        .map((item) => {
          return usStatesData.find((x) => x.stateCode === item)?.name
        })
      //set comma seperated values in local state
      setStateNames(investerStateNames.join(', '))
    }

    if (isComposite) {
      loadingEntites()
    }

    setShowSpinner(false)
  }

  return (
    <>
      <div className='col-6 grid-border border-round-sm investor-election-card-page'>
        <div className='text-left'>
          <div className='grid'>
            <div className='col-12 bottom-border-style'>
              <div className='text-left font-semibold p-2'>{entityName}</div>
            </div>
          </div>
          {showSpinner ? (
            <div className='grid'>
              <div className='col-12 text-center circular-progress'>
                <CircularProgress
                  color='info'
                  size='20px'
                  className='circular-progress-control'
                />
              </div>
            </div>
          ) : (
            <div className='grid'>
              <div className='col-4'>
                <div className='text-left font-light p-2'>Selected States:</div>
              </div>
              <div className='col-8'>
                <div className='text-left font-light p-2'>{stateNames}</div>
              </div>
              {isComposite && (
                <div className='col-4'>
                  <div className='text-left font-light p-2'>
                    Applicable Entities:
                  </div>
                </div>
              )}
              {isComposite && (
                <div className='col-8'>
                  <div className='text-left font-light p-2'>
                    {entitiesList!}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowApiSpinner(false)}
        apiAction={spinnerPromise}
        hideCloseButton={false}
        closeOnSuccess={true}
      />
    </>
  )
}
