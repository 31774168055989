
export interface AppTitle {
  appIcoVariant: string
  appIco: string
  appTag: string
  logo: string
  app: string
  xp: string
}

export interface Props {
  appTitle: AppTitle
}

export const Iconography = ({appTitle}: Props) => {
  return (
    <>
      <div className="cui-c_iconography">
        <span className="cui-c_iconography-id">
          <span className={`material-icons-${appTitle.appIcoVariant}`}>{appTitle.appIco}</span>
        </span>
        <span className="cui-c_iconography-pie">
          <b>{appTitle.appTag}</b>
        </span>
      </div>
    </>
  );
};
export default Iconography;



