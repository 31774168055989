import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFileClientApi } from '../../hooks/use-file-api'
import { SpinnerModal } from '../modal/spinnerModal'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { parentFileListAtom, userEntityGroupState } from '../../state/atom'
import { downloadReport } from '../../signalr/watcher-utilities/download-report'
import {
  FileFlagProperties,
  FileFlags,
} from '../../api-client/file-flags-override'
import produce from 'immer'
import { setFileFlag } from './fileUtilities'
import { File } from '../../api-client/investor-portal-client'

export interface ZipUploadConfirmationSpreadsheetsProps {
  onConfirm: () => void
  onCancel: () => void
  selectedVerificationFile: File
}

export const ZipUploadConfirmationSpreadsheets = ({
  onConfirm,
  onCancel,
  selectedVerificationFile,
}: ZipUploadConfirmationSpreadsheetsProps) => {
  // Get the Download entity Files function from our API hook.
  const fileApi = useFileClientApi()
  const setParentFiles = useSetRecoilState(parentFileListAtom)
  const [zipVerificationDownloadPromise, setZipVerificationDownloadPromise] =
    useState<Promise<any>>()
  const { groups } = useRecoilValue(userEntityGroupState)

  // Get the groupId from the URL.
  const { groupId } = useParams() as {
    groupId: string
  }
  useEffect(() => {
    // Generating random number for operationId.
    const uniqueOperationId = Math.floor(Math.random() * 100000)
    // Finding the group by groupId.
    const clientGroup = groups.find((x) => x.id === parseInt(groupId))
    //API call
    let bulkDownloadEntityPromise: Promise<void>
    // Here When Downloading from Documents Page define the ParentId and
    // while downloading from EntityManager parentId is undefined.

    bulkDownloadEntityPromise = fileApi.getZipUploadConfirmationReport(
      selectedVerificationFile.id,
      clientGroup?.id!,
      uniqueOperationId
    )

    // call the download report helper method which will take cate of download report as well as any operation error
    const downloadReportPromise = downloadReport(
      bulkDownloadEntityPromise,
      uniqueOperationId
    )
    setZipVerificationDownloadPromise(downloadReportPromise)
    // API action completed - hide the modelSpinner for success else show the error message in model
    downloadReportPromise.then((response) => {
      fileApi
        .updateFileStatus(
          selectedVerificationFile.id,
          setFileFlag(
            selectedVerificationFile.flags!,
            FileFlagProperties.IsZipVerified,
            true
          )
        )
        .then(() => {
          // Update the recoil state for flag
          setParentFiles(
            produce((draft) => {
              const fileIndex = draft.files.findIndex(
                (v) => v.id === selectedVerificationFile.id
              )
              if (fileIndex >= 0) {
                draft.files[fileIndex].flags = setFileFlag(
                  draft.files[fileIndex].flags!,
                  FileFlagProperties.IsZipVerified,
                  true
                )
              }
            })
          )
        })

      onConfirm()
    })
  }, [])

  return (
    <div>
      <SpinnerModal
        visible={true}
        errorTitleMessage='Download Error'
        inProgressTitleMessage='Preparing file(s)'
        inProgressMessage='Your file(s) are being downloaded'
        successTitleMessage='File(s) downloaded successfully'
        successMessage='Your file(s) have been downloaded.'
        onClose={() => onCancel()}
        apiAction={zipVerificationDownloadPromise}
      />
    </div>
  )
}
