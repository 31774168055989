import { ReactNode, useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { SpinnerModal } from '../modal/spinnerModal'
import { ProvisionUserWatcher } from '../../signalr/watcher/provision-user-watcher'
import { useRecoilValue } from 'recoil'
import './provisionUsers.scss'
import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { clientAndPartnershipStateAtom } from '../../state/atom'
import { provisionUsersStatusMessages } from './provision-users-common-items'
import { ProvisionUsersFailedContent } from './provisionUsersFailedContent'
import { isProvisionUserHeader } from '../utility/type-guards'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface ProvisionUsersProps {
  isVisible: boolean
  entityGroupId: number
  clientId: number
  onClose: () => void
}

export interface ModalDimensionProps {
  width?: number
  height?: number
}

/** Do Provision Users */
export const ProvisionUsers = ({
  isVisible,
  entityGroupId,
  clientId,
  onClose,
}: ProvisionUsersProps) => {
  // Get the provisionUsers function from our API hook.
  const { provisionUsers } = useFileClientApi()
  const { partnership } = useRecoilValue(clientAndPartnershipStateAtom)
  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const [errorTitle, setErrorTitle] = useState<string>(
    provisionUsersStatusMessages.errorTitleMessage
  )
  const [errorDetail, setErrorDetail] = useState<ReactNode | undefined>()
  const [modalDimension, setModalDimension] = useState<ModalDimensionProps>({})

  //update Modal after processing the provision
  const updateErrorForPartialFailure = (
    provisionUserHeader: ProvisionUserHeader
  ) => {
    // setErrorTitle for partial failure
    setErrorTitle(
      provisionUsersStatusMessages.errorTitleMessageOnPartialFailure
    )
    //set ui for failed list
    setErrorDetail(
      <ProvisionUsersFailedContent
        provisionUserHeader={provisionUserHeader}
        partnership={partnership}
      />
    )
  }

  //begin: do provision on users ( doProvisionUsers )
  const doProvisionUsers = async (abortSignal?: AbortSignal) => {
    try {
      //invoke promise for provision users
      let provisionUsersPromise = provisionUsers(
        entityGroupId,
        clientId,
        abortSignal
      )

      // Initialize watcher for provision users
      let provisionUserWatcher = new ProvisionUserWatcher(provisionUsersPromise)
      provisionUserWatcher.initialize()

      // Provide the promise from our watcher to our spinner, so it can observe its progress.
      setProvisionPromise(provisionUserWatcher.promise)

      // Wait for the promise to complete.  This is necessary
      //  for the catch portion of this block to function.
      await provisionUserWatcher.promise
    } catch (err: any) {
      // If the watch throws an error with a ProvisionUserHeader, then
      //  this indicates a partial failure, and we need to set the
      //  spinner's error content appropriately.
      if (isProvisionUserHeader(err)) {
        updateErrorForPartialFailure(err)
        //set modal dimension for partial failure
        setModalDimension({
          width: 800,
        })
      } else {
        // Since we don't have ProvisionUserHeader, that means some other error happened
        //  and we want the Spinner to reflect that.  Set the spinner's promise so it will show the error.
        setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
        setErrorDetail(`Error: ${err.message}`)

        setProvisionPromise(Promise.reject(err))
        //re-set modal dimension for default view
        setModalDimension({
          width: undefined,
        })
      }
    }
  }

  /** Call ProvisionUsers API method */
  useEffect(() => {
    const abortController = new AbortController()

    // We only want to execute the provisioning of users when the
    //  dialog is visible, since the control will likely always exist
    //  on the page, even if hidden due to isVisible === false.
    if (isVisible) {
      //invoke doProvisionUsers
      doProvisionUsers(abortController.signal)
    } else {
      setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
      //reset ui for error state
      setErrorDetail(undefined)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isVisible, entityGroupId, clientId])

  return (
    <SpinnerModal
      visible={isVisible}
      inProgressTitleMessage={
        provisionUsersStatusMessages.inProgressTitleMessage
      }
      inProgressMessage={provisionUsersStatusMessages.inProgressMessage}
      successTitleMessage={provisionUsersStatusMessages.successTitleMessage}
      successMessage={provisionUsersStatusMessages.successMessage}
      errorTitleMessage={errorTitle}
      errorMessage={errorDetail}
      width={modalDimension.width}
      onClose={() => {
        onClose()
      }}
      apiAction={provisionPromise}
    />
  )
}
