import Tabs from 'devextreme-react/tabs'
import {
  EntityOptionsModel,
  EntityOptionsView,
  SMParentTypes,
} from '../../api-client/investor-portal-client'
import { ButtonFunction, Modal } from '../modal/modalBase'
import './manage-election-settings.scss'
import { useEffect, useState } from 'react'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { InvestorSelection } from './investor-selection'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'
import {
  ITabDetail,
  SMDirty,
  areArraysEqual,
  getDefaultSMDirtyFlag,
} from './state-manager-utils'
import { StateSelection } from './state-selection'
import { smEntityOptionsAtom } from '../../state/atom'
import { useSetRecoilState } from 'recoil'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { CheckBox } from 'devextreme-react'
import './manage-election-settings.scss'
import produce from 'immer'
import { SpinnerModal } from '../modal/spinnerModal'
import { ApiSpinnerContent } from './global-election-settings'
import { useInvestorSelection } from '../../hooks/use-investor-selection'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

/** Manage Settings modal Props */
interface ManageSettingsProps {
  onClose: () => void
  entityOption: EntityOptionsView
}

/** Manage election settings modal */
export const ManageElectionSettings = ({
  onClose,
  entityOption,
}: ManageSettingsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [dirtyFlag, setDirtyFlag] = useState<SMDirty>(getDefaultSMDirtyFlag())
  const commonDialog = useCommonDialogs()
  const setSmEntityOptions = useSetRecoilState(smEntityOptionsAtom)
  const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>()
  // This holds the current record states in the API, which we need to use to save the values back to the API.
  const [initialSelectedStateKeys, setInitialSelectedStateKeys] = useState<
    string[] | undefined
  >()
  // This holds the current record states in the API which are globally saved
  const [globalSelectedStateKeys, setGlobalSelectedStateKeys] = useState<
    string[] | undefined
  >()
  const [showLoadingsSpinner, setShowLoadingSpinner] = useState<boolean>(false)
  const [showSaveSpinner, setShowSaveSpinner] = useState<boolean>(false)
  const stateManagerApi = useStateManagerApi()
  const [useGlobalStates, setUseGlobalStates] = useState<boolean>(
    entityOption?.useGlobalStates!
  )
  const [savePromise, setSavePromise] = useState<Promise<any>>()
  const [loadStatesPromise, setLoadStatesPromise] = useState<Promise<any>>()
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )
  const [useGlobalEntities, setUseGlobalEntities] = useState<boolean>()
  const investorHook = useInvestorSelection()

  /** Set the parent id, parent type & useGlobalSettings flag for investor selection hook */
  useEffect(() => {
    investorHook.setParentId(entityOption.id!)
    investorHook.setParentType(SMParentTypes.AllocatingEntity)
    investorHook.setGlobalSettings(entityOption.useGlobalEntities)
    setUseGlobalEntities(entityOption.useGlobalEntities!)
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const fetchData = async () => {
      setShowLoadingSpinner(true)
      //Loading Global Selected US States
      if (useGlobalStates) {
        const response = await stateManagerApi.getStateElections(
          entityOption.parentId!,
          SMParentTypes.Global,
          abortController.signal
        )
        const stateCodes = response?.stateCodes || []
        setGlobalSelectedStateKeys(stateCodes)
        setSelectedKeys(stateCodes)
        setInitialSelectedStateKeys(stateCodes)
      } else {
        //Loading Allocating Entity States
        const response = await stateManagerApi.getStateElections(
          entityOption.id!,
          SMParentTypes.AllocatingEntity,
          abortController.signal
        )
        const stateCodes = response.stateCodes
        if (stateCodes?.length === 0) {
          setSelectedKeys(globalSelectedStateKeys)
        } else {
          setSelectedKeys(stateCodes)
        }
        setInitialSelectedStateKeys(stateCodes)
      }

      setShowLoadingSpinner(false)
    }

    setLoadStatesPromise(fetchData())

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [useGlobalStates])

  /**Set DirtyState based on StateSelections and use Global setting checkbox*/
  useEffect(() => {
    // If our initialSelectedStateKeys or selectedKeys is not set, then we haven't finished loading
    //  the state from the server yet.
    if (!initialSelectedStateKeys || !selectedKeys) {
      return
    }

    //Check whether the use Global settings changed
    const dirty = entityOption.useGlobalStates !== useGlobalStates

    //check and set the recoil state dirty
    setDirtyFlag(
      produce((draft) => {
        draft.isStateSelectionDirty =
          !areArraysEqual(selectedKeys, initialSelectedStateKeys) || dirty
      })
    )
  }, [selectedKeys, initialSelectedStateKeys, useGlobalStates])

  /** Function to pass through StateSelector Component */
  const handleSaveStates = async () => {
    if (!dirtyFlag.isStateSelectionDirty) {
      return
    }
    setShowSaveSpinner(true)
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Saving State'
        draft.inProgressMessage = 'State Selection is being saved'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'State Selection has been saved'
        draft.errorTitle = 'Error Saving'
        draft.errorMessage = 'An error has occurred'
      })
    )
    const newSavePromise = saveStatesToApi()
    setSavePromise(newSavePromise)
    await newSavePromise
  }

  /**  Function to save the states back to the server.*/
  const saveStatesToApi = async () => {
    const stateUpdateData = {
      parentId: entityOption.id,
      parentType: SMParentTypes.AllocatingEntity,
      stateCodes: useGlobalStates ? [] : selectedKeys,
    }

    // This handles setting the isDirty state when state selections change.
    setDirtyFlag(
      produce((draft) => {
        draft.isStateSelectionDirty = false
      })
    )

    const optionsModel: EntityOptionsModel = {
      allocatingEntityId: entityOption.allocatingEntityId,
      parentId: entityOption.parentId,
      propertyName: 'useGlobalStates',
      propertyValue: useGlobalStates,
      id: entityOption.id,
    }

    const updateElectionsPromise =
      stateManagerApi.updateStateElections(stateUpdateData)

    // Update entity options with new value of useglobalstates
    const insertOrUpdatePromise = stateManagerApi.insertorUpdateSMEntityOptions(
      optionsModel,
      entityOption?.useGlobalStates!
    )

    await Promise.all([updateElectionsPromise, insertOrUpdatePromise])

    // Update entity options in recoil state
    setSmEntityOptions(
      produce((draft) => {
        if (draft.parentId === optionsModel.parentId!) {
          draft.entityOptions.forEach((options: EntityOptionsView) => {
            if (
              options.allocatingEntityId === optionsModel.allocatingEntityId
            ) {
              options.useGlobalStates = useGlobalStates
            }
          })
        }
      })
    )
  }

  /** Function to handle saving states and closing the spinner */
  const handleSaveAllClick = async () => {
    const promises = new Array<Promise<any>>()

    //if found StateSelection dirty then save the changes to DB
    if (dirtyFlag.isStateSelectionDirty) {
      // Push the saveStatesToApi promise into the promises array
      promises.push(saveStatesToApi())
    }
    // if investor selection value(s) changed then save the changes to DB
    if (dirtyFlag.isInvestorSelectionDirty) {
      promises.push(
        investorHook.saveInvestor(
          entityOption.allocatingEntityId,
          entityOption.parentId,
          entityOption.id
        )
      )
    }
    if (promises.length) {
      setShowSaveSpinner(true)
      // set save All api spinner content
      setApiSpinnerContent(
        produce((draft) => {
          draft.inProgressTitle = 'Saving Custom Settings'
          draft.inProgressMessage = 'Custom Settings are being saved'
          draft.successTitle = 'Save Successful'
          draft.successMessage = 'Custom setting have been saved'
          draft.errorTitle = 'Error Saving'
          draft.errorMessage = 'An error has occurred'
        })
      )
      // Wait for all promises to resolve
      const allPromises = Promise.all(promises)
      setSavePromise(allPromises)
      await allPromises
    }
    onClose()
  }

  /** handle Use Global Settings for Investor Selector  */
  const handleEntitiesGlobalCheckbox = () => {
    setUseGlobalEntities(!useGlobalEntities)
    investorHook.setGlobalSettings(!useGlobalEntities)
    //Check whether the use Global settings changed
    if (entityOption.useGlobalEntities === !useGlobalEntities) {
      setInvestorDirty(false)
    } else {
      setInvestorDirty(true)
    }
    // create investor selection session id if its zero
    if (investorHook.sessionRef.current === 0) {
      const createPromise = investorHook.createSession()
      // set promise to state to display spinner modal
      investorHook.setPromise(createPromise)
    }
  }

  /** if there's any change in investor selection then
   * set spinner content, reset dirty flag
   * set save investor selection data to save data in DB */
  const handleInvestorChanges = () => {
    // if there's no change in investor selection then return without save API call
    if (!dirtyFlag.isInvestorSelectionDirty) {
      return
    }
    // set investor selection save spinner content
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Saving Investors'
        draft.inProgressMessage = 'Investor selection is being saved'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'Investor selection has been saved'
        draft.errorTitle = 'Error Saving'
        draft.errorMessage = 'An error has occurred'
      })
    )
    setShowSaveSpinner(true)
    setSavePromise(
      investorHook.saveInvestor(
        entityOption.allocatingEntityId,
        entityOption.parentId,
        entityOption.id
      )
    )
    // reset investor dirty flag
    setInvestorDirty(false)
  }

  /** set investor selection dirty flag */
  const setInvestorDirty = (isDirty: boolean) => {
    setDirtyFlag(
      produce((draft) => {
        draft.isInvestorSelectionDirty = isDirty
      })
    )
  }

  /** Handle Use Global Settings for StateSelector  */
  const handleStatesGlobalCheckbox = () => {
    setUseGlobalStates(!useGlobalStates)
  }

  /** Tab change event */
  const onTabsSelectionChanged = (e: OptionChangedEventInfo<any>) => {
    // Get the new selected index from the event
    const newIndex = e.component.option('selectedIndex')

    // Check if the newIndex is -1, which indicates that no tab is selected.
    if (newIndex !== -1) {
      // Set the selected index only if it's not -1 (i.e., a tab is selected).
      setSelectedIndex(newIndex)
    }
  }

  /**Create the buttons for the dialog box.*/
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => {
        // Check for any unsaved changes and show confirmation dialog
        if (
          dirtyFlag.isInvestorSelectionDirty ||
          dirtyFlag.isStateSelectionDirty
        ) {
          commonDialog.showDialog({
            dialogType: 'general',
            title: 'Unsaved Changes',
            content:
              'Are you sure you want to cancel? There are unsaved changes.',
            omitDefaultButton: true,
            buttonFunctions: [
              {
                label: 'Cancel',
                isCloseAction: true,
                isDefaultAction: true,
                onClick: async () => {},
                buttonProps: {
                  stylingMode: 'contained',
                  type: 'normal',
                  width: 100,
                  'data-testid': 'cancel-button',
                },
              },
              {
                label: 'Proceed',
                isCloseAction: true,
                onClick: async () => {
                  // Close the modal without save
                  onClose()
                },
                buttonProps: {
                  stylingMode: 'contained',
                  type: 'default',
                  width: 110,
                  'data-testid': 'proceed-button',
                },
              },
            ],
          })
        } else {
          // Close the modal
          onClose()
        }
      },
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Save All & Close',
      onClick: async () => {
        handleSaveAllClick()
      },
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 165,
        'data-testid': 'save-all-close-button',
      },
    },
  ]

  /** Tab details */
  const tabs: ITabDetail[] = [
    {
      id: 0,
      text: 'State Selector',
      content: (
        <>
          <CheckBox
            className='useglobal-state-box'
            text='Use Global Settings'
            value={useGlobalStates}
            onValueChanged={handleStatesGlobalCheckbox}
          />
          <StateSelection
            updateSelectedKeys={setSelectedKeys}
            stateSelectionsKeys={selectedKeys}
            onSave={handleSaveStates}
            useGlobalSettings={useGlobalStates}
            height={'calc(71vh - 170px)'}
          />
        </>
      ),
    },
    {
      id: 1,
      text: 'Investor Selector',
      content: (
        <>
          <div className='investor-settings-chkbox'>
            <CheckBox
              id='investor-checkbox'
              value={useGlobalEntities}
              onValueChange={(e: boolean) => {
                if (
                  e &&
                  (investorHook.isSelectionChanged || !useGlobalEntities)
                ) {
                  commonDialog.showDialog({
                    dialogType: 'general',
                    title: 'Revert To Global Settings',
                    content:
                      'By checking this box your selection will revert to Global Settings and you will lose your custom selections. Are you sure you wish to proceed?',
                    omitDefaultButton: true,
                    buttonFunctions: [
                      {
                        label: 'Cancel',
                        isCloseAction: true,
                        isDefaultAction: true,
                        onClick: () => {},
                        buttonProps: {
                          stylingMode: 'contained',
                          type: 'normal',
                          width: 100,
                          'data-testid': 'cancel-button',
                        },
                      },
                      {
                        label: 'Proceed',
                        isCloseAction: true,
                        onClick: () => {
                          handleEntitiesGlobalCheckbox()
                        },
                        buttonProps: {
                          stylingMode: 'contained',
                          type: 'default',
                          width: 110,
                          'data-testid': 'proceed-button',
                        },
                      },
                    ],
                  })
                } else {
                  handleEntitiesGlobalCheckbox()
                }
              }}
            />
            <span className='use-global-settings-chkbox'>
              Use Global Settings
            </span>
          </div>
          <InvestorSelection
            hookRef={investorHook}
            onSave={handleInvestorChanges}
            updateDirtyFlag={setInvestorDirty}
            height='calc(71vh - 170px)'
          />
        </>
      ),
    },
  ]

  return (
    <Modal
      visible={true}
      title={entityOption.allocatingEntityName + ' Settings'}
      buttonFunctions={buttonFunctions}
      maxHeight={770}
      maxWidth={660}
      showCloseButtonInTitle={true}
      className='manage-settings-container'
    >
      <Tabs
        dataSource={tabs}
        selectedIndex={selectedIndex}
        defaultSelectedIndex={0}
        onOptionChanged={onTabsSelectionChanged}
      />

      <div>{tabs[selectedIndex].content}</div>

      <SpinnerModal
        visible={showLoadingsSpinner}
        errorTitleMessage=' API Error'
        inProgressTitleMessage='State Selection'
        inProgressMessage='Loading...'
        successTitleMessage=''
        successMessage=''
        onClose={() => setShowLoadingSpinner(false)}
        apiAction={loadStatesPromise}
        hideCloseButton={true}
      />

      <SpinnerModal
        visible={showSaveSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        errorMessage={apiSpinnerContent.errorMessage}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowSaveSpinner(false)}
        apiAction={savePromise}
      />
    </Modal>
  )
}
