import { useEffect } from "react";
import { SignalREventHandler } from "./signalr-models";
import { signalR } from './signalr-runtime'
import { useUpdateFileSignalRHandler } from "./signal-r-handlers/file-update-handler";

/** Registers SignalR event handlers. */
export const useSignalR = (shouldConnect: boolean) => {
    // We only want to connect to signalR if indicated.  Since this is a hook,
    //  the hook *must* be run.  But we may not want to connect to signalR if
    //  the app is only being loaded for token refresh.
    useEffect(() => {
        if (shouldConnect) {
            signalR.connect()
        }
    }, [])

    // Register any stand-alone hooks that register SignalR events.
    useUpdateFileSignalRHandler()

    // PLACEHOLDER:
    // Get references to the atoms that will be used in the SignalR event handlers.


    // CAUTION: If any new functionality is made to the SignalR Event handlers,
    //  caution must be used that anything using hooks or will need
    //  to cause the useEffect to rerun (by adding a value to the dependency list)
    //  don't get called excessively.
    //  Recoil Pinners are an exception, since their values are maintained by they
    //  the hook that created the pinner itself, but don't cause the useEffect to fire when
    //  their values change.

    useEffect(() => {
        // The handlers to be registered.
        const handlers: SignalREventHandler[] = [
            // PLACEHOLDER:
            //  Add event handlers for SignalR here.
        ]

        // Register the handlers.
        handlers.forEach(h => {
            signalR.registerHandler(h)
        })

        // In the case the calling component dismounts, we want
        //  to be sure to cleanup our handlers so that, if it remounts,
        //  we don't have the handlers being called twice.
        return () => {
            // Unregister the handlers.
            handlers.forEach(h => {
                signalR.unregisterHandler(h)
            })
        }
    }, [])
}
