import { File } from '../api-client/investor-portal-client'

export interface ClientFileList {
  entityId: number
  files: File[]
}

/** Context Menu Item Props */
export interface MenuProps {
  visible?:boolean
  icon: string
  text: string
  disabled?: boolean
  tooltip?: boolean
  tooltipText?: string
  items?: MenuProps[]
  action?: (...args: any[]) => void
}

/** Data grid cellRender properties */
export interface ICellData<T> {
  data?: T
  value?: any
  emails?: string[]
  rowType?: string
  row?: IRowData
}

/** Data grid row data */
export interface IRowData {
  isSelected?: boolean
  rowType?: string
}

/** Set height of the data grid based on the screen size */
export const dgheight = 'calc(100vh - 300px)'