import { ButtonFunction } from '../modalBase'
import { FormattedDialog } from '../formattedDialog'
export interface ConfirmationModalProps {
  functionalButton: () => void
  closeEmailTemplate: () => void
  title: string
  modalMessage: string
}

export const ConfirmationModal = ({
  functionalButton, //set the function to confirm
  closeEmailTemplate,
  title,
  modalMessage,
}: ConfirmationModalProps) => {
  const buttonFunction: ButtonFunction[] = [
    {
      label: 'cancel',
      onClick: () => functionalButton(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'OK',
      onClick: () => closeEmailTemplate(),
      isDefaultAction: false,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'OK-button',
      },
    },
  ]

  return (
    <FormattedDialog
      dialogType='general'
      visible={true}
      title={title}
      buttonFunctions={buttonFunction}
      maxWidth={500}
      maxHeight={160}
      disableScrollbar={true}
      testId='modal-template-dialog'
    >
      <span>{modalMessage}</span>
    </FormattedDialog>
  )
}
