import { getAccessToken } from '@rsmus/react-auth'

/**
 * This file being used in the generation of API clients and
 * this file should not be removed
 */

export const convertStringToDate = (target: string): string | Date => {
  // Date format from server.
  // 2023-11-16T21:01:44Z
  // 2023-11-16T21:01:44.253Z

  // Test for a string that's in the form of a date, and convert the date if it matches.
  if (
    /^\d{4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}(?:\.\d{1,3})?Z$/.test(
      target
    )
  ) {
    return new Date(target)
  }

  return target
}

/** @private This is a support function for converting string date values to strings. */
const convertValue = (propValue: any) => {
  // Test for a string that's in the form of a date, and convert the date if it matches.
  //  This convert the value to a date, if the value conforms to a date format,
  //  otherwise, it will return a string.
  if (typeof propValue === 'string') {
    return convertStringToDate(propValue)
  }

  // If this is an object, then recursively update dates on the object, and return the result.
  if (typeof propValue === 'object' && !!propValue) {
    return fixDateProperties(propValue)
  }

  // The propValue doesn't match any of the previous values, so return the input value.
  return propValue
}

/** Given an object, searches recursively through the object and its nested properties
 *   for a string that matches a time/date format, and converts those properties to Date objects.
 *   If the target is a string, it will be examined and converted if it meets the criteria. */
export function fixDateProperties<T = any>(target: T): T {
  // If there's no input, then return it.
  if (!target) {
    return target
  }

  //  This convert the value to a date, if the value conforms to a date format,
  //  otherwise, it will return a string.
  if (typeof target === 'string') {
    // We're cheating here, but only because someone is playing dirty tricks on us first.
    return convertStringToDate(target) as T
  }

  // Test for a non-object.  Just return the value if it's not an object.
  if (typeof target !== 'object' || target === null || target === undefined) {
    return target
  }

  // We must iterate through arrays differently than
  //  the way we iterate through objects.
  if (Array.isArray(target)) {
    for (let i = 0; i < target.length; i++) {
      target[i] = convertValue(target[i])
    }
  } else {
    for (let n in target) {
      target[n] = convertValue(target[n])
    }
  }

  return target
}

export class AuthorizedApiBase {
  protected transformOptions = async (
    options: RequestInit
  ): Promise<RequestInit> => {
    options.headers = {
      ...options.headers,
      Authorization: await getAccessToken().then((at) => 'Bearer ' + at),
    }
    return Promise.resolve(options)
  }

  protected transformResult(
    url: string,
    response: Response,
    processor: (response: Response) => any
  ) {
    // Get the pre-processed result from the superclass.
    const processedResult = processor(response)

    // If we have a promise, we need to process the result of the promise.  Otherwise,
    //  we want to process the value itself.
    if (processedResult instanceof Promise) {
      return processedResult.then((x) => fixDateProperties(x))
    } else {
      return fixDateProperties(processedResult)
    }
  }
}
