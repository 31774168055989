import { atom } from 'recoil'
import { NavWidgetState } from './nav-widget-models'
import { getDefaultWidgetOptions } from './nav-widget-items'

/** Get the static items which will always be available in the nav widget,
 *   regardless of any circumstances or server data. */
const defaultOptions = getDefaultWidgetOptions()

const defaultWidgetState: NavWidgetState = {
    selections: [],
    options: defaultOptions
}

export const navWidgetState = atom<NavWidgetState>({
    key: 'NAV_WIDGET_OPTIONS',
    default: defaultWidgetState
})
