import React from 'react';
import './error-page.scss';
export const  ErrorPage: React.FC = () => {
  return (
    <div className = 'error-page'>
        <div className = 'error-message'>
           An error occurred in Investor Portal. Please email the RSM Service Desk
          RSMServiceDesk@rsmus.com to notify them that you are unable to access
          Investor Portal. 
        </div>
    </div>
  );
};

 