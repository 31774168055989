import { ButtonFunction} from '../modal/modalBase'
import {
  File
} from '../../api-client/investor-portal-client'
import { ScrollableMessage } from './scrollableMessage'
import { FormattedDialog } from '../modal/formattedDialog'

export interface PublishWarningProps {
  onConfirm: () => void
  onCancel: () => void
  selectedFiles: File[]
  isVisible: boolean
}

export const PublishWarning = ({
  onConfirm,
  onCancel,
  selectedFiles,
  isVisible,
}: PublishWarningProps) => {

  /** Create button for the dailogue box */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm',
      onClick: () => onConfirm(),
      isDefaultAction: false,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'confirm-button',
      },
    },
  ]

  return (
    <div>
      <FormattedDialog
        dialogType='general'
        visible={isVisible}
        title={'Link Verification'}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={400}
        disableScrollbar={true}
      >
        <ScrollableMessage
          staticContent={
            <>
              <p>
                Note that unmapped .zip files are linked to investors outside of
                the normal SSN/EIN processing logic used for .pdf files. As such
                there is inherent risk in using the .zip process.
              </p>
              <p>
                Please confirm that you have reviewed and verified all linking
                to investors in the .zip file you are publishing.
              </p>
            </>
          }
          scrollableContent={
            <div className='files-list'>
              {selectedFiles.map((f) => (
                <div data-testid='file-item' key={f.id!.toString()}>
                  {f.externalFileName}
                </div>
              ))}
            </div>
          }
        ></ScrollableMessage>
      </FormattedDialog>
    </div>
  )
}
