import {
  EntityClient,
  ProvisionUserHeader,
  ProvisionUserRecord,
} from '../../api-client/investor-portal-client'
import DataGrid, {
  Column,
  LoadPanel,
  Paging,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid'
import { dgheight, ICellData } from '../../model/file-model'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import { useMemo } from 'react'

interface ProvisionUsersFailedContentProps {
  provisionUserHeader: ProvisionUserHeader
  partnership: EntityClient | undefined
}

//ui to display successfully provisioned users along with failed users if any
export const ProvisionUsersFailedContent = ({
  provisionUserHeader: provisionUser,
  partnership,
}: ProvisionUsersFailedContentProps) => {
  //get investor name
  const investorName = (data: ICellData<ProvisionUserRecord>) =>
    data?.data?.firstName + ' ' + data?.data?.lastName

  //get partnership name
  const partnershipName = (cellData: ICellData<ProvisionUserRecord>) => {
    return partnership?.name ? partnership?.name : cellData.data?.clientName
  }

  const failedRecords = useMemo(
    () =>
      provisionUser.provisionUserRecords?.filter((r) => r.status === 'ERROR'),
    [provisionUser?.provisionUserRecords]
  )

  return (
    <div className='ip-table'>
      <div className='provision-status-align'>
        <div>{`${provisionUser.successCount} users successfully added`}</div>
        <div>{`${provisionUser.failedCount} users failed listed below`}</div>
      </div>
      <DataGrid
        dataSource={failedRecords}
        keyExpr='id'
        showBorders={true}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        showColumnLines={false}
        showRowLines={true}
        onCellPrepared={addToolTipOnCellPrepared}
        wordWrapEnabled={false}
      >
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={4} />
        <Sorting mode='single' />
        <LoadPanel enabled={true} />
        <Column
          cellRender={investorName}
          caption='Investor Name'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'20%'}
          defaultSortOrder='asc'
        />

        {/* For unbound column specifically required 'allowSearch = true'  */}
        <Column
          dataField='emailAddress'
          caption='Investor Email'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'30%'}
        />
        <Column
          cellRender={partnershipName}
          caption='Entity Name'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'20%'}
        />
        <Column
          dataField='errorMessage'
          caption='Errors'
          encodeHtml={false}
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'30%'}
        />
      </DataGrid>
    </div>
  )
}
