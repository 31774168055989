import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'

export const ProvisionContactEmailBanner = () => {
  return (
    <BannerContainer>
      <div>
        <BannerHeader>Provision Contact Email</BannerHeader>
      </div>
    </BannerContainer>
  )
}
