import React, { useEffect, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { CommonDialogButtonFunction } from '../modal/commonDialog/common-dialog-state'
import { GeneralSettings } from './general-selection'
import Tabs from 'devextreme-react/tabs'
import { useParams } from 'react-router-dom'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import {
  ITabDetail,
  SMDirty,
  areArraysEqual,
  getDefaultSMDirtyFlag,
  isEmailTemplateChangeFound,
  toolbarItems,
} from './state-manager-utils'
import './global-election-settings.scss'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'
import {
  GeneralOptions,
  LoadingStateEnum,
  smGeneralOptionsAtom,
} from '../../state/atom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { SMParentTypes } from '../../api-client/investor-portal-client'
import { StateSelection } from './state-selection'
import produce from 'immer'
import { CircularProgress } from '@mui/material'
import { EmailTemplate } from '../../api-client/investor-portal-client'
import { FormattedDialog } from '../modal/formattedDialog'
import { Button, Form } from 'devextreme-react'
import { Item, RequiredRule } from 'devextreme-react/form'
import { SpinnerModal } from '../modal/spinnerModal'
import { InvestorSelection } from './investor-selection'
import { useInvestorSelection } from '../../hooks/use-investor-selection'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

/**Global Settings Modal Props */
interface GlobalElectionsSettingProps {
  onComplete: () => void
}

/**If any of the value is modified in the tabs {general or state or investor or notifications}
 *  return value as true otherwise false
 */
export const isAnyDirty = (isDirty: SMDirty): boolean => {
  return (
    isDirty.isGeneralDirty ||
    isDirty.isStateSelectionDirty ||
    isDirty.isInvestorSelectionDirty ||
    isDirty.isNotificationDirty
  )
}

/**If any save happend tab level or global save then save set the
 * inprogress spinner message and title
 */
export interface ApiSpinnerContent {
  inProgressTitle: string
  inProgressMessage: string
  successTitle: string
  successMessage: string
  errorTitle: string
  errorMessage: string
}

/** Global election settings modal */
export const GlobalElectionsSetting = ({
  onComplete,
}: GlobalElectionsSettingProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [dirtyFlag, setDirtyFlag] = useState<SMDirty>(getDefaultSMDirtyFlag())
  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }
  const groupIdValue = parseInt(groupId)
  const taxYearValue = parseInt(taxYear)
  const commonDialogApi = useCommonDialogs()
  const stateManagerApi = useStateManagerApi()
  const smgeneralOptions = useRecoilValue(smGeneralOptionsAtom)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [savePromise, setSavePromise] = useState<Promise<any>>()

  // This holds the current record IDs in the API, which we need to use to save the values back to the API.
  const [initialSelectedStateKeys, setInitialSelectedStateKeys] = useState<
    string[] | undefined
  >()
  const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>()
  const [generalOptions, setGeneralOptions] =
    useRecoilState(smGeneralOptionsAtom)
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>()
  const [updatedEmailTemplate, setUpdatedEmailTemplate] = useState<
    EmailTemplate | undefined
  >(undefined)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false)
  const formRef = useRef<Form>(null)
  const [formValidate, setFormValidate] = useState<boolean>(false)
  const [dialogContentMessage, setDialogContentMessage] = useState<string>('')
  const globalOptionsEmailTemplateId =
    smgeneralOptions.generalOptions?.emailTemplateId
  const globalOptionsId = smgeneralOptions.generalOptions?.id
  const investorHook = useInvestorSelection()
  const initialGeneralOptions = smgeneralOptions.generalOptions
  const [localGeneralSelections, setLocalGeneralSelections] =
    useState<GeneralOptions>(initialGeneralOptions)
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )

  //Validating Global Settings notification Email template form
  useEffect(() => {
    // Fire form validation if form is invalid
    if (formValidate) {
      formRef.current?.instance.validate()
    }
  }, [formValidate])

  useEffect(() => {
    const abortController = new AbortController()

    /**When there is no entry in SM GeneralOptions table for the specified taxYear and groupId.
     Make Api Calls and create an entry in general options, state selection and investor selection tables for the specified tax year and groupid
     then it makes API call */
    if (
      smgeneralOptions.taxYear === taxYearValue &&
      smgeneralOptions.entityGroupId === groupIdValue &&
      smgeneralOptions.loadingState === LoadingStateEnum.Loaded
    ) {
      // No general options available
      if (smgeneralOptions.generalOptions === null) {
        stateManagerApi.initializeSMClient(
          taxYearValue,
          groupIdValue,
          abortController.signal
        )
      }
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [taxYearValue, groupIdValue])

  //get the email template
  const getEmailtemplate = async () => {
    await stateManagerApi
      .getEmailTemplateByGeneralOptions(globalOptionsId!)
      .then((result) => {
        //cloning the onject into other object to change the object reference
        const cloneEmailTemplate = JSON.parse(JSON.stringify(result))
        setEmailTemplate(cloneEmailTemplate)
        setUpdatedEmailTemplate(result)
      })
  }

  // Initialize the component when we first enter, to get the state elections.
  useEffect(() => {
    const abortController = new AbortController()

    if (globalOptionsId) {
      // set Parent id and parent type for investor selection component
      investorHook.setParentId(globalOptionsId)
      investorHook.setParentType(SMParentTypes.Global)
      const getStatesList = async () => {
        const response = await stateManagerApi.getStateElections(
          globalOptionsId!,
          SMParentTypes.Global,
          abortController.signal
        )
        setInitialSelectedStateKeys(response.stateCodes ?? [])
        setSelectedKeys(response.stateCodes ?? [])
      }
      getStatesList()
      getEmailtemplate()
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [globalOptionsId])

  /**General Tab  */
  const cutoffErrorModal = () => {
    if (!localGeneralSelections.cutoffDate) {
      commonDialogApi.showDialog({
        dialogType: 'error',
        title: 'Missing Cutoff Date',
        content:
          'You must enter a Cutoff Date in the General tab in order to save',
      })
    }
  }

  /**Update General Selections with recoil general options */
  useEffect(() => {
    setLocalGeneralSelections(smgeneralOptions.generalOptions)
  }, [smgeneralOptions.generalOptions])

  /**Set DirtyFlag for General Selections */
  useEffect(() => {
    const isDirty =
      initialGeneralOptions?.cutoffDate !==
        localGeneralSelections?.cutoffDate ||
      initialGeneralOptions?.applyRuleSet !==
        localGeneralSelections?.applyRuleSet ||
      initialGeneralOptions?.composite !== localGeneralSelections?.composite ||
      initialGeneralOptions?.witholdings !== localGeneralSelections?.witholdings

    setDirtyFlag(
      produce((draft) => {
        draft.isGeneralDirty = isDirty
      })
    )
  }, [localGeneralSelections])

  /** Set General Selections promise and API Spinner */
  const generalSavePromise = () => {
    if (!dirtyFlag.isGeneralDirty) {
      return
    }
    //Show Cutoff Error dialog when cutoff date is not selected while saving
    if (!localGeneralSelections.cutoffDate) {
      cutoffErrorModal()
    } else {
      setApiSpinnerContent(
        produce((draft) => {
          draft.inProgressTitle = 'Saving Selections'
          draft.inProgressMessage = 'Selections are being saved'
          draft.successTitle = 'Selections Successful'
          draft.successMessage = 'Selections have been saved'
          draft.errorTitle = 'Error Saving'
          draft.errorMessage = 'An error has occurred'
        })
      )
      setShowApiSpinner(true)
      setSavePromise(generalSave())
    }
  }

  /** Update General Selections to the API and recoil state,reset dirty flag */
  const generalSave = async () => {
    // Convert cutoffDate in to UTC format
    if (localGeneralSelections.cutoffDate) {
      const localCutoffDate = new Date(localGeneralSelections.cutoffDate)
      const offSet = localCutoffDate.getTimezoneOffset()
      localGeneralSelections.cutoffDate = new Date(
        localCutoffDate.getTime() - offSet * 60000
      )
    }
    await stateManagerApi.updateSMGeneralOptions(localGeneralSelections)
  }

  /**call the save States changes to API method and enable the spinner to show the progress*/
  const saveStatesChanges = async () => {
    if (!dirtyFlag.isStateSelectionDirty) {
      return
    }
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Saving State'
        draft.inProgressMessage = 'State is being saved'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'State has been saved'
        draft.errorTitle = 'Error Saving'
        draft.errorMessage = 'An error has occurred'
      })
    )
    setShowApiSpinner(true)
    setSavePromise(saveStatesToApi())
  }

  // Function to save the states back to the server.
  const saveStatesToApi = async () => {
    await stateManagerApi.updateStateElections({
      parentId: globalOptionsId,
      parentType: SMParentTypes.Global,
      stateCodes: selectedKeys,
    })
    // This handles setting the isDirty state when state selections change.
    setDirtyFlag(
      produce((draft) => {
        draft.isStateSelectionDirty = false
      })
    )
  }

  /** if there's any change in investor selection then
   * set spinner content, reset dirty flag
   * set save investor selection data to save data in DB */
  const saveInvestorChanges = async () => {
    // if there's no change in investor selection then return without save API call
    if (!dirtyFlag.isInvestorSelectionDirty) {
      return
    }
    // set investor selection save spinner content
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Saving Investors'
        draft.inProgressMessage = 'Investor selection is being saved'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'Investor selection has been saved'
        draft.errorTitle = 'Error Saving'
        draft.errorMessage = 'An error has occurred'
      })
    )
    setShowApiSpinner(true)
    setSavePromise(investorHook.saveInvestor())
    // reset investor dirty flag
    setInvestorDirty(false)
  }

  /** set investor selection dirty flag */
  const setInvestorDirty = (isDirty: boolean) => {
    setDirtyFlag(
      produce((draft) => {
        draft.isInvestorSelectionDirty = isDirty
      })
    )
  }

  useEffect(() => {
    // If our initialSelectedStateKeys or selectedKeys is not set, then we haven't finished loading
    //  the state from the server yet.
    if (!initialSelectedStateKeys || !selectedKeys) {
      return
    }

    //check and set the recoil state dirty
    setDirtyFlag(
      produce((draft) => {
        draft.isStateSelectionDirty = !areArraysEqual(
          selectedKeys,
          initialSelectedStateKeys
        )
      })
    )
  }, [selectedKeys, initialSelectedStateKeys])

  /**compare the email template subject and boy with original mail template object and body
   * set the dirty flag and
   * return the result in boolean after coparing with the original template
   * */
  const checkAndSetDirtyForNotification = (): boolean => {
    if (!emailTemplate) {
      return false
    }
    const isDirtyNotificationFound = isEmailTemplateChangeFound(
      emailTemplate,
      updatedEmailTemplate
    )
    setDirtyFlag(
      produce((draft) => {
        draft.isNotificationDirty = isDirtyNotificationFound
      })
    )
    return isDirtyNotificationFound
  }

  const cancelDialog = async () => {
    //check and change the notification dirty state
    const isDirtyNotificationFound = checkAndSetDirtyForNotification()

    //When there is change in any of tab values
    if (isAnyDirty(dirtyFlag) || isDirtyNotificationFound) {
      commonDialogApi.showDialog({
        dialogType: 'general',
        title: 'Unsaved Changes',
        content: 'Are you sure you want to cancel? There are unsaved changes.',
        buttonFunctions: cancelButtonFunctions,
        omitDefaultButton: true,
      })
    } else {
      //When there is no change in any of tab values
      onClose()
    }
  }

  //Close the dialog box
  const onClose = () => {
    onComplete()
  }

  // set the updated EmailTemplateId in generalOptions
  const updateGeneralOptionsEmailTemplateId = (emailTemplateId: number) => {
    setGeneralOptions(
      produce((draft) => {
        draft.generalOptions.emailTemplateId = emailTemplateId
      })
    )
  }

  // Gloabl-Setting-Notification- function to save/update the emailTemplate
  const saveEmailTemplate = async () => {
    await stateManagerApi
      .insertOrUpdateEmailTemplate(globalOptionsId!, updatedEmailTemplate!)
      .then((response) => {
        updateGeneralOptionsEmailTemplateId(response.id!)
        setUpdatedEmailTemplate(response)
        setShowSpinner(false)
      })
    // This handles setting the isDirty state when state selections change.
    setDirtyFlag(
      produce((draft) => {
        draft.isNotificationDirty = false
      })
    )
  }

  /**Save Global Options only when cut off date is defined*/
  const globalSaveWhenDateSet = () => {
    if (!localGeneralSelections.cutoffDate) {
      cutoffErrorModal()
    } else {
      globalSave()
    }
  }
  /**Save Functionality for Save all and Close which call only when flag is Dirty*/
  const globalSave = async () => {
    const promises = new Array<Promise<any>>()

    //check and change the notification dirty state
    const isDirtyNotificationFound = checkAndSetDirtyForNotification()
    if (dirtyFlag.isGeneralDirty) {
      promises.push(generalSave())
    }
    //if found StateSelection dirty then save the changes to DB
    if (dirtyFlag.isStateSelectionDirty) {
      promises.push(saveStatesToApi())
    }
    // if investor selection value(s) changed then save the changes to DB
    if (dirtyFlag.isInvestorSelectionDirty) {
      promises.push(investorHook.saveInvestor())
    }
    //if found notification dirty then save the changes to DB
    if (isDirtyNotificationFound) {
      promises.push(saveEmailTemplate())
    }
    if (promises.length) {
      // set save All api spinner content
      setApiSpinnerContent(
        produce((draft) => {
          draft.inProgressTitle = 'Saving Global Settings'
          draft.inProgressMessage = 'Global Settings is being saved'
          draft.successTitle = 'Save Successful'
          draft.successMessage = 'Global setting has been saved'
          draft.errorTitle = 'Error Saving'
          draft.errorMessage = 'An error has occurred'
        })
      )
      setShowApiSpinner(true)
      const allPromises = Promise.all(promises)
      setSavePromise(allPromises)
      await allPromises
    }
    // close the model
    onClose()
  }

  // Create the buttons for the Global Settings Modal.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        cancelDialog()
      },
      isDefaultAction: true,
      buttonProps: {
        className: 'cancel-btn-global',
        stylingMode: 'contained',
        type: 'normal',
      },
    },
    {
      label: 'Save All & Close',
      onClick: () => {
        globalSaveWhenDateSet()
      },
      buttonProps: {
        className: 'save-btn-global',
        stylingMode: 'contained',
        type: 'default',
      },
    },
  ]

  // Create the buttons for the cancel dialog.
  const cancelButtonFunctions: CommonDialogButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => {},
      isCloseAction: true,
      buttonProps: {
        className: 'cancel-btn-modal',
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
      },
    },
    {
      label: 'Proceed',
      onClick: () => {
        onClose()
      },
      isCloseAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 110,
      },
    },
  ]

  /** global settings notification code
   * The reason for writing global-settings-notification code in this page is
   * Whenever we are stting any state(local or global) losing the HTML-Editor focus.
   * The reason behind it is inside devexteme form control event 'OnOptionChanged' and
   * 'OnValueChanged' is not beaving in order to detect the changes
   * Future Scope: Need to create seperate component for global setting notification and hide and
   * show the 'Cancel-Editing' button based on any change made for Subject and Body
   * */
  //#region  global setting notification code start here

  //reset the subject and body value
  const revertChanges = () => {
    updatedEmailTemplate!.subject = emailTemplate?.subject
    updatedEmailTemplate!.body = emailTemplate?.body
    setShowCancelDialog(false)
  }

  //delete the custom email template so template change to default
  const deleteCustomEmailTemplate = async () => {
    setShowSpinner(true)
    setShowDeleteDialog(false)
    await stateManagerApi
      .deleteEmailTemplateByGeneralOptions(globalOptionsId!)
      .then(() => {
        updateGeneralOptionsEmailTemplateId(0)
        getEmailtemplate()
        setShowSpinner(false)
      })
      .catch((error) => {
        setShowSpinner(false)
        commonDialogApi.showDialog({
          dialogType: 'error',
          title: 'Error',
          content: error.message,
          omitDefaultButton: false,
        })
      })
  }

  //open the confirmation model to delete the custom template
  const confirmDelete = () => {
    setDialogContentMessage(
      'This action is permanent, and cannot be undone. Do you wish to proceed?'
    )
    setShowDeleteDialog(true)
  }

  // Create the buttons for the delete dialog.
  const deleteCustomizationButtons: CommonDialogButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => {
        setShowDeleteDialog(false)
      },
      isCloseAction: true,
      buttonProps: {
        className: 'cancel-btn-modal',
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
      },
    },
    {
      label: 'Ok',
      onClick: () => {
        deleteCustomEmailTemplate()
      },
      isCloseAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 110,
      },
    },
  ]

  // Create the buttons for the cancel dialog.
  const cancelNotificationButtons: CommonDialogButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => {
        setShowCancelDialog(false)
      },
      isCloseAction: true,
      buttonProps: {
        className: 'cancel-btn-modal',
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
      },
    },
    {
      label: 'Ok',
      onClick: () => {
        revertChanges()
      },
      isCloseAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 110,
      },
    },
  ]
  //cancel the inline editing
  const cancelEditing = () => {
    const isChangeFound = isEmailTemplateChangeFound(
      emailTemplate,
      updatedEmailTemplate
    )
    if (isChangeFound) {
      setDialogContentMessage(
        'This will delete unsaved changes, and cannot be undone. Do you wish to proceed?'
      )
      setShowCancelDialog(true)
    }
  }

  //Updating and Saving the Email Template
  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()
    if (
      !formRef.current?.instance?.validate().isValid ||
      !isEmailTemplateChangeFound(emailTemplate, updatedEmailTemplate)
    ) {
      return
    }
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Saving Template'
        draft.inProgressMessage = 'Template is being saved'
        draft.successTitle = 'Save Successful'
        draft.successMessage = 'Template has been saved'
        draft.errorTitle = 'Error Saving'
        draft.errorMessage = 'An error has occurred'
      })
    )
    setShowApiSpinner(true)
    setSavePromise(saveEmailTemplate())
    emailTemplate!.subject = updatedEmailTemplate!.subject
    emailTemplate!.body = updatedEmailTemplate!.body
  }

  function formRefresh() {
    const formValid = !formRef.current?.instance.validate().isValid
    //Set flag to call form validation explicitly (workaround to fire form validation)
    setFormValidate(formValid)
  }

  //Editor options for HTML-Editor
  const htmlEditorOptions = {
    height: 330,
    maxLength: 200,
    toolbar: {
      items: toolbarItems,
    },
    onValueChanged: (e: any) => {
      formRefresh()
    },
  }

  //Email-template(subject and body) form, implimentation for Notification Tab
  const globalSettingsNotification = () => {
    return (
      <div
        className='email-template-form-container form-scroll-horizontal'
        data-testid='form-screen'
      >
        <h4>
          Email Template-{' '}
          {globalOptionsEmailTemplateId !== 0 ? ' Custom' : ' Default'}
        </h4>
        <Form
          formData={updatedEmailTemplate}
          readOnly={false}
          showColonAfterLabel={false}
          showValidationSummary={false}
          ref={formRef}
        >
          <Item
            dataField='subject'
            data-testid='email-subject'
            editorType='dxTextBox'
            editorOptions={{
              onValueChanged: (e: any) => {
                formRefresh()
              },
            }}
          >
            <RequiredRule message='Subject is required' />
          </Item>
          <Item
            dataField='body'
            editorType='dxHtmlEditor'
            label={{ text: 'Body' }}
            editorOptions={htmlEditorOptions}
          >
            <RequiredRule message='Body is required' />
          </Item>
        </Form>
        <div className='save-email-template-button'>
          <Button
            text='Cancel Editing'
            stylingMode='contained'
            className='btn-email-template'
            onClick={() => cancelEditing()}
            data-testid='cancel-editing-button'
          />
          <Button
            text='Delete Customization'
            type='default'
            stylingMode='contained'
            className='btn-email-template'
            onClick={() => confirmDelete()}
            visible={globalOptionsEmailTemplateId !== 0 ? true : false}
            data-testid='delete-customization-button'
          />
          <Button
            text='Save Template'
            type='default'
            stylingMode='contained'
            className='btn-email-template'
            onClick={() => handleSubmit()}
            data-testid='save-template-button'
          />
        </div>
        <FormattedDialog
          visible={showCancelDialog || showDeleteDialog}
          dialogType='general'
          buttonFunctions={
            showCancelDialog
              ? cancelNotificationButtons
              : deleteCustomizationButtons
          }
          title={showCancelDialog ? 'Cancel Editing' : 'Delete Customization'}
        >
          <div className='content-display-align'>{dialogContentMessage}</div>
        </FormattedDialog>
      </div>
    )
  }
  //#endregion global setting notification code end here

  /**Tab Details */
  const tabs: ITabDetail[] = [
    {
      id: 0,
      text: 'General',
      content: (
        <GeneralSettings
          onSave={generalSavePromise}
          generalSelections={localGeneralSelections}
          updateGeneralSelections={setLocalGeneralSelections}
        />
      ),
    },
    {
      id: 1,
      text: 'State Selector',
      content: (
        <StateSelection
          updateSelectedKeys={setSelectedKeys}
          stateSelectionsKeys={selectedKeys}
          onSave={saveStatesChanges}
          height={'calc(73vh - 152px)'}
        />
      ),
    },
    {
      id: 2,
      text: 'Investor Selector',
      content: (
        <InvestorSelection
          hookRef={investorHook}
          onSave={saveInvestorChanges}
          updateDirtyFlag={setInvestorDirty}
          height='calc(73vh - 152px)'
        />
      ),
    },
    {
      id: 3,
      text: 'Notifications',
      content: globalSettingsNotification(),
    },
  ]
  /** Tab change event */
  const handleTabsSelectionChanged = (e: OptionChangedEventInfo<any>) => {
    const newIndex = e.component.option('selectedIndex')
    if (newIndex !== -1) {
      setSelectedIndex(newIndex)
    }
  }

  return (
    <Modal
      visible={true}
      title={'Global Settings'}
      maxHeight={750}
      maxWidth={700}
      showCloseButtonInTitle={true}
      buttonFunctions={buttonFunctions}
      preventModalClose={true}
      className='global-settings-modal'
    >
      {smgeneralOptions.loadingState === LoadingStateEnum.Loaded ? (
        <Tabs
          dataSource={tabs}
          defaultSelectedIndex={0}
          selectedIndex={selectedIndex}
          onOptionChanged={handleTabsSelectionChanged}
          scrollByContent={false}
        />
      ) : (
        <span className='loading-title'> Loading..</span>
      )}
      {smgeneralOptions.loadingState === LoadingStateEnum.Loaded ? (
        <div>{tabs[selectedIndex].content}</div>
      ) : (
        ''
      )}
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        errorMessage={apiSpinnerContent.errorMessage}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowApiSpinner(false)}
        apiAction={savePromise}
      />
      {showSpinner && (
        <div>
          <div>
            <CircularProgress color='info' size='30px' />
            <span className='modal-spinner-alignment'>Deleting...</span>
          </div>
        </div>
      )}
    </Modal>
  )
}
