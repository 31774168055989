import { TagBox } from 'devextreme-react'
import './state-manager-reports.scss'
import { SelectionChangedEvent } from 'devextreme/ui/tag_box'
import { EntityOptionsReport } from '../../../model/report-models'

export interface AllocatingEntitiesDisplayProps {
  entityOptionsView: EntityOptionsReport[]
  selectedEntity: number[] | undefined
  height?: string | number
  updateSelectedKeys: (selectedKeys: number[]) => void
}

/** Display allocating entities using TagBox control*/
export const AllocatingEntitiesDisplay = ({
  entityOptionsView,
  selectedEntity,
  height,
  updateSelectedKeys,
}: AllocatingEntitiesDisplayProps) => {
  /** Remove the allocating entity from the state when user click on remove icon */
  const onSelectionChanged = (e: SelectionChangedEvent) => {
    if (e.removedItems && e.removedItems.length > 0) {
      updateSelectedKeys(
        selectedEntity?.filter(
          (allocatingEntityId) =>
            allocatingEntityId !== e.removedItems[0].allocatingEntityId
        )!
      )
    }
  }
  return (
      <TagBox
        dataSource={entityOptionsView}
        height={height}
        valueExpr='allocatingEntityId'
        displayExpr='allocatingEntityName'
        multiline={true}
        value={selectedEntity}
        onSelectionChanged={onSelectionChanged}
        placeholder='No Records'
      />
  )
}
