import React, { useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { ButtonFunction, Modal } from '../modal/modalBase'
import {
  File,
  FileStatus,
  FileUpdateResult,
} from '../../api-client/investor-portal-client'
import { useRecoilState } from 'recoil'
import { parentFileListAtom } from '../../state/atom'
import produce from 'immer'
import { SpinnerModal } from '../modal/spinnerModal'
import { ScrollableMessage } from './scrollableMessage'
import { FormattedDialog } from '../modal/formattedDialog'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { PublishWarning } from './publishWarning'
import { isFileFlagSet } from './fileUtilities'
import {
  FileFlagProperties,
  FileFlags,
} from '../../api-client/file-flags-override'

export interface PublishFilesProps {
  onConfirm: () => void
  onCancel: () => void
  selectedFiles: File[]
  isVisible: boolean
  setPublishSucceededinParent?: (published: boolean) => void
}

export const PublishFiles = ({
  onConfirm,
  onCancel,
  selectedFiles,
  isVisible,
  setPublishSucceededinParent,
}: PublishFilesProps) => {
  // Get the Publish Files function from our API hook.
  const { publishFiles } = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [isError, setError] = useState(false)
  const [publishSucceded, setPublishSucceded] = useState(false)
  const [publishFailed, setPublishFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [parentFiles, setParentFiles] = useRecoilState(parentFileListAtom)
  const [publishPromise, setPublishPromise] = useState<Promise<any>>()
  const [publishWarningVisible, setPublishWarningVisible] =
    useState<boolean>(false)
  const commonDialogApi = useCommonDialogs()

  // set isZipVerified to true if any of the files is unmappedZipVerified
  const isZipVerified: boolean = selectedFiles.some((s) =>
    isFileFlagSet(s.flags!, FileFlagProperties.IsZipVerified)
  )

  /** Close the publish warning modal and cancel publish */
  const closePublishWarningModal = () => {
    setPublishWarningVisible(false)
    onCancel()
  }

  /** Close the publish warning modal and cancel publish */
  const confirmPublishWarningModal = () => {
    setPublishWarningVisible(false)
    onPublish()
  }

  /** Update published flag in the store*/
  const updatePublishedFlag = (response: FileUpdateResult[]) => {
    response.forEach((fileResult) => {
      if (fileResult.success) {
        setParentFiles(
          produce((draft) => {
            const fileIndex = draft.files.findIndex(
              (v) => v.id === fileResult.fileId
            )
            if (fileIndex >= 0) {
              draft.files[fileIndex].published = fileResult.success
            }
          })
        )
      }
    })
  }

  const getPublishFiles = () => {
    return selectedFiles.filter(
      (f) =>
        !f.published &&
        f.status !== FileStatus.UploadError &&
        (!f.clientInfo?.childrenHasUploadError! ||
          (f.clientInfo?.childCount! > 0 &&
            f.clientInfo?.errorCount! < f.clientInfo?.childCount!)) &&
        f.status !== FileStatus.Inactive &&
        !f.clientInfo?.childFilesInactive!
    )
  }

  useEffect(() => {
    if (isVisible) {
      setError(false)
      setErrorMessage('')
      setPublishFailed(false)
      setPublishSucceded(false)
      /*** Validate Files count > 25 */
      const filesNotPublished = getPublishFiles()
      if (!filesNotPublished || (filesNotPublished as File[]).length === 0) {
        setError(true)
        setErrorMessage(
          'Invalid file(s) or Selected file(s) are already published'
        )
        setPublishSucceded(true)
      } else if (filesNotPublished.length > 25) {
        // if ready to publish or ready to publish with error files are more than 25 , display error model
        onCancel()
        commonDialogApi.showDialog({
          content: 'Exceeded 25 files limit to perform the file publish',
          dialogType: 'error',
        })
      }
    }
  }, [selectedFiles, isVisible])

  const onPublish = () => {
    setErrorMessage('')
    setPublishFailed(false)
    setPublishSucceded(false)
    /*** Validate Files count > 25 */
    const filesNotPublished = getPublishFiles()
    setShowSpinner(true)
    setPublishFailed(false)
    const selectedFileIds = filesNotPublished.map(({ id }) => id) as number[]

    /*** Call api to publish selected files using file id
     * Update published flage in the local store
     */
    let publishFilePrmoise = publishFiles(selectedFileIds)
    if (setPublishSucceededinParent) {
      publishFilePrmoise.then((r) => {
        //Set the isPublished to true when all the all files are published successfully
        setPublishSucceededinParent(r.every((x) => x.success === true))
      })
    }

    setPublishPromise(publishFilePrmoise)
    setPublishWarningVisible(false)
    onConfirm()
  }

  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Publish',
      onClick: () => {
        if (isZipVerified) {
          setPublishWarningVisible(true)
        } else {
          onPublish()
        }
      },
      isDefaultAction: false,
      isDisabled: publishSucceded,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'publish-button',
      },
    },
  ]

  return (
    <div>
      <FormattedDialog
        dialogType='general'
        visible={isVisible}
        title={'Publish File'}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={400}
        disableScrollbar={true}
      >
        <ScrollableMessage
          staticContent={
            <>
              Are you sure you want to publish the selected file(s)? Once you
              publish, the file(s) will be visible to the investor.
            </>
          }
          scrollableContent={
            <div className='files-list'>
              {selectedFiles.map((f) => (
                <div data-testid='file-item' key={f.id!.toString()}>
                  {f.externalFileName}
                </div>
              ))}
            </div>
          }
        ></ScrollableMessage>
      </FormattedDialog>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='File(s) publish failed'
        inProgressTitleMessage='Publish Document'
        inProgressMessage='Selected file(s) being published...'
        successTitleMessage='Publish Successful'
        successMessage='Selected file(s) are published successfully.'
        onClose={() => setShowSpinner(false)}
        apiAction={publishPromise}
      />
      {publishWarningVisible && (
        <PublishWarning
          selectedFiles={selectedFiles.filter((x) =>
            isFileFlagSet(x.flags!, FileFlagProperties.IsUnmappedZipFile)
          )}
          onCancel={closePublishWarningModal}
          onConfirm={confirmPublishWarningModal}
          isVisible={publishWarningVisible}
        ></PublishWarning>
      )}
    </div>
  )
}
