import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr'
import { Observable, Subject } from 'rxjs'
import { SignalREventHandler } from './signalr-models'
import { isAccessTokenExpired, hasToken } from '../components/utility/parse-token'

/** SignalR Controller class that assists in SignalR Functions. */
export class SignalRController {
  constructor(
    private readonly hubUrl: string,
    accessTokenOrFactory: (() => Promise<string> | string) | string
  ) {
    // Validate the inputs.
    if (!hubUrl || !accessTokenOrFactory) {
      throw new Error(
        'Access token or a factory function to generate the access token must be provided.'
      )
    }

    // Create a token factory function, or use the one passed to the ctor.
    const tokenFactory =
      typeof accessTokenOrFactory === 'string'
        ? // Create our own factory function to use the token passed to the ctor.
          () => accessTokenOrFactory
        : // Use the factory function passed to the ctor.
          accessTokenOrFactory

    // Wireup the observables.
    this.onClosed = this.onClosedEmitter.asObservable()
    this.onReconnected = this.onReconnectedEmitter.asObservable()
    this.onReconnecting = this.onReconnectingEmitter.asObservable()

    // Create the hub.
    const builder = new HubConnectionBuilder()
    this.connection = builder
      .withUrl(this.hubUrl, { accessTokenFactory: tokenFactory })
      .configureLogging(LogLevel.Information)
      .build()

    // Wire up the open/closed events.
    this.connection.onclose((err) => this.onClosedEmitter.next(err))
    this.connection.onclose((err) => {
      if (!this.disconnected) {
        this.connect()
      }
    })
    this.connection.onreconnected((id) => this.onReconnectedEmitter.next(id))
    this.connection.onreconnecting((err) =>
      this.onReconnectingEmitter.next(err)
    )
  }

  /** Boolean value indicating whether or not this controller has connected
   *   at least once since its creation. */
  private connectedOnce = false

  /** Disconnected flag*/
  private disconnected = false

  /** Timer index ie., number of retry attempt */
  private timerIndex = 0

  /** Contains the HubConnection that is responsible for the SignalR connection and interaction. */
  protected readonly connection: HubConnection

  /** Boolean value indicating whether or not the controller should continue to attempt to reconnect
   *   to SignalR when it receives a 401 error during the connection process. */
  public reconnectOn401 = false

  private _reconnectTimer: any = undefined
  /** If there is a timer in play to reconnect, this will clear and reset that timer.
   *   If no timer is set, then this is a noop. */
  private clearReconnectTimer() {
    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer)
      this._reconnectTimer = undefined
    }
  }

  /** Returns the state of the connection. */
  get connectionState(): HubConnectionState {
    return this.connection.state
  }

  /** Attempts to make a connection to the hub. */
  connect(isRetry?: boolean): Promise<void> {
    // Clear the reconnect timer, if there is one.
    this.clearReconnectTimer()

    // Only try to start if we're not connected or trying to connect.
    if (
      this.connectionState === HubConnectionState.Disconnected ||
      this.connectionState === HubConnectionState.Disconnecting
    ) {
      if (!this.connectedOnce) {
        console.log(`SignalR attempting to connect... `)
      } else {
        console.log(`SignalR trying to reconnect... `)
      }

      return this.connection
        .start()
        .then(() => {
          this.connectedOnce = true
          console.log(`SignalR connection established.`)
        })
        .catch(async (err: any) => {
          console.log(`SignalR connection attempt failed: ${err}`)

          /** Attempt to re-connect based on timer value
           *  timer value will increase exponentionally based on the number of attempts
           *  after 4 attempts retry timer value always 30 seconds
           *  timer value - 5, 10, 20, 30 seconds */
          const timers: number[] = [5000, 10000, 20000, 30000]
          // set default timer vaue is 5 seconds
          let timerValue = timers[0]
          // if retry then pick the timervalue based on the timer index(number of attempts)
          if (isRetry && isRetry === true) {
            timerValue = timers[this.timerIndex]
            // timer value is already 30 seconds then timer index will be the same
            if (this.timerIndex < 3) {
              this.timerIndex++
            }
          }

          // Set a timer to reconnect if the access token is null or not expired, otherwise
          //  we'll never reconnect, and just end up bombarding logs with errors.
          if (!(await hasToken()) || !(await isAccessTokenExpired()) || this.reconnectOn401) {
            this._reconnectTimer = setTimeout(() => {
              return this.connect(true)
            }, timerValue)
          }
        })
    }

    // Return a promise that resolves when the hub re-connects.
    return new Promise<void>((res) => {
      // Watch for the connection event to happen.
      const subscription = this.onReconnected.subscribe(() => {
        // Unsubscribe from the event.
        subscription.unsubscribe()

        // Complete the promise.
        res()
      })
    })
  }

  /** If the connection is currently connected, then attempts to disconnect. */
  disconnect(): Promise<void> {
    // set disconnect flag
    this.disconnected = true
    // Only attempt to disconnect if we're currently connected (NOT disconnecting or disconnected)
    if (
      this.connectionState !== HubConnectionState.Disconnected &&
      this.connectionState !== HubConnectionState.Disconnecting
    ) {
      return this.connection.stop()
    }

    // Return a promise that resolves when the hub disconnects.
    return new Promise<void>((res) => {
      // Watch for the disconnection event to happen.
      const subscription = this.onClosed.subscribe(() => {
        // Unsubscribe from the event.
        subscription.unsubscribe()

        // Complete the promise.
        res()
      })
    })
  }

  /** Contains all event handlers registered to this controller. */
  private handlers: SignalREventHandler[] = []

  /** Registers a new message handler with SignalR. */
  registerHandler(handler: SignalREventHandler): void {
    // Register the handler.
    this.connection.on(handler.eventName, handler.action)

    // Add this handler to the handler list.
    this.handlers.push(handler)
  }

  /** Unregisters a specified handler from the SignalRConnection. */
  unregisterHandler(handler: SignalREventHandler): void {
    // Ensure we have it in the handler list, and remove it.
    const index = this.handlers.indexOf(handler)

    if (index < 0) {
      throw new Error(`Handler has not been registered.`)
    }

    // Remove it.
    this.handlers.splice(index, 1)

    // Unregister this from the connection.
    this.connection.off(handler.eventName, handler.action)
  }

  /** Unregisters all event handlers from SignalR. */
  clearHandlers(): void {
    // Unregister the handlers.
    this.handlers.forEach((h) => {
      this.connection.off(h.eventName, h.action)
    })

    // Clear the list of handlers.
    this.handlers = []
  }

  private readonly onClosedEmitter = new Subject<Error | undefined>()
  /** Observable event that triggers when the SignalR connection is closed. */
  readonly onClosed: Observable<Error | undefined>

  private readonly onReconnectedEmitter = new Subject<string | undefined>()
  /** Observable event that triggers when the SignalR connection is reopened. */
  readonly onReconnected: Observable<string | undefined>

  private readonly onReconnectingEmitter = new Subject<Error | undefined>()
  readonly onReconnecting: Observable<Error | undefined>
}