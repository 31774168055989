import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { PartnershipBannerStat } from '../../api-client/investor-portal-client'
import { useFileClientApi } from '../../hooks/use-file-api'
import { clientAndPartnershipStateAtom } from '../../state/atom'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { Statistics } from '../../common-components/banners/statistics'
import { NavWidget } from '../nav-widget/navWidget'
import { ViewSelectorControl } from './path-view-selector/view-selector-control'
import { entityManagerViewOptions } from './entity-manager-view-selector-constants'
import './entityListPageBanner.scss'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

/** Entity List Banner */
export const EntityListPageBanner = () => {
  const [partnershipBannerStat, setPartnershipBannerStat] =
    useState<PartnershipBannerStat>()
  const { groupId } = useParams() as {
    groupId: string
  }
  const clientApi = useFileClientApi()
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)

  /** Get client group - documents and entities statistics */
  useEffect(() => {
    setPartnershipBannerStat({
      documents: undefined,
      entities: undefined,
    })
    const abortController = new AbortController()
    clientApi
      .getClientGroupBannerStats(parseInt(groupId), abortController.signal)
      .then((response) => {
        setPartnershipBannerStat({
          documents: response.documents!,
          entities: response.entities!,
        })
      })

    // Abort the API Call when user moves away from this page
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId])

  /** Set banner header name */
  const getHeaderContent = () => {
    const partnershipName: string =
      clientGroupInfo.clientGroup?.name + ' - Entity Manager'
    return partnershipName
  }

  /** Set documents & statistics details*/
  let statisticsItems = [
    {
      name: 'Documents',
      value: partnershipBannerStat?.documents!,
    },
    {
      name: 'Allocating Entities',
      value: partnershipBannerStat?.entities!,
    },
  ]

  return (
    <BannerContainer className='entity-list-page-banner'>
      <div className='align-banner'>
        <div className='banner-header-full-width'>
          <BannerHeader>{getHeaderContent()}</BannerHeader>
          <NavWidget />
        </div>
        <div className='current-view-control'>
          <ViewSelectorControl
            viewOptions={entityManagerViewOptions}
            defaultView='all'
          ></ViewSelectorControl>
        </div>
      </div>
      <Statistics items={statisticsItems} />
    </BannerContainer>
  )
}
