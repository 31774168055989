import { ButtonFunction } from '../modal/modalBase'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { useEffect, useState } from 'react'
import { LoadingStateEnum } from '../../state/atom'
import { UserManagementInfo } from '../../api-client/entity-manager-client-v3'
import { FormattedDialog } from '../modal/formattedDialog'
import { IdmUserStatusTypes } from '../../model/idm-user-statuses'
import { getShortDateString } from '../utility/date-utility'
import { CircularProgress, Grid } from '@mui/material'
import { Button } from 'devextreme-react'
import { IdmActionState } from '../utility/column-utility'
import './cemInfoModal.scss'

/** props for CEM Info Modal */
export interface CemInfoModalProps {
  onCancel: () => void
  email: string
  isVisible: boolean
}

/** Preview CEM Info model */
export const CemInfoModal = ({
  onCancel,
  email,
  isVisible,
}: CemInfoModalProps) => {
  const entityApi = useEntityClientApi()
  const [idmLoadingState, setIdmLoadingState] = useState<LoadingStateEnum>(
    LoadingStateEnum.NotLoaded
  )
  const [idmActionInProgress, setIdmActionInProgress] =
    useState<IdmActionState>('not-called')
  const [idmActionText, setIdmActionText] = useState<string>()
  const [userIdmInfo, setUserIdmInfo] = useState<UserManagementInfo>()

  /** Call Api to get CEM Info and set action button type */
  useEffect(() => {
    setIdmLoadingState(LoadingStateEnum.Loading)
    setIdmActionInProgress('not-called')
    
    entityApi
      .getUserManagementInfo(email)
      .then((response) => {
        setUserIdmInfo(response)
        setIdmLoadingState(LoadingStateEnum.Loaded)

        // from response we also set the Action text for button
        if (response) {
          switch (response.status) {
            case IdmUserStatusTypes.ACTIVE:
            case IdmUserStatusTypes.RECOVERY:
              setIdmActionText('Reset User Password')
              break
            case IdmUserStatusTypes.PROVISIONED:
              setIdmActionText('Resend Activation Email')
              break
            default:
            // Nothing to do here.
          }
        }
      })
      .catch(() => {
        setIdmLoadingState(LoadingStateEnum.Error)
      })
  }, [email])

  /** CEM Action button. */
  const handleActionButton = () => {
    setIdmActionInProgress('in-progress')

    switch (userIdmInfo!.status) {
      case IdmUserStatusTypes.ACTIVE:
      case IdmUserStatusTypes.RECOVERY:
        entityApi
          .resetUserPassword(userIdmInfo!.id!)  
          .then(() => {
            setIdmActionInProgress('called')
          })
          .catch((err) => {
            console.error(err)
            setIdmActionInProgress('error')
          })
        break
      case IdmUserStatusTypes.PROVISIONED:
        entityApi
          .resendActivationEmail(userIdmInfo!.id!)
          .then(() => {
            setIdmActionInProgress('called')
          })
          .catch((err) => {
            console.error(err)
            setIdmActionInProgress('error')
          })
        break
      default:
      // Nothing to do here.
    }
  }

  /** Create the buttons for the dialog box */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Close',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
  ]

  return (
    <FormattedDialog
      dialogType='general'
      visible={isVisible}
      title={'CEM Info'}
      buttonFunctions={buttonFunctions}
      maxWidth={500}
      maxHeight={400}
      disableScrollbar={true}
    >
      <div className='cem-info-modal-content'>
        {/* loading icon when info is loading */}
        {idmLoadingState === LoadingStateEnum.Loading && (
          <Grid container item xs={12}>
            {/* this grid is just for styling */}
            <Grid item xs={5.5}></Grid>
            <Grid item xs={6}>
              <div className='cem-info-loading-icon'>
                <CircularProgress />
              </div>
            </Grid>
          </Grid>
        )}

        {/* display info */}
        {idmLoadingState === LoadingStateEnum.Loaded && userIdmInfo && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <div className='name-and-action-content'>Name:</div>
                <div className='status-and-last-login-content'>Status:</div>
                <div className='status-and-last-login-content'>
                  Last Login :
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className='name-and-action-content'>
                  {userIdmInfo?.firstName! + ' ' + userIdmInfo?.lastName!}
                </div>
                <div className='status-and-last-login-content'>
                  {userIdmInfo?.status}
                </div>
                <div className='status-and-last-login-content'>
                  {getShortDateString(userIdmInfo?.lastLogin, 'Never')}
                </div>
              </Grid>
            </Grid>

            {/* Action Button */}
            <div className='name-and-action-content'>
              Action
              <div>
                {idmActionInProgress === 'not-called' && (
                  <Button
                    type='default'
                    onClick={handleActionButton}
                    text={idmActionText}
                  ></Button>
                )}
                {idmActionInProgress === 'in-progress' && <CircularProgress />}
                {idmActionInProgress === 'called' && (
                  <div>Request Sent Successfully</div>
                )}
                {idmActionInProgress === 'error' && (
                  <div>Unexpected error occurred</div>
                )}
              </div>
            </div>
          </>
        )}

        {/** User is not present in CEM */}
        {idmLoadingState === LoadingStateEnum.Loaded && !userIdmInfo && (
          <div>User not present in CEM</div>
        )}

        {/* Error message */}
        {idmLoadingState === LoadingStateEnum.Error && (
          <div>Error Loading CEM Info</div>
        )}
      </div>
    </FormattedDialog>
  )
}
