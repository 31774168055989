import { EntityOptionsView } from '../../api-client/investor-portal-client'
import { ButtonFunction, Modal } from '../modal/modalBase'

interface StatusViewProps {
  onCancel: () => void
  entityOption: EntityOptionsView
}

/** State manger status vide modal */
export const StatusView = ({ onCancel, entityOption }: StatusViewProps) => {
  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
  ]
  return (
    <Modal
      visible={true}
      title='Status'
      buttonFunctions={buttonFunctions}
      maxWidth={500}
      showCloseButtonInTitle={true}
      className='status-view-container'
    ></Modal>
  )
}
