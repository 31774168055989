import { useRecoilState } from 'recoil'
import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import {
  GeneralOptions,
  LoadingStateEnum,
  smEntityOptionsAtom,
  smGeneralElectionInfoAtom,
  smGeneralOptionsAtom,
  smSubmissionStatusAtom,
} from '../state/atom'
import produce from 'immer'
import {
  EmailTemplate,
  EntityOptionsModel,
  EntityOptionsView,
  InvestorElectionInfo,
  InvestorStateElectionModel,
  InvestorsSearchRequest,
  SMGeneralOptions,
  SMParentTypes,
  StateSelectionsModel,
  InvestorsSearchRequestBaseOfObjectId,
  InvestorNotificationTypes,
  ProvisionUsersRequestModel,
  InvestorReport,
} from '../api-client/investor-portal-client'
import { ObjectId } from '../api-client/object-id-override'
import { wrapApiWithCommonErrorHandlers } from './api-error-wrapper'
import { getApiCallId, isLatestApiCall } from './api-state-management'
import { FilterOptionRequest } from '../api-client/entity-manager-client-v3'

/** State Manager API Wrapper */
export const useStateManagerApi = () => {
  const [generalOptions, setGeneralOptions] =
    useRecoilState(smGeneralOptionsAtom)
  const [entityOptions, setEntityOptions] = useRecoilState(smEntityOptionsAtom)
  const [generalElectionInfo, setGeneralElectionInfo] = useRecoilState(
    smGeneralElectionInfoAtom
  )
  const [submissionStatus, setSubmissionStatus] = useRecoilState(
    smSubmissionStatusAtom
  )

  /** Get state manager general options based on tax year and entity group id */
  const getGeneralOptions = async (taxYear: number, entityGroupId: number) => {
    const callId = getApiCallId(getGeneralOptions)

    try {
      if (taxYear > 0 && entityGroupId > 0) {
        // Validate general options data available in recoil state for the specified tax year and entityGroupId
        // or not. If its not there, then make API call to get the data
        if (
          generalOptions.taxYear !== taxYear ||
          generalOptions.entityGroupId !== entityGroupId
        ) {
          // Update state loaded flag as loading
          setGeneralOptions(
            produce((draft) => {
              draft.taxYear = taxYear
              draft.entityGroupId = entityGroupId
              draft.loadingState = LoadingStateEnum.Loading
              draft.generalOptions = {}
            })
          )
          // Initialize Entity Options
          setEntityOptions(
            produce((draft) => {
              draft.hasLoaded = true
              draft.entityOptions = []
            })
          )
          const response = await filesClientApi.elections_GetGeneralOptions(
            taxYear,
            entityGroupId
          )
          // load entity options data from API
          if (response && response.id) {
            await getEntityOptions(response.id!)
          }

          const generalOptionData: GeneralOptions = response
          // Convert UTC date to local date
          if (generalOptionData && generalOptionData.cutoffDate) {
            var offset = generalOptionData.cutoffDate.getTimezoneOffset()
            generalOptionData.actualCutoffDate = new Date(
              generalOptionData.cutoffDate.getTime() + offset * 60000
            )
          }
          // set the API response in state
          setGeneralOptions(
            produce((draft) => {
              draft.taxYear = taxYear
              draft.entityGroupId = entityGroupId
              draft.loadingState = LoadingStateEnum.Loaded
              draft.generalOptions = generalOptionData
            })
          )
        }
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        // update recoil state for error
        setGeneralOptions(
          produce((draft) => {
            draft.taxYear = taxYear
            draft.entityGroupId = entityGroupId
            draft.loadingState = LoadingStateEnum.NotLoaded
            draft.generalOptions = {}
          })
        )
      }

      throw err
    }
  }
  /** Get state manager general options based on tax year and entity group id */
  const getEntityOptions = async (parentId: number) => {
    const callId = getApiCallId(getEntityOptions)

    try {
      if (parentId > 0) {
        const response =
          await filesClientApi.elections_GetEntityOptionsByParentId(parentId)

        // set the API response in state
        setEntityOptions(
          produce((draft) => {
            draft.parentId = parentId
            draft.hasLoaded = true
            draft.entityOptions = response
          })
        )
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        // update state for error
        setEntityOptions(
          produce((draft) => {
            draft.parentId = parentId
            draft.hasLoaded = true
            draft.entityOptions = []
          })
        )
      }

      throw err
    }
  }

  /** Insert or Update entity options based on id */
  const insertorUpdateSMEntityOptions = async (
    entityOptionsModel: EntityOptionsModel,
    oldValue: boolean
  ) => {
    const callId = getApiCallId(insertorUpdateSMEntityOptions)
    try {
      const response =
        await filesClientApi.elections_InsertorUpdateSMEntityOptions(
          entityOptionsModel
        )
      setEntityOptions(
        produce((draft) => {
          draft.parentId = entityOptionsModel.parentId!
          draft.entityOptions.forEach((options: EntityOptionsView) => {
            if (options.allocatingEntityId === response.allocatingEntityId) {
              options.id = response.id
              options.useGlobalEntities = response.useGlobalEntities
              options.useGlobalStates = response.useGlobalStates
            }
          })
        })
      )
    } catch (err) {
      if (isLatestApiCall(callId)) {
        // update entity options in state in case of error
        setEntityOptions(
          produce((draft) => {
            draft.parentId = entityOptionsModel.parentId!
            draft.entityOptions.forEach((options: EntityOptionsView) => {
              if (options.id === entityOptionsModel.id) {
                options[
                  entityOptionsModel.propertyName as keyof EntityOptionsView
                ] = oldValue as any
              }
            })
            draft.hasLoaded = true
          })
        )
      }

      throw err
    }
  }

  /** Update General options based on General Options */
  const updateSMGeneralOptions = async (
    generalOptionsModal: SMGeneralOptions
  ) => {
    const callId = getApiCallId(updateSMGeneralOptions)

    try {
      const response = await filesClientApi.elections_UpdateGeneralOptions(
        generalOptionsModal
      )
      const generalOptionData: GeneralOptions = response
      // Convert UTC date to local date
      if (generalOptionData.cutoffDate) {
        var offset = generalOptionData.cutoffDate.getTimezoneOffset()
        generalOptionData.actualCutoffDate = new Date(
          generalOptionData.cutoffDate.getTime() + offset * 60000
        )
      }
      setGeneralOptions(
        produce((draft) => {
          draft.entityGroupId = generalOptions.entityGroupId!
          draft.taxYear = generalOptions.taxYear
          draft.generalOptions = generalOptionData
          draft.loadingState = LoadingStateEnum.Loaded
        })
      )
    } catch (err) {
      if (isLatestApiCall(callId)) {
        // update general options to null in case of error
        setGeneralOptions(
          produce((draft) => {
            draft.entityGroupId = generalOptions.entityGroupId!
            draft.taxYear = generalOptions.taxYear
            draft.generalOptions = {}
            draft.loadingState = LoadingStateEnum.NotLoaded
          })
        )
      }

      throw err
    }
  }

  /** Initialize the Client Based on taxYear and groupId */
  const initializeSMClient = async (taxYear: number, entityGroupId: number, abortSignal?: AbortSignal) => {
    const callId = getApiCallId(initializeSMClient)

    try {
      // Update state loaded flag as loading
      setGeneralOptions(
        produce((draft) => {
          draft.taxYear = taxYear
          draft.entityGroupId = entityGroupId
          draft.loadingState = LoadingStateEnum.Loading
          draft.generalOptions = {}
        })
      )
      const response = await filesClientApi.elections_InitializeClient(
        taxYear,
        entityGroupId,
        abortSignal
      )
      if (response && response.id && isLatestApiCall(callId)) {
        // set the API response in state
        setGeneralOptions(
          produce((draft) => {
            draft.taxYear = taxYear
            draft.entityGroupId = entityGroupId
            draft.loadingState = LoadingStateEnum.Loaded
            draft.generalOptions = response
          })
        )
        await getEntityOptions(response.id!)
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        setGeneralOptions(
          produce((draft) => {
            draft.taxYear = taxYear
            draft.entityGroupId = entityGroupId
            draft.loadingState = LoadingStateEnum.NotLoaded
            draft.generalOptions = {}
          })
        )
      }

      throw err
    }
  }

  /** Get the email-template by generalOptionId */
  const getEmailTemplateByGeneralOptions = async (generalOptionId: number) => {
    return await filesClientApi.elections_GetEmailTemplateByGeneralOptions(
      generalOptionId
    )
  }

  /** Insert or Update email-template by generalOptionId and emailTemplate */
  const insertOrUpdateEmailTemplate = async (
    generalOptionId: number,
    emailTemplate: EmailTemplate
  ) => {
    return await filesClientApi.elections_InsertorUpdateEmailTemplate(
      generalOptionId,
      emailTemplate
    )
  }

  /** Delete email-template by generalOptionId */
  const deleteEmailTemplateByGeneralOptions = async (
    generalOptionId: number
  ) => {
    return await filesClientApi.elections_DeleteEmailTemplateByGeneralOptions(
      generalOptionId
    )
  }

  /** Get State Elections based on parentId and ParentType */
  const getStateElections = async (
    parentId: number,
    parentType: SMParentTypes,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.elections_GetStateElections(
      parentId,
      parentType,
      abortSignal
    )
  }

  /** Updates Selected States to API */
  const updateStateElections = async (stateSelection: StateSelectionsModel) => {
    return await filesClientApi.elections_UpdateStateSelections(
      stateSelection
    )
  }

  /** Get investors based on the search creteria from investor selection table */
  const getInvestors = async (searchRequest: InvestorsSearchRequest) => {
    return await filesClientApi.investorSelection_InvestorSearch(
      searchRequest
    )
  }

  /** Get investors based on the session id & search creteria from investor selection session table */
  const getInvestorSelections = async (
    searchRequest: InvestorsSearchRequest
  ) => {
    return await filesClientApi.investorSelectionSession_GetInvestorSelections(
      searchRequest
    )
  }

  /** Get whether all the investrors are selected based on parent id and parent type */
  const isAllInvestorsSelected = async (
    patentId: number,
    parentType: SMParentTypes,
    useGlobalEntities: boolean | undefined
  ) => {
    return await filesClientApi.investorSelection_IsAllInvestorsSelected(
      patentId,
      parentType,
      useGlobalEntities
    )
  }

  /** Get whether all the investors are selected based on the session id */
  const isAllInvestorSessionSelected = async (
    sessionId: number,
    signal?: AbortSignal
  ) => {
    return await filesClientApi.investorSelectionSession_IsAllInvestorsSelected(
      sessionId,
      signal
    )
  }

  /** Create the investors in investor selection session table from investor selection table
   * and return the session id */
  const createInvestorSelectionSession = async (
    patentId: number,
    parentType: SMParentTypes,
    useGlobalEntities: boolean | undefined
  ) => {
    return await filesClientApi.investorSelectionSession_CreateInvestorSelectionSession(
      patentId,
      parentType,
      useGlobalEntities
    )
  }

  /** Update investor selection based on the investor id
   * Individual investor selection change
   */
  const updateInvestorSelection = async (
    sessionId: number,
    entityId: number,
    isSelected: boolean
  ) => {
    return await filesClientApi.investorSelectionSession_UpdateInvestorSelection(
      sessionId,
      entityId,
      isSelected
    )
  }

  /** Update all investor selection based on the session id
   * Select All or Select None
   */
  const updateAllInvestorSelections = async (
    sessionId: number,
    isSelected: boolean
  ) => {
    return await filesClientApi.investorSelectionSession_UpdateAllInvestorSelections(
      sessionId,
      isSelected
    )
  }

  /** Update/insert investor selection table from investor selection session record table
   * based on the session id and
   * delete the entries in investor selection session & investor selection session record table
   */
  const saveInvestorSelectionSession = async (sessionId: number) => {
    return await filesClientApi.investorSelectionSession_EndInvestorSelectionSession(
      sessionId
    )
  }

  /** Delete investor selection session records based on the session id */
  const cancelInvestorSelectionSession = async (sessionId: number, abortSignal?: AbortSignal) => {
    return await filesClientApi.investorSelectionSession_CancelInvestorSelectionSession(
      sessionId,
      abortSignal
    )
  }

  /** Delete custom investor selection records based on the entity options id & parent id
   * This api call will be made when the user toggele between useGlobalEntities option and
   * finally selected useGlobalEntities as true and click on Save or Save All
   */
  const clearCustomEntitySelections = async (
    entityOptionsId: number,
    parentType: SMParentTypes
  ) => {
    return await filesClientApi.elections_ClearCustomEntitySelections(
      entityOptionsId,
      parentType
    )
  }

  /**Update Published Status in Entity Options table */
  const publishEntityOptions = async (
    generalOptionsId: number,
    allocatingEntityIds: number[]
  ) => {
    const response = await filesClientApi.elections_PublishEntityOptions(
      generalOptionsId,
      allocatingEntityIds
    )

    setEntityOptions(
      produce((draft) => {
        draft.parentId = generalOptionsId
        draft.hasLoaded = true
        draft.entityOptions = response
      })
    )
  }

  /** Get the state manager investor details */
  const getInvestorsStatus = async (
    stateSelection: InvestorsSearchRequestBaseOfObjectId,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorSelection_GetInvestorStatus(
      stateSelection,
      signal
    )
  }

  /** Returns the election status of investors for a specified search query. */
  const getInvestorStatusAllSelectedState = async (
    searchRequest: InvestorsSearchRequestBaseOfObjectId,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorSelection_GetAllUpdatedStateForStatusSelection(
      searchRequest,
      signal
    )
  }

  /** Updates the selection state for a specified investor status in the server's cached details. */
  const updateInvestorStatusSelection = async (
    sessionId: ObjectId,
    investorId: number,
    newValue: boolean,
    abortSignal?: AbortSignal
  ) => {
    await filesClientApi.investorSelection_UpdateInvestorStatusSelection(
      sessionId,
      investorId,
      newValue,
      abortSignal
    )
  }

  /** Updates the selection status for all investor status items in the cache on the server. */
  const updateAllInvestorStatusSelection = async (
    sessionId: ObjectId,
    filterValue: string,
    newValue: boolean
  ) => {
    await filesClientApi.investorSelection_SetAllInvestorStatusSelections(
      sessionId,
      newValue,
      filterValue
    )
  }

  /** Cleans up the server-side cache for a specified ObjectID. */
  const cleanupServerSideCache = async (sessionId: ObjectId) => {
    await filesClientApi.caching_CleanupCache(sessionId)
  }

  //get investor election info for investor view
  const getInvestorElectionInfo = async (
    entityGroupId: number,
    investorId: number,
    taxYear: number,
    signal?: AbortSignal | undefined
  ) => {
    const callId = getApiCallId(getInvestorElectionInfo)

    try {
      setGeneralElectionInfo(
        produce((draft) => {
          draft.cutoffDate = undefined
          draft.electionsInfo = {}
          draft.entityInfo = {}
          draft.loadingState = LoadingStateEnum.Loading
        })
      )
      const response =
        await filesClientApi.investorElections_GetInvestorElectionInfo(
          entityGroupId,
          investorId,
          taxYear,
          signal
        )
      if (response) {
        let actualCutoffDate = response.cutoffDate
        // Convert UTC date to local date
        if (actualCutoffDate) {
          var offset = actualCutoffDate.getTimezoneOffset()
          actualCutoffDate = new Date(
            actualCutoffDate.getTime() + offset * 60000
          )
        }

        if (isLatestApiCall(callId)) {
          // set the API response in state
          setGeneralElectionInfo(
            produce((draft) => {
              draft.cutoffDate = actualCutoffDate
              draft.electionsInfo = response.electionsInfo
              draft.entityInfo = response.entityInfo
              draft.loadingState = LoadingStateEnum.Loaded
            })
          )
        }

        // Call Submission Status only when the general information is saved already in DB
        if (response.electionsInfo?.id! > 0) {
          await getSubmissionStatus(response.electionsInfo?.id!, signal)
        } else {
          if (isLatestApiCall(callId)) {
            // reset submission status
            setSubmissionStatus(
              produce((draft) => {
                draft.isGeneralInfoSubmitted = false
                draft.submissionStatuses = undefined
                draft.isDirtyFlags.isGeneralDirty = false
                draft.isDirtyFlags.isCompositeDirty = false
                draft.isDirtyFlags.isWithholdingDirty = false
                draft.loadingState = LoadingStateEnum.Loaded
              })
            )
          }
        }
      }
    } catch (err: any) {
      if (isLatestApiCall(callId)) {
        setGeneralElectionInfo(
          produce((draft) => {
            draft.cutoffDate = undefined
            draft.electionsInfo = undefined
            draft.entityInfo = undefined
            draft.loadingState = LoadingStateEnum.NotLoaded
          })
        )
      }

      throw err
    }
  }

  //Delete investor election info
  const deleteInvestorElections = async (investorElectionsInfoId: number) => {
    return await filesClientApi.investorElections_DeleteInvestorElections(
      investorElectionsInfoId
    )
  }

  //Update investor election info
  const updateInvestorElectionInfo = async (
    investorElectionInfo: InvestorElectionInfo
  ) => {
    return await filesClientApi.investorElections_UpdateInvestorElectionInfo(
      investorElectionInfo
    )
  }

  /** Get the state manager investor elections */
  const getInvestorElections = async (
    investorElectionsInfoId: number,
    entityOptionsId: number | null | undefined,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorElections_GetInvestorElections(
      investorElectionsInfoId,
      entityOptionsId,
      signal
    )
  }

  /** Get the state manager eligible states for elections */
  const getEligibleStates = async (
    investorElectionsInfoId: number,
    entityOptionsId: number | null | undefined,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorElections_GetEligibleStates(
      investorElectionsInfoId,
      entityOptionsId,
      signal
    )
  }

  /** Get the state manager investor elections for withholdings tab*/
  const getInvestorWithholdingEntities = async (
    investorElectionsInfoId: number,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorElections_GetInvestorWithholdingEntities(
      investorElectionsInfoId,
      signal
    )
  }

  /** Update Investor Elections - Composite & Withholdings tab */
  const updateInvestorElections = async (
    investorStateElection: InvestorStateElectionModel
  ) => {
    return await filesClientApi.investorElections_UpdateInvestorElections(
      investorStateElection
    )
  }

  //get investor election info for investor view
  const getSubmissionStatus = async (
    investorInfoId: number,
    signal?: AbortSignal | undefined
  ) => {
    const callId = getApiCallId(getSubmissionStatus)

    try {
      setSubmissionStatus(
        produce((draft) => {
          draft.isGeneralInfoSubmitted = false
          draft.submissionStatuses = undefined
          draft.isDirtyFlags.isGeneralDirty = false
          draft.isDirtyFlags.isCompositeDirty = false
          draft.isDirtyFlags.isWithholdingDirty = false
          draft.loadingState = LoadingStateEnum.Loading
        })
      )

      const response = await filesClientApi.investorElections_SubmissionStatus(
        investorInfoId,
        signal
      )

      if (response && isLatestApiCall(callId)) {
        // set the API response in state
        setSubmissionStatus(
          produce((draft) => {
            draft.isGeneralInfoSubmitted = !!response.isGeneralInfoComplete
            draft.submissionStatuses = response
            draft.isDirtyFlags.isGeneralDirty = false
            draft.isDirtyFlags.isCompositeDirty = false
            draft.isDirtyFlags.isWithholdingDirty = false
            draft.loadingState = LoadingStateEnum.Loaded
          })
        )
      }
    } catch (err: any) {
      if (isLatestApiCall(callId)) {
        setSubmissionStatus(
          produce((draft) => {
            draft.isGeneralInfoSubmitted = false
            draft.submissionStatuses = undefined
            draft.isDirtyFlags.isGeneralDirty = false
            draft.isDirtyFlags.isCompositeDirty = false
            draft.isDirtyFlags.isWithholdingDirty = false
            draft.loadingState = LoadingStateEnum.NotLoaded
          })
        )
      }

      throw err
    }
  }

  /** save the in-eligible elctions*/
  const autoSaveIneligibileElections = async (
    investorElectionsInfoId: number,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.investorElections_AutoSaveIneligibileElections(
      investorElectionsInfoId,
      signal
    )
  }

  /** submit the election*/
  const submitElection = async (investorElectionsInfoId: number) => {
    return await filesClientApi.investorElections_SubmitElections(
      investorElectionsInfoId
    )
  }

  /** Unlock InvestorSlections */
  const unlockSelections = async (sessionId: ObjectId) => {
    return await filesClientApi.investorElections_UnlockInvestorSelection(
      sessionId
    )
  }

  /** Unlock InvestorSlections for Individuals */
  const unlockSelectionsForIndividualInvestor = async (
    investorId: number,
    entityId: number,
    taxYear: number,
    sessionId: ObjectId
  ) => {
    return await filesClientApi.investorElections_UnlockIndividualInvestorSelection(
      investorId,
      entityId,
      taxYear,
      sessionId
    )
  }

  /** Calls the API to get email template */
  const getEmailTemplate = async (emailId: number, isReminder: boolean) => {
    return await filesClientApi.getEmailTemplate(emailId, isReminder)
  }

  /**Calls the API for starting to send initial and reminder Mails */
  const sendInitialReminderEmails = async (
    selectionCacheId: string | null,
    notificationType: InvestorNotificationTypes,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.investorElectionsEmail_SendElectionNotification(
      selectionCacheId,
      notificationType,
      abortSignal
    )
  }

  /**Calls the API for starting to send initial and reminder Mails for individual Investor*/
  const sendInitialReminderIndividualEmails = async (
    selectionCacheId: string | null,
    investorId: number,
    notificationType: InvestorNotificationTypes,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.investorElectionsEmail_SendElectionNotification2(
      selectionCacheId,
      investorId,
      notificationType,
      abortSignal
    )
  }

  /** Perform user provisioning based on the investor email */
  const provisionClient = async (
    selectionCacheId: string,
    entityGroupId: number,
    uniqueToken: string,
    investorId: number,
    allocatingEntityIds: number[],
    abortSignal?: AbortSignal
  ) => {
    const provisionUserRequest: ProvisionUsersRequestModel = {
      entityGroupId: entityGroupId,
      uniqueToken: uniqueToken,
    }
    // If investor id is there then set investor id to perform
    // provisioning only for specified investor id
    if (investorId > 0) {
      provisionUserRequest.investorId = investorId
      provisionUserRequest.allocatingEntityIds = allocatingEntityIds
    } else {
      // Perform user provisioning for all the selected investors
      provisionUserRequest.cacheId = selectionCacheId!
    }
    // API call
    return await filesClientApi.investorElections_ProvisionClient(
      provisionUserRequest,
      abortSignal
    )
  }

  /**Calls the API for starting to send initial and reminder Mails */
  const getTaxYears = async (entityGroupId: number, abort: AbortSignal) => {
    return await filesClientApi.elections_GetTaxYears(entityGroupId, abort)
  }

   /** Returns the filter options for a given property name (column name) for the records
   *   in the StateManagerInvestorPage component. */
   const getInvestorStatusFilterOptions = async (
    cacheId: ObjectId,
    filterField: string,
    filterValue: string,
    skip: number,
    take: number
  ) => {
    try {
      const filterOptionRequest: FilterOptionRequest = {
        cacheId: cacheId,
        filterField: filterField,
        filterValue: filterValue,
        skip: skip,
        take: take,
      }
      return await filesClientApi.investorSelection_GetInvestorSelectionFilterOptions(
        filterOptionRequest
      )
    } catch (err) {
      throw new Error(`Error occurred while getting filter options: ${err}`)
    }
  }

  /** Reset the updated mailids to mongo DB cached data */
  const resetEmailIdsForIndividualInvestor = async (
    investorId: number,
    sessionId: ObjectId,
    emailIds: string
  ) => {
    await filesClientApi.investorElections_ResetEmailIdsForIndividualInvestor(
      investorId,
      sessionId,
      emailIds
    )
  }

  /** Checks whether or not all allocating entities for a specified general options ID have at least one
   *   selected state.  This is done before publishing so we can warn the user if they're about to
   *   publish an allocating entity that would result in no meaningful outcome.   */
  const entityOptionsHaveStates = async (
    generalOptionsId: number,
    allocatingEntityIds: number[]
  ) => {
    return await filesClientApi.elections_EntityOptionsHaveStates({
      generalOptionsId: generalOptionsId,
      allocatingEntityIds: allocatingEntityIds,
    })
  }

   /** Get the InvestorTable report */
   const getInvestorTableReport = async (
    entityGroupId: number,
    taxYear: number,
    cacheId: string,
    uniqueOperationId: number,
    allocatingEntityIds: number[],
    operationType: string
  ) => {

    const investorReport: InvestorReport = {
      allocatingEntityIds: allocatingEntityIds,
      entityGroupId: entityGroupId,
      taxYear: taxYear,
      uniqueOperationId: uniqueOperationId,
      operationType: operationType,
      cacheId : cacheId!
    }

    // Passing details with operationId (operation id is unique number)
    await filesClientApi.report_RequestStateManagerReports(investorReport)
  }

  /** Get the ClientFamily or Partner Sight report */
  const getClientPartnerSightReport = async (
    entityGroupId: number,
    taxYear: number,
    investorId: number,
    cacheId: string,
    uniqueOperationId: number,
    allocatingEntityIds: number[],
    operationType: string,
    includeNotSubmitted?: boolean
  ) => {

    const investorReport: InvestorReport = {
      allocatingEntityIds: allocatingEntityIds,
      entityGroupId: entityGroupId,
      taxYear: taxYear,
      uniqueOperationId: uniqueOperationId,
      operationType: operationType,
      includeNotSubmitted: includeNotSubmitted
    }

    // If investor id is there then set investor id to generate
    // report only for specified investor id
    if (investorId && investorId > 0) {
      investorReport.investorId = investorId

    } else {
      // Generate report for all the selected investors
      investorReport.cacheId = cacheId!
    }

    // Passing details with operationId (operation id is unique number)
    await filesClientApi.report_RequestStateManagerReports(investorReport)

  }

  /** Get the get Demographic Changes Report. As of now its returning dummy promise,will implement in future stories */
  const getDemographicChangesReport = async (
    entityGroupId: number,
    taxYear: number,
    investorId: number,
    cacheId: string,
    uniqueOperationId: number,
    allocatingEntityIds: number[],
    operationType: string
  ) => {
    const investorReport: InvestorReport = {
      allocatingEntityIds: allocatingEntityIds,
      entityGroupId: entityGroupId,
      taxYear: taxYear,
      uniqueOperationId: uniqueOperationId,
      operationType: operationType,
    }

    // If investor id is there then set investor id to generate
    // report only for specified investor id
    if (investorId && investorId > 0) {
      investorReport.investorId = investorId

    } else {
      // Generate report for all the selected investors
      investorReport.cacheId = cacheId!

    }

    // Passing details with operationId (operation id is unique number)
    await filesClientApi.report_RequestStateManagerReports(investorReport)
  }

  /** Get allocating entity Ids based on the cache id */
  const getSelectedInvestorAllocatingEntityIds = async (cacheId: string, abortSignal?: AbortSignal) => {
    return await filesClientApi.investorSelection_GetSelectedInvestorAllocatingEntityIds(
      cacheId,
      abortSignal
    )
  }

  /** Checks whether or not all allocating entities for a specified general options ID have at least one
   *   is Published.and returns Investors Count who have submitted the elections */
  const entityOptionsHavePublished = async (generalOptionsId: number) => {
    return await filesClientApi.elections_EntityOptionsHavePublished(
      generalOptionsId
    )
  }

  const getInvestorSelectionOperationCaps = async (cacheId: string) => {
    return await filesClientApi.investorSelection_GetInvestorSelectionOperationCaps(
      cacheId
    )
  }

  /** Retrieves Submitted Investors and Total Investors Count from Cache */
  const getElectionStatus = async (cacheId: string, abortSignal?: AbortSignal) => {
    return await filesClientApi.investorSelection_GetElectionStatus(cacheId, abortSignal)
  }

  return wrapApiWithCommonErrorHandlers({
    getGeneralOptions,
    getEntityOptions,
    insertorUpdateSMEntityOptions,
    initializeSMClient,
    getEmailTemplateByGeneralOptions,
    insertOrUpdateEmailTemplate,
    deleteEmailTemplateByGeneralOptions,
    getStateElections,
    updateStateElections,
    getInvestors,
    getInvestorSelections,
    isAllInvestorsSelected,
    createInvestorSelectionSession,
    updateInvestorSelection,
    updateAllInvestorSelections,
    saveInvestorSelectionSession,
    cancelInvestorSelectionSession,
    clearCustomEntitySelections,
    isAllInvestorSessionSelected,
    updateSMGeneralOptions,
    getInvestorsStatus,
    publishEntityOptions,
    getInvestorElectionInfo,
    deleteInvestorElections,
    updateInvestorElectionInfo,
    getInvestorElections,
    getEligibleStates,
    getInvestorWithholdingEntities,
    updateInvestorElections,
    updateAllInvestorStatusSelection,
    updateInvestorStatusSelection,
    getInvestorStatusAllSelectedState,
    cleanupServerSideCache,
    getSubmissionStatus,
    autoSaveIneligibileElections,
    submitElection,
    unlockSelections,
    unlockSelectionsForIndividualInvestor,
    getEmailTemplate,
    sendInitialReminderEmails,
    sendInitialReminderIndividualEmails,
    getInvestorStatusFilterOptions,
    provisionClient,
    getTaxYears,
    resetEmailIdsForIndividualInvestor,
    entityOptionsHaveStates,
    getClientPartnerSightReport,
    getDemographicChangesReport,
    entityOptionsHavePublished,
    getSelectedInvestorAllocatingEntityIds,
    getInvestorSelectionOperationCaps,
    getElectionStatus,
    getInvestorTableReport
  })
}
