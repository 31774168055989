import React, { useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { ButtonFunction, Modal } from '../modal/modalBase'
import './deleteFilesConfirmation.scss'
import { File } from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'

export interface DeleteFileConfirmationProps {
  onConfirm: () => void
  onCancel: () => void
  files: File[]
  isVisible: boolean
}

/*** Delete Parent File confirmation modal */
export const DeleteFileConfirmation = ({
  onConfirm,
  onCancel,
  files,
  isVisible,
}: DeleteFileConfirmationProps) => {
  // Get the Delete Files function from our API hook.
  const { deleteFiles } = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [deletePrmoise, setDeletePromise] = useState<Promise<any>>()
  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm Delete',
      onClick: async () => {
        setShowSpinner(true)
        let delPromise = deleteFiles(files.map((f) => f.id!))
        setDeletePromise(delPromise)
        onConfirm()
        await delPromise
      },
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 160,
        'data-testid': 'confirm-delete-button',
      },
    },
  ]

  return (
    <div>
      <Modal
        visible={isVisible}
        title='Delete Package(s)'
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={300}
      >
        <div
          className='ModalConfirmation'
          data-testid='delete-files-confirmation'
        >
          <div className='files-list'>
            {files.map((f) => (
              <div data-testid='file-item' key={f.id!.toString()}>
                {f.externalFileName}
              </div>
            ))}
          </div>
          <div>
            This action will permanently delete the selected package(s) and
            cannot be reverted.
          </div>
        </div>
      </Modal>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='File delete failed'
        inProgressTitleMessage='Delete Document'
        inProgressMessage='The document is being deleted...'
        successTitleMessage='Delete Successful'
        successMessage='The document has been deleted.'
        onClose={() => setShowSpinner(false)}
        apiAction={deletePrmoise}
      />
    </div>
  )
}
