import moment from 'moment'
import { REACT_APP_REQUESTASSISTANCEEMAIL } from '../../envVariables'

export enum InvestorElectionPagesEnum {
  Default = 'InvestorElectionsPage',
  ElectionReview = 'ElectionReview',
  ElectionSubmissionPage = 'ElectionSubmissionPage',
  SubmissionOverview = 'SubmissionOverview',
  ElectionsNotSubmitted = 'ElectionsNotSubmitted'
}

/**
 * Tax classification types(updated on 10/2023)
 */
export const taxClassificationTypes = [
  { name: 'Individual', value: 'Individual' },
  { name: 'Corporation', value: 'Corporation' },
  { name: 'Partnership', value: 'Partnership' },
  { name: 'S Corporation', value: 'S Corporation' },
  { name: 'Estate', value: 'Estate' },
  { name: 'Trust', value: 'Trust' },
  { name: 'Grantor Trust', value: 'Grantor Trust' },
  { name: 'Exempt Organization', value: 'Exempt Organization' },
  { name: 'Limited Liability Company', value: 'Limited Liability Company' },
  {
    name: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership',
  },
  { name: 'IRA', value: 'IRA' },
]

/** Handle Contact Adminstration to trigger Email*/
export const handleContactEmail = (
  investorName: string,
  investorGroupName: string,
  taxYear: string
) => {
  const toEmail = REACT_APP_REQUESTASSISTANCEEMAIL
  // Construct email with dynamic values
  const subject = `Please Unlock: ${getEncodedString(investorName)} - Elections`
  const body = `Please Unlock ${taxYear} - ${getEncodedString(
    investorName
  )} - ${getEncodedString(investorGroupName)} Elections`
  const mailtoLink = `mailto:${getEncodedString(
    toEmail
  )}?subject=${getEncodedString(subject)}&body=${getEncodedString(body)}`

  // Create an invisible anchor element
  const anchor = document.createElement('a')

  // Set the anchor's href to the mailto link
  anchor.href = mailtoLink

  // add the anchor to the document's body
  document.body.appendChild(anchor)

  // trigger a click event on the anchor
  anchor.click()

  // remove the anchor from the document to clean up
  document.body.removeChild(anchor)
}

/** Handle Contact Adminstration to trigger Email with blank subject and body*/
export const handleContactEmailWithBlankSubjectAndBody = () => {
  const toEmail = REACT_APP_REQUESTASSISTANCEEMAIL

  // Construct email with dynamic values
  const mailtoLink = `mailto:${getEncodedString(toEmail)}`

  // Create an invisible anchor element
  const anchor = document.createElement('a')

  // Set the anchor's href to the mailto link
  anchor.href = mailtoLink

  // add the anchor to the document's body
  document.body.appendChild(anchor)

  // trigger a click event on the anchor
  anchor.click()

  // remove the anchor from the document to clean up
  document.body.removeChild(anchor)
}

//Get the encoded string
export const getEncodedString = (stringValue: string) => {
  return stringValue.replaceAll('&', '%26')
}

// Format date string(MM/DD/YYYY)
export function renderFormattedDate(date: Date) {
  // Formatting the date
  return moment(date.toLocaleString().split('T')[0]).format('MM/DD/YYYY')
}

// Foreign country enum, we are going to use this value across state-manager
export enum ForeignCountryEnum {
  Name = 'Foreign Country',
  StateCode = 'FOREIGN',
}

export interface NameCardProps {
  id: number
  name: string
  logoUrl: string
  isFavorite: boolean
  list?: Array<string>
  partnerEntityId: number
  logoUri?: string
  uniqueId: string
}
