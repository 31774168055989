import { useEffect, useMemo, useState } from 'react'
import { InvestorElectionsPage } from './investorElectionsPage'
import { InvestorElectionPagesEnum } from './investor-utils'
import { useParams } from 'react-router-dom'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { LoadingStateEnum, smGeneralElectionInfoAtom } from '../../state/atom'
import { useRecoilValue } from 'recoil'
import { getInvestorLandingRoute } from '../utility/route-creation'
import { ElectionSubmissionComplete } from './electionSubmissionComplete'
import { ElectionReview } from './investor-review/investor-election-review'
import { InvestorElectionOverview } from './investor-review/investor-submission-overview'
import './investorElectionsMaster.scss'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { InvestorNoElectionsSubmitted } from './investor-no-elections-submitted'
import { convertDateToUTC, defaultDate } from '../utility/date-utility'

export const InvestorElectionMaster = () => {
  const stateManagerApi = useStateManagerApi()
  const navigate = useNavigate()
  const { loadingState, electionsInfo, cutoffDate } = useRecoilValue(
    smGeneralElectionInfoAtom
  )
  const dateOfSubmission = electionsInfo?.submittedDate
  const [isShowElectionReview, setIsShowElectionReview] =
    useState<boolean>(false)
  const [isShowElectionOverview, setIsShowElectionOverview] =
    useState<boolean>(false)

  const { groupId, partnerId, taxYear } = useParams() as {
    groupId: string
    partnerId: string
    taxYear: string
  }

  /** check if cutoff date is past */
  const isCutOffDatePast = (cutOffDate: Date) => {
    const currentDate = new Date()
    if (cutOffDate) {
      // convert dates to UTC and compare
      if (convertDateToUTC(cutoffDate!) <= convertDateToUTC(currentDate)) {
        return true
      }
    }
    return false
  }

  // make sure cutOff date is past, not the default date and have no for electionInfo
  const electionsNotSubmitted =
    (electionsInfo === undefined || electionsInfo === null) &&
    cutoffDate !== defaultDate &&
    isCutOffDatePast

  /** Call the API to get the Investor election info
   * using this on General tab of Investor Elections
   *  */
  useEffect(() => {
    const abortController = new AbortController()
    /** If there's any change in the tax year or partnerId or groupId, load the InvestorElectionInfo data from the API */
    stateManagerApi
      .getInvestorElectionInfo(
        parseInt(groupId),
        parseInt(partnerId),
        parseInt(taxYear),
        abortController.signal
      )
      .catch((err) => {
        //if any error Found then redirect user to landing page
        navigate(getInvestorLandingRoute())
      })
    setIsShowElectionOverview(false)
    setIsShowElectionReview(false)
    // Abort the API Call when user moves away from this page
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [taxYear, partnerId, groupId])

  /** Return the default page to load */
  const electionPage = useMemo(() => {
    /** If submittedDate is recorded then default it to Submission Page  */
    if (dateOfSubmission) {
      // if user choose to see the submission overview then isShowElectionOverview will be true and SubmissionOverview page will be visible
      if (isShowElectionOverview) {
        return InvestorElectionPagesEnum.SubmissionOverview
      } else {
        // defult page if sbmissionDate is available
        return InvestorElectionPagesEnum.ElectionSubmissionPage
      }
    } else if (isShowElectionReview) {
      /**If no submitted Date and all the tabs data is saved,
       */
      return InvestorElectionPagesEnum.ElectionReview
    } else if (electionsNotSubmitted) {
      // Elections Not submitted page
      return InvestorElectionPagesEnum.ElectionsNotSubmitted
    } else {
      // default page if no submission date is available
      return InvestorElectionPagesEnum.Default
    }
    // inject the dependency if we need to load the diffrent page
  }, [
    dateOfSubmission,
    isShowElectionReview,
    isShowElectionOverview,
    electionsNotSubmitted,
  ])

  return (
    <div className='election-master'>
      {loadingState === LoadingStateEnum.Loaded ? (
        <div>
          {electionPage === InvestorElectionPagesEnum.Default && (
            <InvestorElectionsPage
              showElectionReview={(value: boolean) =>
                setIsShowElectionReview(value)
              }
            />
          )}
          {electionPage === InvestorElectionPagesEnum.ElectionReview && (
            <ElectionReview
              showElectionReview={(value: boolean) =>
                setIsShowElectionReview(value)
              }
            />
          )}
          {electionPage ===
            InvestorElectionPagesEnum.ElectionSubmissionPage && (
            <ElectionSubmissionComplete
              showElectionOverview={(value: boolean) =>
                setIsShowElectionOverview(value)
              }
            />
          )}
          {electionPage === InvestorElectionPagesEnum.SubmissionOverview && (
            <InvestorElectionOverview
              showElectionOverview={(value: boolean) =>
                setIsShowElectionOverview(value)
              }
            />
          )}
          {electionPage === InvestorElectionPagesEnum.ElectionsNotSubmitted && (
            <InvestorNoElectionsSubmitted />
          )}
        </div>
      ) : (
        <div className='loading-title'>
          <span> Loading...</span>
        </div>
      )}
    </div>
  )
}
