import {
    useLocation,
    matchPath,
} from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { navWidgetState } from './nav-widget-state'
import { NavWidgetOption, NavWidgetOptionsByLevel } from './nav-widget-models'
import produce from 'immer'
import { useRecoilPinner } from '../../signalr/useRecoilPinner'
import { useEffect } from 'react'


/** Given the visiblePaths property value, from a NavWidgetOption, returns
 *   a boolean value indicating whether or not it matches a specified path. */
function isPathMatch(p: NavWidgetOption['visibilityPaths'], currentPath: string) {
    // If the value is just "true", then it's a match.
    if (p === true) {
        return true
    }

    // We're forced to include the value false, so let's just go with it, though it doesn't make sense.
    if (p === false) {
        return false
    }

    // Check for a string match.
    if (typeof p === 'string') {
        return matchPath(p, currentPath)
    }

    // All that's left is an array of strings.  Match against any of them.
    return p.some(s => matchPath(s, currentPath))
}

export function useNavWidgetUtilities() {
    const navWidgetValue = useRecoilValue(navWidgetState)
    const location = useLocation()

    // Get the options from the state.
    const navOptions = navWidgetValue.options

    /** Returns the nav options grouped by their level property. */
    const groupNavOptionsByLevel = () => {
        const result = {} as NavWidgetOptionsByLevel
        const arrayResult = [] as NavWidgetOption[][]

        navOptions
            .filter(o => isPathMatch(o.visibilityPaths, location.pathname))
            .forEach(option => {
                // Get the group for this item.
                let group = result[option.level]

                // Create the new group, and add it to the results
                //  if it doesn't exist.
                if (!group) {
                    group = [] as NavWidgetOption[]
                    result[option.level] = group

                    arrayResult[option.level] = group
                }

                // Add this item to the group.
                group.push(option)
            })

        result.asArray = arrayResult

        return result
    }

    /** Provides the navigation options for each level of navigation for the current
     *   URL location of the site. */
    const generateNavWidgetStateForLocation = (): NavWidgetOption[] => {
        // Return value.
        const result = [] as NavWidgetOption[]

        // Get the nav options for each level.
        const levels = groupNavOptionsByLevel()

        // Get the nav option for each level.  Stop looking
        //  when the first level is found without a navigation option.
        let curLevel = 0
        while (levels[curLevel]) {
            const curResult = levels[curLevel]
              // Sort, first, by the sortOrder property.  undefined should always go last.
              .sort((v1, v2) => {
                const vs1 =
                  v1.sortOrder === undefined ? Number.MAX_VALUE : v1.sortOrder
                const vs2 =
                  v2.sortOrder === undefined ? Number.MAX_VALUE : v2.sortOrder

                return vs1 - vs2
              })
              // Repackage our list so we can sort it easily.
              .map((x) => ({ sortVal: x.isManuallySelected ? 1 : 0, value: x }))
              // Sort the items, so the manually selected items are first.
              .sort((v1, v2) => v2.sortVal - v1.sortVal)
              // Unpackage our sort wrappers.
              .map((x) => x.value)
              // Find the item for the current level, if there is one.
              .find(
                (x) =>
                  x.isManuallySelected ||
                  (x.selectionPath &&
                    matchPath(x.selectionPath as string, location.pathname))
              )

            // Only add this if there is a result.
            if (curResult) {
                result.push(curResult)
            }

            curLevel++
        }

        return result
    }

    return {
        /** Returns the nav options grouped by their level property. */
        groupNavOptionsByLevel,

        /** Provides the navigation options for each level of navigation for the current
         *   URL location of the site. */
        generateNavWidgetStateForLocation
    }
}

/** Allows the addition of dynamic NavWidget locations.  When this hook goes out of scope, those items will be removed.
 *   If the dynamic items must be maintained throughout the lifetime of the application, use this hook
 *   on a component that will not go out of scope, such as the App.tsx, or similar component. */
export function useNavWidgetPageItems(additions: NavWidgetOption[]) {
    const widgetState = useRecoilPinner(navWidgetState)

    useEffect(() => {
        // Create a new block ID for these items.
        const blockId = Math.floor(Math.random() * 10000000)

        // Copy the widget options that will be added to the output.
        const newWidgetOptions = additions.map(a => {
            return { ...a, blockId: blockId }
        })

        // Add these to the widget state.
        widgetState.set(produce(draft => {
            draft.options.push(...newWidgetOptions)
        }))

        return () => {
            // Remove all items in the state that were
            //  added for this group.
            widgetState.set(produce(draft => {
                draft.options = draft.options.filter(o => o.blockId !== blockId)
            }))
        }
    }, [additions])
}