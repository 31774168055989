import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import {
  clientAndPartnershipStateAtom,
  userEntityGroupState,
} from '../../../state/atom'
import { getClientGroupPageRoute } from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { NavWidgetOption } from '../nav-widget-models'
import { CLIENT_GROUP_LIST_LEVEL } from '../widget-levels'

export const useClientsNavWidget = () => {
  const entityGroupState = useRecoilValue(userEntityGroupState)
  const clientAndPartnerships = useRecoilValue(clientAndPartnershipStateAtom)

  const navItems: NavWidgetOption[] = useMemo(
    () =>
      entityGroupState.groups.map((g) => ({
        label: g.name!,
        level: CLIENT_GROUP_LIST_LEVEL,
        visibilityPaths: true,
        navigationPath: getClientGroupPageRoute(g.id!),
        isManuallySelected: clientAndPartnerships.clientGroupId === g.id,
      })),
    [entityGroupState, clientAndPartnerships.clientGroupId]
  )

  useNavWidgetPageItems(navItems)
}
