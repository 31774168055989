import { useEffect } from 'react'
import { SignalREventHandler } from '../signalr-models'
import { useRecoilPinner } from '../useRecoilPinner'
import { signalR } from '../signalr-runtime'
import { File } from '../../api-client/investor-portal-client'
import { childFileListAtom, parentFileListAtom } from '../../state/atom'
import produce from 'immer'

/** Given an existing list of file in the current state, updates the file list
 *   to use a new state of a specified file.  This function assumes that the draft
 *   of the produce function (from immer) is being passed as the existing file list.
 */
function updateFile(f: File, draftFiles: File[]) {
  // Try to find the existing item for this change.
  const existingId = draftFiles.findIndex(
    (c) =>
      c.id === f.id ||
      c.externalFileName?.toLocaleLowerCase() ===
        f.externalFileName?.toLocaleLowerCase()
  )

  if (existingId >= 0) {
    // Handle a deletion.
    if (f.isDeleted) {
      draftFiles.splice(existingId, 1)
    } else {
      // Set email template if its empty from API
      if (!f.emailTemplate || f.emailTemplate.length === 0) {
        f.emailTemplate = draftFiles[existingId].emailTemplate
      }
      // Replace the existing item.
      draftFiles[existingId] = f
    }
  } else {
    // Since this file doesn't exist already, then it must be added
    //  (unless it's deleted).
    if (!f.isDeleted) {
      draftFiles.push(f)
    }
  }
}

/** Registers the SignalR handler for the UpdateFiles event. */
export const useUpdateFileSignalRHandler = () => {
  // PLACEHOLDER:
  // Get references to the atoms that will be used in the SignalR event handler.
  const childFileListPinner = useRecoilPinner(childFileListAtom)
  const parentFileListPinner = useRecoilPinner(parentFileListAtom)

  useEffect(() => {
    // The handler to be registered.
    const handler: SignalREventHandler = {
      eventName: 'FilesUpdated',
      action: (updatedFiles: File[]) => {
        // Update the parent file list.
        parentFileListPinner.set((curVal) =>
          produce(curVal, (draft) => {
            updatedFiles.forEach((f) => {
              if (f.partnershipEntityId === draft.partnershipId && !f.parentId) {
                updateFile(f, draft.files)
              }
            })
          })
        )

        // Update the child file list.
        childFileListPinner.set((curVal) =>
          produce(curVal, (draft) => {
            updatedFiles.forEach((f) => {
              if (f.parentId === draft.parentFileId) {
                updateFile(f, draft.files)
              }
            })
          })
        )
      },
    }

    // Register the handler.
    signalR.registerHandler(handler)

    // In the case the calling component dismounts, we want
    //  to be sure to cleanup our handler so that, if it remounts,
    //  we don't have the handler being called twice.
    return () => {
      // Unregister the handler.
      signalR.unregisterHandler(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
