
/** Returns a readable size of bytes in the form of KB, MB, etc, given
 *   a specified byte value. */
export function getByteSizeFromBytes(bytes: number | undefined): string {
    if (!bytes) {
        return '0 b'
    }

    // Calculate the different sizes.
    const kb = bytes / 1024
    const mb = kb / 1024
    const gb = mb / 1024

    // First value that is >=1 (not just a decimal) should
    //  be used.  Start highest, and work down.
    if (gb >= 1) {
        return Math.round(gb) + 'gb'
    }

    if (mb >= 1) {
        return Math.round(mb) + 'mb'
    }

    if (kb >= 1) {
        return Math.round(kb) + 'kb'
    }

    return Math.round(bytes) + 'b'
}
