import { useContext, useMemo } from 'react'
import { PermissionTypes } from '../api-client/entity-manager-client-v3'
import { AccessManagerContext } from './access-provider'
import {
  PermissionRequestSet,
  PermissionRequest,
  PermissionRequestLevels,
} from './permission-models'

/** Given an array of objects, flattens all values of all objects within the array into a single array. */
export function decomposePermissionSet_INTERNAL(
  permissionRequestSet: PermissionRequestSet
) {
  // Return value.
  const result = new Array<any>()

  // For every object in the array, flatten the values into an array, and add its elements to the result.
  permissionRequestSet.forEach((x) => {
    var values = Object.values(x)
    result.push(...values)
  })

  // Return the result.
  return result
}

/** When checking a set of permissions in the `usePermissions` hook, this specifies whether to return if either
 *   ALL or ANY permission checks are a match. */
export type PermissionCheckTypes = 'all' | 'any'

/** Accepts a set of PermissionRequest objects, and returns a boolean value indicating whether or not the user has access to some/all of the requested resources.  */
export const usePermissions = (
  permissionRequestSet: PermissionRequestSet,
  checkType: PermissionCheckTypes = 'all'
) => {
  // Get the context with the permission data.
  const permissionData = useContext(AccessManagerContext)

  // Get the values of all of the requests as a flat array to be used in the useMemo dependency array.
  const decomposedVariables =
    decomposePermissionSet_INTERNAL(permissionRequestSet)

  return useMemo<boolean>(() => {
    // If we have no permission data yet, then we can't check anything.
    if (!permissionData) {
      return false
    }

    /** Function that will check the access of a single PermissionRequest object. */
    const hasAuthorization = (permission: PermissionRequest) => {
      // Get the permission data for this permission's name.
      const permissionSet = permissionData[permission.permissionName]

      // Ensure we don't have BOTH a clientId and an ipmNumber.
      if (!!permission.ipmNumber && !!permission.entityOrClientId) {
        throw new Error(
          `Permission set for ${permission.permissionName} may not specify both entityOrClientId and ipmNumber.`
        )
      }

      // If we don't have a permission set, then we don't have access.
      if (!permissionSet) {
        return false
      }

      // If the request type is Any, then all we need is to have a permission set.
      if (permission.level === PermissionRequestLevels.Any) {
        return true
      }

      // Translate the permission type to the appropriate level.
      let requestLevel: PermissionTypes
      switch (permission.level) {
        case PermissionRequestLevels.Client:
          requestLevel = PermissionTypes.Client
          break
        case PermissionRequestLevels.Entity:
          requestLevel = PermissionTypes.Entity
          break
        default:
          throw new Error(`Invalid permission level: ${permission.level}`)
      }

      if (!!permission.entityOrClientId) {
        // Check the case where we have the client ID or entity ID.
        return permissionSet.some(
          (x) =>
            x.entityId === permission.entityOrClientId &&
            x.level === requestLevel
        )
      } else if (!!permission.ipmNumber) {
        // Check the case where we have the IPM number.
        return permissionSet.some(
          (x) => x.ipmCode === permission.ipmNumber && x.level === requestLevel
        )
      } else {
        // If we don't have either of the above values, then this request is invalid.
        throw new Error(
          `At least one of entityOrClientId or ipmNumber must be specified.`
        )
      }
    }

    // Depending on the type of the request, return the results of the check.
    if (checkType === 'all') {
      return permissionRequestSet.every(hasAuthorization)
    } else if (checkType === 'any') {
      return permissionRequestSet.some(hasAuthorization)
    } else {
      throw new Error(`Invalid check type: ${checkType}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionData, checkType, ...decomposedVariables])
}

/** Hook used to determine whether or not permission have been loaded from the server. */
export const useIsPermissionsLoaded = () => {
  // Get the context with the permission data.
  const permissionData = useContext(AccessManagerContext)

  // If we have data, then they've been loaded.  Otherwise, they haven't.
  return !!permissionData
}
