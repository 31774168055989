import { FallbackProps } from 'react-error-boundary'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { useEffect } from 'react'
import './error-page.scss'
import { getErrorPageRoute } from '../utility/route-creation'
/** Error fall back component - to display error message in UI */
export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate()
  // In the future, we need to log this error message for development debugging purposes. We can save this error to MongoDB or SQL and generate a unique ID for it.
  // Pass this ID as a route parameter to the error page and display the unique ID on the error page. This ID can be used as a reference for error debugging.

  //console.log("Logged Error:" + error);

  // Instead of rendering the error as a fallback component, we navigate to an error page. This approach is taken to prevent errors from appearing in isolated parts of the UI,
  // such as the Banner, while still allowing the user to interact with other parts of the application like the left menu and main container. Redirecting to a custom error page
  //ensures a more consistent and controlled error handling experience.
  useEffect(() => {
    navigate(getErrorPageRoute())
  }, [])

  return (
    <div className='error-page'>
      <div className='error-message'>
        An error occurred in Investor Portal. Please email the RSM Service Desk
        RSMServiceDesk@rsmus.com to notify them that you are unable to access
        Investor Portal.
      </div>
    </div>
  )
}
