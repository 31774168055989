/** Get data from local storage based on the key */
export const getLocalStorageValue = (key: any) => {
  return localStorage.getItem(key)
}

/** Set data in local storage based on the key */
export const setLocalStorageValue = (key: any, value: any) => {
  localStorage.setItem(key, value)
}

/** Remove data from local storage based on the key */
export const removeLocalStorageValue = (key: any) => {
  if (getLocalStorageValue(key)) {
    localStorage.removeItem(key)
  }
}
