
export function saveBlob(blob: Blob, fileName: string) {
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    ; (window.navigator as any).msSaveBlob(blob, fileName)
  } else {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
  }
}