import { ReactNode } from "react";
import { RecoilPinner, useRecoilPinner } from "../../../signalr/useRecoilPinner";
import { FormattedDialogTypes, getButtonTypeForDialogType } from "../formattedDialog";
import { CommonDialogButtonFunction, CommonDialogProps, commonDialogs } from "./common-dialog-state";
import { produce, castDraft } from 'immer'

export interface CommonDialogOptions {
    /** Indicates the type of dialog to show, ultimately dictating the style of the dialog. */
    dialogType: FormattedDialogTypes
    /** The optional title to show in the dialog.  If omitted, a standard title is used, based on the dialogType property. */
    title?: string
    /** The content to show in the dialog. */
    content: ReactNode
    /** Optional ID number to assign to the dialog.  This shold be UNIQUE from every
     *   other dialog open.  If omitted, a random ID is generated and returned from the function.
     *   The ID is used to obtain a reference to this dialog data, and alter it. */
    testId?: string
    /** Optional set of buttons to display. */
    buttonFunctions?: CommonDialogButtonFunction[]
    omitDefaultButton?: boolean
    /** Boolean value indicating whether or not button functions with the isCloseAction will be
     *   styled based on the `dialogType` property.  Default: true
     */
    setDefaultButtonColors?: boolean
    /**
   * Setting this flag to true will disable the default behavior of closing the modal
   * when clicking outside while setting it to false will allow the modal to close on outside click.
   */
    preventModalClose?:boolean
}

/** Hook that provides methods for showing arbitrary dialogs, and getting the existing "common dialogs".
 *   This requires a hook, since it uses the recoil state.  */
export const useCommonDialogs = () => {
    const commonDialogsPinner = useRecoilPinner(commonDialogs)

    /** Displays a formatted dialog with specified properties, and returns its ID, so it may be manipulated, if needed. */
    const showDialog = ({ testId, dialogType, title, content, buttonFunctions, omitDefaultButton, setDefaultButtonColors,preventModalClose }: CommonDialogOptions) => {
        // Generate a unique ID for this dialog.
        const dialogId = Math.floor(Math.random() * 1000000)

        // Copy the button functions, so we can alter them.
        let functions = buttonFunctions?.slice()

        // Create the function that closes the dialog, when the close button is clicked.
        const closeDialog = () => {
            // Get the state with the specific ID.
            const targetId = commonDialogsPinner.value.findIndex((p) => p.id === dialogId)

            // Exit if the target wasn't found.
            if (targetId < 0) {
                return
            }

            // Close this dialog.
            commonDialogsPinner.set(
                produce((draft) => {
                    // The index of this item might have changed since we found it earlier.
                    //  Remember, this function may not be called right away.
                    const curTargetId = draft.findIndex((p) => p.id === dialogId)

                    // If the target still exists, then update it.
                    if (curTargetId >= 0) {
                        draft.splice(curTargetId, 1)
                    }
                })
            )
        }

        // Get the default button type for this dialog type.
        const defaultButtonType = getButtonTypeForDialogType(dialogType)

        // Create a close button to use as needed.
        const closeButton = {
            label: 'Close',
            onClick: () => closeDialog(),
            isCloseAction: true,
            isDefaultAction: true,
            buttonProps: {
                type: defaultButtonType,
            },
        } as CommonDialogButtonFunction

        if (!functions) {
            if (!omitDefaultButton) {
                // If there are no button functions for this, then
                //  create a default set of button functions.
                functions = [closeButton]
            } else {
                functions = []
            }
        } else {
            // Update the functions to close the dialog when they are called.
            functions = functions
                .map((f) => {
                    return produce(f, draft => {
                        if (draft.isCloseAction) {
                            const oldClick = draft.onClick
                            draft.onClick = () => {
                                // Call the existing function, if there is one.
                                if (oldClick) {
                                    oldClick()
                                }

                                // Close the dialog.
                                closeDialog()
                            }
                        }
                    })
                })

            // If there are no close actions, then add one.
            if (!omitDefaultButton && functions.every((f) => !f.isCloseAction)) {
                functions.push(closeButton)
            }
        }

        // Set the default colors, if required.
        //  We only want to do this if there's no type already specified.
        if (setDefaultButtonColors !== false) {
            functions = functions.map((f) =>
                produce(f, draft => {
                    if (draft.isCloseAction && !f.buttonProps?.type) {
                        if (!draft.buttonProps) {
                            draft.buttonProps = {
                                type: defaultButtonType,
                            }
                        } else {
                            draft.buttonProps!.type = defaultButtonType
                        }
                    }
                })
            )
        }

        // Create a new dialog with the defined properties.
        commonDialogsPinner.set(produce(draft => {
            draft.push({
                id: dialogId,
                testId: testId,
                dialogType: dialogType,
                title: title,
                children: content,
                visible: true,
                buttonFunctions: castDraft<CommonDialogButtonFunction[] | undefined>(functions),
                preventModalClose:preventModalClose
            })
        }))

        // Return the ID of the dialog, so it may be manipulated outside this function.
        return dialogId
    }

    /** Updates a specified common dialog.  NOTE: Some automation is performed when setting up a new dialog
     *   for automatically closing a dialog and such.  This is not done with this method. 
     *   Updates here should be similar to the FormattedDialog component, EXCEPT, you must use
     *   the close method when closing a dialog so the state is properly cleaned up.
     * */
    const updateDialog = (dialogInfo: CommonDialogProps) => {
        commonDialogsPinner.set(produce(draft => {
            // Find the dialog in question.
            const dialogIndex = draft.findIndex(x => x.id === dialogInfo.id)

            // If not found, then we have a problem.
            if (dialogIndex < 0) {
                throw new Error(`Could not find a common dialog with the id of ${dialogInfo.id}`)
            }

            // Update the dialog information.
            draft[dialogIndex] = castDraft(dialogInfo)
        }))
    }

    /** Returns a dialog, found by its ID number. */
    const getDialogById = (id: number) => {
        // We don't want people updating the actual state object.
        return { ...commonDialogsPinner.value.find(d => d.id === id) } as CommonDialogProps
    }

    /** Closes a commonDialog by its specified ID. */
    const closeDialog = (dialogId: number) => {
        // Close the dialog.
        commonDialogsPinner.set(produce(draft => {
            // Find the index of the dialog.  We do this here so our update is in sync with
            //  the rest of the operations.
            const index = draft.findIndex(x => x.id === dialogId)

            // If not found, then we can't do much.
            if (index < 0) {
                throw new Error(`No dialog was found with the ID of ${dialogId}.`)
            }

            draft.splice(index)
        }))
    }

    return {
        /** Displays a formatted dialog with specified properties, and returns its ID, so it may be manipulated, if needed. */
        showDialog,
        /** Returns a dialog, found by its ID number. */
        getDialogById,
        /** Updates a dialog object with specified values.  This uses the id property from the input to find and update the dialog. */
        updateDialog,
        /** Closes a commonDialog by its specified ID. */
        closeDialog,
    }
}