import produce from 'immer'
import { atom } from 'recoil'
import {
  NavigateFunction,
  useNavigate as useRouterNavigate,
} from 'react-router-dom'
import { useRecoilPinner } from '../signalr/useRecoilPinner'
import { useCommonDialogs } from '../components/modal/commonDialog/common-dialog-operations'
import { useEffect } from 'react'

interface NavGuard {
  canBlock: () => boolean
  message: string
  cleanup?: () => void
}
const navGuardState = atom({
  key: 'NAV_GUARD_STATE',
  default: [] as NavGuard[],
})

/** Hook helps to prevent the naigation if there's  any unsaved changes in the page*/
export const useNavigationGuard = (
  newGuard: () => boolean,
  message: string,
  cleanup?: () => void
) => {
  const pinner = useRecoilPinner(navGuardState)

  useEffect(() => {
    window.onbeforeunload = function () {
      for (let i = pinner.value.length - 1; i >= 0; i--) {
        if (pinner.value[i].canBlock()) {
          return pinner.value[i].message
        }
      }
    }

    pinner.set(
      produce((draft) => {
        draft.push({ canBlock: newGuard, message, cleanup })
      })
    )

    return () => {
      // clear registered items
      pinner.set(
        produce((draft) => {
          const index = draft.findIndex(newGuard)
          draft.splice(index, 1)
        })
      )
      // if its last item then set to null
      if (pinner.value.length - 1 < 1) {
        window.onbeforeunload = null
      }
    }
  }, [newGuard, message, cleanup])
}

/** Navigate to the target without navguard */
export const useNavigation = () => {
  const navigate = useRouterNavigate() as any
  return navigate as NavigateFunction
}

/** Navigate to the target with navguard */
export const useNavigate = () => {
  const pinner = useRecoilPinner(navGuardState)
  const navigate = useRouterNavigate() as any
  const commonDialogApi = useCommonDialogs()

  // We don't care about any here
  const guardedNavigate = (args: any) => {
    let canNavigate = true
    for (let i = pinner.value.length - 1; i >= 0; i--) {
      if (pinner.value[i].canBlock()) {
        canNavigate = false
        commonDialogApi.showDialog({
          dialogType: 'general',
          title: 'Unsaved Changes',
          content: pinner.value[i].message,
          buttonFunctions: [
            {
              label: 'CLOSE',
              onClick: () => { },
              isCloseAction: true,
              buttonProps: {
                className: 'cancel-btn-modal',
                stylingMode: 'contained',
                type: 'default',
                width: 100,
              },
            },
          ],
          omitDefaultButton: true,
        })
      }
    }
    // if no  changes then execure navigate function
    if (canNavigate) {
      navigate(args)
    }
  }

  return guardedNavigate as NavigateFunction
}

/** Provides a function that performs checks on all anv guards registered with the useNavigationGuard method, and
 *   if all are valid, executes a specified callback function.   This should be used for functions like "logout" and similar. */
export const useNavigationAction = () => {
  const pinner = useRecoilPinner(navGuardState)
  const commonDialogApi = useCommonDialogs()

  // We don't care about any here
  const callbackGuard = (callbackAction: () => void) => {
    let canNavigate = true
    for (let i = pinner.value.length - 1; i >= 0; i--) {
      if (pinner.value[i].canBlock()) {
        canNavigate = false
        commonDialogApi.showDialog({
          dialogType: 'general',
          title: 'Unsaved Changes',
          content: pinner.value[i].message,
          buttonFunctions: [
            {
              label: 'CLOSE',
              onClick: () => { },
              isCloseAction: true,
              buttonProps: {
                className: 'cancel-btn-modal',
                stylingMode: 'contained',
                type: 'default',
                width: 100,
              },
            },
          ],
          omitDefaultButton: true,
        })
      }
    }

    // if no changes then execute navigate function
    if (canNavigate) {
      callbackAction()
    }
  }

  return callbackGuard
}
