import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ButtonFunction } from './modalBase'
import { OidcIdentityContext } from '@rsmus/react-auth'
import { FormattedDialog } from './formattedDialog'

export interface SessionTimeoutProps {
  closeDialog: () => void
  isOpen: boolean
}
const baseButtonProps = {
  useSubmitBehavior: true,
  stylingMode: 'contained',
}

export const SessionTimeoutModal = ({ closeDialog, isOpen }: SessionTimeoutProps) => {
  const [timeId, setTimeId] = useState<any>()
  const { logout } = useContext(OidcIdentityContext)
  const idleTime: number = 60 * 1000

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isOpen) {
        //If Idle timeout popup is open then close the popup and redirect to login page
        closeDialog()
        logout(true)
      }
    }, idleTime)
    setTimeId(timeoutId)
  }, [isOpen, closeDialog, logout])

  //Click on 'CONTINUE SESSION' - Close the popup & Clear the timer
  const onContinueClick = useCallback(() => {
    clearTimeout(timeId)
    closeDialog()
  }, [closeDialog, timeId])

  const buttonFunction = useMemo(() => {
    const buttons = [
      {
        label: 'Continue Session',
        display: true,
        isDefaultAction: true,
        buttonProps: {
          className: 'session-btn',
          ...baseButtonProps,
          'data-testid': 'session-btn',
          width:'200 px'
        },
        onClick: () => onContinueClick(),
      },
    ] as (ButtonFunction & { display?: boolean })[]

    // Return the buttons we need, based on their display property.
    return buttons.filter((x) => !!x.display)
  }, [onContinueClick])

  return (
    <FormattedDialog
      dialogType='general'
      visible={isOpen}
      title='Time Out'
      buttonFunctions={buttonFunction}
      maxWidth={570}
      maxHeight={180}
      disableScrollbar={true}
    >
        <span>
          Your session has been inactive. If you would like to continue your
          session, please click "Continue Session" button below, otherwise you
          will be logged out.
        </span>
    </FormattedDialog>
  )
}
