import { useState } from 'react'
import { FormattedDialog } from '../../modal/formattedDialog'
import { ButtonFunction } from '../../modal/modalBase'
import { SMSendEmails } from './send-email'
import { InvestorNotificationTypes } from '../../../api-client/investor-portal-client'

export interface EmailConfirmationModalProps {
  onPreviewClose: () => void
  emailType?: string
  sessionId: string | undefined
  investorId: number
  sendIndividualMail: boolean
  isAllSelected: boolean
  onCancel?: () => void
}

/** Confirmational Modal for Sending Initial and Reminder Email from Investor Page*/
export const EmailConfirmationModal = ({
  onPreviewClose,
  emailType,
  sessionId,
  sendIndividualMail,
  isAllSelected,
  investorId,
  onCancel,
}: EmailConfirmationModalProps) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const emailNotificationType = emailType === InvestorNotificationTypes.Initial
  const modalTitle = emailNotificationType
    ? 'Send ' + emailType + ' Email'
    : 'Send ' + emailType

  const initialModalMessage =
    isAllSelected && !sendIndividualMail
      ? 'All Investors for this Client who have not already received an Initial Email will be sent the Initial Email.  Do you wish to proceed?'
      : 'Selected Investor(s) for this Client who have not already received an Initial Email will be sent the Initial Email.  Do you wish to proceed?'

  const reminderModalMessage =
    isAllSelected && !sendIndividualMail
      ? 'All Investors for this Client with incomplete elections will receive this reminder.  Do you wish to proceed?'
      : 'Selected investor(s) for this Client with incomplete elections will receive this reminder.  Do you wish to proceed?'

  const modalMessage = emailNotificationType
    ? initialModalMessage
    : reminderModalMessage

  const buttonFunction: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => onPreviewClose(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Send Emails',
      onClick: () => {
        setShowSpinner(true)
      },
      isDefaultAction: false,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 140,
        'data-testid': 'sendemails-button',
      },
    },
  ]

  return (
    <div>
      <FormattedDialog
        dialogType='general'
        visible={true}
        title={modalTitle}
        buttonFunctions={buttonFunction}
        maxWidth={550}
        maxHeight={160}
        disableScrollbar={true}
        testId='modal-template-dialog'
      >
        <span>{modalMessage}</span>
      </FormattedDialog>
      <SMSendEmails
        onCloseModal={() => onPreviewClose()}
        emailType={
          emailNotificationType
            ? InvestorNotificationTypes.Initial
            : InvestorNotificationTypes.Reminder
        }
        sessionId={sessionId}
        isSpinnerVisible={showSpinner}
        investorId={investorId}
        sendIndividualMail={sendIndividualMail}
      ></SMSendEmails>
    </div>
  )
}
