import { useNavigate } from '../../../hooks/useNavigationGuard'
import './nav-link.scss'

export interface NavLinkProps {
  to: string
  onClick?: () => void
  children?: any
}

/** Link coponent to get control over for navigation */
export const NavLink = ({ to, onClick, children }: NavLinkProps) => {
  const navigate = useNavigate()

  /** Handle onClick for navigation  */
  const clickHandler = () => {
    // if there's any onclick event in the link then execute that first and then call navigate function
    if (onClick) {
      onClick()
    }
    navigate(to)
  }

  return (
    <div onClick={clickHandler} className='nav-link'>
      {children}
    </div>
  )
}
