import React, { ReactNode } from 'react'
import { PermissionRequestSet } from '../../access-manager/permission-models'
import {
  PermissionCheckTypes,
  usePermissions,
} from '../../access-manager/use-permission'

type IsAuthorizationProps = {
  checkType?: PermissionCheckTypes
  permissions: PermissionRequestSet
  children: ReactNode
}

/** Verify whether the user have permission to access the page*/
export const IsAuthorized = ({
  children,
  permissions,
  checkType,
}: IsAuthorizationProps) => {
  // Default checkType to 'all' as the default value.
  if (!checkType) {
    checkType = 'all'
  }

  // Check that the user has the specified authorization.
  const isAuthorized = usePermissions(permissions, checkType)

  // If the user is not authorized, do not display the child components.
  if (!isAuthorized) {
    return null
  }

  // Display the child components.
  return <>{children}</>
}
