import React from 'react'

type CounterDisplayProps = {
  count: number
  isVisible: boolean
  title: string
}

/**
 * Component to display the 'title' text with item count
 * @count - Number of items
 * @isVisible - Whether items are visible already or not
 * @title - Name of the file or display
 */
export const CounterDisplay = ({
  count,
  isVisible,
  title,
}: CounterDisplayProps) => {
  if (isVisible) {
    return (
      <div className='counter-display-title'>
        {title}
        <span className='count-border'>{count}</span>
      </div>
    )
  }
  return <div></div>
}
