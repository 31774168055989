import { ClientFileList } from '../../state/atom'
import { File } from '../../api-client/investor-portal-client'
import { RecoilPinner } from '../useRecoilPinner'
import { SignalRWatcher } from './signalr-watcher'
import produce from 'immer'

/** Watches for relink files, and resolves/rejects when all expected relink files have been processed.*/
export class RelinkFileWatcher extends SignalRWatcher {
  constructor(
    apiPromise: Promise<any>,
    readonly parentFileApiPiner: RecoilPinner<ClientFileList>,
    readonly ids: number[]
  ) {
    super('CompleteInvestorRelink', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)
  }

  /** SignalR handler to process each file received in the messages. */
  handlerAction(fileIds: number[], parentFiles: File[]) {
    //Update the recoil state of ParentFiles
    this.parentFileApiPiner.set((curVal) => {
      const updatedVal = produce(curVal, (draft) => {
        parentFiles.forEach((parentFile) => {
          const parentFileIndex = draft.files.findIndex(
            (f) => f.id === parentFile.id
          )
          if (parentFileIndex !== -1) {
            draft.files[parentFileIndex] = parentFile
          }
        })
      })
      return updatedVal
    })

    if (
      fileIds.length === this.ids.length &&
      this.ids.every((fileId) => fileIds.includes(fileId))
    ) {
      this.operationComplete()
    }
  }
}
