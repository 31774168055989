import { atom } from 'recoil'
import {
  EntityClient,
  File,
  EntityGroup,
  UserProfileResult,
  PartnershipDetails,
  PartnerDetails,
  FeatureFlagsView,
  InvestorEntityRelation,
  SMGeneralOptions,
  EntityOptionsView,
  SMInvestorElectionsInfo,
  EntityManagerInfo,
  InvestorSubmissionStatus,
} from '../api-client/investor-portal-client'
import { getLocalStorageValue, setLocalStorageValue } from './local-store'

export interface ClientFileList {
  partnershipId: number
  files: File[]
  isLoading: boolean
  searchTerm?: string
}

export interface PartnershipDetailsList {
  details: PartnershipDetails[]
  isLoading: boolean
}
export interface PartnerDetailsList {
  details: PartnerDetails[]
  isLoading: boolean
}

export const parentFileListAtom = atom<ClientFileList>({
  key: 'PARENT_FILE_LIST',
  default: {
    partnershipId: 0,
    files: [],
    isLoading: false,
    searchTerm: '',
  },
})

export const partnershipDetailsListAtom = atom<PartnershipDetailsList>({
  key: 'PARTNERSHIP_DETAILS_LIST',
  default: {
    details: [],
    isLoading: false,
  },
})

export const partnerDetailsListAtom = atom<PartnerDetailsList>({
  key: 'PARTNER_DETAILS_LIST',
  default: {
    details: [],
    isLoading: false,
  },
})

export interface ClientChildFileList {
  parentFileId: number
  files: File[]
  isLoading: boolean
}

export const childFileListAtom = atom<ClientChildFileList>({
  key: 'CHILD_FILE_LIST',
  default: {
    parentFileId: 0,
    files: [],
    isLoading: true,
  },
})

export const searchTextAtom = atom({
  key: 'SEARCH_TERM',
  default: '',
})

export const userEntityGroupState = atom<{
  groups: EntityGroup[]
  isLoading: boolean
  isInitialized: boolean
}>({
  key: 'USER_ENTITY_GROUP_LIST',
  default: {
    groups: [],
    isLoading: false,
    isInitialized: false,
  },
})

export interface EntityClientState {
  clients: EntityClient[]
  featureFlag: FeatureFlagsView
  loadingState: LoadingStateEnum
}

export type EntityGroupPartnershipState = { [key: number]: EntityClientState }

export const entityGroupPartnershipStateAtom =
  atom<EntityGroupPartnershipState>({
    key: 'ENTITY_GROUP_PARTNERSHIPS',
    default: {},
  })

export const userProfileFromIdmAtom = atom<{
  userProfileResult: UserProfileResult
}>({
  key: 'USER_PROFILE_FROM_IDM',
  default: {
    userProfileResult: {},
  },
})

export interface ClientAndPartnershipState {
  clientGroup?: EntityGroup
  partnership?: EntityClient
  partnershipId?: number
  clientGroupId?: number
  clientId?: number
}
export const clientAndPartnershipStateAtom = atom<ClientAndPartnershipState>({
  key: 'CLIENT_AND_PARTNERSHIPS',
  default: {
    clientGroup: {},
    partnership: {},
    partnershipId: undefined,
    clientGroupId: undefined,
    clientId: undefined,
  },
})

export interface InvestorFiles {
  isLoading: boolean
  files: File[]
}
export const investorFilesAtom = atom<InvestorFiles>({
  key: 'INVESTOR_FILES',
  default: {
    isLoading: false,
    files: [],
  },
})

export interface InvestorEntityState {
  investorEntities: InvestorEntityRelation
  hasLoaded: boolean
}

export const investorRelationsAtom = atom<InvestorEntityState>({
  key: 'INVESTOR_RELATIONS',
  default: {
    investorEntities: {},
    hasLoaded: false,
  },
})

export enum LoadingStateEnum {
  Loaded = 'Loaded',
  Loading = 'Loading',
  NotLoaded = 'NotLoaded',
  Error = 'Error',
}

export interface GeneralOptions extends SMGeneralOptions {
  actualCutoffDate?: Date
}

export interface SMGeneralOptionsState {
  taxYear: number
  entityGroupId: number
  generalOptions: GeneralOptions
  loadingState: LoadingStateEnum
}

export const smGeneralOptionsAtom = atom<SMGeneralOptionsState>({
  key: 'SM_GENERAL_OPTIONS',
  default: {
    taxYear: 0,
    entityGroupId: 0,
    generalOptions: {},
    loadingState: LoadingStateEnum.NotLoaded,
  },
})

export interface EntityOptionsState {
  parentId: number
  entityOptions: EntityOptionsView[]
  hasLoaded: boolean
}

export interface SMGeneralElectionInfo {
  cutoffDate?: Date
  electionsInfo?: SMInvestorElectionsInfo
  entityInfo?: EntityManagerInfo
  loadingState: LoadingStateEnum
}

export const smEntityOptionsAtom = atom<EntityOptionsState>({
  key: 'SM_ENTITY_OPTIONS',
  default: {
    parentId: 0,
    entityOptions: [],
    hasLoaded: false,
  },
})

export const leftNavExpandedAtom = atom<boolean>({
  key: 'LEFT_NAV_EXPANDED',
  default: false,
})

export const smGeneralElectionInfoAtom = atom<SMGeneralElectionInfo>({
  key: 'SM_GENERAL_ELECTION_INFO',
  default: {
    cutoffDate: undefined,
    electionsInfo: undefined,
    entityInfo: undefined,
    loadingState: LoadingStateEnum.NotLoaded,
  },
})

export interface SMDirtyFlags {
  isGeneralDirty: boolean
  isCompositeDirty: boolean
  isWithholdingDirty: boolean
}

export interface SMSubmissionStatus {
  isGeneralInfoSubmitted: boolean
  submissionStatuses: InvestorSubmissionStatus | undefined
  isDirtyFlags: SMDirtyFlags
  loadingState: LoadingStateEnum
}

export const smSubmissionStatusAtom = atom<SMSubmissionStatus>({
  key: 'SM_SUBMISSION_STATUS',
  default: {
    isGeneralInfoSubmitted: false,
    submissionStatuses: undefined,
    isDirtyFlags: {
      isGeneralDirty: false,
      isCompositeDirty: false,
      isWithholdingDirty: false,
    },
    loadingState: LoadingStateEnum.NotLoaded,
  },
})

export interface AppSettings {
  landingPagePopupShown: boolean
  viewOptions: { [key: string]: string }
}

export const appSettingsAtom = atom<AppSettings>({
  key: 'APP_STATE_VALUES',
  default: {
    landingPagePopupShown: false,
    viewOptions: {},
  },
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const data = getLocalStorageValue('APP_STATE_VALUES')
      if (data) {
        setSelf(JSON.parse(data))
      }
    },
    ({ onSet }) => {
      onSet((value) => {
        setLocalStorageValue('APP_STATE_VALUES', JSON.stringify(value))
      })
    },
  ],
})

// Hold error detail
export const errorAtom = atom<string>({
  key: 'ERROR_MESSAGE',
  default: undefined,
})
