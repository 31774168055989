import React from 'react'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'

/** Client Group List Page Banner */
export const ClientGroupsListBanner = () => {
  return (
    <BannerContainer className='single-title'>
      {/* Header of the banner titled with Clients*/}
      <BannerHeader>Clients</BannerHeader>
    </BannerContainer>
  )
}
