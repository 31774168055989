import './investor-withholding-elections.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { InvestorStateSelections } from './investor-state-selections'
import { useCallback, useEffect, useState } from 'react'
import { SelectBox, TextBox } from 'devextreme-react'
import { SpinnerModal } from '../modal/spinnerModal'
import { InvestorWithholdingEntity } from '../../api-client/investor-portal-client'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import produce from 'immer'
import SimpleTooltip from '../reuasble-components/simpleTooltip'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface InvestorWithholdingElectionsProps {
  investorElectionsInfoId: number
  updateCanSave: (isUpdated: boolean) => void
  onDirtyStateChange: (isDirty: boolean) => void
  setParentButtons: (buttons: React.ReactNode) => void
}

/** Investor Withholdings tab */
export const InvestorWithholdingElections = ({
  investorElectionsInfoId,
  updateCanSave,
  onDirtyStateChange,
  setParentButtons,
}: InvestorWithholdingElectionsProps) => {
  const stateManagerApi = useStateManagerApi()
  const commonDialog = useCommonDialogs()
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()
  const [isElectionAvailable, setIsElectionAvailable] = useState<boolean>(true)
  const [entityOptionsId, setEntityOptionsId] = useState<number>()
  const [isDirtyFlag, setIsDirtyFlag] = useState<boolean>(false)
  const [allocatingEntities, setAllocatingEntities] =
    useState<InvestorWithholdingEntity[]>()

  // popover id for  withholding elections
  const withholdingElectionsPopoverId =
    'withholdingelections' + +investorElectionsInfoId + entityOptionsId
  // popover id for  entity selection
  const entityElectionsPopoverId =
    'entityselection' + investorElectionsInfoId + entityOptionsId
  //popover id for entity completed
  const entityCompletedPopoverId =
    'entitycompleted' + investorElectionsInfoId + entityOptionsId

  /** load withholdings data based on the  investorElectionsInfoId*/
  useEffect(() => {
    const abortController = new AbortController()

    /** Load withholdings data based on the investor election info id */
    const loadWithHoldingData = async () => {
      /** Load the investor state elections data from the API */
      const investorWithHoldings: InvestorWithholdingEntity[] =
        await stateManagerApi.getInvestorWithholdingEntities(
          investorElectionsInfoId,
          abortController.signal
        )

      const investorHoldings: InvestorWithholdingEntity[] = []
      // check investorStateElection and sort the allocating entities
      // Add the items and sort in the below orde:
      // First add the in-complete withholding &
      // then add the complete withholding & not eligible elections
      if (investorWithHoldings && investorWithHoldings.length > 0) {
        // get completed withholding entities
        const completedWithHoldings = investorWithHoldings.filter(
          (holding) => holding.isComplete && holding.hasEligibleElections
        )

        // get not eligible withholding entities
        const noWithHoldings = investorWithHoldings.filter(
          (holding) => !holding.hasEligibleElections
        )

        //get in-complete withholding entities
        const inCompletedHoldings = investorWithHoldings.filter(
          (holding) => !holding.isComplete && holding.hasEligibleElections
        )

        // Apply sorting by name and set in the investor holdings state
        inCompletedHoldings.sort(
          (a: InvestorWithholdingEntity, b: InvestorWithholdingEntity) =>
            a.name!.toLowerCase() < b.name!.toLowerCase() ? -1 : 1
        )
        investorHoldings.push(...inCompletedHoldings)

        completedWithHoldings.sort(
          (a: InvestorWithholdingEntity, b: InvestorWithholdingEntity) =>
            a.name!.toLowerCase() < b.name!.toLowerCase() ? -1 : 1
        )
        investorHoldings.push(...completedWithHoldings)

        noWithHoldings.sort(
          (a: InvestorWithholdingEntity, b: InvestorWithholdingEntity) =>
            a.name!.toLowerCase() < b.name!.toLowerCase() ? -1 : 1
        )
        investorHoldings.push(...noWithHoldings)

        // set witholdings in state
        setAllocatingEntities(investorHoldings)
        // set first entity as default to load state selections
        setEntityOptionsId(investorHoldings[0].entityOptionId)
      } else {
        setAllocatingEntities(investorHoldings)
        setEntityOptionsId(0)
      }
    }

    /** If  load the investor state elections data from the API */
    setShowApiSpinner(true)
    // Set API call promise to display spinner modal
    setSpinnerPromise(loadWithHoldingData())

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [investorElectionsInfoId])

  /** check entitis object all entities are completed or not*/
  useEffect(() => {
    const isAllCompleted = allocatingEntities?.every((entity) => {
      return entity.isComplete || !entity.hasEligibleElections
    })

    //set the canUpdate flag
    updateCanSave(isAllCompleted!)
  }, [allocatingEntities, updateCanSave])

  /** Set isComplete flag to display completed tick mark in UI */
  const setUpdateSave = useCallback(
    (value: boolean) => {
      setAllocatingEntities(
        produce((draft) => {
          draft?.forEach((f) => {
            if (
              f.entityOptionId === entityOptionsId &&
              f.isComplete === false
            ) {
              f.isComplete = value
            }
          })
        })
      )
    },
    [entityOptionsId]
  )

  // Set completed entities count
  const completedCount = allocatingEntities?.filter((entity) => {
    return entity.isComplete || !entity.hasEligibleElections
  })?.length

  /** When there is an allocating entity change then check for dirty flag
   * if dirty flag is set then display unsaved changes
   */
  const onAllocatingEntityChanged = (value: number) => {
    if (isDirtyFlag) {
      commonDialog.showDialog({
        dialogType: 'general',
        title: ' Unsaved Changes',
        content:
          'You have unsaved changes. If you continue changes will be lost. Would you like to proceed?',
        omitDefaultButton: true,
        buttonFunctions: [
          {
            label: 'Cancel',
            isCloseAction: true,
            isDefaultAction: true,
            onClick: () => {},
            buttonProps: {
              stylingMode: 'contained',
              type: 'normal',
              width: 100,
              'data-testid': 'cancel-button',
            },
          },
          {
            label: 'Proceed',
            isCloseAction: true,
            onClick: () => {
              setEntityOptionsId(value)
              setIsDirtyFlag(false)
              onDirtyStateChange(false)
            },
            buttonProps: {
              stylingMode: 'contained',
              type: 'default',
              width: 110,
              'data-testid': 'ok-button',
            },
          },
        ],
      })
    } else {
      setEntityOptionsId(value)
    }
  }

  /** set the allocating entity name in textbox and info flag for complete */
  const fieldRender = (data: InvestorWithholdingEntity) => {
    return (
      <div className='div-select-box'>
        <TextBox
          defaultValue={data && data.name}
          readOnly={true}
          className='select-box-text-align'
        />
        {data && (data.isComplete! || data.hasEligibleElections === false) && (
          <span className='align-completed-icon'>
            <CheckCircleIcon color='info' fontSize='medium' />
          </span>
        )}
      </div>
    )
  }

  /** set the allocating entity name and info flag for complete*/
  const itemRender = (data: InvestorWithholdingEntity) => {
    if (data) {
      return (
        <div>
          {data.name}
          {(data.isComplete! || data.hasEligibleElections === false) && (
            <CheckCircleIcon color='info' fontSize='small' />
          )}
        </div>
      )
    }
  }

  const handleDirtyStateChange = useCallback(
    (isDirty: boolean) => {
      setIsDirtyFlag(isDirty)
      onDirtyStateChange(isDirty)
    },
    [onDirtyStateChange]
  )

  return (
    <div className='investor-withholding-elections'>
      <div className='field-label'>
        Select your States for Withholding Elections
        <SimpleTooltip popupMessage='Make selections below. Selecting a State indicates that you are requesting a withholding exemption election to be made on your behalf for that specific State, for that specific Entity, for the particular tax year' />
      </div>
      <div className='main'>
        <div className='firstColumn'>
          <div>
            Current Entity Selection
            <SimpleTooltip popupMessage='Complete selections for all available entities listed in this dropdown' />
          </div>
          <div>
            <SelectBox
              dataSource={allocatingEntities}
              displayExpr='name'
              valueExpr='entityOptionId'
              data-testid='allocating-entity-select-box'
              value={entityOptionsId}
              itemRender={itemRender}
              fieldRender={fieldRender}
              showClearButton={false}
              onValueChange={onAllocatingEntityChanged}
            />
          </div>
        </div>
        <div className='secondColumn'>
          <div>
            Entities Completed
            <SimpleTooltip popupMessage='Number of entities where you have made the election for this particular tax year' />
          </div>
          <div className='entities-align'>
            <span className='count-text-display'>
              {completedCount}
              <span className='count-text-align'>/</span>
              {allocatingEntities?.length}
            </span>
          </div>
        </div>
        <SpinnerModal
          visible={showApiSpinner}
          errorTitleMessage='Error Occured'
          inProgressTitleMessage='Withholdings'
          inProgressMessage='Loading...'
          successTitleMessage=''
          successMessage=''
          onClose={() => setShowApiSpinner(false)}
          apiAction={spinnerPromise}
          hideCloseButton={true}
          closeOnSuccess={true}
        />
      </div>
      {investorElectionsInfoId > 0 && entityOptionsId! > 0 && (
        <InvestorStateSelections
          investorElectionsInfoId={investorElectionsInfoId}
          entityOptionsId={entityOptionsId}
          onDirtyStateChange={handleDirtyStateChange}
          updateCanSave={setUpdateSave}
          setElectionAvailable={setIsElectionAvailable}
          setParentButtons={setParentButtons}
        ></InvestorStateSelections>
      )}
    </div>
  )
}
