import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'

export const HelpPageBanner = () => {
  return (
    <BannerContainer className='client-group-details-banner'>
      <BannerHeader>Request Assistance</BannerHeader>
    </BannerContainer>
  )
}
