import { ICellData, MenuProps } from '../../model/file-model'
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { Button, CheckBox, ContextMenu, DataGrid } from 'devextreme-react'
import {
  Column,
  Item,
  Scrolling,
  SearchPanel,
  Toolbar,
  Paging,
  Editing,
} from 'devextreme-react/data-grid'
import { CircularProgress, Grid } from '@mui/material'
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import {
  InvestorSearchResult,
  InvestorSelectionOptions,
  SortOrderTypes,
} from '../../api-client/investor-portal-client'
import './investor-selection.scss'
import { useInvestorSelection } from '../../hooks/use-investor-selection'
import newFileFlagSvg from '../../ui-kit/assets/img/icon/new-file-flag.svg'
import { SpinnerModal } from '../modal/spinnerModal'
import { NativeEventInfo } from 'devextreme/events'
import dxCheckBox from 'devextreme/ui/check_box'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'AZ':
      icon = <SortByAlphaOutlinedIcon />
      break
    case 'ZA':
      icon = <SortByAlphaOutlinedIcon />
      break
    case 'checked':
      icon = <CheckBoxOutlinedIcon />
      break
    case 'unchecked':
      icon = <CheckBoxOutlineBlankOutlinedIcon />
      break
  }
  return (
    <div>
      <span style={{ fontSize: '6px', width: '4px', height: '4px' }}>
        {icon}
      </span>
      {e.text}
    </div>
  )
}

type investorHook = ReturnType<typeof useInvestorSelection>

// Investor selection component properties
export interface InvestorSelectionProps {
  hookRef: investorHook
  onSave: () => void
  updateDirtyFlag: (isDirty: boolean) => void
  height: string
}

/** set investor name and display new icon based on the isNew proprty value*/
const setInvestorName = (cellData: ICellData<InvestorSearchResult>) => {
  const rowData = cellData.data
  // if isNew is true then display new icon along with the investor name
  if (rowData?.isNew) {
    return (
      <span>
        {rowData?.investorName}
        <img src={newFileFlagSvg} width={25} height={25} alt='New Investor' />
      </span>
    )
  } else {
    // if isNew is false then display investor name alone
    return <span>{rowData?.investorName}</span>
  }
}

/** Investor Selection Component */
export const InvestorSelection = ({
  hookRef,
  onSave,
  updateDirtyFlag,
  height,
}: InvestorSelectionProps) => {
  const [isSearching, setIsSearching] = useState(false)
  const [disableSelectAll, setDisableSelectAll] = useState(false)
  const dataGridRef = useRef<DataGrid>(null)
  const commonDialog = useCommonDialogs()
  const stateManagerApi = useStateManagerApi()
  const [isAllSelected, setAllSelected] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [investorPromise, setInvestorPromise] = useState<Promise<any>>()
  const [abortController, setAbortController] = useState<AbortController>()
  const [investorCount, setInvestorCount] = useState<number | undefined>(0)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const totalCount = dataGridRef.current?.instance?.totalCount()
    if (totalCount !== undefined && totalCount !== -1) {
      // If totalCount is defined and not -1 (when the datagrid is loading it is setting to -1), update the count and set loading to false
      setInvestorCount(totalCount)
      setLoading(false)
    } else {
      // If totalCount is -1 or undefined, set loading to true
      setLoading(true)
    }
  }, [isSearching, hookRef])

  /** disable select All option when there's any search text available or using globalsettings */
  useEffect(() => {
    // disable SelectAll when Filter is enabled or Search is Enabled
    if (hookRef.isGlobalSettings || isSearching || hookRef.isFilterSet) {
      setDisableSelectAll(true)
    } else {
      setDisableSelectAll(false)
    }
  }, [isSearching, hookRef.isGlobalSettings, hookRef.isFilterSet])

  /** Set select All option based on the API response */
  useEffect(() => {
    const sessionId = hookRef.sessionRef.current
    // set select all flag based on the api response
    if (sessionId > 0) {
      const abortController = new AbortController()
      setAbortController(abortController)
      stateManagerApi
        .isAllInvestorSessionSelected(sessionId, abortController.signal)
        .then((response) => {
          setAllSelected(response === InvestorSelectionOptions.SelectAll)
          setAbortController(undefined)
        })
      // Set isDirty flag in the parent component
      updateDirtyFlag(true)
    } else {
      const globalSettings =
        hookRef.isGlobalSettings === undefined ? true : hookRef.isGlobalSettings
      stateManagerApi
        .isAllInvestorsSelected(
          hookRef.parentId,
          hookRef.parentType,
          globalSettings
        )
        .then((response) => {
          setAllSelected(response === InvestorSelectionOptions.SelectAll)
        })
    }
  }, [hookRef.sessionRef.current])

  /** Set select All checkbox based on the  checkbox click
   * if all checkboxes are checked in the data grid, then call API and
   * set the select All checkbox based on the API response
   * if any one of the checkbox in the data grid is not checked, then set select All checkbox to false
   */
  useEffect(() => {
    if (
      hookRef.investorSelectionChange.length > 0 &&
      dataGridRef.current?.instance?.getDataSource().items() &&
      dataGridRef.current?.instance?.getDataSource().items().length > 0
    ) {
      // get not checked items in data grid
      const notSelectedItems = dataGridRef.current?.instance
        .getDataSource()
        .items()
        ?.filter((d: InvestorSearchResult) => !d.isSelected)
      // all items are checked in data grid & select All option is not checked
      if (notSelectedItems.length === 0 && !isAllSelected) {
        // if any API call already made to isAllInvestorSessionSelected method then
        // abort the call and make new API call
        if (abortController) {
          abortController.abort(DISMOUNT_ABORT_REASON)
        }
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        const sessionId = hookRef.sessionRef.current
        if (sessionId > 0) {
          stateManagerApi
            .isAllInvestorSessionSelected(sessionId, newAbortController.signal)
            .then((response) => {
              if (response === InvestorSelectionOptions.SelectAll) {
                // set select All checkbox to true
                setAllSelected(true)
              }
              // reset abort controller in state
              setAbortController(undefined)
            })
        }
      } else if (notSelectedItems.length > 0 && isAllSelected) {
        // one of the item in data grid is not checked & select All option is checked
        // then set select All option to unchecked
        setAllSelected(false)
      }
    }
  }, [hookRef.investorSelectionChange])

  /** Set promise and display spinner */
  useEffect(() => {
    if (hookRef.promise) {
      if (!showSpinner) {
        setShowSpinner(true)
        setInvestorPromise(hookRef.promise)
      }
    }
  }, [hookRef.promise])

  /** Grid Menu options */
  const masterContextMenu: MenuProps[] = useMemo(() => {
    return [
      {
        text: 'Alphabetical A-Z',
        icon: 'AZ',
        action: () => {
          hookRef.sortType(SortOrderTypes.Asc)
        },
      },
      {
        text: 'Alphabetical Z-A',
        icon: 'ZA',
        action: () => {
          hookRef.sortType(SortOrderTypes.Desc)
        },
      },
      {
        text: 'Selected',
        icon: 'checked',
        action: () => {
          hookRef.selectionType(true)
        },
      },
      {
        text: 'Not Selected',
        icon: 'unchecked',
        action: () => {
          hookRef.selectionType(false)
        },
      },
    ]
  }, [hookRef])

  /** Update all investors based on the selection ie., SelectAll or SelectNone */
  const updateAllInvestors = async (isSelected: boolean) => {
    //Set investor selection dirty flag
    updateDirtyFlag(true)
    setAllSelected(isSelected)
    setShowSpinner(true)
    const updatePromise = hookRef.updateAllInvestors(isSelected)
    // set promise to state to display spinner modal
    setInvestorPromise(updatePromise)
    const success = await updatePromise
    // Refresh data grid to update selectAll or None in the data grid
    if (success) {
      dataGridRef.current?.instance.refresh()
    }
  }

  /** Select All checkbox click*/
  const handleSelectAll = (
    e: NativeEventInfo<dxCheckBox, Event> & ValueChangedInfo
  ) => {
    // If there's no event object (for example, when this function is called without a click event),
    // it might trigger when the 'Select All' functionality is disabled,so return in this case
    if (!e.event) {
      return
    }
    updateAllInvestors(!isAllSelected)
  }

  /** Clear selection button click */
  const clearSelections = async () => {
    // Deselect All
    updateAllInvestors(false)
  }

  /** Clear filter button click - reset the selection type */
  const clearFilter = () => {
    hookRef.selectionType(undefined)
  }

  /** Set searching flag to enable or disable select All option in data grid */
  const handleSearch = useCallback((value: string) => {
    setIsSearching(!!value)
  }, [])

  return (
    <div className='investor-selector-container'>
      <div className='investorselector-datagrid'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          dataSource={hookRef.dataSource}
          keyExpr='investorId'
          noDataText={hookRef.isLoading ? 'Loading...' : 'No Data Found.'}
          width='100%'
          columnAutoWidth={true}
          height={height}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={false}
          showColumnHeaders={false}
          data-testid='sm-investor-selection-datagrid'
          remoteOperations={true}
        >
          <Paging defaultPageSize={13} />
          <Scrolling
            mode='virtual'
            rowRenderingMode='virtual'
            showScrollbar={true}
          />
          <SearchPanel
            visible={true}
            width='220px'
            onTextChange={handleSearch}
          />
          <Toolbar>
            <Item location='before'>
              <Grid
                className='grid-container'
                container
                item
                xs={11}
                minWidth={550}
                justifyContent={'flex-start'}
              >
                <Grid item xs={3} className='select-all-chkbox'>
                  <div className='checkbox-container'>
                    <CheckBox
                      disabled={disableSelectAll}
                      value={isAllSelected}
                      onValueChanged={handleSelectAll}
                    />
                    <span className='select-all-chkbox-text'>Select All</span>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() => clearFilter()}
                    type='default'
                    stylingMode='text'
                    data-testid='sm-clear-filter'
                    text='Clear Filter'
                  />
                </Grid>
              </Grid>
            </Item>
            <Item name='searchPanel' />
            <Item location='after'>
              <Grid container minWidth={50} justifyContent={'flex-end'}>
                <ContextMenu
                  width={120}
                  dataSource={masterContextMenu}
                  showEvent='mouseenter'
                  target='#investorSelectionMasterActionBtn'
                  itemRender={renderMenuItem}
                  onItemClick={(e) => {
                    const item = e.itemData as MenuProps | undefined
                    if (item?.action) {
                      item.action()
                    }
                  }}
                />
                <Button id='investorSelectionMasterActionBtn' height={35}>
                  <FilterListSharpIcon />
                </Button>
              </Grid>
            </Item>
          </Toolbar>
          <Editing
            mode='cell'
            allowUpdating={true}
            allowAdding={false}
            refreshMode='repaint'
          />
          <Column
            dataField='isSelected'
            dataType='boolean'
            caption=''
            allowSearch={false}
            allowSorting={false}
            allowEditing={!hookRef.isGlobalSettings}
            cssClass={hookRef.isGlobalSettings ? 'disabled-checkbox' : ''}
            allowFiltering={false}
            width={'6%'}
          />
          <Column
            dataField='investorName'
            caption='Investor Name'
            allowEditing={false}
            cellRender={setInvestorName}
            width={'50%'}
          />
        </DataGrid>
        <div className='investor-footer'>
          <>
            <span className='investor-text'>Investors </span>
            {loading ? (
              <CircularProgress size={15} /> // Show a spinner while loading
            ) : (
              <span className='investors-count'> {investorCount}</span>
            )}
          </>
          <div className='investor-selector-buttons'>
            <Button
              text='Clear Selection'
              type='default'
              stylingMode='contained'
              className='clear-btn-investor'
              disabled={hookRef.isGlobalSettings}
              onClick={() =>
                commonDialog.showDialog({
                  dialogType: 'general',
                  title: 'Clearing Selection',
                  content: 'Are you sure you want to clear selections?',
                  omitDefaultButton: true,
                  buttonFunctions: [
                    {
                      label: 'Cancel',
                      isCloseAction: true,
                      isDefaultAction: true,
                      onClick: async () => {},
                      buttonProps: {
                        stylingMode: 'contained',
                        type: 'normal',
                        width: 100,
                        'data-testid': 'cancel-button',
                      },
                    },
                    {
                      label: 'Proceed',
                      isCloseAction: true,
                      onClick: async () => {
                        clearSelections()
                      },
                      buttonProps: {
                        stylingMode: 'contained',
                        type: 'default',
                        width: 110,
                        'data-testid': 'proceed-button',
                      },
                    },
                  ],
                })
              }
              data-testid='sm-clear-button'
            ></Button>
            <Button
              text='Save Selection'
              type='default'
              stylingMode='contained'
              className='save-btn-investor'
              data-testid='sm-Save-button'
              onClick={onSave}
            ></Button>
          </div>
        </div>
      </div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage=''
        errorMessage=''
        inProgressTitleMessage='Update'
        inProgressMessage='Update is in progress'
        successTitleMessage=''
        successMessage=''
        onClose={() => {
          setShowSpinner(false)
        }}
        apiAction={investorPromise}
        hideCloseButton={true}
        closeOnSuccess={true}
      />
    </div>
  )
}
