import { filesClientApi } from "../../api-client/investor-portal-client-runtime"
import { uploadFileToAzure } from "./azure-upload"


/** Uploads a new K1 file to the API. */
export const uploadK1Document = async (f: File, clientId: number, 
    documentSubtype: string, taxYear: string | undefined, partnershipId: number, 
    entityGroupId: number, progress: (x: number) => void) => {
    // Exit if there's no file.
    if (!f) {
        return
    }

    // Get the SAS Uri, and let the API know that we're about to upload
    //  a file, so it can add the information to the database, and such.
    const result = await filesClientApi.files_PrepareUpload({
        clientId,
        documentSubType: documentSubtype ?? '',
        fileNames: [f.name],
        taxYear,
        partnershipEntityId: partnershipId,
    })

    // Upload the file directly to Azure, using the SAS Uri.
    await uploadFileToAzure(result[0].externalSasUri!, f, p => {
        progress(p)
    })

    // Notify the API that the upload is complete, so it can process the new file.
    await filesClientApi.files_UploadComplete({
        clientId,
        fileName: f.name,
        entityGroupId,
    })
}