import { Grid, Container, Box, Breakpoint} from "@mui/material";

interface Props {
  children?: React.ReactNode
  mastheadDx?: false | Breakpoint
  mastheadImage?: string
}

const Masthead = ({children, mastheadDx, mastheadImage}: Props) => {
  return (
    <div className="cui-c_masthead">
      <div className="cui-c_masthead-wrapper">          
        <Container maxWidth={mastheadDx} className="cui-c_masthead-container">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8}>
                {children}
            </Grid>
          </Grid>
          <Box className="cui-c_masthead-image_box" display={{xs: 'none', md: 'block' }}>
            <img className="cui-c_masthead-image" src={mastheadImage} />            
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Masthead;
