import { SelectBoxOptions } from "../../../types/types"

/** Provides the set of views that can be selected for the ClientGroup page. */
export const clientGroupViewOptions: SelectBoxOptions[] = [
    {
        label: 'Allocating Entity',
        value: 'entity'
    },
    {
        label: 'Investor',
        value: 'investor'
    }
]