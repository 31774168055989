import { atom } from 'recoil'
import { TaxYear } from '../../../api-client/investor-portal-client'

/** Reset year value */
export const getResetTaxYearState = () =>
  ({
    year: undefined,
  } as TaxYear)

export const taxYearState = atom<TaxYear>({
  key: 'TAX_YEAR_STATE',
  default: getResetTaxYearState(),
})
