import './bannerContainer.scss'
interface Props {
  children?: React.ReactNode
  className?: string
}
export const BannerContainer = ({ children, className }: Props) => {
  return (
    <div
      id='main_border'
      className={'banner-container-wrapper ' + className ?? ''}
    >
      {children}
    </div>
  )
}
