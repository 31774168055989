import { Container, Button, Drawer, IconButton } from '@mui/material'
import { useState, useMemo, useEffect } from 'react'
import DataGrid, {
  SearchPanel,
  Column,
  GroupPanel,
  Toolbar,
  Item,
  Paging,
  LoadPanel,
} from 'devextreme-react/data-grid'
import { DataSourceLike } from 'devextreme/data/data_source'
import { useRecoilValue } from 'recoil'
import { investorRelationsAtom } from '../../../state/atom'
import '../../landingPage/landingPage.scss'
import { InvestorNameCard } from '../investorNameCard'
import { IsAuthorized } from '../../authentication/isAuthorized'
import { NameCardProps } from '../investor-utils'
import * as Permissions from '../../../model/permissions'
import { permissionRequestAny } from '../../../access-manager/permission-request-creation'

let defaultData: DataSourceLike<string, string> | undefined

/** list of NameCardProps */
interface CardData {
  data: NameCardProps
}

/** Investor landing page component - Tiles view */
export const InvestorLandingTilesView = () => {
  const { investorEntities, hasLoaded } = useRecoilValue(investorRelationsAtom)
  const [sortOrder, setSortOrder] = useState('asc')
  const [investorData, setInvestorData] = useState<NameCardProps[]>([])

  /** load clients and favorites */
  useEffect(() => {
    let cards: NameCardProps[] = []

    investorEntities.clientGroupEntities?.forEach((e) => {
      e.clientGroupIds?.forEach((groupId) => {
        const clientGroup = investorEntities.clientGroups?.find(
          (group) => group.id === groupId
        )

        const favorite = investorEntities.favoriteClients?.some(
          (f) => f.clientGroupId === groupId && f.partnerEntityId === e.id
        )

        const groupData: NameCardProps = {
          id: clientGroup?.id!,
          name: `${clientGroup?.name!} - ${e.name}`,
          logoUrl: clientGroup?.logoUrl!,
          isFavorite: favorite!,
          partnerEntityId: e.id!,
          uniqueId: clientGroup?.id! + '-' + e.id!,
        }

        cards.push(groupData)
      })
    })
    // set investor data in recoil state
    setInvestorData(cards)
  }, [investorEntities])

  const [investorDrawerActive, setInvestorDrawerActive] = useState({
    pos: false,
    data: defaultData,
    name: '',
  })

  const toggleInvestorDrawer =
    (
      open: boolean,
      list: DataSourceLike<string, string> | undefined,
      investor: string
    ) =>
    () => {
      setInvestorDrawerActive({ pos: open, data: list, name: investor })
    }

  // Passing row items to show in tile(card)
  const investorNameCard = (cardData: CardData) => {
    return (
      <InvestorNameCard
        id={cardData.data.id}
        name={cardData.data.name}
        logo={cardData.data.logoUrl}
        isFavorite={cardData.data.isFavorite}
        partnerEntityId={cardData.data.partnerEntityId}
        onEditClient={() => {}}
      ></InvestorNameCard>
    )
  }

  /* Sort favorite items in alphabetical order and then 
     order non favorite item in alphabetical order */
  const sortedInvestorData = useMemo(() => {
    const favorite = investorData.filter((e) => e.isFavorite)
    const nonFavorite = investorData.filter((e) => !e.isFavorite)
    if (sortOrder === 'desc') {
      favorite.sort((a: NameCardProps, b: NameCardProps) =>
        b.name!.localeCompare(a.name!, undefined, { sensitivity: 'accent' })
      )
      nonFavorite.sort((a: NameCardProps, b: NameCardProps) =>
        b.name!.localeCompare(a.name!, undefined, { sensitivity: 'accent' })
      )
    } else {
      favorite.sort((a: NameCardProps, b: NameCardProps) =>
        a.name!.localeCompare(b.name!, undefined, { sensitivity: 'accent' })
      )
      nonFavorite.sort((a: NameCardProps, b: NameCardProps) =>
        a.name!.localeCompare(b.name!, undefined, { sensitivity: 'accent' })
      )
    }
    //concatenating and returning first fvorite and then non-favorite to keep favorite groups on top
    return favorite.concat(nonFavorite)
  }, [sortOrder, investorData])

  /* Explicit sorting by IsFavorite and Name to keep favorite groups on top*/
  const onPositionSortingChanged = () => {
    sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
  }

  return (
    <IsAuthorized permissions={[permissionRequestAny(Permissions.INVESTOR)]}>
      <div className='investor-landing-page'>
        <Container maxWidth={false} className='cui-c_section cui-h_mt'>
          <DataGrid
            id='grid-landing-tile-view'
            dataSource={sortedInvestorData}
            keyExpr='uniqueId'
            showBorders={false}
            height='calc(100vh - 222px)'
            className={'cui-c_table cui-c_table-dx cui-c_table-dx_tile'}
            showColumnHeaders={false}
            showRowLines={false}
            showColumnLines={false}
            dataRowRender={investorNameCard}
            noDataText={
              !hasLoaded
                ? 'Loading...'
                : hasLoaded && investorEntities.clientGroups?.length === 0
                ? 'No Data Found.'
                : ''
            }
          >
            <SearchPanel visible={true} width='200px' />
            <Paging defaultPageSize={sortedInvestorData.length} />
            <LoadPanel enabled={true} />
            <Column
              dataField='name'
              allowSearch={true}
              allowSorting={true}
            ></Column>
            <GroupPanel visible={true} />
            <Toolbar>
              <Item location='before'>
                <Button
                  onClick={onPositionSortingChanged}
                  variant='outlined'
                  size='small'
                >
                  Investment
                  <span
                    className='material-icons-outlined'
                    style={{ fontSize: '12px', marginLeft: '4px' }}
                  >
                    {sortOrder === 'desc' ? 'south' : 'north'}
                  </span>
                </Button>
              </Item>
              <Item name='searchPanel' />
            </Toolbar>
          </DataGrid>
        </Container>
        <Drawer
          anchor={'right'}
          open={investorDrawerActive['pos']}
          onClose={toggleInvestorDrawer(false, defaultData, '')}
          className='cui-c_drawer'
        >
          <div className='cui-c_drawer-container'>
            <div className='cui-c_drawer-header'>
              <h4>{investorDrawerActive['name']}</h4>
              <Button
                size='small'
                onClick={toggleInvestorDrawer(false, defaultData, '')}
              >
                <span className='material-icons-outlined'>close</span>
              </Button>
            </div>
            <div className='cui-c_drawer-body'>
              <DataGrid
                dataSource={investorDrawerActive['data']}
                showBorders={true}
                className={'cui-c_table cui-c_table-dx'}
              >
                <SearchPanel visible={true} width='200px' />
                <Column cellRender={cellRenderInvestorDownload} width='60px' />
                <Column dataField='Document' />
                <Column dataField='TaxYear' defaultSortOrder='desc' />
                <Column dataField='Investor' groupIndex={0} />
                <Toolbar>
                  <Item location='before'>
                    <div className='cui-c_table-heading'>Documents</div>
                  </Item>
                  <Item location='after'></Item>
                  <Item name='searchPanel' />
                </Toolbar>
              </DataGrid>
            </div>
          </div>
        </Drawer>
      </div>
    </IsAuthorized>
  )
}
function cellRenderInvestorDownload() {
  return (
    <IconButton size='small'>
      <span className='material-icons-outlined'>file_download</span>
    </IconButton>
  )
}
