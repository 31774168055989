import React, { useEffect, useState } from 'react'
import { clientGroupViewOptions } from './client-group-details-constants'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  clientGroupDetailsState,
  ClientGroupDetailsViewTypes,
} from '../client-groups/client-group-details-state'
import produce from 'immer'
import './clientGroupDetailsBanner.scss'
import {
  clientAndPartnershipStateAtom,
  parentFileListAtom,
  partnershipDetailsListAtom,
} from '../../state/atom'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import {
  Statistics,
  StatisticsItem,
} from '../../common-components/banners/statistics'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { useFileClientApi } from '../../hooks/use-file-api'
import { useParams } from 'react-router-dom'
import { PartnershipBannerStat } from '../../api-client/investor-portal-client'
import { TaxYearControl } from '../../common-components/banners/tax-year-control/tax-year-control'
import { NavWidget } from '../nav-widget/navWidget'
import { CurrentViewControl } from './current-view-control/current-view-control'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export const ClientGroupDetailsBanner = () => {
  const [partnershipBannerStat, setPartnershipBannerStat] = useState<
    PartnershipBannerStat | undefined
  >()
  const [clientGroupDetails, setClientGroupDetails] = useRecoilState(
    clientGroupDetailsState
  )
  const { files } = useRecoilValue(parentFileListAtom)
  const { isLoading } = useRecoilValue(partnershipDetailsListAtom)
  const { groupId } = useParams() as { groupId: string }
  const clientApi = useFileClientApi()
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)

  useEffect(() => {
    const abortController = new AbortController()
    clientApi
      .getClientGroupBannerStats(parseInt(groupId), abortController.signal)
      .then((response) =>
        setPartnershipBannerStat({
          documents: response.documents!,
          entities: response.entities!,
        })
      )

    // Abort the API Call when user moves away from this page
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, files])

  /** Set changed view value based on Document Status Page*/
  const onClientGroupViewChanged = (value: string) => {
    setClientGroupDetails(
      produce((draft) => {
        draft.currentView = value as ClientGroupDetailsViewTypes
      })
    )
  }

  /** Set banner header name based on Client Group*/
  const getHeaderContent = () => {
    let clientName: string = 'Document Status'
    const clientGroupName: string = clientGroupInfo.clientGroup?.name!
    if (clientGroupName) {
      clientName = clientGroupName + ' - ' + clientName
    }
    return clientName
  }

  /** Set documents & statistics details*/
  const statisticsItems: StatisticsItem[] = [
    {
      name: 'Documents',
      value: partnershipBannerStat?.documents!,
    },
    {
      name: 'Allocating Entities',
      value: partnershipBannerStat?.entities!,
    },
  ]

  return (
    <BannerContainer className='client-group-details-banner'>
      <div className='align-banner'>
        <div className='banner-header-full-width'>
          <BannerHeader>{getHeaderContent()}</BannerHeader>
          <NavWidget />
        </div>
        <div className='current-view control-spacing current-view-control-spacing'>
          <CurrentViewControl
            viewItems={clientGroupViewOptions}
            isDisabled={isLoading}
            currentViewValue={clientGroupDetails.currentView}
            onValueChange={onClientGroupViewChanged}
          />
        </div>
        <div>
          <TaxYearControl isDisabled={isLoading} />
        </div>
      </div>
      <Statistics items={statisticsItems} />
    </BannerContainer>
  )
}
