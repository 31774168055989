import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { NavWidget } from '../nav-widget/navWidget'
import {
  LoadingStateEnum,
  clientAndPartnershipStateAtom,
  smGeneralOptionsAtom,
} from '../../state/atom'
import { useRecoilValue } from 'recoil'
import { TaxYearControlStateManager } from './state-manager-tax-year-control/state-manager-tax-year'
import produce from 'immer'
import { EnabledFeature } from '../authentication/enabledFeature'
import './stateManagerBanner.scss'
import { CircularProgress } from '@mui/material'
import { renderFormattedDate } from '../investor-view/investor-utils'
import AddCardIcon from '@mui/icons-material/AddCard'
import { useEffect, useState } from 'react'
import { AddTaxYearModal } from './state-manager-tax-year-control/add-tax-year-modal'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { TaxYear } from '../../api-client/investor-portal-client'
import { ViewSelector } from './view-selector'
import { useParams } from 'react-router-dom'
import { SelectBoxOptions } from '../../../types/types'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface StateManagerBannerProps {
  stateElections: boolean
}
export const StateManagerBanner = ({
  stateElections,
}: StateManagerBannerProps) => {
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  const smgeneralOptions = useRecoilValue(smGeneralOptionsAtom)
  const [showNewTaxYearModal, setShowNewTaxYearModal] = useState<boolean>(false)
  const [taxYearOptions, setTaxYearOptions] = useState<SelectBoxOptions[]>()
  const [taxYearData, setTaxYearData] = useState<TaxYear[]>([])
  const [selectTaxYear, setSelectTaxYear] = useState<string>()

  const { getTaxYears } = useStateManagerApi()

  /** State Manager View Options */
  const stateManagerViewOptions: SelectBoxOptions[] = [
    {
      label: 'Allocating Entity',
      value: 'entity',
    },
    {
      label: 'Investor',
      value: 'investor',
    },
  ]

  /** Get groupId & taxYear from params */
  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }

  /** Set AppStateKey based on the group id and tax year */
  const appStateKey =
    groupId && taxYear ? `StateManagerAdmin_${groupId}_${taxYear}` : ''

  /** Set cutoff date from recoil state */
  const getCutOffDate = () => {
    const cutoffDate = smgeneralOptions?.generalOptions?.actualCutoffDate
    if (smgeneralOptions.loadingState === LoadingStateEnum.Loading) {
      return <CircularProgress color='info' size='20px' />
    }
    let datestring: string

    //Convert date to string and set it
    if (cutoffDate) {
      datestring = renderFormattedDate(cutoffDate)
    } else {
      datestring = 'Not Set'
    }

    return datestring
  }

  /** Set banner header name based on Client Group*/
  const getHeaderContent = () => {
    let statusName: string = 'State Manager'
    const clientGroupName: string = clientGroupInfo.clientGroup?.name!
    if (clientGroupName) {
      statusName = clientGroupName + ' - ' + statusName
    }
    return statusName
  }

  /** Call API to get the tax years based on the entity group Id */
  useEffect(() => {
    const abortController = new AbortController()

    if (clientGroupInfo.clientGroupId! > 0) {
      getTaxYears(clientGroupInfo.clientGroupId!, abortController.signal).then(
        (response) => {
          setTaxYearData(response)
        }
      )
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [clientGroupInfo.clientGroupId])

  /** Set tax year to the dropdown */
  useEffect(() => {
    const taxYearInvestorViewOptions: SelectBoxOptions[] = (taxYearData ?? [])
      .map((y) => {
        return {
          label: y.taxyear!,
          value: y.taxyear,
          icon: y.isLocked ? 'lock' : 'myIcon',
        }
      })
      .sort((a, b) => (a.label > b.label ? -1 : 1))
    setTaxYearOptions(taxYearInvestorViewOptions!)
  }, [taxYearData])

  /** Update tax year if there's any change in  CutoffDate */
  useEffect(() => {
    if (taxYearData && smgeneralOptions?.generalOptions?.taxYear) {
      setTaxYearData(
        produce((draft) => {
          const yearIndex = draft.findIndex(
            (v) =>
              v.taxyear ===
              smgeneralOptions?.generalOptions?.taxYear?.toString()
          )
          if (yearIndex >= 0) {
            // set isLocked- based on the cutoff date
            draft[yearIndex].isLocked =
              smgeneralOptions?.generalOptions?.isLocked
          }
        })
      )
    }
  }, [
    smgeneralOptions?.generalOptions?.cutoffDate
  ])

  return (
    <EnabledFeature isFeatureEnabled={stateElections}>
      <BannerContainer className='state-manager-banner'>
        <div className='cui-c_card align-banner'>
          <div className='banner-header-full-width'>
            <BannerHeader>{getHeaderContent()}</BannerHeader>
            <NavWidget />
          </div>
          <div className='current-view-control'>
            <ViewSelector
              appStateKey={appStateKey!}
              defaultView='entity'
              viewOptions={stateManagerViewOptions}
            ></ViewSelector>
          </div>
          <div className='tax-year-dropdown'>
            <TaxYearControlStateManager
              selectYearWhenUnset={true}
              viewOptions={taxYearOptions!}
              selectYear={selectTaxYear}
            />
          </div>
          <div
            title='Add Tax Year'
            onClick={() => {
              setShowNewTaxYearModal(true)
            }}
          >
            <AddCardIcon fontSize='large' color='info'></AddCardIcon>
          </div>
          <div className='cutoff-date'>
            <BannerHeader>
              <div className='date'>{getCutOffDate()}</div>
            </BannerHeader>
            <label className='title'>Cutoff Date</label>
          </div>
        </div>
      </BannerContainer>
      {showNewTaxYearModal && (
        <AddTaxYearModal
          onCancel={() => setShowNewTaxYearModal(false)}
          onSave={(year: TaxYear) => {
            // Set the new year in the dropdown
            setSelectTaxYear(year.taxyear)

            // Add new tax year if its not available in the state
            setTaxYearData(
              produce((draft) => {
                const yearIndex = draft.findIndex(
                  (v) => v.taxyear === year.taxyear
                )
                // if the year is not available then add to the list
                if (yearIndex === -1) {
                  draft.push(year)
                }
              })
            )
            // Hide new tax year add modal
            setShowNewTaxYearModal(false)
          }}
        ></AddTaxYearModal>
      )}
    </EnabledFeature>
  )
}
