import { InitiateNotificationGenerationRequestModel } from '../api-client/investor-portal-client'
import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import { wrapApiWithCommonErrorHandlers } from './api-error-wrapper'

export const useNotificationClientApi = () => {
  /** Calls the API to trigger investor email*/
  const sendInvestorEmails = async (
    fileIds: number[],
    emailAddresses: string[],
    entityGroupId: number
  ) => {
    const notificationRequest: InitiateNotificationGenerationRequestModel = {
      fileIds: fileIds,
      emailAddresses: emailAddresses,
      entityGroupId: entityGroupId,
    }

    return await filesClientApi.notifications_InitiateNotificationGeneration(
      notificationRequest
    )
  }

  /** Calls the API to resend investor emails */
  const resendInvestorEmails = async (
    fileIds: number[],
    entityGroupId: number
  ) => {
    const notificationRequest: InitiateNotificationGenerationRequestModel = {
      fileIds: fileIds,
      emailAddresses: [],
      entityGroupId: entityGroupId,
    }

    return await filesClientApi.resendStuckEmails(
      notificationRequest
    )
  }

  return wrapApiWithCommonErrorHandlers({
    sendInvestorEmails,
    resendInvestorEmails
  })
}
