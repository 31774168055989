import { SelectBoxOptions } from '../../../types/types'

/** Provides the set of views that can be selected for the ClientGroup page. */
export const entityManagerViewOptions: SelectBoxOptions[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Allocating Entity',
    value: 'allocatingentity',
  },
  {
    label: 'Investor',
    value: 'investor',
  },
]
