import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import './linkInvestorDialog.scss'
import { useFileClientApi } from '../../hooks/use-file-api'
import {
  LinkInvestorSearchRequest,
  Entity,
  SortOrderTypes,
} from '../../api-client/investor-portal-client'
import TextBox from 'devextreme-react/text-box'
import { File } from '../../api-client/investor-portal-client'
import { Button, DataGrid, Popover } from 'devextreme-react'
import { useSetRecoilState } from 'recoil'
import { Link, useParams } from 'react-router-dom'
import { childFileListAtom } from '../../state/atom'
import produce from 'immer'
import { SpinnerModal } from '../modal/spinnerModal'
import { getPartnerIdFromFileTag } from '../investor-files/fileUtilities'
import {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid'
import { FocusedRowChangedEvent } from 'devextreme/ui/data_grid'
import { ICellData } from '../../model/file-model'
import SimpleTooltip from '../reuasble-components/simpleTooltip'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import { NativeEventInfo } from 'devextreme/events'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface LinkInvestorDialogProps {
  onCancel: () => void
  onSave: () => void
  file: File | undefined
  isUnmappedZip: boolean
}

const baseButtonProps = {
  useSubmitBehavior: true,
  stylingMode: 'contained',
}

//check the sort order chosen by user and return
const getSortDirection = (sortOptions: any): SortOrderTypes => {
  return !sortOptions || sortOptions[0].desc === false
    ? SortOrderTypes.Asc
    : SortOrderTypes.Desc
}

//check the sort field chosen by user and return
const getSortField = (sortOptions: any): string | undefined => {
  if (sortOptions) {
    return (
      sortOptions[0].selector.toString().charAt(0).toUpperCase() +
      sortOptions[0].selector.slice(1)
    )
  } else {
    return undefined
  }
}

/** Set the Allocating Entities for the column, if multiple allocating entity found then display
 * Multiple Entities Found as hyperlink and display the Multiple Entity(ies) on mouse over as tooltip
 */
const setAssociatedAllocatingEntity = (cellData: ICellData<Entity>) => {
  const names = cellData.data?.entityLinks?.map((link) => link.name) || []
  if (cellData.rowType === 'data' && names) {
    if (names.length === 1) {
      return <SimpleTooltip displayText={names[0]} popupMessage={names[0]} />
    } else if (names.length > 1) {
      const toolTipArray: string[] = [names[0]!]

      for (let index = 1; index < names.length; index++) {
        toolTipArray.push(names[index]!)
      }

      return (
        <SimpleTooltip
          displayText={
            <span className='tooltip-string-link'>Multiple Entities Found</span>
          }
          popupMessage={toolTipArray.join('; ')}
        />
      )
    }
  }
  return ''
}

export const LinkInvestorDialog = ({
  onCancel,
  onSave,
  file,
  isUnmappedZip,
}: LinkInvestorDialogProps) => {
  const clientApi = useFileClientApi()
  const setChildFiles = useSetRecoilState(childFileListAtom)
  const [searchVal, setSearchVal] = useState('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [updatePrmoise, setUpdatePromise] = useState<Promise<any>>()

  const [popOverTarget, setPopOverTarget] = useState<string>()
  const [popOverVisible, setPopOverVisible] = useState<boolean>(false)
  const [popOverText, setPopOverText] = useState<string | string[]>()

  const { groupId } = useParams() as {
    groupId: string
  }

  const currentApiRequestAbortControllerRef = useRef<
    AbortController | undefined
  >()

  const [selectedId, setSelectedId] = useState<number>(file?.partnerEntityId!)
  //Here None should only be highlighted when the entityId is 0 ie.No investor is selected
  const isNoneClicked = selectedId === 0
  const enableSaveButton: boolean = selectedId !== file?.partnerEntityId

  /** Function to executed when None button is clicked */
  const onNoneClicked = () => {
    setSelectedId(0) //  selected ID when "NONE" button is clicked
  }

  /** Function to executed when Row is clicked ;highlight the row. */
  const onRowClick = useCallback(
    (e: FocusedRowChangedEvent<Entity, number>) => {
      if (e?.row) {
        const selectedRowId = e.row.key
        setSelectedId(selectedRowId)
      }
    },
    []
  )

  /**  Effect to clean up any ongoing API requests when file ID, group ID, or search value changes.*/
  useEffect(() => {
    return () => {
      // Abort any API calls currently in flight.
      if (currentApiRequestAbortControllerRef.current) {
        currentApiRequestAbortControllerRef.current.abort(DISMOUNT_ABORT_REASON)
      }
    }
  }, [file?.id, groupId, searchVal])

  /**Render tooltip text and align center.*/
  const renderTooltip = () => {
    if (typeof popOverText === 'string') {
      return <div className='content-center'>{popOverText}</div>
    } else {
      return (
        <div className='content-center popup-tooltip'>
          {popOverText!.map((name) => {
            return <div>{name} |</div>
          })}
        </div>
      )
    }
  }

  /** Create the buttons for the Modal. */
  const buttonFunctions = useMemo(() => {
    const buttons = [
      {
        label: 'Cancel',
        display: true,
        isDefaultAction: true,
        buttonProps: {
          className: 'cancel-btn',
          ...baseButtonProps,
          'data-testid': 'cancel-button',
        },
        onClick: () => onCancel(),
      },
      {
        label: 'Save',
        display: true,
        isDisabled: !enableSaveButton,
        buttonProps: {
          className: 'save-btn',
          ...baseButtonProps,
          'data-testid': 'save-button',
          type: 'default',
        },
        onClick: () => handleSubmit(),
      },
    ] as (ButtonFunction & { display?: boolean })[]

    // Return the buttons we need, based on their display property.
    return buttons.filter((x) => !!x.display)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, enableSaveButton])

  /** Set PopOver configuration to display.*/
  const setPopOverDisplay = (
    showPopOver: boolean,
    text: string | string[],
    target: string
  ) => {
    setPopOverVisible(showPopOver)
    setPopOverText(text)
    setPopOverTarget(target)
  }

  /** Set the email id for the column, if multiple emails found then display
   * Multiple Emails Found as hyperlink and display the email(s) on mouse over as tooltip.
   */
  const setEmailAddresses = (cellData: ICellData<Entity>) => {
    const emailList =
      typeof cellData.data?.emailAddress === 'string'
        ? cellData.data?.emailAddress.split(';')
        : []

    if (cellData.rowType === 'data' && emailList.length > 0) {
      const controlId = 'email-' + (cellData.data?.id ?? '') // Provide default value for id
      const firstEmail = emailList.length > 0 ? emailList[0] : '' // Provide default value for first email

      // If only one email is available
      if (emailList.length === 1) {
        return (
          <div>
            <span
              id={'name-' + controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, firstEmail, '#name-' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              {firstEmail}
            </span>
          </div>
        )
      } else if (emailList.length > 1) {
        // If multiple emails are present
        return (
          <div>
            <Link
              to=''
              id={'link-' + controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, emailList, '#link-' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              Multiple Emails Found
            </Link>
          </div>
        )
      }
    }

    return ''
  }

  /** custom store */
  const dataStore = useMemo(() => {
    // We define our search request to  load the entities
    let searchRequest: LinkInvestorSearchRequest | undefined = undefined

    // Abort controller so that subsequent loads can cancel
    //  previous calls to load.
    let abortController:
      | AbortController
      | undefined /** Loads the entity  data from the server. */
    const loadEntityHandler = async (loadOptions: LoadOptions<Entity>) => {
      // Abort the previous call, if we have a controller.
      abortController?.abort()

      // Create a new abort controller so we can abort, if needed.
      abortController = new AbortController()

      currentApiRequestAbortControllerRef.current = abortController

      // Set loading to true to display loading in UI
      setIsLoading(true)

      // Search request object
      searchRequest = {
        clientGroupId: parseInt(groupId),
        sortDirection: getSortDirection(loadOptions.sort),
        sortField: getSortField(loadOptions.sort),
        pageSize: loadOptions['take'],
        skipCount: loadOptions['skip'],
        searchText: searchVal,
      }

      // Make API call and set data in store
      const responseData = await clientApi.getAllPartnerEntities(
        file?.id!,
        searchRequest,
        abortController.signal
      )
      // Set loading flag to false
      setIsLoading(false)

      return {
        data: responseData,
        totalCount: responseData.length,
      }
    }

    return new CustomStore({
      key: 'id',
      load: loadEntityHandler,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file?.id, groupId, searchVal])

  /**Function to be executed While Saving and updating into RecoilState. */
  const handleSubmit = () => {
    if (selectedId || selectedId === 0) {
      setShowSpinner(true)
      let updatedPartnerEntityPromise = clientApi
        .updatedPartnerEntityOnFile(file?.id!, selectedId!)
        .then(async (response: Entity) => {
          setChildFiles(
            produce((draft) => {
              const fileIndex = draft.files.findIndex(
                (item) => item.id === file?.id
              )
              if (fileIndex >= 0) {
                const emailTemplate = draft.files[fileIndex].emailTemplate
                draft.files[fileIndex] = response
                draft.files[fileIndex].emailTemplate = emailTemplate
              }
            })
          )
          await clientApi.updateParentFileByIdInRecoil(file?.parentId!)
          onSave()
          setShowSpinner(false)
        })
      setUpdatePromise(updatedPartnerEntityPromise)
    }
  }

  /** Function called when the search input value changes. */
  const handleSearchChange = (
    e: NativeEventInfo<TextBoxInstance, Event> & ValueChangedInfo
  ) => {
    // Check if the event object exists
    if (e.event) {
      setSearchVal(e.value)
    }
  }

  return (
    <Modal
      visible={true}
      title={'Link Investor '}
      buttonFunctions={buttonFunctions}
      maxWidth={isUnmappedZip ? 850 : 700}
      maxHeight={650}
      showCloseButtonInTitle={true}
      className='link-investor-modal'
      testId='link-investor-dialog'
    >
      <div className='form-container form-scroll-horizontal'>
        <div className='content-style'>
          <div> Search</div>
          <TextBox
            className='search-box'
            onValueChanged={handleSearchChange}
            valueChangeEvent='keyup'
            placeholder='Search...'
            value={searchVal}
            width={isUnmappedZip ? 750 : 600}
            showClearButton={true}
          ></TextBox>
        </div>
        {!isUnmappedZip && (
          <div className='content-style'>
            <div> EIN/SSN</div>
            <TextBox
              value={getPartnerIdFromFileTag('PartnerId', file?.tags)}
              width={600}
              disabled
            ></TextBox>
          </div>
        )}
        <div className='content-style'>
          <Button
            stylingMode={isNoneClicked ? 'contained' : 'outlined'}
            type='default'
            data-testid='none-Button'
            onClick={onNoneClicked}
            text='NONE'
          />
        </div>
        <div className='content-style'>
          <div> Select investor to link</div>
          <div className='list-container'>
            <DataGrid
              showBorders={true}
              dataSource={dataStore}
              data-testid='filterd-entity-list'
              noDataText={isLoading ? 'Loading..' : ''}
              columnAutoWidth={true}
              height='300px'
              width='100%'
              repaintChangesOnly={false}
              remoteOperations={true}
              showColumnLines={false}
              showRowLines={true}
              wordWrapEnabled={false}
              hoverStateEnabled={true}
              focusedRowEnabled={true}
              focusedRowKey={selectedId}
              onFocusedRowChanged={onRowClick}
            >
              <Paging defaultPageSize={8} />
              <Scrolling mode='infinite' rowRenderingMode='virtual' />
              <SearchPanel visible={false} width='600px' text={searchVal} />
              <Column
                dataField='id'
                caption='Entity ID'
                dataType='string'
                allowSearch={true}
                allowSorting={true}
                width={'15%'}
                allowFiltering={false}
              />
              <Column
                dataField='identificationNumber'
                caption='EIN/SSN'
                dataType='string'
                allowSearch={true}
                allowSorting={true}
                visible={isUnmappedZip}
                width={'15%'}
                allowFiltering={false}
              />
              <Column
                dataField='sortedName'
                caption='Entity Name'
                dataType='string'
                allowSearch={true}
                allowSorting={true}
                defaultSortOrder='asc'
                width={'20%'}
                allowFiltering={false}
              />
              <Column
                dataField='emailAddress'
                caption='Email Address'
                dataType='string'
                allowSorting={true}
                cellRender={setEmailAddresses}
                data-includeTooltip={false}
                width={'30%'}
                allowFiltering={true}
                allowSearch={true}
                allowEditing={false}
              />
              <Column
                caption='Associated Allocating Entities'
                dataField='entityLinks'
                allowSearch={true}
                allowSorting={true}
                allowFiltering={false}
                width={'30%'}
                dataType='string'
                cellRender={setAssociatedAllocatingEntity}
                allowEditing={false}
              />
              <Column
                dataField='matchingCriteria'
                caption='Match Criteria'
                dataType='string'
                allowSearch={true}
                allowSorting={true}
                visible={isUnmappedZip}
                width={'15%'}
                allowFiltering={false}
              />
            </DataGrid>
          </div>
        </div>
      </div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Link Investor'
        inProgressTitleMessage='Link Investor'
        inProgressMessage='Link Investor in progress'
        successTitleMessage='Link Investor'
        successMessage='Selected partner entity saved successfully.'
        onClose={() => setShowSpinner(false)}
        apiAction={updatePrmoise}
      />
      <Popover
        target={popOverTarget}
        visible={popOverVisible}
        width={280}
        contentRender={renderTooltip}
      ></Popover>
    </Modal>
  )
}
