import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import './ui-kit/sass/cui.theme.blue.light.scss'
import './ui-kit/sass/vendor/dx/cui.theme.dx.blue.light.css'
import '../node_modules/primeflex/primeflex.css'
import React, { useCallback, useMemo } from 'react'
import { OidcIdentity, getAccessToken } from '@rsmus/react-auth'
import { Eula } from '@rsmus/rsm-eula'
import { IsAuthenticated } from './components/route/isAuthenticated'
import {
    REACT_APP_CEM_ROLES_AUTHORITY,
    REACT_APP_IDMAUTHENTICATIONAUTHORITY,
    REACT_APP_IDMAUTHENTICATIONCLIENTID,
    REACT_APP_IDMAUTHENTICATIONEULASERVICEURL,
    REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS,
} from './envVariables'
import { useSignalR } from './signalr/useSignalR'
import Layout from './components/layout/Layout'
import { setup } from './ui-kit/components/investor/Data'
import { useLocation, Outlet } from 'react-router-dom'
import './App.scss'
import './sass/components/context-menu.scss'
import { CommonDialog } from './components/modal/commonDialog/commonDialog'
import { Banner } from './components/banners/banner'
import { SessionTimeoutExternalUser } from './components/layout/SessionTimeout'
import { useNavigate } from './hooks/useNavigationGuard'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/error-boundary/ErrorFallback'
import { AccessProvider } from './access-manager/access-provider'
import { TokenChecker } from './tokenChecker'

const App: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const getToken = useCallback(async (): Promise<string> => {
        const token = await getAccessToken()
        return token || ''
    }, [])

    // Check if we're running in an IFrame.  If so, then the app
    //  is only being loaded for token refresh, and not actual operation.
    const runningInIframe = window.self !== window.top
    useSignalR(!runningInIframe)

    // function to handle the identity redirect call.
    const handleRedirect = (url: string) => {
        try {
            const fullUrl = new URL(url)
            if (fullUrl.pathname !== location.pathname) {
                navigate(fullUrl.pathname)
            } else {
                window.history.replaceState({}, '', url)
            }
        } catch (err) {
            console.log('handleRedirect error here ')
        }
    }

    const identity = useMemo(() => {
        // If we're running in an IFrame, then this is just for token refresh.  We don't want the
        //  whole app to load, since it will trigger unnecessary server communications.
        if (runningInIframe) {
            return (
                <OidcIdentity
                    authority={REACT_APP_IDMAUTHENTICATIONAUTHORITY}
                    client_id={REACT_APP_IDMAUTHENTICATIONCLIENTID}
                    redirect_uri={window.location.origin}
                    autoLogin={true}
                    postLogoutRedirectUri={'https://rsmus.com'}
                    rolesAuthority={REACT_APP_CEM_ROLES_AUTHORITY}
                    rolesAuthorityLevels={['application', 'engagement']}
                    rolesAuthorityType='CEM'
                    response_type='code'
                    accessTokenExpiringNotificationTimeInSeconds={
                        REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS
                    }
                    automaticSilentRenew={false}
                    silentRequestTimeoutInSeconds={60}
                    onDeepLinkRedirectCallback={handleRedirect}
                    scope={'openid profile tsa_investor_portal'}
                >
                    <div>Dummy content for token refresh page.</div>
                </OidcIdentity>
            )
        }

        return (
            <OidcIdentity
                authority={REACT_APP_IDMAUTHENTICATIONAUTHORITY}
                client_id={REACT_APP_IDMAUTHENTICATIONCLIENTID}
                redirect_uri={window.location.origin}
                rolesAuthority={REACT_APP_CEM_ROLES_AUTHORITY}
                rolesAuthorityLevels={['application', 'engagement']}
                rolesAuthorityType='CEM'
                response_type='code'
                autoLogin={true}
                redirectAfterLogout={true} // works in conjunction with postLogoutRedirectUri
                includeIdTokenInSilentRenew={false}
                postLogoutRedirectUri={'https://rsmus.com'}
                accessTokenExpiringNotificationTimeInSeconds={
                    REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS
                }
                automaticSilentRenew={true}
                silentRequestTimeoutInSeconds={60}
                onDeepLinkRedirectCallback={handleRedirect}
                scope={'openid profile tsa_investor_portal'}
            >
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <IsAuthenticated>
                        <Eula
                            host={REACT_APP_IDMAUTHENTICATIONEULASERVICEURL}
                            tokenFactory={getToken}
                        >
                            <AccessProvider>     
                                <Layout appTitle={setup.appTitle} appTheme={'blue'}>
                                    <div className='content-container'>
                                        <Banner />
                                        <Outlet />
                                        <CommonDialog />
                                        <SessionTimeoutExternalUser />
                                    </div>
                                </Layout>
                            </AccessProvider>
                        </Eula>
                    </IsAuthenticated>
                </ErrorBoundary>
            </OidcIdentity>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return identity
}

export default App
