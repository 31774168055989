import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NavDataProps } from '../components/layout/leftnav/LeftNav'
import {
  getClientsPageRoute,
  getEntityManagerRoute,
  getLandingPageRoute,
  getManageExistingInvestorsRoute,
  getProvisionUserByEmailPageRoute,
  getStateManagerRoute,
  getVerifyUserPageRoute,
} from '../components/utility/route-creation'
import { REACT_APP_FEATURES_MANAGEEXISTING, REACT_APP_FEATURES_STATEMANAGER } from '../envVariables'
import { REACT_APP_FEATURES_ENTITYMANAGER } from '../envVariables'
import { ensureNumberOrUndefined } from '../utilities/utilities'
import { entityGroupPartnershipStateAtom } from '../state/atom'
import { useRecoilValue } from 'recoil'

export function useNavDataAdmin() {
  /** Notify: is statemanager active for the current app?
   * We are storing it into a variable for further reference
   */ 
  const enableStateManager = REACT_APP_FEATURES_STATEMANAGER ?? false  
  const enableEntityManager = REACT_APP_FEATURES_ENTITYMANAGER ?? false
  const enableManageExistingInvestorsFeature = REACT_APP_FEATURES_MANAGEEXISTING ?? false
  const EntityClientGroup = useRecoilValue(entityGroupPartnershipStateAtom)
  //notifying groupId to inject relative paths for the navData
  const { groupId } = useParams() as { groupId: string }
  const groupIdNumber = ensureNumberOrUndefined(groupId)

  //Enabling StateManager icon in leftnav with State Elections flag from recoil state
  const clientState = groupIdNumber ? EntityClientGroup[groupIdNumber] : undefined
  const elections = clientState ?
    (clientState.featureFlag?.stateElections ? clientState.featureFlag?.stateElections : false)
    : false

  // Memoizing nav data for Admin view
  const navData: NavDataProps = useMemo(() => {
    return {
      default: {
        title: 'Home',
        path: getLandingPageRoute(),
        icon: 'home',
        iconsType: 'filled',
      },
      menu: [
        {
          title: 'Provision Contact Email',
          path: getProvisionUserByEmailPageRoute(),
          icon: 'contact_mail',
          tooltip: '',
        },
        {
          title: 'Manage Existing Investors',
          path: getManageExistingInvestorsRoute(),
          icon: 'contact_mail',
          tooltip: '',
          visible: enableManageExistingInvestorsFeature,
        },
        {
          title: 'User Verification',
          path: getVerifyUserPageRoute(),
          icon: 'admin_panel_settings',
          tooltip: '',
        },
        {
          title: 'State Manager',
          path: getStateManagerRoute(groupIdNumber),
          icon: 'place',
          tooltip: 'State Manager',
          visible: elections! && enableStateManager,
        },
        {
          title: 'Entity Manager',
          path: getEntityManagerRoute(groupIdNumber),
          icon: 'work',
          tooltip: 'Entity Manager',
          visible: !!groupIdNumber && enableEntityManager,
        },
        {
          title: 'Settings',
          icon: 'settings',
          tooltip: 'Settings',
          visible: true,
          menu: [
            {
              title: 'Clients',
              path: getClientsPageRoute(),
              tooltip: 'Clients',
              visible: true,
            },
          ],
        },
      ],
    }
  }, [groupIdNumber, elections, enableStateManager, enableEntityManager, enableManageExistingInvestorsFeature])

  return navData
}
