import CheckBox from 'devextreme-react/check-box'
import './general-selection.scss'
import { Calendar } from 'devextreme-react/calendar'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { Button } from 'devextreme-react'
import { SMGeneralOptions } from '../../api-client/investor-portal-client'
import { useEffect, useState } from 'react'
import { renderFormattedDate } from '../investor-view/investor-utils'
import { GeneralOptions } from '../../state/atom'

export interface GeneralSettingsProps {
  onSave: () => void
  generalSelections: GeneralOptions
  updateGeneralSelections: (generalSelections: SMGeneralOptions) => void
}
/**General Selection Tab */
export const GeneralSettings = ({
  onSave,
  generalSelections,
  updateGeneralSelections,
}: GeneralSettingsProps) => {
  const commonDialog = useCommonDialogs()
  const [selections, setSelections] =
    useState<GeneralOptions>(generalSelections)

  /**Handle Composite,withholds and applyruleset checkbox */
  const handleCheckboxChange = (name: string, value: boolean) => {
    // Update the data state directly
    const newData = { ...generalSelections, [name]: value }
    updateGeneralSelections(newData) // Send back the updated data
  }

  /**Handle Cutoff date change */
  const handleDateChange = (e: { value?: Date }) => {
    if (!!e.value) {
      const newDate = e.value
      // Update the data state directly
      const newData = { ...generalSelections, cutoffDate: newDate }
      newData.actualCutoffDate = new Date(newDate)
      updateGeneralSelections(newData) // Send back the updated data
    }
  }

  /**Set Selections to General Selections to update checkboxes */
  useEffect(() => {
    setSelections(generalSelections)
  }, [generalSelections])

  /**Button Function for Clear Selections */
  const clearSelections = () => {
    const clearData: SMGeneralOptions = {
      ...selections,
      applyRuleSet: false,
      composite: false,
      witholdings: false,
      cutoffDate: undefined,
    }
    updateGeneralSelections(clearData)
  }

  return (
    <div>
      <div className='general-tab-container'>
        <div className='content-container'>
          <div className='left-container'>
            <h4>Election Options</h4>
            <div className='composites'>
              <CheckBox
                text='Composites'
                value={selections?.composite!}
                onValueChanged={(e) =>
                  handleCheckboxChange('composite', e.value)
                }
              />
            </div>
            <div className='withholdings'>
              <CheckBox
                text='Withholdings'
                value={selections?.witholdings!}
                onValueChanged={(e) =>
                  handleCheckboxChange('witholdings', e.value)
                }
              />
            </div>
            <h4>Rulesets</h4>
            <div className='rulesets'>
              <CheckBox
                text='Apply Rulesets'
                value={selections?.applyRuleSet!}
                onValueChanged={(e) =>
                  handleCheckboxChange('applyRuleSet', e.value)
                }
              />
            </div>
          </div>
          <div className='right-container' data-testid='cuttoff-date'>
            <h4>CutOff Date</h4>
            <Calendar
              value={
                selections?.actualCutoffDate
                  ? renderFormattedDate(selections?.actualCutoffDate!)
                  : undefined
              }
              onValueChanged={handleDateChange}
            />
          </div>
        </div>
      </div>
      <div className='general-tab-buttons'>
        <Button
          text='Clear Selection'
          type='default'
          stylingMode='contained'
          className='clear-btn-State'
          onClick={() =>
            commonDialog.showDialog({
              dialogType: 'general',
              title: 'Clearing Selection',
              content: 'Are you sure you want to clear selections?',
              omitDefaultButton: true,
              buttonFunctions: [
                {
                  label: 'Cancel',
                  isCloseAction: true,
                  isDefaultAction: true,
                  onClick: () => {},
                  buttonProps: {
                    stylingMode: 'contained',
                    type: 'normal',
                    width: 100,
                    'data-testid': 'cancel-button',
                  },
                },
                {
                  label: 'Proceed',
                  isCloseAction: true,
                  onClick: () => {
                    clearSelections()
                  },
                  buttonProps: {
                    stylingMode: 'contained',
                    type: 'default',
                    width: 110,
                    'data-testid': 'proceed-button',
                  },
                },
              ],
            })
          }
          data-testid='sm-general-clear-button'
        ></Button>
        <Button
          text='Save Selection'
          type='default'
          stylingMode='contained'
          className='save-btn-State'
          onClick={onSave}
          data-testid='sm-general-Save-button'
        ></Button>
      </div>
    </div>
  )
}
