import { FC, useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { leftNavExpandedAtom } from '../../../state/atom'
import Iconography, { AppTitle } from '../Iconography'
import './leftnav.scss'
import { NavTree } from './navTree'
import { usePermissions } from '../../../access-manager/use-permission'
import { INVESTOR } from '../../../model/permissions'
import { permissionRequestAny } from '../../../access-manager/permission-request-creation'

export type IconsType = string | undefined
export interface NavItemProps {
  title: string
  path?: string
  tooltip?: string
  icon?: string
  iconsType?: IconsType
  menu?: NavItemProps[]
  visible?: boolean
}

export interface NavDataProps {
  default: NavItemProps
  menu: NavItemProps[]
}

interface LeftNavProps {
  appTitle: AppTitle
}

export const LeftNav: FC<LeftNavProps> = ({ appTitle }) => {
  const [menuHeight, setMenuHeight] = useState(window.innerHeight)
  const updateMenuHeight = () => {
    setMenuHeight(window.innerHeight)
  }

  //is leftNav expanded?
  const [isNavExpanded, setNavExpanded] = useRecoilState(leftNavExpandedAtom)
  const isInvestor = usePermissions([permissionRequestAny(INVESTOR)])

  useEffect(() => {
    window.addEventListener('resize', updateMenuHeight)
    return () => window.removeEventListener('resize', updateMenuHeight)
  })

  // if the user is investor then expand LeftNav
  useEffect(() => {
    if (isInvestor) {
      setNavExpanded(true)
    }
  }, [isInvestor])

  return (
    <div
      id='cui-layout_leftnav'
      data-testid='layout_leftnav'
      className={isNavExpanded ? 'nav-expanded' : 'nav-collapsed'}
    >
      <div className='cui-nav' style={{ height: menuHeight }}>
        <menu>
          <Iconography appTitle={appTitle}></Iconography>
          <div
            className='menu-icon-button'
            onClick={() => setNavExpanded(!isNavExpanded)}
            data-testid='link'
          >
            <span className='material-icons'>menu</span>
            <span className='nav-name'>Menu</span>
          </div>
        </menu>
        <NavTree />
      </div>
    </div>
  )
}
