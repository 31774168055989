import { NavWidgetOption } from './nav-widget-models'
import { CLIENT_GROUP_MENU_LEVEL } from './widget-levels'

// Address dynamic "static" items.

/** Returns the static default values to provide for the navigation widget.  The return value
 *   may be modified based on the circumstances.  */
export function getDefaultWidgetOptions(): NavWidgetOption[] {
  return [
  ]
}
