import { Button, Popup, ScrollView } from 'devextreme-react'
import { IButtonOptions } from 'devextreme-react/ui/button'
import CloseIcon from '@mui/icons-material/Close'
import './modalBase.scss'
import { PropsWithChildren, useRef, useState, useEffect } from 'react'

/**  */
export interface DialogIButtonOptions extends Partial<IButtonOptions> {
  /** Test ID to use in unit testing, if any. */
  ['data-testid']?: string
}

export interface ButtonFunction {
  /** The text to show for the button. */
  label: string

  /** Boolean value indicating whether or not the button is disabled. */
  isDisabled?: boolean

  /** Boolean value indicating whether display the button or not */
  visible?: boolean

  /** Function to execute when the button is clicked. */
  onClick: () => void | Promise<void>

  /** Set of button properties to apply to the button created for this function, if any. */
  buttonProps?: DialogIButtonOptions

  /** Specifies that this action is the default action to use
   *   when the user clicks outside the dialog, or otherwise closes it. */
  isDefaultAction?: boolean

  /** String value indication left or right alignment */
  alignment?: string
}

export interface ModalProps {
  visible: boolean
  buttonFunctions?: ButtonFunction[]
  title: string | React.ReactNode
  maxWidth?: number
  maxHeight?: number
  /** When provided, sets the data-testid on the main contianer of the modal to this value. */
  testId?: string
  height?: string
  /** Boolean value indicating that the internal scrollbar on the modal should not be used.
   *   Default: false
   */
  disableScrollbar?: boolean
  className?: string
  /** Boolean value indicating whether or not to show the
   *   "X" button in the title bar of the dialog, which closes
   *   the dialog.  NOTE: A "default" button method must be provided
   *   also to make this button appear. */
  showCloseButtonInTitle?: boolean
  /**
   * Setting this flag to true will disable the default behavior of closing the modal
   * when clicking outside while setting it to false will allow the modal to close on outside click.
   */
  preventModalClose?: boolean
}

/** Returns the standard OK and Cancel functions, using a specified
 *   set of functions to call when the buttons were clicked. */
export function createStandardFunctions(
  onCancelFunc: () => void,
  onOkFunc: () => void
): ButtonFunction[] {
  return [
    {
      label: 'CANCEL',
      onClick: () => onCancelFunc(),
      alignment: 'right',
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'OK',
      onClick: () => onOkFunc(),
      alignment: 'right',
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'ok-button',
      },
    },
  ]
}

export const Modal = ({
  visible,
  children,
  buttonFunctions,
  title,
  maxWidth,
  maxHeight,
  height,
  disableScrollbar,
  className,
  showCloseButtonInTitle,
  testId,
  preventModalClose,
}: PropsWithChildren<ModalProps>) => {
  const ref = useRef<Popup>(null)
  const [maxHeightVal, setMaxHeightVal] = useState(maxHeight)
  const [leftButtons, setLeftButtons] = useState<ButtonFunction[]>([])
  const [rightButtons, setRightButtons] = useState<ButtonFunction[]>([])

  // Ensure there are no more than one default function specified.
  //  Find the single function, if one exists, in the process.
  let defaultCount = 0
  let defaultFunction: Function | undefined = undefined

  buttonFunctions?.forEach((f) => {
    if (f.isDefaultAction) {
      defaultCount++
      defaultFunction = f.onClick
    }
  })

  if (defaultCount > 1) {
    throw new Error(
      `buttonFunctions has more than one function with it's isDefaultAction set to true.`
    )
  }

  // This hack re-adjusts the size of the dialog to correct a visual
  //  anomaly that happens on the first render.
  useEffect(() => {
    if (maxHeight) {
      setMaxHeightVal(maxHeight - 1)
    } else {
      setMaxHeightVal(2000)
    }

    if (visible) {
      setTimeout(() => {
        setMaxHeightVal(maxHeight)
      }, 5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  // This hack re-adjusts the size of the dialog to correct a visual
  //  anomaly that happens on the first render.
  useEffect(() => {
    const leftAlignButtons: ButtonFunction[] = []
    const rightAlignButtons: ButtonFunction[] = []
    buttonFunctions?.forEach((f) => {
      if (f.alignment && f.alignment === 'left') {
        leftAlignButtons.push(f)
      } else {
        rightAlignButtons.push(f)
      }
      setLeftButtons(leftAlignButtons)
      setRightButtons(rightAlignButtons)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonFunctions])

  const titleRenderFunc = (_: any) => (
    <div className='modal-header'>
      <div className='modal-header-title'>{title}</div>
      {defaultFunction && showCloseButtonInTitle && (
        <div className='modal-close-button-container'>
          <div
            onClick={() => defaultFunction!()}
            className='modal-close-button'
            role='button'
          >
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  )

  return (
    <Popup
      ref={ref}
      visible={visible}
      titleRender={titleRenderFunc}
      maxWidth={maxWidth}
      maxHeight={maxHeightVal}
      hideOnOutsideClick={!preventModalClose && defaultFunction}
      height={height ?? '80vh'}
    >
      <div
        id={`modal-content-container`}
        data-testid={testId}
        className={`${className ?? ``}`}
      >
        {!disableScrollbar && (
          <ScrollView>
            <div id='modal-main-content'>{children}</div>
          </ScrollView>
        )}
        {disableScrollbar && <div id='modal-main-content'>{children}</div>}
        <div className='button-functions'>
          <div className='left-aligned-buttons'>
            {leftButtons?.map((f) => (
              <Button
                id={f.label}
                key={f.label}
                onClick={f.onClick}
                disabled={f.isDisabled}
                visible={f.visible !== false}
                text={f.label}
                {...((f.buttonProps ?? {}) as any)}
              ></Button>
            ))}
          </div>
          <div className='right-aligned-buttons'>
            {rightButtons?.map((f) => (
              <Button
                id={f.label}
                key={f.label}
                onClick={f.onClick}
                disabled={f.isDisabled}
                visible={f.visible !== false}
                text={f.label}
                {...((f.buttonProps ?? {}) as any)}
              ></Button>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  )
}
