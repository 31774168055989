import { OperationErrorWatcher } from "../watcher/operation-error-watcher"
import { ReportCompleteWatcher } from "../watcher/report-complete-watcher"

/** This method is responsible to unify the report download and operation error watcher */
export const downloadReport = (apiPromise: Promise<any>, operationId: number): Promise<any> => {

    //Call the report complete watcher
    const reportCompleteWatcher = new ReportCompleteWatcher(
        apiPromise,
        operationId
    )
    //Call the operation error watcher
    const operationErrorWatcher = new OperationErrorWatcher(
        reportCompleteWatcher.promise,
        operationId
    )
    // Initialize all watchers
    reportCompleteWatcher.initialize()
    operationErrorWatcher.initialize()
    operationErrorWatcher.reportWatcher = reportCompleteWatcher
    reportCompleteWatcher.operationWatcher = operationErrorWatcher

    //return the report complete watcher promise to show spinner model
    return reportCompleteWatcher.promise
}