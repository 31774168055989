import React, { PropsWithChildren, useMemo, ReactNode } from 'react'
import { Modal, ButtonFunction } from './modalBase'
import { CircularProgress } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './formattedDialog.scss'
import { ButtonType } from 'devextreme/ui/button'

export type FormattedDialogTypes =
  | 'in-progress'
  | 'error'
  | 'success'
  | 'general'

export interface FormattedDialogProps {
  /** This is only used for unit tests. */
  testId?: string
  /** Indicates the type of dialog to show, ultimately dictating the style of the dialog. */
  dialogType: FormattedDialogTypes
  /** Sets whether or not the dialog is visible. */
  visible: boolean
  /** The title of the dialog.  This is only required for the `general` dialogType.
   *   Otherwise, if it is missing, then a standard title is provided. */
  title?: string
  /** Optional buttons to display on the dialog.  If omitted, then the standard 'close'
   *   button is provided, with the style appropriate for the dialogType. */
  buttonFunctions?: Array<ButtonFunction>
  /** Sets the max width of the dialog. 450px is the default value.  */
  maxWidth?: number
  /** Sets the max height of the dialog.  250px is the default value. */
  maxHeight?: number
  /** Sets the height of the dialog.  While 80vw is the default value,
   *   This is typically limited by the maxHeight. */
  height?: string

  disableScrollbar?: boolean

  /** Function to call when the standard 'close' button is called.
   *   Note: The close button will automatically close the dialog after
   *   calling this method.  */
  onCloseClicked?: () => void

  /** Hide the close button if the value is true */
  hideCloseButton?: boolean

   /** Prevent close model when click outside if the value is true */
   preventModalClose?: boolean
}

/** Returns the standard default button type for a specific dialogType. */
export const getButtonTypeForDialogType = (
  dialogType: FormattedDialogTypes
) => {
  if (dialogType === 'error') {
    return 'danger'
  }
  return 'default'
}

export const FormattedDialog = ({
  title,
  visible,
  dialogType,
  children,
  buttonFunctions,
  maxWidth,
  maxHeight,
  height,
  onCloseClicked,
  testId,
  disableScrollbar,
  hideCloseButton,
  preventModalClose,
}: PropsWithChildren<FormattedDialogProps>) => {
  type ModalDetails = {
    icon: ReactNode | null
    title: ReactNode | string | null
    buttonFunctions: Array<ButtonFunction>
  }

  // If this is a "General" dialog, then we MUST have a title.
  if (dialogType === 'general' && !title) {
    throw new Error(
      `the 'title' property must be provided when the 'dialogType' is 'general'.`
    )
  }

  // Determine the title content.
  const modalDetails: ModalDetails = useMemo(() => {
    // Create the base return value.
    let baseResult: Partial<ModalDetails>
    let buttonType = 'default' as ButtonType
    switch (dialogType) {
      case 'error':
        baseResult = {
          icon: <ErrorOutlineIcon color='error' />,
          title: 'Error',
        }
        buttonType = 'danger'
        break

      case 'in-progress':
        baseResult = {
          icon: (
            <CircularProgress
              color='info'
              size='20px'
              style={{ margin: '2px' }}
            />
          ),
          title: 'In Progress...',
        }
        break

      case 'general':
        baseResult = {
          icon: null,
          title: title,
        }
        break

      case 'success':
        baseResult = {
          icon: <CheckCircleOutlineIcon color='info' fontSize='medium' />,
          title: 'Success',
        }
        break

      default:
        baseResult = {
          icon: null,
          title: null,
        }
        break
    }

    // Update the output with the actual title, if there was one provided.
    if (title) {
      baseResult.title = title
    }

    if (buttonFunctions) {
      baseResult.buttonFunctions = buttonFunctions
    } else {
      if (!onCloseClicked) {
        throw new Error(`onCloseClicked must be set.`)
      }
      baseResult.buttonFunctions = [
        {
          label: 'Close',
          onClick: () => onCloseClicked(),
          alignment: 'right',
          isDefaultAction: true,
          visible: !hideCloseButton,
          buttonProps: {
            stylingMode: 'contained',
            type: buttonType,
            'data-testid': 'close-button',
          },
        },
      ]
    }

    // Return the result.
    return baseResult as ModalDetails
  }, [dialogType, title, buttonFunctions, onCloseClicked])

  const dialogTitle = useMemo(
    () => (
      <div className='formatted-dialog-modal-title'>
        <div>{modalDetails.icon}</div>
        <div className='formatted-dialog-title-text'>{modalDetails.title}</div>
      </div>
    ),
    [modalDetails]
  )

  return (
    <Modal
      testId={testId}
      title={dialogTitle}
      visible={visible}
      buttonFunctions={modalDetails.buttonFunctions}
      maxWidth={maxWidth ?? 400}
      maxHeight={maxHeight ?? 250}
      height={height}
      className='FormattedDialog'
      disableScrollbar={disableScrollbar}
      preventModalClose={preventModalClose}
    >
      {children}
    </Modal>
  )
}
