import React, { useEffect, useState } from 'react'
import { File, FileStatus } from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'
import { useNotificationClientApi } from '../../hooks/use-notification-api'
import { useRecoilPinner } from '../../signalr/useRecoilPinner'
import { childFileListAtom, parentFileListAtom } from '../../state/atom'
import { FileEmailMessageWatcher } from '../../signalr/watcher/file-message-watcher'

export interface ResendEmailConfirmationProps {
  isVisible: boolean
  onConfirm: () => void
  files: File[]
  entityGroupId: number
}

/** Resend Email confirmation dialog */
export const ResendEmailConfirmation = ({
  isVisible,
  onConfirm,
  files,
  entityGroupId,
}: ResendEmailConfirmationProps) => {
  // Get the send email function from our API hook.
  const { resendInvestorEmails } = useNotificationClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [mailPromise, setMailPromise] = useState<Promise<any>>()
  const childFileListPinner = useRecoilPinner(childFileListAtom)
  const parentFileRecoilPinner = useRecoilPinner(parentFileListAtom)

  // send email if file status is EmailInProgress
  useEffect(() => {
    const sendEmail = async () => {
      setShowSpinner(true)
      // Filter files with status as 'EmailInProgress'
      const filteredFiles = files.filter(
        (f) =>
          f.emails &&
          f.emails.length > 0 &&
          f.status === FileStatus.EmailInProgress
      )
      const fileIds: number[] = filteredFiles.map((file) => file.id!)
      let mailPromise = resendInvestorEmails(fileIds, entityGroupId)

      let emailMessageWatcher = new FileEmailMessageWatcher(
        mailPromise,
        childFileListPinner,
        parentFileRecoilPinner,
        fileIds
      )
      // Initialize email watcher to update file status using SignalR
      emailMessageWatcher.initialize()
      setMailPromise(emailMessageWatcher.promise)
      onConfirm()
      await mailPromise
    }
    if (isVisible) {
      sendEmail()
    }
  }, [entityGroupId, isVisible])

  return (
    <div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Resend email failed'
        inProgressTitleMessage='Resend Email'
        inProgressMessage='Please wait while your email is sending...'
        successTitleMessage='Email Success'
        successMessage='Email resent to selected investor(s).'
        onClose={() => setShowSpinner(false)}
        apiAction={mailPromise}
      />
    </div>
  )
}
