/** Returns the route to navigate to the parent files for a specified group and client ID set. */
export function getParentFileListRoute(
  groupId: string | number,
  partnershipId: string | number
): string {
  return `/admin/groups/${groupId}/partnerships/${partnershipId}`
}

/** Returns the route to navigate to the child file list of a specified parent file. */
export function getChildFileListRoute(
  groupId: string | number,
  partnershipId: string | number,
  parentFileId: string | number
) {
  return `/admin/groups/${groupId}/partnerships/${partnershipId}/children/${parentFileId}`
}

/** Returns the route to show the client group page for a specified client group ID. */
export function getClientGroupPageRoute(groupId: string | number): string {
  return `/admin/groups/${groupId}`
}

/** Returns the route to the landing page. */
export function getLandingPageRoute(): string {
  return '/admin'
}

/** Returns the route to the page "verifyuser". */
export function getVerifyUserPageRoute(): string {
  return '/admin/verifyuser'
}
/** Returns the route to the page "verifyuser". */
export function getProvisionUserByEmailPageRoute(): string {
  return '/admin/provisionuserbyemail'
}

/** Returns the route to the Investor files page. */
export function getInvestorFilesRoute(): string {
  return '/investor/investorfiles'
}


/** Returns the route to show statemanager page. */
export function getStateManagerRoute(groupId: number | undefined): string {
  return `/admin/groups/${groupId}/statemanager`
}

/** Returns the route to show entitymanager page. */
export function getEntityManagerRoute(groupId: number | undefined): string {
  return `/admin/groups/${groupId}/entitymanager/entities`
}

/** Returns the route to show statemanager page for Investor. */
export function getStateManagerRouteInvestor(
  groupId: string | number,
  partnerId: string | number,
  taxYear?: string | number
): string {
  if (taxYear) {
    return `/investor/groups/${groupId}/stateelections/${partnerId}/${taxYear}`
  }
  return `/investor/groups/${groupId}/stateelections/${partnerId}`
}

/** Returns the route to show statemanager with taxyear url page. */
export function getStateManagerTaxRoute(
  groupId: string | number,
  taxYear: string
): string {
  return `/admin/groups/${groupId}/statemanager/${taxYear}`
}

/** Returns the route to the Investor files page. */
export function getInvestorPageRoute(groupId: string | number,
  partnerId: string | number): string {
  return `/investor/investorfiles/${groupId}/${partnerId}`
}

/** Returns the route to the unauthorized page. */
export function getUnAuthorizedRoute(): string {
  return '/unauthorized'
}

export function getTermsOfUseRoute(): string {
  return '/terms-of-use'
}

/** Returns the route to the investor landing page. */
export function getInvestorLandingRoute(): string {
  return '/investor/investorlandingpage'
}

/** Returns the route to show Clients page. */
export function getClientsPageRoute(): string {
  return `/admin/application/settings/clients`
}

/** Returns the route to Manage Existing Investors page. */
export function getManageExistingInvestorsRoute(): string {
  return `/admin/existinginvestors`
}
/** Returns the route to Error page. */
export function getErrorPageRoute(): string {
  return `/error`
}