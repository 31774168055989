import React, { useEffect, useRef, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { ButtonFunction, Modal } from '../modal/modalBase'
import produce from 'immer'
import {
  Form,
  Item,
  Label,
  RequiredRule,
  ButtonItem,
  GroupItem,
  PatternRule,
} from 'devextreme-react/form'
import {
  QuickEditEntryModel,
  QuickEditEmailModel,
  OptionChangedEvent,
} from '../../client-models/clientmodels'
import { DataGrid } from 'devextreme-react'
import { Column, Editing } from 'devextreme-react/data-grid'
import './quickEditEntry.scss'
import { SpinnerModal } from '../modal/spinnerModal'
import { childFileListAtom } from '../../state/atom'
import { useSetRecoilState } from 'recoil'
import { FormattedDialog } from '../modal/formattedDialog'
import { RowRemovingInfo } from 'devextreme/ui/data_grid'
import {
  multipleEmailOrDomainPattern,
  singleEmailPattern,
} from '../../utilities/email-validation'
import { ProvisionUserByEmail } from '../user/provisionUserByEmails'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'

interface QuickEditEntryProps {
  onCancel: () => void
  onSave: () => void
  detail: QuickEditEntryModel
  selectionCacheId?: string 
  onComplete: () => void
}

/** Add/Update entity email */
export const QuickEditEntry = ({
  onCancel,
  onSave,
  detail,
  selectionCacheId,
  onComplete,
}: QuickEditEntryProps) => {
  // Update entity email based on entity id using API hook.
  const { updateEntityEmail } = useFileClientApi()
  const stateManagerApi = useStateManagerApi()
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [emailList, setEmailList] = useState<QuickEditEmailModel[]>([])
  const formRef = useRef<Form>(null)
  const gridRef = useRef<DataGrid>(null)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [emailPrmoise, setEmailPromise] = useState<Promise<any>>()
  const setChildFiles = useSetRecoilState(childFileListAtom)
  const [deleteModal, setDeleteModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [emailModel, setEmailModel] = useState<QuickEditEmailModel>()
  const [emailErrorModal, setEmailErrorModal] = useState<boolean>(false)
  const [addDisabled, setAddDisabled] = useState(true)
  const [provisionClick, setProvisionClick] = useState<boolean>(false)
  const [showProvision, setShowProvision] = useState<boolean>(false)

  //Here are we are tracking the previous email with the new email whether it is same or not
  //As we do not need to enable the Save button when we are having the previous email
  //Here we are initialing to true as in only chnages where there is no changes in email
  const [isSameEmail, setisSameEmail] = useState<boolean>(true)

  useEffect(() => {
    const emailData: QuickEditEmailModel[] = []
    // Set the exiting email in a table
    if (detail.emails) {
      const emailArray = detail.emails
      for (let index = 0; index < emailArray.length; index++) {
        if (emailArray[index]) {
          emailData.push({
            id: index + 1,
            email: emailArray[index],
          })
        }
      }
      setEmailList(emailData)
    }
  }, [detail.emails])

  /** Update emails in the child file store*/
  const updateChildFileEmail = () => {
    setChildFiles(
      produce((draft) => {
        const fileIndex = draft.files.findIndex((v) => v.id === detail.fileId)
        if (fileIndex >= 0) {
          draft.files[fileIndex].emails = emailList.map((item) => item['email'])
        }
      })
    )
  }

  /** Validate form and add email to the list */
  const addEmailClick = (e?: React.FormEvent) => {
    e?.preventDefault()
    if (!formRef.current?.instance?.validate().isValid) {
      return
    }
    // Split emails using ;
    let newEmails: string[] = detail.email!.split(';')
    setSaveDisabled(false)
    newEmails.forEach((mail) => {
      const oldEmail = emailList.find((v) => v.email === mail)
      if (!oldEmail) {
        emailList.push({
          id: Math.max(...emailList.map((o) => o.id), 0) + 1,
          email: mail,
        })
      }
    })
    setEmailList(emailList)
    detail.email = undefined
    gridRef.current?.instance.refresh()
    formRef.current?.instance.repaint()
  }

  // Show confirm delete email modal
  const emailRowDelete = (e: RowRemovingInfo<QuickEditEmailModel, any>) => {
    setDeleteModal(true)
    setEmailModel(e.data)
    e.cancel = true
  }

  // Delete email from the state
  const onDelete = () => {
    setSaveDisabled(false)
    const index = emailList.findIndex((d) => d.id === emailModel?.id)
    if (index >= 0) {
      emailList.splice(index, 1)
    }
    setEmailList(emailList)
    gridRef.current?.instance.refresh()
    setDeleteModal(false)
  }

  // Update entity email based on the entity Id
  const updateEmail = async (
    partnerEntityId: number,
    selectionCacheId: string,
    emailString: string
  ) => {
    await updateEntityEmail(partnerEntityId, emailString)
    //if its state manager QEE call then reset the updated mailids to mongo DB cached data
    if (selectionCacheId) {
      await stateManagerApi.resetEmailIdsForIndividualInvestor(
        partnerEntityId,
        selectionCacheId!,
        emailString
      )
      //once email reset complete calling the onComplete() to refresh the state-manager investor view table
      onComplete()
    }
  }

  /** Validate form and Call api method to update email in entity */
  const handleSave = async () => {
    let emailString: string = ''
    emailList.slice().forEach((e) => {
      if (emailString) {
        emailString = emailString + ';'
      }
      emailString = emailString + e.email
    })
    if (emailString.length > 500) {
      setEmailErrorModal(true)
    } else {
      setShowSpinner(true)
      setEmailPromise(
        updateEmail(detail.partnerEntityId, selectionCacheId!, emailString)
      )
    }
  }

  /** Perform user provisioning  */
  const handleProvision = async () => {
    // Save disabled then only do user provisioning without update email address
    if (saveDisabled) {
      setShowProvision(true)
    } else {
      // Update emails and perform provisioning
      setProvisionClick(true)
      await handleSave()
    }
  }

  // Validate email using regex
  const validateEmail = (emailText: string) => {
    return new RegExp(multipleEmailOrDomainPattern).test(emailText)
  }
  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Provision',
      onClick: () => handleProvision(),
      visible: !selectionCacheId,
      alignment: 'left',
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 125,
        'data-testid': 'provision-button',
        disabled: emailList.length > 0 ? false : true,
      },
    },
    {
      label: saveDisabled ? 'Close' : 'Cancel',
      onClick: () => {
        if (
          saveDisabled &&
          (provisionClick ||
            emailList.length === detail.emails?.length ||
            emailList.length === 0)
        ) {
          onCancel()
        } else {
          setCancelModal(true)
        }
      },
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Save',
      isDisabled: saveDisabled,
      onClick: () => {
        setProvisionClick(false)
        handleSave()
      },
      visible: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'save-button',
        disabled: saveDisabled,
      },
    },
  ]

  // Delete button functions
  const deletebuttons: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        setDeleteModal(false)
      },
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm Delete',
      onClick: () => onDelete(),
      visible: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 160,
        'data-testid': 'confirm-delete-button',
      },
    },
  ]

  // Cancel button functions
  const cancelbuttons: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        setCancelModal(false)
      },
      visible: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'confirm-delete-button',
      },
    },
    {
      label: 'OK',
      onClick: () => {
        onCancel()
      },
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'ok-button',
      },
    },
  ]
  // Cancel button functions
  const closeButtons: ButtonFunction[] = [
    {
      label: 'Close',
      onClick: () => {
        setEmailErrorModal(false)
      },
      visible: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'close-email-button',
      },
    },
  ]

  return (
    <Modal
      visible={true}
      title='Quick Edit'
      buttonFunctions={buttonFunctions}
      maxWidth={500}
      height='auto'
      showCloseButtonInTitle={true}
      className='quick-edit-container'
    >
      <div data-testid='quick-edit-entry'>
        <div className='form-container form-scroll-horizontal'>
          <Form
            formData={detail}
            readOnly={false}
            showColonAfterLabel={false}
            showValidationSummary={false}
          >
            <Item
              dataField='entityName'
              editorOptions={{
                readOnly: true,
              }}
              data-testid='entity-name'
              editorType='dxTextBox'
            />
            <Item
              dataField='einNumber'
              data-testid='ein-number'
              visible={!selectionCacheId}
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text='EIN/SSN' />
            </Item>
          </Form>
        </div>
        <div>
          <div>Email Address</div>
          <Form
            formData={detail}
            readOnly={false}
            showColonAfterLabel={false}
            showValidationSummary={false}
            ref={formRef}
            colCount={7}
          >
            <GroupItem colSpan={6}>
              <Item
                dataField='email'
                data-testid='email'
                editorType='dxTextBox'
                editorOptions={{
                  onOptionChanged: (e: OptionChangedEvent) => {
                    // Validate the textbox entered value & enable Add button
                    if (e.name === 'text') {
                      if (e.value.length > 0 && validateEmail(e.value)) {
                        setAddDisabled(false)
                      } else {
                        setAddDisabled(true)
                      }
                    }
                  },
                  onEnterKey: () => {
                    addEmailClick()
                  },
                }}
              >
                <Label text='To add multiple emails, please seperate using &#39;;&#39;' />
                <RequiredRule message='Email is required' />
                <PatternRule
                  ignoreEmptyValue={false}
                  message='Email is invalid'
                  pattern={multipleEmailOrDomainPattern}
                />
              </Item>
            </GroupItem>
            <GroupItem colSpan={1} cssClass='add-button-align'>
              <ButtonItem
                horizontalAlignment='left'
                cssClass='dx-button-item'
                buttonOptions={{
                  text: 'Add',
                  type: 'default',
                  disabled: addDisabled,
                  'data-testid': 'submit-button',
                  onClick: () => {
                    addEmailClick()
                  },
                }}
              />
            </GroupItem>
          </Form>
        </div>
        <div className='email-table'>
          <DataGrid
            id='gridQEE'
            ref={gridRef}
            dataSource={emailList}
            keyExpr='id'
            repaintChangesOnly={true}
            showColumnLines={false}
            showRowLines={true}
            wordWrapEnabled={false}
            showBorders={true}
            showColumnHeaders={true}
            onRowRemoving={emailRowDelete}
            onRowUpdating={() => setSaveDisabled(false)}
            onRowValidating={(e) => {
              //here track whether the changed email is same as previous email
              if (e.newData.email !== e.oldData.email) {
                setisSameEmail(false) 
              }
              //disabling and enabling the save accordingly by checking the email is valid
              setSaveDisabled(!e.isValid)
            }}
            onEditCanceled={
              //when we click on edit cancel we do not need to enable the save button when we are having no changes
              () => {
                setSaveDisabled(isSameEmail)
              }
            }
            height={220}
          >
            <Editing
              mode='cell'
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={false}
              confirmDelete={false}
            />
            <Column
              caption='Id'
              dataField='id'
              visible={false}
              defaultSortOrder='desc'
            ></Column>
            <Column caption='Email' dataField='email'>
              <RequiredRule message='Email is required' />
              <PatternRule
                message='Email is invalid'
                pattern={singleEmailPattern}
              />
            </Column>
          </DataGrid>
        </div>
      </div>
      <FormattedDialog
        visible={deleteModal}
        dialogType='general'
        buttonFunctions={deletebuttons}
        title='Delete Email'
      >
        {emailModel?.email}
        <div className='content-display-align'>
          This action will permanently delete the selected email and cannot be
          reverted. Do you wish to proceed?
        </div>
      </FormattedDialog>
      <FormattedDialog
        visible={cancelModal}
        dialogType='general'
        buttonFunctions={cancelbuttons}
        title='Unsaved Changes'
      >
        <div className='content-display-align'>
          There are unsaved changes to the email address(es). If you close now,
          these changes will not be saved. Do you wish to proceed?
        </div>
      </FormattedDialog>
      <FormattedDialog
        visible={emailErrorModal}
        dialogType='error'
        buttonFunctions={closeButtons}
        title='Error'
      >
        <div className='content-display-align'>
          Total email characters exceed maximum allowable amount. Revise/remove
          email addresses as needed.
        </div>
      </FormattedDialog>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Updating Entity failed'
        inProgressTitleMessage='Updating Entity'
        inProgressMessage='Entity is updating'
        successTitleMessage=''
        successMessage=''
        onClose={(successFlag?: boolean) => {
          setShowSpinner(false)
          if (successFlag) {
            // if QEE model called from state-manager investor page then no need to update the recoil state for child page
            if (!selectionCacheId) {
              updateChildFileEmail()
            }
            // If provision button clicked then display provisioning user modal
            if (provisionClick) {
              setSaveDisabled(true)
              setShowProvision(true)
            } else {
              onSave()
            }
          }
        }}
        apiAction={emailPrmoise}
      />
      <ProvisionUserByEmail
        isVisible={showProvision}
        emails={emailList.map((f) => f.email)}
        onClose={(successFlag?: boolean) => {
          setShowProvision(false)
          // Provisioning user succeded then close the QEE modal
          if (successFlag) {
            onSave()
          } else {
            setSaveDisabled(true)
          }
        }}
      ></ProvisionUserByEmail>
    </Modal>
  )
}
