import { useFileClientApi } from './use-file-api'
import { useEffect } from 'react'
import { DISMOUNT_ABORT_REASON } from '../components/utility/abort-constants'
import { usePermissions } from '../access-manager/use-permission'
import { permissionRequestAny } from '../access-manager/permission-request-creation'
import * as Permissions from '../model/permissions'

/** Hook to load investor files, when the user is an investor. */
export default function useInvestorFiles() {
  const clientApi = useFileClientApi()

  const isInvestor = usePermissions([permissionRequestAny(Permissions.INVESTOR)])

  useEffect(() => {
    const abortController = new AbortController()

    // We only want to do this with investors.
    if (isInvestor) {
      clientApi.getInvestorFiles(abortController.signal)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isInvestor])
}
