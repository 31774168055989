import React, { useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modalBase'
import './previewEmailTemplate.scss'
import { Form, Item } from 'devextreme-react/form'
import { EmailTemplate } from '../../../api-client/investor-portal-client'
import { HtmlEditor } from 'devextreme-react'

export interface PreviewEmailTemplateDialogProps {
  onCancel: () => void
  title: string
  emailTemplate: EmailTemplate
  additionalMarkUp: string
}

const baseButtonProps = {
  useSubmitBehavior: true,
  stylingMode: 'contained',
}
export const PreviewEmailTemplateDialog = ({
  onCancel,
  title,
  emailTemplate,
  additionalMarkUp,
}: PreviewEmailTemplateDialogProps) => {
  const formRef = useRef<Form>(null)
  const [formData] = useState<EmailTemplate>(emailTemplate)
  const bodywithmarkup = emailTemplate?.body! + additionalMarkUp

  const buttonFunctions = useMemo(() => {
    const buttons = [
      {
        label: 'Back to Edit',
        display: true,
        isDefaultAction: true,
        buttonProps: {
          className: 'back-to-edit-btn',
          ...baseButtonProps,
          'data-testid': 'edit-btn',
        },
        onClick: () => onCancel(),
      },
    ] as (ButtonFunction & { display?: boolean })[]

    // Return the buttons we need, based on their display property.
    return buttons.filter((x) => !!x.display)
  }, [])
  return (
    <Modal
      visible={true}
      title={'Preview > ' + title + ' Template'}
      buttonFunctions={buttonFunctions}
      maxWidth={750}
      maxHeight={600}
      showCloseButtonInTitle={true}
      className='preview-email-modal'
      testId='preview-email-template-dialog'
    >
      <div
        className='form-container form-scroll-horizontal'
        data-testid='form-screen'
      >
        <Form formData={formData} ref={formRef}>
          <Item label={{ text: 'Subject' }}>
            <HtmlEditor
              defaultValue={emailTemplate?.subject}
              readOnly={true}
            ></HtmlEditor>
          </Item>
          <Item label={{ text: 'Body' }}>
            <HtmlEditor
            className='preview-htmleditor'
              defaultValue={bodywithmarkup}
              readOnly={true}
            ></HtmlEditor>
          </Item>
        </Form>
      </div>
    </Modal>
  )
}
