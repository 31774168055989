import { OidcIdentityContext } from '@rsmus/react-auth'
import { useState, useContext, useCallback, useEffect } from 'react'
import {SessionTimeoutModal} from '../modal/sessionTimeoutModal'
import { userIsExternal } from '../utility/parse-token'
import { REACT_APP_EXTERNAL_USER_TIMEOUT } from '../../envVariables'

//List of events considered not the page idle
const domEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]

let timerId: any = 0

export const SessionTimeoutExternalUser = () => {
  // Idle timeout popup display is applicable only for external user

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  // Get the User and check for external user
  const auth = useContext(OidcIdentityContext)
  const isExternal = auth.user ? userIsExternal(auth.user) : true

  // Read Idle time period from environment variable/App configuration
  let maxIdleTime = 29 * 60 * 1000
  const configValue: number = parseInt(REACT_APP_EXTERNAL_USER_TIMEOUT || '') || 0
  if (configValue > 0) {
    // To get the Popup one minute before the max idle time
    maxIdleTime = (configValue - 1) * 60 * 1000
  }

  const resetTime = useCallback(() => {
    clearTimeout(timerId)
    if (isExternal) {
      timerId = setTimeout(() => {
        setModalOpen(true) // If idle time exceeds then show the popup
        resetTime()
      }, maxIdleTime)
    }
  }, [isExternal, maxIdleTime])

  // If there's any specified event happens then reset the timer
  useEffect(() => {
    domEvents.forEach((event) => document.addEventListener(event, resetTime))
    return () => {
      domEvents.forEach((event) =>
        document.removeEventListener(event, resetTime)
      )
    }
  }, [resetTime])

  // Call resetTime initially
  useEffect(() => {
    resetTime()
  }, [resetTime])

  return (
    <>
      {isExternal && (
        <div data-testid='session-timeout'>
          <SessionTimeoutModal
            closeDialog={() => setModalOpen(false)}
            isOpen={isModalOpen}
          />
        </div>
      )}
    </>
  )
}
