import moment from 'moment'
import { useRecoilValue } from 'recoil'
import { LoadingStateEnum, smGeneralElectionInfoAtom } from '../../state/atom'
import { investorRelationsAtom } from '../../state/atom'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { TaxYearControlStateManager } from './state-manager-tax-year-control/state-manager-tax-year'
import './investor-elections-page-banner.scss'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { TaxYear } from '../../api-client/investor-portal-client'
import { ensureNumberOrUndefined } from '../../utilities/utilities'
import { renderFormattedDate } from '../investor-view/investor-utils'
import { EntityType } from '../../model/entity-types'
import { SelectBoxOptions } from '../../../types/types'

/* Investor View StateManager Elections Page Banner */
export const InvestorElectionPageBanner = () => {
  const { cutoffDate, loadingState } = useRecoilValue(smGeneralElectionInfoAtom)

  /** Set cutoff date from recoil state */
  const getCutOffDate = () => {
    if (loadingState === LoadingStateEnum.Loading) {
      return <CircularProgress color='info' size='20px' />
    }
    let dateString: string

    //Convert date to string and set it
    if (cutoffDate) {
      dateString = renderFormattedDate(cutoffDate)
    } else {
      dateString = 'Not Set'
    }

    return dateString
  }

  const investorRelations = useRecoilValue(investorRelationsAtom)

  const { groupId, partnerId } = useParams() as {
    groupId: string
    partnerId: string
  }

  const groupIdNumber = ensureNumberOrUndefined(groupId)
  const partnerIdNumber = ensureNumberOrUndefined(partnerId)

  /** convert response data to the data type we need */
  const investorRelationsTaxYears: TaxYear[] | undefined =
    investorRelations?.investorEntities?.stateManagerTaxYears?.find(
      (x) =>
        x.investorId === partnerIdNumber && x.clientGroupId === groupIdNumber
    )?.taxYears

  /** taxYear Dropdown Option */
  const taxYearInvestorViewOptions: SelectBoxOptions[] = (
    investorRelationsTaxYears ?? []
  )
    .map((y) => {
      return {
        label: y.taxyear!,
        value: y.taxyear,
        icon: y.isLocked ? 'lock' : 'myIcon',
      }
    })
    .sort((a, b) => (a.label > b.label ? -1 : 1))

  /** Update Banner with Client Group Name and Investor Name  */
  const getDisplayName = () => {
    if (!investorRelations.hasLoaded) {
      return <CircularProgress color='info' size='20px' />
    }

    const investorEntity =
      investorRelations?.investorEntities?.clientGroupEntities?.find(
        (x) => x.id === partnerIdNumber
      )

    if (!investorEntity) {
      return ''
    }

    const clientGroupIds = investorEntity.clientGroupIds || []
    const investorEntitiesClientGroups =
      investorRelations?.investorEntities?.clientGroups || []

    const clientGroupName = clientGroupIds
      .map((groupId) => {
        const group = investorEntitiesClientGroups.find(
          //groupIdNumber is params groupId
          (group) => group.id === groupIdNumber && group.id === groupId
        )
        return group?.name
      })
      .filter(Boolean) // Filter out undefined values

    const entityDisplayName =
      investorEntity.entityType === EntityType.Business
        ? `${clientGroupName} - ${investorEntity.entityName}`
        : `${clientGroupName} - ${investorEntity.firstName} ${investorEntity.lastName}`

    return entityDisplayName.trim()
  }

  return (
    <BannerContainer className='investor-election-page-banner'>
      <div className='welcome-text-and-tax-year-dropdown-alignment'>
        {/* Display welcome text and todays date*/}
        <div>
          <BannerHeader>{getDisplayName()}</BannerHeader>
          <label>Today: {moment().format('MMM DD, YYYY')}</label>
        </div>

        {/* TaxYear Dropdown*/}
        <div className='tax-year-dropdown'>
          <TaxYearControlStateManager
            selectYearWhenUnset={true}
            viewOptions={taxYearInvestorViewOptions}
          ></TaxYearControlStateManager>
        </div>
      </div>

      {/* Cutoff Date*/}
      <div className='cutoff-date'>
        {/* Cutoff date is just a place holder */}
        <BannerHeader>{getCutOffDate()}</BannerHeader>
        <label>Cutoff date</label>
      </div>
    </BannerContainer>
  )
}
