import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { AppTitle } from './Iconography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useDynamicNavWidgetItems } from '../../components/nav-widget/nav-widget-implementations/dynamic-nav-widget-items'
import { useClientAndPartnershipFromUrl } from '../../hooks/useClientAndPartnershipFromUrl'
import { useFileSearchReset } from '../../hooks/use-file-search'
import { useAdminAuthorization } from '../../hooks/use-admin-authorization'
import { leftNavExpandedAtom } from '../../state/atom'
import { useRecoilValue } from 'recoil'
import { useInvestorRelation } from '../../hooks/use-investor-relation'
import useInvestorFiles from '../../hooks/use-investor-files'
import { LeftNav } from './leftnav/LeftNav'
import { useSMGeneralOption } from '../../hooks/use-sm-general-option'
import { useValidGroupRedirect } from '../../redirects/use-valid-group'
import useErrorHandler from '../../hooks/use-error-handler'
import { useSignalR } from '../../signalr/useSignalR'
import '../error-boundary/error-page.scss'

interface Props {
  children?: React.ReactNode
  appTitle: AppTitle
  maxWidth?: string
  appSwitcher?: boolean
  appTheme?: 'blue' | 'green' | 'orange'
}

const Layout = ({
  children,
  appTitle,
  maxWidth,
  appSwitcher,
  appTheme,
}: Props) => {
  //Import Theme

  let themeColor

  switch (appTheme) {
    case 'green':
      themeColor = '#3F9C35'
      break
    case 'blue':
      themeColor = '#009CDE'
      break
    case 'orange':
      themeColor = '#E87722'
      break
    default:
      themeColor = '#009CDE'
      break
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
      },
    },
    typography: {
      fontFamily: [
        'Arial',
        'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })

  //is leftNav expanded?
  const isNavExpanded = useRecoilValue(leftNavExpandedAtom)
  const [isScrolled, setScrolled] = useState(false)
  const location = useLocation()
  const isError: boolean = location.pathname.startsWith('/error')

  useClientAndPartnershipFromUrl()
  useDynamicNavWidgetItems()
  useFileSearchReset()
  useAdminAuthorization()
  useInvestorRelation()
  useInvestorFiles()
  useSMGeneralOption()
  useValidGroupRedirect()
  useErrorHandler()
  useSignalR(true)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  if (isError) {
    return (
      <div className='error-page'>
        <div className='error-message'>
          An error occurred in Investor Portal. Please email the RSM Service
          Desk RSMServiceDesk@rsmus.com to notify them that you are unable to
          access Investor Portal.
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        id='cui-layout'
        cui-layout-dx={maxWidth ? maxWidth : undefined}
        className={`${isNavExpanded ? 'layout-expanded' : 'layout-collapsed'} ${
          isScrolled && 'layout-scrolled'
        }`}
      >
        {<LeftNav appTitle={appTitle} />}
        <div id='cui-layout_main'>
          <Header appTitle={appTitle} appSwitcher={appSwitcher} />
          <main className='cui-page'>
            <div className='cui-page_wrapper'>
              <div id='main_content'>{children}</div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}
export default Layout
