import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  EmailStatusTypes,
  InvestorSearchResultEmailStatus,
} from '../../api-client/investor-portal-client'
import {
  getEmailStatusText,
  getFormattedDateWithNeverForEmailSent,
} from './state-manager-utils'
import { DateFormat } from '../../model/date-formats'

export interface EmailStatusTemplateProps {
  status?: InvestorSearchResultEmailStatus
}

export const EmailStatusTemplate = ({ status }: EmailStatusTemplateProps) => {
  const [formattedDate, setFormattedDate] = useState<string | null>(null)
  const [emailStatusText, setEmailStatusText] = useState<string | null>(null)

  useEffect(() => {
    // Check if there's a status date
    if (status?.statusDate) {
      // Set the formatted date
      setFormattedDate(getFormattedDateWithNeverForEmailSent(status.statusDate, DateFormat.shortDateFormat))

      // Set the email status text
      setEmailStatusText(getEmailStatusText(status?.status!))
    }
  }, [status?.statusDate, status?.status])

  // If there's no value, then there's no status.
  if (!status?.statusDate) {
    return <div title='No email attempted'>never</div>
  }

  // Return value.
  let result: React.ReactElement | undefined = undefined
  // Get the display date in the proper format.  The date is not
  //  serialized to an actual date, and is really a string, so we have
  //  to handle that.  This is an app-wide issue.
  const dateDisplay = getFormattedDateWithNeverForEmailSent(status.statusDate, DateFormat.longDateTimeFormat)
  if (status.status === EmailStatusTypes.Complete) {
    result = <div title={dateDisplay}>{formattedDate}</div>
  } else {
    // Form the result.
    result = <div title={dateDisplay}>{emailStatusText}</div>
  }
  return result
}
