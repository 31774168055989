import { useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { SpinnerModal } from '../modal/spinnerModal'
import { downloadReport } from '../../signalr/watcher-utilities/download-report'

// creating interface
export interface activityReportProps {
  onConfirm: () => void
  onCancel: () => void
  entityGroupId: number
}

//Investor Activity Report Component
export const ActivtyReport = ({
  onConfirm,
  onCancel,
  entityGroupId,
}: activityReportProps) => {
  const { getInvestorActivityReport } = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [reportCompletePromise, setReportCompletePromise] =
    useState<Promise<any>>()

  // call Activity Report API
  useEffect(() => {
    setShowSpinner(true)
    //generating random number for operationId
    const uniqueOperationId = Math.floor(Math.random() * 100000)
    // API call to get the sas-URI
    const investorActivityReportPrmoise = getInvestorActivityReport(
      entityGroupId,
      uniqueOperationId
    )
    setReportCompletePromise(investorActivityReportPrmoise)
    // call the download report helper method which will take cate of download report as well as any operation error
    const downloadReportPromise = downloadReport(
      investorActivityReportPrmoise,
      uniqueOperationId
    )
    setReportCompletePromise(downloadReportPromise)
    // API action completed - hide the modelSpinner for success else show the error message in model
    downloadReportPromise.then((response) => {
      //hideing the spinner model
      setShowSpinner(false)
      onConfirm()
    })
    // The extra dependencies are not the same on every call, so they're omitted here.
    //  Specifically onConfirm and getInvestorActivityReport.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityGroupId])

  return (
    <div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='An error has occurred. Please try again.'
        inProgressTitleMessage='Preparing File'
        inProgressMessage='Your file is being downloaded. Please wait.'
        successTitleMessage='File downloaded successfully'
        successMessage='Your file has been downloaded.'
        onClose={() => onCancel()}
        apiAction={reportCompletePromise}
      />
    </div>
  )
}
