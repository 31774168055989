import { usStatesData } from '../../../state-manager/state-manager-utils'
import {
  InvestorWithholdingEntity,
  SMInvestorElectionsInfo,
  SMInvestorStateElection,
} from '../../../../api-client/investor-portal-client'
import { useStateManagerApi } from '../../../../hooks/use-state-manager-api'
import { SpinnerModal } from '../../../modal/spinnerModal'
import { useEffect, useRef, useState } from 'react'
import { PrintSubmissionOverview } from './pdf-submission-overview'
import { saveBlob } from '../../../utility/saveBlobFn'
import { Button } from 'devextreme-react'
import { pdf } from '@react-pdf/renderer'
import { useFileClientApi } from '../../../../hooks/use-file-api'
import { ElectionDetail } from './pdf-utils'

interface PdfInvestorElectionProps {
  isVisible: boolean
  investorElectionsInfoId: number
  investorId: number
  generalOptionsId: number
  taxYear: string
  investorGroupName: string
  investorName: string
  electionsInfo: SMInvestorElectionsInfo
  onClose: () => void
}

/** Print Overview - load data from server, generate PDF & save */
export const PdfInvestorElection = ({
  isVisible,
  investorElectionsInfoId,
  investorId,
  generalOptionsId,
  taxYear,
  investorGroupName,
  investorName,
  electionsInfo,
  onClose,
}: PdfInvestorElectionProps) => {
  const stateManagerApi = useStateManagerApi()
  const apiClient = useFileClientApi()
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()

  const buttonRef = useRef<Button>(null)
  const compositeRef = useRef<ElectionDetail>()
  const withHoldingsRef = useRef<ElectionDetail[]>()

  /** Make API call and get election data based on the investor election info id */
  useEffect(() => {
    const abortController = new AbortController()

    /**  GetInvestorElectionEntities - Composites & Withholdings */
    const getInvestorElectionEntities = async () => {
      // Get the results from the server.
      const entityListResult = await apiClient.getInvestorElectionEntities(
        investorId!,
        generalOptionsId!,
        abortController.signal
      )

      // Create an intermediate list to set the state with.
      let newCompositeList: string[] = []

      // Fill the entity lists with the results.
      entityListResult.allocatingEntities!.forEach((entity) => {
        // Get the entity references for this allocating entity.
        var references = entityListResult.investorSelections!.filter(
          (x) =>
            x.allocatingEntityReference!.entityOptions!.allocatingEntityId ===
            entity.id
        )

        // Determine which lists the allocating entity belongs in, and add it to them.
        if (
          references.some((r) => r.allocatingEntityReference!.enableComposites)
        ) {
          newCompositeList.push(entity.name!)
        }
      })

      // Sort the list
      newCompositeList = newCompositeList
        .map((x) => ({ sortName: x.toLocaleLowerCase(), name: x }))
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
        .map((x) => x.name)

      if (newCompositeList.length === 0) {
        newCompositeList.push('None')
      }
      
      return newCompositeList
    }

    /** Load Composite, withholdings election data */
    const loadElectionData = async () => {
      // Get composite data based on the investor electioninfo id
      const investorStateElections: SMInvestorStateElection[] =
        await stateManagerApi.getInvestorElections(
          investorElectionsInfoId!,
          undefined,
          abortController.signal
        )

      compositeRef.current = {
        entityName: 'Composite Elections',
        states: getStates(investorStateElections),
        compositeList: await getInvestorElectionEntities(),
      }

      // Get withholdings data based on the investor electioninfo id & entity option id
      const investorWithHoldings: InvestorWithholdingEntity[] =
        await stateManagerApi.getInvestorWithholdingEntities(
          investorElectionsInfoId!,
          abortController.signal
        )

      let withHoldings: ElectionDetail[] = []
      for (const index in investorWithHoldings) {
        const response = await stateManagerApi.getInvestorElections(
          investorElectionsInfoId!,
          investorWithHoldings[index].entityOptionId,
          abortController.signal
        )

        withHoldings.push({
          entityName: investorWithHoldings[index].name!,
          states: getStates(response),
        })
      }

      // Sort the list in alphabetical order
      withHoldings = withHoldings
        .map((x) => ({
          sortName: x.entityName.toLocaleLowerCase(),
          entityName: x,
        }))
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
        .map((x) => x.entityName)
      withHoldingsRef.current = withHoldings

      // Programatically trigger to generate PDF and download
      buttonRef.current?.instance.element().click()

      //Close Spinner modal
      onClose()
    }

    if (isVisible) {
      // Set API call promise to display spinner modal
      const apiPromise = loadElectionData()
      setSpinnerPromise(apiPromise)
    }
  }, [generalOptionsId, investorElectionsInfoId, investorId, isVisible])

  /** Get state names as comma seperated list from  SMInvestorStateElection list*/
  const getStates = (investorStateElections: SMInvestorStateElection[]) => {
    let stateNames: string = ''
    if (investorStateElections.length === 0) {
      stateNames = 'None Available'
    } else if (investorStateElections.length === 1) {
      const stateCode = investorStateElections
        .map((x) => x.stateCode)
        .toString()
      // check the state codes if we get only one result
      if (stateCode === 'NONE') {
        stateNames = 'None Selected'
      } else {
        stateNames = usStatesData.find((x) => x.stateCode === stateCode)?.name!
      }
    } else {
      // get the names for all the state codes
      const investerStateNames = investorStateElections
        .map((x) => x.stateCode)
        .map((item) => {
          return usStatesData.find((x) => x.stateCode === item)?.name
        })
      //set comma seperated values in local state
      stateNames = investerStateNames.join(', ')
    }
    return stateNames
  }

  return (
    <div>
      <Button
        ref={buttonRef}
        id='btn-submission-download-Pdf'
        style={{ display: 'none' }}
        onClick={async () => {
          const displayName = `${investorGroupName} - ${investorName}`
          const doc = (
            <PrintSubmissionOverview
              taxYear={taxYear}
              title={displayName}
              electionsInfo={electionsInfo}
              compositeData={compositeRef.current!}
              withHoldingData={withHoldingsRef.current!}
            />
          )
          const asPdf = pdf()
          asPdf.updateContainer(doc)
          const blob = await asPdf.toBlob()
          // set filename
          const fileName = `${investorGroupName}_${investorName}_${taxYear}.pdf`
          // Save blob to user machine
          saveBlob(blob, fileName)
        }}
        text='Print'
        type='normal'
        data-testid='submission-overview-print'
      ></Button>
      <SpinnerModal
        visible={isVisible}
        errorTitleMessage='Error Printing'
        errorMessage='An error has occurred'
        inProgressTitleMessage='Printing Elections'
        inProgressMessage='Elections are being printed'
        successTitleMessage=''
        successMessage=''
        closeOnSuccess={true}
        hideCloseButton={false}
        onClose={() => onClose()}
        apiAction={spinnerPromise}
      />
    </div>
  )
}
