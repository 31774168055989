import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
  LoadingStateEnum,
  entityGroupPartnershipStateAtom,
} from '../state/atom'
import { getLandingPageRoute } from '../components/utility/route-creation'
import { useNavigate } from '../hooks/useNavigationGuard'
import { useParams } from 'react-router-dom'

/** This hook helps to redirect to Landing Page when user does not have access to desired client group  */
export const useValidGroupRedirect = () => {
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupsAndPartnerships = useRecoilValue(entityGroupPartnershipStateAtom)
  const navigate = useNavigate()
  const groupIdNumber = parseInt(groupId)
  const entityGroup = groupsAndPartnerships[groupIdNumber]

  useEffect(() => {
    if (!isNaN(groupIdNumber) && entityGroup && entityGroup?.loadingState === LoadingStateEnum.Error) {
      // If entitygroup is not available navigate away
      navigate(getLandingPageRoute())
    }
  }, [groupIdNumber, entityGroup, navigate])
}
