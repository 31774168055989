import { IconsType, NavItemProps } from './LeftNav'
import { materialIconsType } from './navTree'
import { NavLink } from './nav-link'

//Build Nav Tree
export const NavList = (
  items: NavItemProps[],
  title: string,
  iconsType: IconsType
) => {
  //content of each item to be rendered in further parent LI
  const renderNavItem = (item: NavItemProps) => (
    <>
      {item.icon && (
        <span className={`icon ${materialIconsType(iconsType)}`}>
          {item.icon}
        </span>
      )}
      <span className='nav-name'>{item.title}</span>
    </>
  )

  //make new array with menu items which are not having property "visible === false"
  // const itemsVisible = items.filter((each) => each.visible === undefined || each.visible !== false)
  const itemsVisible = items.filter((each) => each.visible !== false)

  //content of each LI to be rendered in further parent UL
  const renderNavLi = itemsVisible.map((i: NavItemProps, index: number) => (
    <li key={index}>
      {i.path && <NavLink to={i.path} children={renderNavItem(i)}></NavLink>}
      {!i.path && renderNavItem(i)}
      {i.menu && NavList(i.menu, i.title, iconsType)}
    </li>
  ))

  return (
    <ul className={`nav-list nav-list_lvl-two`} title={title}>
      {renderNavLi}
    </ul>
  )
}
