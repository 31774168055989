import React, { useEffect, useState } from 'react'
import './clientGroupDetails.scss'
import {
  clientGroupDetailsState,
  getDefaultClientGroupDetailsState,
} from './client-group-details-state'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ClientGroupStatusTable } from './client-group-status'
import { InvestorStatusTable } from './investor-status'
import {
  getResetTaxYearState,
  taxYearState,
} from '../../common-components/banners/tax-year-control/tax-year-control-state'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { entityGroupPartnershipStateAtom } from '../../state/atom'
import { useParams } from 'react-router-dom'
import produce from 'immer'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export const ClientGroupDetails = () => {
  const [pageState, setPageState] = useRecoilState(clientGroupDetailsState)
  const setTaxYear = useSetRecoilState(taxYearState)
  //const clientApi = useFileClientApi()
  const { getClient } = useEntityClientApi()

  const [entityGroupPartnerships, setEntityGroupPartnerships] = useRecoilState(
    entityGroupPartnershipStateAtom
  )
  // Get the groupId  from the URL.
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupIdNumber = parseInt(groupId)

  useEffect(() => {
    const abortController = new AbortController()

    // Since the state for this page is handled through global state, we can't
    //  just rely on the state resetting when the page is left.  We must clear the state
    //  when this page is recreated, so old selections aren't retained.
    setPageState(getDefaultClientGroupDetailsState())
    setTaxYear(getResetTaxYearState())

    //Updating State Elections in Recoil state to Enable and Disable State Manager
    const response = getClient(groupIdNumber, abortController.signal)
    response.then((data) => {
      const clientState = entityGroupPartnerships[groupIdNumber]
      if (clientState) {
        setEntityGroupPartnerships(
          produce((draft) => {
            draft[groupIdNumber].featureFlag.stateElections =
              data.featureFlagsView?.stateElections
          })
        )
      }
    })

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [
    groupId,
    setPageState,
    setTaxYear,
    groupIdNumber,
    entityGroupPartnerships,
    setEntityGroupPartnerships,
  ])

  return (
    <div className='client-group-details'>
      <div>
        {pageState.currentView === 'entity' ? (
          <ClientGroupStatusTable />
        ) : (
          <InvestorStatusTable />
        )}
      </div>
    </div>
  )
}
