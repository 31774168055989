import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { SignalRWatcher } from './signalr-watcher'

/** Watches for Provision Users, and resolves/rejects when all expected users have been provisioned.*/
export class ProvisionUserByEmailsWatcher extends SignalRWatcher<ProvisionUserHeader[]> {
  constructor(apiPromises: Promise<ProvisionUserHeader>[]) {
    if (!apiPromises || apiPromises.length < 1) {
      throw new Error(`apiPromises cannot be undefined, and must contain at least one promise.`)
    }

    // Construct a single promise for the API promises.
    const apiPromise = Promise.all(apiPromises)

    // Call the base ctor.
    super('SendUpdatedProvisionUserHeader', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)

    // Flag that's set when all API promises are complete, and
    //  and will be set to false if any of them have records.
    let hasNoRecords = true

    // Update the hasNoRecords flag if any promise resolves to a header with at least one  record.
    //  We also want to collect the records as they come in.
    apiPromises.forEach(p => {
      p.then(header => {
        if (header.provisionUserRecords!.length > 0) {
          hasNoRecords = false
        }

        this.provisionUsersHeaders.push(header)
      })
    })

    // Wait for all promises to complete. If none of them have records, then
    //  we need just need to complete the operation.
    apiPromise.then(() => {
      if (hasNoRecords) {
        this.operationComplete()
      }
    })
  }

  /** Set of ProvisionUserHeader objects received from the API promises,
   *   and used to monitor for completion. */
  private provisionUsersHeaders: ProvisionUserHeader[] = []

  /** SignalR handler to process each provision received in the messages. */
  handlerAction(header: ProvisionUserHeader, uniqueOperationId: string) {
    const sourceHeader = this.provisionUsersHeaders.find(h => h.id === header.id)

    // If we don't find a source header, then this message wasn't for this watcher.
    if (!sourceHeader) {
      return
    }

    // Replace all provision user records in the existing header, with
    //  the records in the header from the message.  This basically updates
    //  the statuses of the records we already have.
    header.provisionUserRecords!.forEach((r) => {
      const existingRecordIndex =
        sourceHeader.provisionUserRecords!.findIndex(
          (r2) => r2.id === r.id
        )
      if (existingRecordIndex >= 0) {
        sourceHeader.provisionUserRecords![existingRecordIndex] = r
      }
    })

    // If the status is not NEW, then this header is complete.  But we have
    //  to ensure all headers are completed before we complete the operation.
    if (header.status !== 'NEW') {
      // Update the stats of the local header, since this will be
      //  returned in the operationComplete call.
      sourceHeader.failedCount = header.failedCount
      sourceHeader.successCount = header.successCount
      sourceHeader.status = header.status

      // Check if ALL provisionUserHeaders are done, so
      //  we can complete the operation.  If not, then we have to wait.
      if (this.provisionUsersHeaders.every(h => h.status !== 'NEW')) {
        // If any header failed, then we have to fail the operation.
        //  Otherwise, we need to complete it.
        if (this.provisionUsersHeaders.every(h => h.failedCount === 0)) {
          this.operationComplete(this.provisionUsersHeaders)
        } else {
          this.operationFailed(this.provisionUsersHeaders)
        }
      }
    }
  }
}