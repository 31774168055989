import { ReactNode, useEffect, useState } from 'react'
import { SpinnerModal } from '../modal/spinnerModal'
import './provisionUsers.scss'
import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { provisionUsersStatusMessages } from './provision-users-common-items'
import { ProvisionUsersFailedStatus } from './provisionUsersFailedStatus'
import { isProvisionUserHeaderList } from '../utility/type-guards'
import { useUserApi } from '../../hooks/use-user-api'
import { ProvisionUserByEmailsWatcher } from '../../signalr/watcher/provision-user-watcher-by-emails'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface ProvisionUserEmailProps {
  isVisible: boolean
  emails: string[]
  onClose: (success?: boolean) => void
}

export interface ModalDimensionProps {
  width?: number
  height?: number
}

/** User Provisioning by email */
export const ProvisionUserByEmail = ({
  isVisible,
  emails,
  onClose,
}: ProvisionUserEmailProps) => {
  // API method for user provisioning by email
  const userApi = useUserApi()
  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const [errorTitle, setErrorTitle] = useState<string>(
    provisionUsersStatusMessages.errorTitleMessage
  )
  const [errorDetail, setErrorDetail] = useState<ReactNode | undefined>()
  const [modalDimension, setModalDimension] = useState<ModalDimensionProps>({})

  // update Modal after user provisioning
  const updateErrorForPartialFailure = (
    provisionUserHeaders: ProvisionUserHeader[]
  ) => {
    // setErrorTitle for failure
    setErrorTitle(
      provisionUsersStatusMessages.errorTitleMessageOnPartialFailure
    )
    // Display failed list
    setErrorDetail(
      <ProvisionUsersFailedStatus provisionUserHeaders={provisionUserHeaders} />
    )
  }

  // Provision users by email
  const userProvision = async (abortSignal?: AbortSignal) => {
    try {
      const apiPromises: Promise<ProvisionUserHeader>[] = []
      emails.forEach((mail) => {
        const uniqueOperationId = Math.floor(Math.random() * 100000).toString()
        //invoke promise for provision users by email
        let provisionUsersPromise = userApi.provisionUserByEmail(
          mail,
          uniqueOperationId,
          abortSignal
        )
        apiPromises.push(provisionUsersPromise)
      })

      // Initialize watcher for provision users
      let provisionUserWatcher = new ProvisionUserByEmailsWatcher(apiPromises)
      provisionUserWatcher.initialize()

      // set promise for watcher to display spinner, to observe its progress
      setProvisionPromise(provisionUserWatcher.promise)

      // Wait for the promise to complete.  This is necessary
      //  for the catch portion of this block to function.
      await provisionUserWatcher.promise
    } catch (err: any) {
      // If the watch throws an error with a ProvisionUserHeader, then
      //  this indicates a partial failure, and we need to set the
      //  spinner's error content appropriately.
      if (isProvisionUserHeaderList(err)) {
        updateErrorForPartialFailure(err)
        // set modal dimension for partial failure
        setModalDimension({
          width: 800,
        })
      } else {
        // Since we don't have ProvisionUserHeader, that means some other error happened
        //  and we want the Spinner to reflect that.  Set the spinner's promise so it will show the error.
        setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
        setErrorDetail(`Error: ${err.message}`)

        setProvisionPromise(Promise.reject(err))
        //re-set modal dimension for default view
        setModalDimension({
          width: undefined,
        })
      }
    }
  }

  /** Call ProvisionUsers API method */
  useEffect(() => {
    const abortController = new AbortController()

    // Execute the provisioning of users when the dialog is visible,
    // since the control will likely always exist
    //  on the page, even if hidden due to isVisible === false.
    if (isVisible) {
      //invoke userProvision
      userProvision(abortController.signal)
    } else {
      setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
      // Reset error detail
      setErrorDetail(undefined)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isVisible, emails])

  return (
    <SpinnerModal
      visible={isVisible}
      inProgressTitleMessage={
        provisionUsersStatusMessages.inProgressTitleMessage
      }
      inProgressMessage={provisionUsersStatusMessages.inProgressMessage}
      successTitleMessage=''
      successMessage=''
      errorTitleMessage={errorTitle}
      errorMessage={errorDetail}
      width={modalDimension.width}
      onClose={(successFlag?: boolean) => {
        // Success flag is true then close the parent modal ie., QEE
        onClose(successFlag)
        setModalDimension({
          width: undefined,
        })
      }}
      apiAction={provisionPromise}
    />
  )
}
