import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFileClientApi } from '../../hooks/use-file-api'
import { SpinnerModal } from '../modal/spinnerModal'
import { useRecoilValue } from 'recoil'
import { userEntityGroupState } from '../../state/atom'
import { downloadReport } from '../../signalr/watcher-utilities/download-report'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
export interface BulkDownloadEntitySpreadsheetProps {
  onConfirm: () => void
  onCancel: () => void
  isVisible: boolean
  parentId?: number
  cacheId?: string
  filterString?: string
}
export const BulkDownloadEntitySpreadsheet = ({
  onConfirm,
  onCancel,
  parentId,
  cacheId,
  filterString,
}: BulkDownloadEntitySpreadsheetProps) => {
  // Get the Download entity Files function from our API hook.
  const fileApi = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [bulkDownloadPromise, setBulkDownloadPromise] = useState<Promise<any>>()
  const { groups } = useRecoilValue(userEntityGroupState)

  // Get the groupId from the URL.
  const { groupId } = useParams() as {
    groupId: string
  }

  useEffect(() => {
    const abortController = new AbortController()

    setShowSpinner(true)
    //generating random number for operationId
    const uniqueOperationId = Math.floor(Math.random() * 100000)
    //Finding the group by groupId
    const clientGroup = groups.find((x) => x.id === parseInt(groupId))
    //API call
    let bulkDownloadEntityPromise: Promise<void>
    //Here When Downloading from Documents Page define the ParentId (cacheId is undefined) and
    //while downloading from EntityManager parentId is undefined
    if (parentId) {
      bulkDownloadEntityPromise = fileApi.getBulkDownloadTemplateforFiles(
        clientGroup?.name,
        clientGroup?.id!,
        uniqueOperationId,
        parentId,
        abortController.signal
      )
    } else {
      bulkDownloadEntityPromise = fileApi.getBulkDownloadTemplateforEntities(
        clientGroup?.name,
        clientGroup?.id!,
        uniqueOperationId,
        cacheId,
        filterString,
        abortController.signal
      )
    }

    // call the download report helper method which will take cate of download report as well as any operation error
    const downloadReportPromise = downloadReport(
      bulkDownloadEntityPromise,
      uniqueOperationId
    )
    setBulkDownloadPromise(downloadReportPromise)

    // API action completed - hide the modelSpinner for success else show the error message in model
    downloadReportPromise.then((response) => {
      //hideing the spinner model
      setShowSpinner(false)
      onConfirm()
    })

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [cacheId, filterString, groupId, groups, parentId])

  return (
    <div>
      <SpinnerModal
        visible={true}
        errorTitleMessage='Download Error'
        inProgressTitleMessage='Preparing file(s)'
        inProgressMessage='Your file(s) are being downloaded'
        successTitleMessage='File(s) downloaded successfully'
        successMessage='Your file(s) have been downloaded.'
        onClose={() => onCancel()}
        apiAction={bulkDownloadPromise}
      />
    </div>
  )
}
