import { List } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import './investor-entity-list.scss'
import SimpleTooltip from '../reuasble-components/simpleTooltip'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface InvestorEntityListProps {
  generalOptionsId?: number
  investorId?: number
}

export const InvestorEntityList = ({
  generalOptionsId,
  investorId,
}: InvestorEntityListProps) => {
  const apiClient = useFileClientApi()
  const [compositeAllocatingEntityNameList, setCompositeEntityList] = useState<
    string[]
  >([])
  const [withholdingAllocatingEntityNameList, setWithholdingEntityList] =
    useState<string[]>([])

  useEffect(() => {
    const abortController = new AbortController()

    const updateLists = async () => {
      // Get the results from the server.
      const entityListResult = await apiClient.getInvestorElectionEntities(
        investorId!,
        generalOptionsId!,
        abortController.signal
      )

      // Create an intermediate list to set the state with.
      let newCompositeList: string[] = []
      let newWithholdingList: string[] = []

      // Fill the entity lists with the results.
      entityListResult.allocatingEntities!.forEach((entity) => {
        // Get the entity references for this allocating entity.
        var references = entityListResult.investorSelections!.filter(
          (x) =>
            x.allocatingEntityReference!.entityOptions!.allocatingEntityId ===
            entity.id
        )

        // Determine which lists the allocating entity belongs in, and add it to them.
        if (
          references.some((r) => r.allocatingEntityReference!.enableComposites)
        ) {
          newCompositeList.push(entity.name!)
        }
        if (
          references.some((r) => r.allocatingEntityReference!.enableWithholding)
        ) {
          newWithholdingList.push(entity.name!)
        }
      })

      // Sort the lists.
      newWithholdingList = newWithholdingList
        .map((x) => ({ sortName: x.toLocaleLowerCase(), name: x }))
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
        .map((x) => x.name)
      newCompositeList = newCompositeList
        .map((x) => ({ sortName: x.toLocaleLowerCase(), name: x }))
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
        .map((x) => x.name)

      // Update the two state lists.
      setWithholdingEntityList(newWithholdingList)
      setCompositeEntityList(newCompositeList)
    }

    if (investorId && generalOptionsId) {
      updateLists()
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [generalOptionsId, investorId])

  return (
    <div className='investor-entity-list h-full'>
      {generalOptionsId && investorId && (
        <>
          <div
            data-testid='investor-entity-list-header'
          >
            For your reference, elections made as part of this process apply to the following entities
            <SimpleTooltip popupMessage='Please note that you may encounter situations where the Composites and/or Withholdings tabs note that "No elections are available this year". This occurs when State tax laws preclude available elections based on your Entity Type and/or State of Residency.'/>
          </div>
          <div className='control-container'>
            <div className='dx-field list'>
              <div className='dx-field-label'>Composites</div>
              <div className='listing'>
                {compositeAllocatingEntityNameList.map((x) => (
                  <div key={x} data-testid='composite-entity-name'>
                    {x}
                  </div>
                ))}
              </div>
            </div>

            <div className='dx-field list'>
              <div className='dx-field-label'>Withholdings</div>
              <div className='listing'>
                {withholdingAllocatingEntityNameList.map((x) => (
                  <div key={x} data-testid='withholding-entity-name'>
                    {x}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
