import './investor-election-details.scss'
import { InvestorGeneralReview } from './investor-general-review'
import { InvestorElectionsCard } from './investor-election-card'
import { InvestorWithholdingReview } from './investor-withholding-review'

export interface InvestorElectionDetailsProps {
  investorElectionsInfoId: number | undefined 
}

export const InvestorElectionDetails = ({
  investorElectionsInfoId,
}: InvestorElectionDetailsProps) => {
  return (
    <div className='investor-details-page-container'>
      <div className='grid'>
        {/* load the investor general info stateOfResidency and entityType */}
        <InvestorGeneralReview />
        {/* load the composite state elections */}
        <InvestorElectionsCard
          isComposite={true}
          investorElectionsInfoId={investorElectionsInfoId}
          entityOptionsId={undefined}
          entityName='Composite Elections'
        />
      </div>
      {/* load the withholding state elections for each entity*/}
      <InvestorWithholdingReview
        investorElectionsInfoId={investorElectionsInfoId}
      />
    </div>
  )
}


