import { Button, ContextMenu } from 'devextreme-react'
import { useMemo } from 'react'
import { ToolTarget } from '../../common-components/toolTarget'
import './tile.scss'
import { MenuProps } from '../../client-models/clientmodels'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  /**Below conition made to show the disable button with hover
   * Targeted to MVP3.0
   * once we have some action for the 'Manage Users' we will not have need of this 'if' condition anymore
   */
  if (e.text === 'Manage Users') {
    return (
      <>
        <ToolTarget toolTipContent='Functionality not currently available'>
          <div className='disabled-tile-menu-item'>
            <span className='material-icons-outlined'>{e.icon}</span>
            {e.items ? <span className='dx-icon-spinright' /> : null}
            {e.text}
          </div>
        </ToolTarget>
      </>
    )
  } else {
    return (
      <>
        <span className='material-icons-outlined'>{e.icon}</span>
        {e.items ? <span className='dx-icon-spinright' /> : null}
        {e.text}
      </>
    )
  }
}
interface Props {
  children?: React.ReactNode
  title?: string
  url?: string
  isActive?: boolean
  id: number
  isContextMenuVisible: boolean
  clientMenus: MenuProps[]
  onEditClient: (entityGroupId: number) => void
}

const Tile = ({
  children,
  title,
  url,
  isActive,
  id,
  isContextMenuVisible,
  clientMenus,
  onEditClient,
}: Props) => {
  const uniqueButtonId = useMemo(() => Math.floor(Math.random() * 1000000), [])
  return (
    <div className={`cui-c_tile ${isActive && 'cui-c_tile--active'}`}>
      <div className='cui-c_tile-header'>
        <div className='cui-c_tile-header_title'>
          <a href={url}>{title}</a>
        </div>
        <div>
          <Button
            stylingMode='text'
            id={'contextMenuBtn' + uniqueButtonId}
            data-testid='btnContextMenu'
            visible={isContextMenuVisible}
          >
            <span id='contextMenuBtn' className='material-icons'>
              more_horiz
            </span>
          </Button>
          <ContextMenu
            height={5}
            width={5}
            dataSource={clientMenus}
            showEvent='click'
            target={'#contextMenuBtn' + uniqueButtonId}
            itemRender={renderMenuItem}
            onItemClick={(e) => {
              const item = e.itemData as MenuProps | undefined
              if (item?.action) {
                item.action()
              }
            }}
          />
        </div>
      </div>
      <div className='cui-c_tile-body'>{children}</div>
    </div>
  )
}
Tile.defaultProps = {
  isActive: true,
  title: 'Tile Title Placeholder',
}
export default Tile
