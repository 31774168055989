import React, { useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { ButtonFunction, Modal } from '../modal/modalBase'
import './relinkChildFileConfirmation.scss'
import { File } from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'
import { RelinkFileWatcher } from '../../signalr/watcher/relink-files-watcher'
import { ScrollableMessage } from './scrollableMessage'
import { useRecoilPinner } from '../../signalr/useRecoilPinner'
import { parentFileListAtom } from '../../state/atom'

export interface RelinkChildFileConfirmationProps {
  onConfirm: () => void
  onCancel: () => void
  files: File[]
  isVisible: boolean
  entityGroupId: number
}

/** Relink Child File confirmation modal */
export const RelinkChildFileConfirmation = ({
  onConfirm,
  onCancel,
  files,
  isVisible,
  entityGroupId,
}: RelinkChildFileConfirmationProps) => {
  // Get the relink files function from our API hook.
  const { relinkFiles } = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [reLinkPrmoise, setRelinkPromise] = useState<Promise<any>>()
  const parentFileRecoilPinner = useRecoilPinner(parentFileListAtom)
  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm',
      onClick: async () => {
        setShowSpinner(true)
        let relinkPromise = relinkFiles(
          files.map((f) => f.id!),
          entityGroupId
        )
        let relinkFileWatcher = new RelinkFileWatcher(
          relinkPromise,
          parentFileRecoilPinner,
          files.map((f) => f.id!)
        )
        relinkFileWatcher.initialize()
        setRelinkPromise(relinkFileWatcher.promise)
        onConfirm()
        await relinkPromise
      },
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 160,
        'data-testid': 'confirm-relink-button',
      },
    },
  ]

  return (
    <div>
      <Modal
        visible={isVisible}
        title='Re-link Investors'
        buttonFunctions={buttonFunctions}
        disableScrollbar={true}
        maxWidth={500}
        maxHeight={300}
      >
        <ScrollableMessage
          staticContent={'Would you like to relink the selected file(s)?'}
          scrollableContent={
            <div className='files-list'>
              {files.map((f) => (
                <div data-testid='file-item' key={f.id!.toString()}>
                  {f.externalFileName}
                </div>
              ))}
            </div>
          }
        ></ScrollableMessage>
      </Modal>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Re-link investors failed.'
        inProgressTitleMessage='Re-link investors'
        inProgressMessage='Re-link investors in-progress...'
        successTitleMessage='Re-link investors success'
        successMessage='Re-link investors success.'
        onClose={() => {setShowSpinner(false); onConfirm()}}
        apiAction={reLinkPrmoise}
      />
    </div>
  )
}
