
import { REACT_APP_IDMAUTHENTICATIONEULASERVICEURL } from '../envVariables'
import { useCallback, useState } from 'react'
import { HttpRequestState } from './utility'
import { getAccessToken } from '@rsmus/react-auth/dist/services/userManagerFactory'

export const useTermsOfUse = () => {
    const baseUrl = REACT_APP_IDMAUTHENTICATIONEULASERVICEURL
    const [requestStatus, setRequestStatus] = useState<HttpRequestState>('idle')
    const [termOfUse, setTermOfUse] = useState('')

    /** Request the Terms of Use from EULA server */
    const getTermsOfUse = useCallback(
        async (termsOfUseId: string) => {
            if (!baseUrl) {
                return
            }
            try {
                setRequestStatus('in-progress')
                // Get the access token
                const accessToken = await getAccessToken()
                const response = await fetch(`${baseUrl}/api/eulas/${termsOfUseId}/content`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': 'bearer ' + accessToken!
                        }
                    })

                // Get the response text
                const textResponse = await response.text();
                if (response) {
                    // If success then set the response text
                    setTermOfUse(textResponse)
                    setRequestStatus('success')
                } else {
                    setRequestStatus('failed')
                }
            } catch (error) {
                setRequestStatus('failed')
            }
        },
        // eslint-disable-next-line
        [baseUrl]
    )

    return { getTermsOfUse, requestStatus, termOfUse }
}
