import { useRecoilState } from 'recoil'
import './investor-landing-page.scss'
import { appSettingsAtom } from '../../../state/atom'
import { InvestorLandingTilesView } from './investor-landing-tiles-view'
import { InvestorLandingListView } from './investor-landing-list-view'
import { useCommonDialogs } from '../../modal/commonDialog/common-dialog-operations'
import produce from 'immer'
import { useEffect } from 'react'

// Investor landing page component
export const InvestorLandingPage = () => {
  const commonDialog = useCommonDialogs()
  const [appState, setAppState] = useRecoilState(appSettingsAtom)

  /** To set the current view */
  const currentView = appState?.viewOptions['InvestorLandingPageView']
    ? appState?.viewOptions['InvestorLandingPageView']
    : 'tile'

  /** Display Get started popup if its not displayed */
  useEffect(() => {
    if (!appState.landingPagePopupShown) {
      commonDialog.showDialog({
        dialogType: 'general',
        title: 'Get Started!',
        content:
          'Investor Portal is a secure system to electronically distribute ' +
          'individual K-1 and other financial documents directly to investors. ' +
          'Investors who consent to receiving these documents electronically ' +
          'may access the portal to download their documents.',
        omitDefaultButton: true,
        buttonFunctions: [
          {
            label: 'Close',
            isCloseAction: true,
            isDefaultAction: true,
            onClick: () => {
              // Set popup shown to true, so that we will not show the popup next time
              setAppState(
                produce((draft) => {
                  draft.landingPagePopupShown = true
                })
              )
            },
            buttonProps: {
              stylingMode: 'contained',
              type: 'default',
              width: 100,
              'data-testid': 'close-button',
            },
          },
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {currentView === 'tile' ? (
        <InvestorLandingTilesView />
      ) : (
        <InvestorLandingListView />
      )}
    </>
  )
}
