import React, { useCallback, useMemo, useState } from 'react'
import { ButtonFunction, Modal } from '../../modal/modalBase'
import { BulkUploadDetail } from '../entity-utils'
import './upload-bulk-entity.scss'
import { useRecoilValue } from 'recoil'
import { clientAndPartnershipStateAtom } from '../../../state/atom'
import { FileUpload } from '../../fileUpload/fileUpload'
import { Subject } from 'rxjs'
import { useEntityClientApi } from '../../../hooks/use-entity-api'
import { FormattedDialog } from '../../modal/formattedDialog'
import { downloadFileFromBlobStorage } from '../../../hooks/file-download-api'
import { BulkUploadResult } from '../../../api-client/entity-manager-client-v3'

interface UploadBulkEntityProps {
  uploadDetail: BulkUploadDetail
  onSuccess: (bulkUploadResult: BulkUploadResult) => void
  onCancel: () => void
}

/** EntityTypeSelector to add new entity. */
export const UploadBulkEntity = ({
  uploadDetail,
  onSuccess,
  onCancel,
}: UploadBulkEntityProps) => {
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  const [uploadTriggered, setUploadTriggered] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [isModelVisible, setIsModelVisible] = useState(true)
  const [errorModalDetails, setErrorModalDetails] = useState({
    visible: false,
    errorMessage: '',
    showGenericError: true, 
    errorFileUrl: '',
    fileName: '',
  })
  const { uploadBulkEntities } = useEntityClientApi()

  // Upload trigger subject
  const uploadTriggerSubject =useMemo(()=>{
   return new Subject<void>()
  },[])

  // Upload trigger
  const uploadTrigger =useMemo(()=>{
    return uploadTriggerSubject.asObservable()
   },[uploadTriggerSubject])
   
  // Create the buttons for the Modal.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        onCancel()
      },
      isDefaultAction: true,
      buttonProps: {
        className: 'cancel-btn',
        stylingMode: 'contained',
        type: 'normal',
      },
    },
    {
      label: 'Add Document',
      visible: !uploadTriggered,
      isDisabled: uploadTriggered || !selectedFile,
      buttonProps: {
        'data-testid': 'add-document-button',
        type: 'default',
      },
      onClick: () => {
        if (!selectedFile) {
          return
        }
        setUploadTriggered(true)
        uploadTriggerSubject.next()
      },
    },
  ]

  // Upload the Entities 
  const uploadEntities = async (file: File) => {
    if (!file) {
      return
    }
    const result = await uploadBulkEntities(uploadDetail, file)
    if (!result.success) {
      downloadBulkEntityErrorSheet(result.errorFileUrl!, file.name)
      setErrorModalDetails({
        visible: true,
        errorMessage: 'File contains errors, see downloaded error listing.',
        showGenericError: false,
        errorFileUrl: result.errorFileUrl!,
        fileName: file.name,
      })
    } else {
      onSuccess(result)
      setIsModelVisible(false)
    }
  }

  /** Called by the file upload control if an error occurs during upload.  This will show the error modal. */
  const uploadErrorHandler = useCallback((err: any) => {
    let errorDetail: string = ''
    let isError: boolean = true
    if (err.response) {
      errorDetail = err.response.replace(/^"(.*)"$/, '$1')
      isError = false
    } else {
      errorDetail = err?.toString()
    }
    setErrorModalDetails({
      visible: true,
      errorMessage: errorDetail,
      showGenericError: isError,
      errorFileUrl: '',
      fileName: ''
    })
  }, [])

  //Close error modal
  const closeErrorModal = useCallback(() => {
    setUploadTriggered(false)
    setErrorModalDetails({
      visible: false,
      errorMessage: '',
      showGenericError: true,
      errorFileUrl: '',
      fileName: ''
    })
    setIsModelVisible(false)
  }, [])

  // Download the error sheet from blob storage
  const downloadBulkEntityErrorSheet = async(errorFileUrl: string, fileName: string)=>{
    await downloadFileFromBlobStorage(errorFileUrl, fileName)
  }

  return (
    <div className='bulk-upload-entity-selector'>
      <Modal
        visible={isModelVisible}
        title={'Upload Bulk - ' + clientGroupInfo.clientGroup?.name}
        maxHeight={430}
        maxWidth={550}
        showCloseButtonInTitle={true}
        buttonFunctions={buttonFunctions}
        preventModalClose={true}
        className='bulk-upload-modal'
        data-testid='bulk-upload-modal-test'
      >
        <FileUpload
          uploadFileFn={() => uploadEntities(selectedFile!)}
          uploadFileTrigger={uploadTrigger}
          setFileSelection={(file: File | undefined) => {
            setSelectedFile(file)
          }}
          fileAcceptance={{
            acceptTypes: '.xlsx, .xlsm',
            acceptanceMessage: '.xlsx, or .xlsm',
          }}
          onUploadComplete={() => setIsModelVisible(false)}
          onUploadError={uploadErrorHandler}
        ></FileUpload>
      </Modal>
      <FormattedDialog
        visible={errorModalDetails.visible}
        dialogType='error'
        title='Upload Error'
        onCloseClicked={closeErrorModal}
        preventModalClose={true}
      >
        {errorModalDetails.showGenericError && (
          <div>Upload error: {errorModalDetails.errorMessage}</div>
        )}
        {!errorModalDetails.showGenericError && (
          <div className='bulk-upload-error-model'>
            <div>{errorModalDetails.errorMessage}</div>
            <div className='download-link-area'>
              <a
                href='javascript:void(0)'
                onClick={() => {
                  downloadBulkEntityErrorSheet(
                    errorModalDetails.errorFileUrl!,
                    errorModalDetails.fileName
                  )
                }}
              >
                If file does not downloaded, click here
              </a>
            </div>
          </div>
        )}
      </FormattedDialog>
    </div>
  )
}
