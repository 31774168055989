import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { PartnershipBannerStat } from '../../api-client/investor-portal-client'
import { useFileClientApi } from '../../hooks/use-file-api'
import {
  parentFileListAtom,
  clientAndPartnershipStateAtom,
} from '../../state/atom'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'
import { Statistics } from '../../common-components/banners/statistics'
import { TaxYearControl } from '../../common-components/banners/tax-year-control/tax-year-control'
import { NavWidget } from '../nav-widget/navWidget'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface ParentChildFileBannerProps {
  isParentBanner: boolean
}

/** Parent Child File Banner */
export const ParentChildFileBanner = ({
  isParentBanner,
}: ParentChildFileBannerProps) => {
  const [partnershipBannerStat, setPartnershipBannerStat] =
    useState<PartnershipBannerStat>()
  const { groupId } = useParams() as {
    groupId: string
  }
  const clientApi = useFileClientApi()
  const { files, isLoading } = useRecoilValue(parentFileListAtom)
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  // Get client group - documents and entities statistics
  useEffect(() => {
    setPartnershipBannerStat({
      documents: undefined,
      entities: undefined,
    })
    const abortController = new AbortController()
    clientApi
      .getClientGroupBannerStats(parseInt(groupId), abortController.signal)
      .then((response) => {
        setPartnershipBannerStat({
          documents: response.documents!,
          entities: response.entities!,
        })
      })

    // Abort the API Call when user moves away from this page
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, files])

  /** Set banner header name based on Parent or children*/
  const getHeaderContent = () => {
    let partnershipName: string = ''
    if (isParentBanner) {
      partnershipName =
        clientGroupInfo.partnership?.name !== undefined
          ? clientGroupInfo.partnership?.name + ' - Documents'
          : ''
    } else {
      partnershipName = 'Investor Documents'
    }
    return partnershipName
  }
  /** Set documents & statistics details*/
  let statisticsItems = [
    {
      name: 'Documents',
      value: partnershipBannerStat?.documents!,
    },
    {
      name: 'Allocating Entities',
      value: partnershipBannerStat?.entities!,
    },
  ]
  return (
    <BannerContainer>
      <div className='align-banner'>
        <div className='banner-header-full-width'>
          <BannerHeader>{getHeaderContent()}</BannerHeader>
          <NavWidget />
        </div>
        {isParentBanner && <TaxYearControl isDisabled={isLoading} />}
      </div>
      <Statistics items={statisticsItems} />
    </BannerContainer>
  )
}
