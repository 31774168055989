import { useRecoilValue } from 'recoil'
import '../landingPage/landingPage.scss'
import { appSettingsAtom } from '../../state/atom'
import { AdminLandingPageTilesView } from './admin-landing-tiles-view'
import { AdminLandingPageListView } from './admin-landing-list-view'

// Admin landing page component
export const AdminLandingPage = () => {
  const appState = useRecoilValue(appSettingsAtom)

  /** To set the current view */
  const currentView = appState?.viewOptions['InvestorLandingPageView']
    ? appState?.viewOptions['InvestorLandingPageView']
    : 'tile'
  return (
    <>
      {currentView === 'tile' ? (
        <AdminLandingPageTilesView />
      ) : (
        <AdminLandingPageListView />
      )}
    </>
  )
}