import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  getInvestorFilesRoute,
  getInvestorLandingRoute,
  getLandingPageRoute,
  getTermsOfUseRoute,
  getUnAuthorizedRoute,
} from '../components/utility/route-creation'
import { REACT_APP_FEATURES_STATEMANAGER } from '../envVariables'
import { useNavigate } from './useNavigationGuard'
import { useEffect } from 'react'
import { useIsPermissionsLoaded, usePermissions } from '../access-manager/use-permission'
import { permissionRequestAny } from '../access-manager/permission-request-creation'
import { ADMIN, INVESTOR } from '../model/permissions'

/** Validate and Authorize user permission to view the page */
export function useAdminAuthorization() {
  const location = useLocation()
  const navigate = useNavigate()

  const isPermissionsLoaded = useIsPermissionsLoaded()
  const isInvestor = usePermissions([permissionRequestAny(INVESTOR)])
  const isAdmin = usePermissions([permissionRequestAny(ADMIN)])

  /** 1) If URL prefix with admin/ and user does not have admin permission then
   *      a) If user has 'Investor' permission then redirect to 'investor/investorfiles'
   *      b) If user does not have 'Investor & Admin' permission then redirect to 'Unauthorized' page
   *  2) If URL prefix with investor/ and user does not have investor permission then
   *      a) If user has 'Admin' permission then redirect to Landing page
   *      b) If user does not have 'Admin & Investor' permission then redirect to 'Unauthorized' page
   */
  useEffect(() => {
    if (isPermissionsLoaded) {
      const isAdminPath: boolean = location.pathname.startsWith('/admin')
      const isInvestorPath: boolean = location.pathname.startsWith('/investor')

      if (location.pathname.toLowerCase() === getTermsOfUseRoute().toLowerCase()) {
        // Do nothing.  We don't care if anyone is on the terms-of-use page.
      } else if (
        isAdminPath &&
        !isAdmin
      ) {
        // Route prefixed with admin but user does not have admin permission
        // User with investor permission redirect to investor page
        if (isInvestor) {
          REACT_APP_FEATURES_STATEMANAGER === true
            ? navigate(getInvestorLandingRoute())
            : navigate(getInvestorFilesRoute())
        } else {
          navigate(getUnAuthorizedRoute())
        }
      } else if (
        // Route prefixed with investor but user does not have admin permission
        isInvestorPath && !isInvestor
      ) {
        // User with admin permission redirect to landing page
        if (isAdmin) {
          navigate(getLandingPageRoute())
        } else {
          navigate(getUnAuthorizedRoute())
        }
      } else if (
        // Route not prefixed with admin or investor then check permission and redirect accordingly
        !isAdminPath &&
        !isInvestorPath &&
        !location.pathname.startsWith('/help') &&
        !location.pathname.startsWith('/logout') &&
        !location.pathname.startsWith('/error')
      ) {
        if (isAdmin) {
          navigate(getLandingPageRoute())
        } else if (isInvestor) {

          REACT_APP_FEATURES_STATEMANAGER === true
            ? navigate(getInvestorLandingRoute())
            : navigate(getInvestorFilesRoute())
        } else if (!location.pathname.startsWith('/unauthorized')) {
          navigate(getUnAuthorizedRoute())
        }
      }
    }
  }, [location.pathname, isInvestor, isAdmin, isPermissionsLoaded])
}
