import { Tooltip } from 'devextreme-react'
import React from 'react'

export interface ToolTargetProps {
  toolTipContent: string | React.ReactNode
  children: React.ReactNode
  /** Boolean value indicating whether or not the wrapper div of this element should
   *   be shown as a span or a div. */
  isInline?: boolean
}

export function ToolTarget(props: ToolTargetProps) {
  const [showTooltip, setShowTooltip] = React.useState(false)

  const ref = React.useRef<any>()

  const wrapperStyle = {} as any
  if (props.isInline) {
    wrapperStyle.display = 'inline-block'
  }

  return (
    <div style={wrapperStyle}>
      <div
        ref={ref}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {props.children}
      </div>
      <Tooltip visible={showTooltip} target={ref.current} position='top'>
        {props.toolTipContent}
      </Tooltip>
    </div>
  )
}
