import { SignalRWatcher } from './signalr-watcher'
import { saveBlob } from '../../components/utility/saveBlobFn'
import { DownloadReportMessage } from '../../api-client/investor-portal-client'
import { OperationErrorWatcher } from './operation-error-watcher'

/** Watches for report complete, and resolves/rejects when expected 'report complete' has been processed.*/
export class ReportCompleteWatcher extends SignalRWatcher {
  constructor(
    apiPromise: Promise<any>,
    private readonly operationId: number
  ) {
    super('ReportComplete', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)
  }
  operationWatcher?: OperationErrorWatcher
  reportCompleteOperationFailed(err: any): void {
    this.operationFailed(err)
  }

  /** save file method is to prepare blob and filename and extention */
  saveFile = async (response: DownloadReportMessage) => {
    const request = await fetch(response.reportPathSasUri!)
    saveBlob(await request.blob(), `${response.substitutionTokens!['[Title]']}.${response.substitutionTokens!['[Extension]']}`)
  }

  /** SignalR handler to process the received in the messages. */
  handlerAction(uniqueOperationId: number, response: DownloadReportMessage) {
    if (uniqueOperationId !== this.operationId) {
      return
    }
    this.saveFile(response)
      .then(() => {
        this.operationWatcher!.operationCompleted()
        this.operationComplete()
      })
      .catch((err) => {
        this.operationFailed(err)
      })
  }
}
