import React, { useCallback, useMemo, useRef, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { RequiredRule } from 'devextreme-react/form'
import {
  DataGrid,
  Column,
  HeaderFilter,
  Scrolling,
  SearchPanel,
  LoadPanel,
  Sorting,
  PatternRule,
} from 'devextreme-react/data-grid'
import { dgheight } from '../../model/file-model'
import './provisionContactEmail.scss'
import { useUserApi } from '../../hooks/use-user-api'
import {
  ProvisionUserHeader,
  ProvisionUserRecord,
  VerifyUserViewModel,
} from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'
import { ProvisionUserByEmailWatcher } from '../../signalr/watcher/provision-user-by-email-watcher'
import { ProvisionUserHeaderStatusTypes, VerifyUserByEmailRow } from './types'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import produce from 'immer'
import dxDataGrid from 'devextreme/ui/data_grid'
import { EventInfo } from 'devextreme/events'
import { singleEmailPattern } from '../../utilities/email-validation'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { UserManagementInfo } from '../../api-client/entity-manager-client-v3'
import { Button, TextBox, Validator } from 'devextreme-react'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { getShortDateString } from '../utility/date-utility'
import { IdmUserStatusTypes } from '../../model/idm-user-statuses'
import { LoadingStateEnum } from '../../state/atom'

type IdmActionState = 'not-called' | 'in-progress' | 'called'

/** Provisional Contact Email Component */
export const ProvisionContactEmail = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<VerifyUserByEmailRow[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const [documentCount, setDocumentCount] = useState<number>(0)
  const dataGridRef = useRef<DataGrid>(null)
  const [userIdmInfo, setUserIdmInfo] = useState<UserManagementInfo>()
  const [emailAddress, setEmailAddress] = useState<string>()
  const validatorRef = useRef<Validator>(null)
  const searchRef = useRef<TextBox>(null);
  const [searchText, setSearchText] = useState('')
  const [idmLoadingState, setIdmLoadingState] = useState<LoadingStateEnum>(
    LoadingStateEnum.NotLoaded
  )
  const [idmActionInProgress, setIdmActionInProgress] =
    useState<IdmActionState>('not-called')

  const userApi = useUserApi()
  const entityApi = useEntityClientApi()

  // We must disable the provision user button if we don't have any details from the
  //  server for this email, or if the email address isn't set.
  const disableProvisionButton =
    (emailAddress?.length ?? 0) < 1 || (userDetails?.length ?? 0) < 1

  /** Update status in store */
  const updateProvisionStatus = (userHeader: ProvisionUserHeader) => {
    // Get copies of the server-based records, with their client IDs, entity group IDs, and entity group names
    //  being filled in.  This may be on the header or on the records themselves.
    function chooseValue<
      K extends Extract<keyof ProvisionUserRecord, keyof ProvisionUserHeader>
    >(
      record: ProvisionUserRecord,
      field: K
    ): ProvisionUserRecord[K] | ProvisionUserHeader[K] {
      return record[field] ?? userHeader[field]
    }

    const records = userHeader.provisionUserRecords?.map((r) => ({
      ...r,
      clientId: chooseValue(r, 'clientId'),
      clientName: r.cemClientName,
      entityGroupId: chooseValue(r, 'entityGroupId'),
    }))
    // Using the new header, update the status of the existing user rows:

    // Update the rows.
    const newRows = userDetails.map((r) => {
      // Find a matching provision user record for this local record.
      const match = records?.find(
        (sr) =>
          sr.clientId?.toString() === r.companyId &&
          sr.entityGroupId === r.clientGroupId
      )

      // IF no match was found, then return the original value.
      if (!match) {
        return r
      }

      // Update the row to reflect the new data.
      return {
        ...r,
        companyName: match.clientName ?? r.companyName,
        provisionOperationStatus:
          match.status === 'NEW'
            ? 'LOADING'
            : (match.status as ProvisionUserHeaderStatusTypes),
      }
    })

    // Update response status to store
    setUserDetails(
      produce((draft) => {
        draft.forEach((e) => {
          const row = newRows.find(
            (v) =>
              v.clientGroupId === e.clientGroupId && v.companyId === e.companyId
          )
          if (row !== undefined && row.provisionOperationStatus !== undefined) {
            e.companyName = row.companyName
            e.provisionOperationStatus =
              row.provisionOperationStatus as ProvisionUserHeaderStatusTypes
          }
        })
      })
    )

    // if user is provisioning for first time then refresh the user Info
    if (!userIdmInfo) {
      updateUserIdmData(emailAddress!)
    }
  }

  const updateUserIdmData = async (email: string) => {
    // Clear the IDM Action state, so it can be called again, if it was already called.
    setIdmActionInProgress('not-called')

    setIdmLoadingState(LoadingStateEnum.Loading)
    try {
      const userInfo = await entityApi.getUserManagementInfo(email)
      setUserIdmInfo(userInfo)
      setIdmLoadingState(LoadingStateEnum.Loaded)
    } catch (err) {
      setIdmLoadingState(LoadingStateEnum.Error)
    }
  }

  /** Validate Provision contact email and call API */
  const submitProvisionContact = () => {
    // Validate form
    if (!validatorRef.current!.instance.validate().isValid) {
      return
    }

    // Exit if there's no email address.
    if (!emailAddress) {
      return
    }

    // Make this call to update the IDM info on the page, but do not block it with await.
    updateUserIdmData(emailAddress)

    setLoading(true)
    setDisableSubmit(true)
    setUserDetails([])

    // Call Api
    userApi
      .verifyUserByEmail(emailAddress)
      .then((response: VerifyUserViewModel[]) => {
        setDisableSubmit(false)
        setUserDetails(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /** Call api method to Provision user */
  const handleProvision = async () => {
    setShowSpinner(true)

    // Set status as 'REQUESTED'
    setUserDetails(
      produce(userDetails, (draft) => {
        draft.forEach((e) => (e.provisionOperationStatus = 'REQUESTED'))
      })
    )

    const uniqueOperationId = Math.floor(Math.random() * 100000).toString()
    // Make API call to perform provisioning based on email
    let provisionPromise = userApi.provisionUserByEmail(
      emailAddress!,
      uniqueOperationId
    )
    provisionPromise
      ?.then((response) => {
        // Set status as 'LOADING'
        setUserDetails(
          produce(userDetails, (draft) => {
            draft.forEach((e) => (e.provisionOperationStatus = 'LOADING'))
          })
        )
      })
      .catch((e) => {
        // Set status as 'ERROR'
        setUserDetails(
          produce(userDetails, (draft) => {
            draft.forEach((e) => (e.provisionOperationStatus = 'ERROR'))
          })
        )
        throw new Error('Error occured in provisionUserByEmail ' + e)
      })
    // Initialize watcher
    let provisionUserByEmailWatcher = new ProvisionUserByEmailWatcher(
      provisionPromise,
      updateProvisionStatus,
      uniqueOperationId
    )
    provisionUserByEmailWatcher.initialize()
    setProvisionPromise(provisionUserByEmailWatcher.promise)
    // API action completed - to update status for display
    provisionUserByEmailWatcher.promise.then(
      (userHeader: ProvisionUserHeader) => {
        updateProvisionStatus(userHeader)
      }
    )
  }

  /** Sets filtered records displayed in grid view */
  const setFilteredAndSortedRowsCount = useCallback(() => {
    const grid = dataGridRef.current!.instance
    const filterExpr = grid.getCombinedFilter(true)
    const dataSource = grid.getDataSource()
    const loadOptions = dataSource.loadOptions()
    dataSource.store().load({
      filter: filterExpr,
      sort: loadOptions.sort,
      group: loadOptions.group,
    })
  }, [])

  /** Get the list of records displayed in the child datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
    setFilteredAndSortedRowsCount()
  }

  /** Validate form and Call api method */
  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()
    // Clear search panel text and sorting on submit click
    dataGridRef.current!.instance.clearFilter()
    dataGridRef.current!.instance.clearSorting()
    submitProvisionContact()
  }

  const toolbarContent = (
    <Grid container alignItems='center' className='spaced-content'>
      <Grid item>
        <TextBox
          id='email-textbox'
          className='email-textbox'
          value={emailAddress}
          onValueChanged={(data: ValueChangedEvent) =>
            setEmailAddress(data.value)
          }
          onEnterKey={submitProvisionContact}
        >
          <Validator ref={validatorRef}>
            <RequiredRule message='Email is invalid' />
            <PatternRule
              ignoreEmptyValue={false}
              message='Email is invalid'
              pattern={singleEmailPattern}
            />
          </Validator>
        </TextBox>
      </Grid>

      <Grid item>
        <Button
          elementAttr={{
            id: 'submit-button',
          }}
          text='Submit'
          type='default'
          useSubmitBehavior={true}
          disabled={disableSubmit}
          onClick={() => handleSubmit()}
        />
      </Grid>

      <Grid item>
        <Button
          text='Provision'
          type='default'
          useSubmitBehavior={false}
          disabled={disableProvisionButton}
          onClick={() => handleProvision()}
        />
      </Grid>
    </Grid>
  )

  const cemButtonAction = useCallback(async () => {
    // If there's no user, then there's nothing to do.
    if (!userIdmInfo) {
      return
    }

    // Set the flag indicating we're making this API call.
    setIdmActionInProgress('in-progress')

    try {
      // The returned user info from API call.
      let userInfo: UserManagementInfo | undefined

      switch (userIdmInfo.status) {
        case IdmUserStatusTypes.ACTIVE:
        case IdmUserStatusTypes.RECOVERY:
          userInfo = await entityApi.resetUserPassword(userIdmInfo.id!)
          break
        case IdmUserStatusTypes.PROVISIONED:
          userInfo = await entityApi.resendActivationEmail(userIdmInfo.id!)
          break
        default:
        // Nothing to do here.
      }

      // If there is user info, then update it.
      if (userInfo) {
        setUserIdmInfo(userInfo)
      }
    } catch (err) {
      // Not sure what to do with this error, but it can't hurt to log it.
      console.error(err)
    } finally {
      // Clear the ability to call the IDM action again.
      setIdmActionInProgress('called')
    }

    // We don't need the entityApi, which is what disable is for.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdmInfo?.id])

  const idmButtonLabel = useMemo(() => {
    // If there's no status, then there's no label.
    if (!userIdmInfo?.status) {
      return ''
    }

    switch (userIdmInfo.status) {
      case IdmUserStatusTypes.ACTIVE:
      case IdmUserStatusTypes.RECOVERY:
        return 'Reset User Password'
      case IdmUserStatusTypes.PROVISIONED:
        return 'Resend Activation Email'
      default:
        return ''
    }
  }, [userIdmInfo?.status])

  const idmInfoContent = (
    <Grid container className='user-management-info margin-top'>
      <Grid item xs={12} className='section-title'>
        CEM Info
      </Grid>
      <Grid item xs={12} md={6}>
        <div className='info-field'>
          <div>Name:</div>
          <div>
            {userIdmInfo?.firstName} {userIdmInfo?.lastName}
          </div>
        </div>
        <div className='info-field'>
          <div>Status:</div>
          <div> {userIdmInfo?.status} </div>
        </div>
        <div className='info-field'>
          <div>Last Login:</div>
          <div>{getShortDateString(userIdmInfo?.lastLogin, 'Never')}</div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>Action</div>
        <div>
          {idmActionInProgress === 'not-called' && (
            <Button
              elementAttr={{
                id: 'idm-action-button',
              }}
              type='default'
              onClick={cemButtonAction}
              text={idmButtonLabel}
            ></Button>
          )}
          {idmActionInProgress === 'in-progress' && <CircularProgress />}
          {idmActionInProgress === 'called' && (
            <div>Request Sent Successfully</div>
          )}
        </div>
      </Grid>
    </Grid>
  )

  const idmInfoLoadingContent = (
    <Grid container className='user-management-info margin-top'>
      <Grid item xs={12} className='section-title'>
        CEM Info
      </Grid>
      <Grid item xs={12}>
        <CircularProgress color='info' size='20px' style={{ margin: '2px' }} />
      </Grid>
    </Grid>
  )

  const idmInfoNull = (
    <Grid container className='user-management-info margin-top'>
      <Grid item xs={12} className='section-title'>
        CEM Info
      </Grid>
      <Grid item xs={12} className='info-field'>
        <div>User not present in CEM</div>
      </Grid>
    </Grid>
  )

  const fullToolbarContent = (
    <Grid container alignItems='flex-end' className='padded-sides'>
      <Grid item xs={12} md={8}>
        <Grid container alignItems='flex-end'>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <div className='instruction-list'>
                  Email address must be added to Entity in Entity Manager before
                  the email can be provisioned in RSM CEM.
                </div>
              </Grid>
              <Grid item xs={12}>
                {toolbarContent}
              </Grid>
            </Grid>
          </Grid>

          {idmLoadingState === LoadingStateEnum.Loaded &&
            userIdmInfo &&
            idmInfoContent}

          {idmLoadingState === LoadingStateEnum.Loaded &&
            !userIdmInfo &&
            idmInfoNull}

          {idmLoadingState === LoadingStateEnum.Loading &&
            idmInfoLoadingContent}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        alignItems='center'
        className='search-container'
      >
        <ClearFiltersButton className='clear-button' gridRef={dataGridRef} />
        <TextBox
          className='search-box'
          ref={searchRef}
          onValueChanged={(e) => setSearchText(e.value)}
          valueChangeEvent='keyup'
          placeholder={'Search...'}
          value={searchText}
          showClearButton={true}
        >
          <Button
            icon='search'
            type='normal'
            className='icon-button'
            onClick={() => {
              searchRef.current!.instance.focus()
            }}
          />
        </TextBox>
      </Grid>
    </Grid>
  )

  return (
    <div className='provision-email-container'>
      <div>{fullToolbarContent}</div>
      <div className='ip-table'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          keyExpr='entityId'
          noDataText={
            isLoading && userDetails.length === 0 && documentCount === 0
              ? 'Loading...'
              : !isLoading && (userDetails.length === 0 || documentCount === 0)
              ? 'No Data Found.'
              : ''
          }
          dataSource={userDetails}
          columnAutoWidth={true}
          height={dgheight}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={true}
          wordWrapEnabled={false}
          onCellPrepared={addToolTipOnCellPrepared}
          onContentReady={onContentReady}
        >
          <SearchPanel
            visible={false}
            text={searchText}
            width={350}
            placeholder='Search...'
          />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Sorting mode='single' />
          <LoadPanel enabled={false} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column
            dataField='emailAddress'
            caption='Email Address'
            allowSearch={true}
            allowSorting={true}
            defaultSortOrder='desc'
            width={'13%'}
          ></Column>
          <Column
            dataField='entityName'
            caption='Investor Name'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'13%'}
          />
          <Column
            dataField='partnershipClientStatus'
            caption='Allocating Entity Info'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'21%'}
          />
          <Column
            dataField='clientGroup'
            caption='Client Name'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'15%'}
          />
          <Column
            dataField='companyName'
            caption='CEM Info'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'18%'}
          />
          <Column
            dataField='companyId'
            caption='Company ID'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'10%'}
          />
          <Column
            dataField='provisionOperationStatus'
            caption='Provision Status'
            alignment='left'
            allowSearch={true}
            allowSorting={true}
            width={'10%'}
          />
        </DataGrid>
      </div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Provisioning user failed.'
        inProgressTitleMessage='Provision User'
        inProgressMessage='Provisioning user in-progress...'
        successTitleMessage='Provision User Success'
        successMessage='Provisioning user completed.'
        onClose={() => {
          setShowSpinner(false)
        }}
        apiAction={provisionPromise}
      />
    </div>
  )
}
