import './investor-view-elections.scss'
import { InvestorGeneralReview } from '../investor-view/investor-review/investor-general-review'
import { InvestorElectionsCard } from '../investor-view/investor-review/investor-election-card'
import { InvestorWithholdingReview } from '../investor-view/investor-review/investor-withholding-review'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { useEffect, useState } from 'react'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface InvestorElectionDetailsProps {
  investorElectionsInfoId: number
  isVisible: boolean
  onCancel: () => void
  investorName: string | undefined
  investorId: number
  groupId: number
  taxYear: number
}

/** Preview investor elections model */
export const InvestorViewElections = ({
  investorElectionsInfoId,
  isVisible,
  onCancel,
  investorName,
  investorId,
  groupId,
  taxYear,
}: InvestorElectionDetailsProps) => {
  const stateManagerApi = useStateManagerApi()
  const commonDialog = useCommonDialogs()
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()

    setIsloading(true)
    stateManagerApi
      .getInvestorElectionInfo(
        groupId,
        investorId,
        taxYear,
        abortController.signal
      )
      .catch((err) => {
        commonDialog.showDialog({
          title: 'Error Previewing Elections',
          dialogType: 'error',
          content: `Error Previewing Elections: ${err}`,
        })
        onCancel()
      })
      .finally(() => setIsloading(false))

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, investorId, taxYear])

  /** Button functions of the Model */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CLOSE',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
  ]

  return (
    <Modal
      visible={isVisible}
      title={'View Elections - ' + investorName}
      buttonFunctions={buttonFunctions}
      disableScrollbar={false}
      showCloseButtonInTitle={true}
      preventModalClose={true}
    >
      {isLoading && (
        <div>
          <>Loading...</>
        </div>
      )}
      {!isLoading && (
        <div className='investor-view-elections-container'>
          <div className='grid'>
            {/* load the investor general info stateOfResidency and entityType */}
            <InvestorGeneralReview />
            {/* load the composite state elections */}
            <InvestorElectionsCard
              isComposite={true}
              investorElectionsInfoId={investorElectionsInfoId}
              entityOptionsId={undefined}
              entityName='Composite Elections'
            />
          </div>
          {/* load the withholding state elections for each entity*/}
          <InvestorWithholdingReview
            investorElectionsInfoId={investorElectionsInfoId}
          />
        </div>
      )}
    </Modal>
  )
}
