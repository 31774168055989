import { OidcIdentityContext, UserRoles, useUserRoles as useCemUserRoles } from '@rsmus/react-auth'
import { useCallback, useContext } from 'react'
import { REACT_APP_CEM_ROLES_AUTHORITY } from '../envVariables'


/** Returns function that allow easy access to user role resolution. */
export const useUserRoles = () => {
    const { user } = useContext(OidcIdentityContext)
    // Because we're blocking useUserRoles in the definition, we need to recast to any so we don't get blocked here.
    const useUserRolesAny = useCemUserRoles as any
    const userRoles = useUserRolesAny({ user: user!, rolesAuthority: REACT_APP_CEM_ROLES_AUTHORITY, rolesAuthorityType: 'CEM' }) as UserRoles

    /** Returns all roles found in CEM for the user. */
    const getRoles = useCallback(() => {
        const result = [] as string[]

        // Add all of the role names to the return list.
        for (let n in userRoles.cemRoles) {
            const roles = userRoles.cemRoles[n as string].map(x => x.role)
            roles.forEach(r => result.push(...r))
        }

        // Remove duplicates.
        const unique = result.reduce((p, c) => {
            if (!p.includes(c)) {
                p.push(c)
            }
            return p
        }, [] as string[])

        return unique
    }, [userRoles.cemRoles])

    /** Returns a boolean value indicating whether or not the current user has a role of a specified name in CEM. */
    const hasRole = useCallback(
        (roleName: string) => getRoles().includes(roleName)
        , [getRoles])

    return {
        /** Returns all roles found in CEM for the user. */
        getRoles,
        /** Returns a boolean value indicating whether or not the current user has a role of a specified name in CEM. */
        hasRole
    }
}