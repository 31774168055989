import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react'
import { Grid } from '@mui/material'
import {
  Form,
  ButtonItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { dgheight, ICellData } from '../../model/file-model'
import {
  DataGrid,
  Column,
  Scrolling,
  LoadPanel,
  PatternRule,
} from 'devextreme-react/data-grid'
import { VerifyUserViewModel } from '../../api-client/investor-portal-client'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import './verifyUsers.scss'
import {
  multipleEmailOrDomainPattern,
  notMultipleDomainOnly,
} from '../../utilities/email-validation'
import { verifyUserApiRequest } from './verify-user-response-streaming'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { LoadIndicator } from 'devextreme-react'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

type ViewModelDataRow = VerifyUserViewModel & { rowId: number }

/** Textbox Change event */
interface ITextBoxEvent {
  value: string
  name: string
}

// styling for rendering missing/noresult values for datagrid cell
const setNoDataStatus = (cellData: ICellData<File>) => {
  if (cellData.rowType === 'data' && cellData.value) {
    let cellClass = ''
    if (cellData.value === 'No Results') {
      cellClass = 'no-data-text'
    } else if (cellData.value === 'Missing') {
      cellClass = 'no-data-text'
    } else if (cellData.value === 'No CEM Org or Partnership Client') {
      cellClass = 'no-data-text'
    }
    return (
      <span title={cellData.value} className={cellClass}>
        {cellData.value}
      </span>
    )
  }
  return ''
}

export const VerifyUsers = () => {
  const formRef = useRef<Form>(null)
  const [disableEmail, setDisableEmail] = useState<boolean>(true)
  const [clientFormInfo] = useState({ email: '' })
  const [email, setEmail] = useState<string>('')
  const [dataSource, setDataSource] = useState<DataSource>()
  const tableRef = useRef<DataGrid>(null)
  /** Boolean value indicating whether or not the streaming response is still loading. */
  const [isLoading, setIsLoading] = useState<boolean>(false)
  /** AbortController used to cancel a verity user request if we're finished with it. */
  const requestCancelation = useRef<AbortController>()

  /** If any verify user requests are in flight, this will cancel them. */
  const cancelInflightRequests = useCallback(() => {
    // Set the loading state.  We probably don't want to do this here for real,
    //  but it seems like the right thing to do.
    setIsLoading(false)

    // Cancel the request, if there is one in flight.
    if (requestCancelation.current) {
      requestCancelation.current.abort(DISMOUNT_ABORT_REASON)
    }

    // Clear the value, since we can't cancel it again.
    requestCancelation.current = undefined
  }, [])

  useEffect(() => {
    // If we're leaving the page, and there are in-flight requests, then we need to cancel them.
    return () => cancelInflightRequests()
  }, [cancelInflightRequests])

  /** Validate form and Call api method */
  const handleSubmit = useCallback(
    async (e?: React.FormEvent) => {
      if (formRef.current?.instance.validate().isValid) {
        setEmail(clientFormInfo.email)
      }

      // Scroll to the top of the table.  If we don't, then it's possible to get
      //  a bunch of "skeleton" objects as placeholders in the missing rows of the
      //  newly incoming data.
      tableRef.current?.instance.getScrollable().scrollTo({ top: 0 })

      // Cancel any in-flight requests.
      cancelInflightRequests()

      // Indicate that we're loading data now.
      setIsLoading(true)

      // Start the API request.  IMPORTANT!!  This request streams the response
      //  and returns tools to work with the in-flight request.  Note that the
      //  call returns BEFORE the actual request is complete in this case.
      const requestInfo = await verifyUserApiRequest(clientFormInfo.email)

      // Create a new data store.  Doing it with an ArrayStore and DataSource
      //  is important, because doing it in almost any other way causes the
      //  table to blink.
      const newDataSet = new ArrayStore({
        data: [] as ViewModelDataRow[],
        key: 'rowId',
      })
      const newDataSource = new DataSource({
        store: newDataSet,
        reshapeOnPush: true,
      })

      // Set the new data source for the resutls table.
      setDataSource(newDataSource)

      // This counter is used for providing a key/ID for each result in the data, and
      //  helps to prevent blinking.
      let counter = 0

      // Subscribe to the observable to respond to each result value that comes
      //  through the streaming response.
      requestInfo.data.subscribe({
        next: (x) => {
          // We have another new result.  Add it to the data set.
          newDataSet.push([
            {
              type: 'insert',
              data: { ...x, rowId: counter },
              key: counter++,
            },
          ])
        },
        error: (err) => {
          // Since we're in an error state, we should stop trying to receive new data.
          cancelInflightRequests()
          setIsLoading(false)
        },
        complete: () => {
          // Indicate that we're done loading.
          setIsLoading(false)
        },
      })

      // Set the abort controller, in case we need to cancel our call.
      requestCancelation.current = requestInfo.cancelation
    },

    // We do NOT want to include the cancelation token here, since we'll get a new one
    //  for each request, and those are fired from this function.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientFormInfo.email]
  )

  //function for enabling and disabling email
  const textBoxEditorOptions = useMemo(
    () => ({
      // Press 'Enter' key in the email textbox
      // state changes for disbling submit
      onEnterKey: () => {
        handleSubmit()
      },
      onValueChanged: (e: ITextBoxEvent) => {
        setDisableEmail(!formRef.current?.instance.validate().isValid)
      },
    }),
    [handleSubmit]
  )

  // Creates a unique key for a cell in the data grid, to prevent flickering.
  // two rows with same key leads to flickering issue, in order to make it unique we take row ID from loadData() response and attach column name in order to make it unique
  const getId =
    (prefix: string) =>
    (data: ICellData<VerifyUserViewModel & { rowId: number }>) =>
      `${prefix}-${data.data?.rowId}`

  return (
    <div className='verify-user-container'>
      <div className='verify-user-table'>
        <h3>Verify User</h3>
        <Grid container item justifyContent={'flex-start'}>
          <Grid item xs={8}>
            <div className='form-container form-scroll-horizontal'>
              <Form
                ref={formRef}
                formData={clientFormInfo}
                colCount={4}
                readOnly={false}
                showColonAfterLabel={false}
                showValidationSummary={false}
                labelLocation='left'
                labelMode='hidden'
              >
                <SimpleItem
                  colSpan={2}
                  dataField='email'
                  data-testid='verify-users'
                  editorType='dxTextBox'
                  editorOptions={textBoxEditorOptions}
                >
                  <RequiredRule message='Email is invalid' />
                  <PatternRule
                    ignoreEmptyValue={false}
                    message='Email is invalid'
                    pattern={multipleEmailOrDomainPattern}
                  />
                  <PatternRule
                    ignoreEmptyValue={true}
                    message='Only one domain per search'
                    pattern={`${notMultipleDomainOnly}`}
                  />
                </SimpleItem>

                <ButtonItem
                  horizontalAlignment='left'
                  buttonOptions={{
                    className: 'button-verifyUser',
                    text: 'Submit',
                    type: 'default',
                    useSubmitBehavior: true,
                    disabled: disableEmail,
                    'data-testid': 'submit-button',
                    onClick: () => {
                      handleSubmit()
                    },
                  }}
                />
              </Form>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='ip-table'>
        <div>
          {isLoading && (
            <div className='loading-row'>
              <div>
                <LoadIndicator id='medium-indicator' height={40} width={40} />
              </div>
              <div>Loading...</div>
            </div>
          )}
        </div>
        <DataGrid
          showBorders={true}
          keyExpr='rowId'
          dataSource={dataSource}
          columnAutoWidth={false}
          height={dgheight}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={true}
          wordWrapEnabled={false}
          onCellPrepared={addToolTipOnCellPrepared}
          ref={tableRef}
        >
          <Scrolling mode='infinite' rowRenderingMode='virtual' />
          <LoadPanel enabled={true} />
          <Column
            dataField='emailAddress'
            caption='Email Address'
            alignment='left'
            width={'20%'}
            allowSorting={false}
            cellKeyFn={getId('email')}
          />
          <Column
            dataField='companyId'
            caption='Company ID'
            alignment='left'
            allowSorting={false}
            width={'10%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('companyId')}
          />
          <Column
            dataField='companyName'
            caption='Entity Name'
            alignment='left'
            allowSorting={false}
            width={'20%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('companyName')}
          />
          <Column
            dataField='userStatus'
            caption='CEM Info'
            alignment='left'
            allowSorting={false}
            width={'8%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('userStatus')}
          />
          <Column
            dataField='partnershipClientStatus'
            caption='Allocating Entity Info '
            alignment='left'
            allowSorting={false}
            width={'8%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('partnershipClientStatus')}
          />
          <Column
            dataField='clientGroup'
            caption='Client Name'
            alignment='left'
            allowSorting={false}
            width={'12%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('clientGroup')}
          />
          <Column
            dataField='entityName'
            caption='Investor Name'
            alignment='left'
            allowSorting={false}
            width={'15%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('entityName')}
          />
          <Column
            dataField='entityId'
            caption='Entity ID'
            alignment='left'
            allowSorting={false}
            width={'7%'}
            cellRender={setNoDataStatus}
            cellKeyFn={getId('entityId')}
          />
        </DataGrid>
      </div>
    </div>
  )
}
