

/** The list of known types for IDM statuses.  More may exist, but we only need a few in the app. */
export enum IdmUserStatusTypes {
    ACTIVE = 'ACTIVE',
    PROVISIONED = 'PROVISIONED',
    DEPROVISIONED = 'DEPROVISIONED',
    PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
    RECOVERY = 'RECOVERY', // Recovery password was sent.
}

export const idmUserStatusTypes = Object.values(IdmUserStatusTypes)