
let counts = {} as { [k: string]: number }

/** This will clear the counters used in the isLatestApiCall and getApiCallId methods.  This is used
 *   ONLY for unit tests and should not be used in actual code.
 */
export const TESTING_clearApiCounters = () => {
    counts = {}
}

export const API_ID_WRAPPER_PROPERTY_NAME = 'API_WRAPPER_NAME'

/** Returns the "Wrapper Name" of a specified function.  If a string is passed to this function
 *   then the string is returned.  This is a passthrough for usage in the isLatestApiCall and getApiCallId
 *   methods.  See the setApiWrapperName method for more details about the "Wrapper Name".
 */
export function getApiWrapperName(target: Function | string): string | undefined {
    // If a string was passed to the function then just return it as-is.
    if (typeof target === 'string') {
        return target
    }

    // Recast the target to appease the TypeScript error checking.
    const targetRecast = target as { [n: string]: any }
    // Return the wrapper name property from the target.
    return targetRecast[API_ID_WRAPPER_PROPERTY_NAME] as string | undefined
}

/** This sets a "wrapper name" on a specified function, which should be unique, so that it
 *   can be identified later when using the getApiCallId.  Since the "name" property of functions
 *   are not guaranteed to be set, and they are readonly, we are not able
 *   to use it as an identifier.  This name takes the place of that. */
export function setApiWrapperName(target: Function, name: string) {
    // Recast the target to appease the TypeScript error checking.
    const targetRecast = target as { [n: string]: any }
    // Set the wrapper name property from the target.
    targetRecast[API_ID_WRAPPER_PROPERTY_NAME] = name
}

export interface ApiCallId {
    id: number
    functionReference: Function | string
}

/** Given a specified function (using it's "Wrapper Name"), returns a sequential integer value
 *   used to indicate if it is the latest call, using the isLatestApiCall method.
 *   The actual return value includes the original function to remove the need to
 *   include it in the isLatestApiCall.
 *  A string may be passed to this as an identifier instead of the function.  That string should be unique for the function
 *   call being guarded.
 */
export function getApiCallId(apiFunctionOrName: Function | string): ApiCallId {
    // Get the wrapper name for the function being passed in.  If a string was provided
    //  this call will return the string back.
    const functionName = getApiWrapperName(apiFunctionOrName)
    if (!functionName || functionName.trim() === '') {
        throw new Error(`Function does not have a wrapper name: ${API_ID_WRAPPER_PROPERTY_NAME}`)
    }

    // Find the call number for this API call.  This is just a counter
    //  and every call for a given function name will have a new value which
    //  is just the previous value incremented by 1.
    const newValue = (counts[functionName] ?? 0) + 1
    // Update the cached value for this function name to service the next call.
    counts[functionName] = newValue

    // Return the result.  We include the function name so the later call to
    //  isLatestApiCall will already have this information and we don't have to provide it again.
    return {
        id: newValue,
        functionReference: apiFunctionOrName
    }
}

/** Returns a boolean value indicating whether or not a the current function call was the latest instance
 *   called by comparing values returned from the getApiCallId method.  Given the same function (based on name)
 *   this indicates if a the function has been invoked again since when getApiCallId had been used to obtain the id
 *   used for comparison.
 */
export function isLatestApiCall(startId: ApiCallId): boolean {
    // Get the name of the function using the functionReference in the startId information.
    const functionName = getApiWrapperName(startId.functionReference)

    // If we don't have an identifier, then we have a problem.
    if (!functionName || functionName.trim() === '') {
        throw new Error(`Function does not have a wrapper name: ${API_ID_WRAPPER_PROPERTY_NAME}`)
    }

    // Return whether or not the current function call ID is the same
    //  as the last one for this function.
    return counts[functionName] === startId.id
}