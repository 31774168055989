import React from 'react'
import { Button, DataGrid } from 'devextreme-react'
import './clear-filters-button.scss'

export interface ClearFiltersButtonProps {
  gridRef: React.RefObject<DataGrid<any, any>>
  onClicked?: () => void
  className?: string
}

export const ClearFiltersButton = ({
  gridRef,
  onClicked,
  className,
}: ClearFiltersButtonProps) => {
  const baseClassName = 'button-clear-filters'

  const actClassName = className
    ? className + ' ' + baseClassName
    : baseClassName

  return (
    <Button
      elementAttr={{
        'data-testid': 'buttonClearFilters',
      }}
      data-testid='buttonClearFilters'
      className={actClassName}
      text='CLEAR FILTERS'
      type='normal'
      stylingMode='text'
      onClick={() => {
        gridRef.current?.instance.clearFilter('header')
        if (onClicked) {
          onClicked()
        }
      }}
    />
  )
}
