import { ReactNode, useEffect, useState } from 'react'
import { SpinnerModal } from '../modal/spinnerModal'
import { ProvisionUserWatcher } from '../../signalr/watcher/provision-user-watcher'
import { useRecoilValue } from 'recoil'
import './provisionUsers.scss'
import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { clientAndPartnershipStateAtom } from '../../state/atom'
import { provisionUsersStatusMessages } from './provision-users-common-items'
import { ProvisionUsersFailedContent } from './provisionUsersFailedContent'
import { isProvisionUserHeader } from '../utility/type-guards'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface StateManagerProvisionUsersProps {
  isVisible: boolean
  selectionCacheId?: string
  investorId: number | undefined
  allocatingEntityIds: number[] | undefined
  entityGroupId: number
  onClose: () => void
}

export interface ModalDimensionProps {
  width?: number
  height?: number
}

/** State Manager - Provision Users modal */
export const StateManagerProvisionUsers = ({
  isVisible,
  selectionCacheId,
  investorId,
  allocatingEntityIds,
  entityGroupId,
  onClose,
}: StateManagerProvisionUsersProps) => {
  // API hook to make API call
  const { provisionClient } = useStateManagerApi()
  const { partnership } = useRecoilValue(clientAndPartnershipStateAtom)
  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const [errorTitle, setErrorTitle] = useState<string>(
    provisionUsersStatusMessages.errorTitleMessage
  )
  const [errorDetail, setErrorDetail] = useState<ReactNode | undefined>()
  const [modalDimension, setModalDimension] = useState<ModalDimensionProps>({})

  //update Modal after processing the provision
  const updateErrorForPartialFailure = (
    provisionUserHeader: ProvisionUserHeader
  ) => {
    //set modal dimension for partial failure
    setModalDimension({
      width: 800,
      height: 400,
    })
    // setErrorTitle for partial failure
    setErrorTitle(
      provisionUsersStatusMessages.errorTitleMessageOnPartialFailure
    )
    // Set UI modal to display failed provision users list
    setErrorDetail(
      <ProvisionUsersFailedContent
        provisionUserHeader={provisionUserHeader}
        partnership={partnership}
      />
    )
  }

  /** Perform user provisioning */
  const performProvisioning = async (abortSignal?: AbortSignal) => {
    try {
      const uniqueOperationId = Math.floor(Math.random() * 100000).toString()
      //invoke promise for provision users
      let provisionUsersPromise = provisionClient(
        selectionCacheId!,
        entityGroupId,
        uniqueOperationId,
        investorId!,
        allocatingEntityIds!,
        abortSignal
      )

      // Initialize watcher for provision users
      let provisionUserWatcher = new ProvisionUserWatcher(provisionUsersPromise)
      provisionUserWatcher.initialize()

      // set promise in state
      setProvisionPromise(provisionUserWatcher.promise)

      // Wait for the promise to complete.
      // This is required to handle errors in catch block
      await provisionUserWatcher.promise
    } catch (err: any) {
      // If the watcher throws an error with a ProvisionUserHeader, then
      //  this indicates a partial failure, and we need to set the
      //  spinner's error content appropriately.
      if (isProvisionUserHeader(err)) {
        updateErrorForPartialFailure(err)
        //set modal dimension for partial failure
        setModalDimension({
          width: 800,
        })
      } else {
        // Since we don't have ProvisionUserHeader, unexpected error occurred
        // Set the spinner  promise to display error
        setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
        setErrorDetail(`Error: ${err.message}`)

        setProvisionPromise(Promise.reject(err))

        //re-set modal dimension for default view
        setModalDimension({
          width: undefined,
        })
      }
    }
  }

  /** Call ProvisionUsers API method */
  useEffect(() => {
    const abortController = new AbortController()

    // We only want to execute the provisioning of users when the
    //  dialog is visible, since the control will likely always exist
    //  on the page, even if hidden due to isVisible === false.
    if (isVisible) {
      //invoke doProvisionUsers
      performProvisioning(abortController.signal)
    } else {
      setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
      //reset ui for error state
      setErrorDetail(undefined)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isVisible, entityGroupId, selectionCacheId, investorId])

  return (
    <SpinnerModal
      visible={isVisible}
      inProgressTitleMessage={
        provisionUsersStatusMessages.inProgressTitleMessage
      }
      inProgressMessage={provisionUsersStatusMessages.inProgressMessage}
      successTitleMessage={provisionUsersStatusMessages.successTitleMessage}
      successMessage={provisionUsersStatusMessages.successMessage}
      errorTitleMessage={errorTitle}
      errorMessage={errorDetail}
      width={modalDimension.width}
      onClose={() => {
        onClose()
      }}
      apiAction={provisionPromise}
    />
  )
}
