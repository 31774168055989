import { ProgressBar } from 'devextreme-react'
import Notify from 'devextreme/ui/notify'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ClearIcon from '@mui/icons-material/Clear'
import './fileUpload.scss'
import { getByteSizeFromBytes } from '../utility/file-utilities'
import { Observable } from 'rxjs'
import { FileUploadState, FileUploadStates } from './file-upload-model'
import { useFileUploadStateInternal } from './use-file-upload-state'
import produce from 'immer'

interface FileInfo {
  /** Gets or sets the selected file for the control. */
  file?: File
  /** Gets or sets the string representation of the file's size. */
  fileSize?: string
}

/** Provides details about which files are accepted in the file upload, and
 *   the text to be shown to the user about the criteria. */
export interface FileAcceptanceDetails {
  /**
   *  Passed to the `accept` attribute of the input[tye=file] control.
   *   See `https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept` for more details.
   */
  acceptTypes?: string
  /**
   *  Message to display, inside the control, about what file types, and/or other details,
   *   constitute a valid file.
   */
  acceptanceMessage?: string

  /** Max size of the file. */
  maxFileSize?: number
}

const uploadStateDefaultValue: FileUploadState = {
  state: 'Nothing Selected',
  progress: 0,
}

export interface FileUploadProps {
  /** Optional key when state needs to be retained as a Recoil state.  This value
   *   comes from the useFileUploadState hook. Some uses of this component destroy and recreate
   *   the component prematurely.  This is a workaround for that. */
  stateKey?: number
  /** Function called when a file is selected by this control. */
  setFileSelection?: (file: File | undefined) => void
  /** Observable that will trigger the upload when it signals. */
  uploadFileTrigger?: Observable<void>
  /** Function to call to upload the file. */
  uploadFileFn?: (progress: (bytesSent: number) => void) => Promise<void>
  /** Defines criteria for validating a file selected by the user. */
  fileAcceptance?: FileAcceptanceDetails
  /** Optional function to call if an error occurs while the file is uploaded. */
  onUploadError?: (err: any) => void
  /** Optional function to call on successful upload of file */
  onUploadComplete?: () => void
}

export const FileUpload = ({
  stateKey,
  setFileSelection,
  uploadFileTrigger,
  uploadFileFn,
  fileAcceptance,
  onUploadError,
  onUploadComplete,
}: FileUploadProps) => {
  const isUploadCalledRef = useRef(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  // Get the state elements from the hook.  These will either use a
  //  the local useState or recoil state, depending on whether or not
  //  a stateKey is provided.  NOTE: That key should come from the useFileUploadState
  //  hook, by a higher level component.
  const [fileUploadState, setComponentState] =
    useFileUploadStateInternal(stateKey)

  // Create a function to update the state and progress values of the component's state.
  const setFileUploadState = useCallback(
    ({ state, progress }: { state: FileUploadStates; progress: number }) => {
      setComponentState(
        produce((draft) => {
          draft.progress = progress
          draft.state = state
        })
      )
    },
    [setComponentState]
  )

  // Local state equivalents of the file information (both get and set mechanics).
  const selectedFile: FileInfo = {
    file: fileUploadState.file,
    fileSize: fileUploadState.fileSize,
  }
  const setSelectedFileState = useCallback(
    (fileInfo?: FileInfo) => {
      setComponentState(
        produce((draft) => {
          draft.file = fileInfo?.file
          draft.fileSize = fileInfo?.fileSize
        })
      )
    },
    [setComponentState]
  )

  /** Boolean value indicating whether or not the user can make changes on the control. */
  const isControlEditable =
    (fileUploadState?.state === 'Nothing Selected' ||
      fileUploadState?.state === 'Selected' ||
      fileUploadState?.state === 'Error') &&
    !isUploadCalledRef.current

  let disabledClass = isControlEditable ? '' : 'disabled'


  /** Handles opening the file selection dialog when the "edit" text is clicked. */
  const onEditTextClicked = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault()
    // Exit if the page is not editable.
    if (!isControlEditable) {
      return
    }

    fileInputRef.current!.click()
  }

  /** Validates the selected file, returning a boolean value indicating whether or
   *   not the file is valid, and also shows a toast popup if it is not. */
  const validateFile = useCallback(
    (f: File) => {
      // Perform validation on the file type.
      if (
        fileAcceptance?.acceptTypes &&
        fileAcceptance.acceptTypes.trim() !== ''
      ) {
        const lcaseName = f.name.toLowerCase()
        // Find at least one extension in the criteria
        //  that matches the file's extension.  Return that result.
        const result = fileAcceptance.acceptTypes
          .toLowerCase()
          .split(',')
          .some((ext) => {
            const fileExtM = /\.([^.]+)$/i.exec(lcaseName)
            return fileExtM && fileExtM[0] === ext.trim()
          })

        // Show the snackbar with the error message if we didn't find a matching file type.
        if (!result) {
          Notify(
            {
              message: 'File type is invalid.',
              type: 'error',
            },
            undefined,
            3500
          )
          return result
        }
      }

      // Perform validation on the file size.
      if (fileAcceptance?.maxFileSize) {
        if (f.size > fileAcceptance!.maxFileSize) {
          Notify(
            {
              message: 'File size is too large.',
              type: 'error',
            },
            undefined,
            3500
          )
          return false
        }
      }

      return true
    },
    [fileAcceptance]
  )

  /** Handles selection of a new file, whether through the input element
   *   or through drag/drop. */
  const setSelectedFile = useCallback(
    (files: FileList | undefined | null) => {
      if (files?.length) {
        // If the file is not valid, then exit.
        if (!validateFile(files[0])) {
          return
        }
        setSelectedFileState({
          file: files[0],
          fileSize: getByteSizeFromBytes(files[0].size),
        })
        setFileUploadState({
          progress: 0,
          state: 'Selected',
        })
        if (setFileSelection) {
          setFileSelection(files[0])
        }
      } else {
        setSelectedFileState(undefined)
        if (setFileSelection) {
          setFileSelection(undefined)
        }
      }
    },
    [validateFile, setSelectedFileState, setFileUploadState, setFileSelection]
  )

  /** Callback to handle when the progress of the file upload changes. */
  const uploadProgressFn = useCallback(
    (bytes: number) => {
      if (bytes === selectedFile?.file?.size) {
        setFileUploadState({
          progress: 100,
          state: 'Upload Complete',
        })
      } else {
        setFileUploadState({
          progress: bytes,
          state: 'Loading',
        })
      }
    },
    [selectedFile?.file?.size, setFileUploadState]
  )

  // Handles subscribing and unsubscribing to the observable that fires when the owning control
  //  wishes to start the upload of the file.
  useEffect(() => {
    if (uploadFileTrigger && uploadFileFn) {
      const unSub = uploadFileTrigger.subscribe(async () => {
        // If the upload has already been triggered, then we don't want to
        //  try and do it a second time.
        if (!isUploadCalledRef.current) {
          // Set the flag indicating that the upload has been triggered,
          //  so this method cannot be triggered twice.
          isUploadCalledRef.current = true

          // Update our progress to indicate
          //  that the upload has started.
          setFileUploadState({
            progress: 0,
            state: 'Loading',
          })

          try {
            // Perform the upload.
            await uploadFileFn(uploadProgressFn)

            // The upload is done.
            setFileUploadState({
              progress: 100,
              state: 'Upload Complete',
            })

            //when upload complete
            if (onUploadComplete) {
              onUploadComplete()
            }
          } catch (err) {
            // Uh oh, something happened during the upload.
            console.log(`File upload error: ${err?.toString()}`)
            setFileUploadState({
              progress: 0,
              state: 'Error',
            })
            //reset the ref current value to false so we can upload again
            isUploadCalledRef.current = false
            // Call the error function if it was provided.
            if (onUploadError) {
              //setting the disableClass to '' so upload control will be in enable state
              disabledClass=''        
              onUploadError(err)
            }
          }
        }
      })

      return () => unSub.unsubscribe()
    }
  }, [
    uploadFileTrigger,
    uploadFileFn,
    uploadProgressFn,
    setFileUploadState,
    onUploadComplete,
    onUploadError,
  ])

  /** Clears the selected file.  Used when the user clicks the "x" button on the file display. */
  const clearFileValue = useCallback(() => {
    setFileUploadState(uploadStateDefaultValue)
    setSelectedFileState(undefined)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    if (setFileSelection) {
      setFileSelection(undefined)
    }
  }, [setFileSelection, setFileUploadState, setSelectedFileState])

  /** Handles the drag/drop operation of a file on the file drop target. */
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    // Exit if the page isn't editable.
    if (!isControlEditable) {
      return
    }

    // Get the files from the event.
    const files = event.dataTransfer?.files
    setSelectedFile(files)
  }

  return (
    <div className='file-upload-control'>
      <div className={disabledClass}>
        <div
          className='file-upload-control-container'
          onDrop={(e) => onDrop(e)}
          onDragOver={(e) => {
            e.preventDefault()
          }}
        >
          <input
            ref={fileInputRef}
            type='file'
            onChange={(e) => setSelectedFile(e.target.files)}
            id='file-input'
            accept={fileAcceptance?.acceptTypes}
          />
          <div className='rounded-icon with-margined'>
            <UploadFileIcon></UploadFileIcon>
          </div>
          <div>
            <span
              data-testid='upload-drop-target'
              className={`upload-click-text ${disabledClass}`}
              onClick={(e) => onEditTextClicked(e)}
            >
              Click to upload
            </span>
            <span>or drag and drop</span>
          </div>
          {fileAcceptance?.acceptanceMessage && (
            <div className='file-acceptance-message'>
              {fileAcceptance?.acceptanceMessage}
            </div>
          )}
        </div>
      </div>
      {selectedFile.file && (
        <div className='file-selection-box'>
          <div className='file-selection-box-container'>
            <div className='column-1'>
              <div className='rounded-icon'>
                <UploadFileIcon></UploadFileIcon>
              </div>
            </div>
            <div className='column-2'>
              <div className='file-name'>{selectedFile!.file?.name}</div>
              <div className='file-size-and-state'>
                {selectedFile!.fileSize} . {fileUploadState.state}
              </div>
            </div>
            <div className='column-3'>
              {isControlEditable && (
                <div className='clear-button' onClick={() => clearFileValue()}>
                  <ClearIcon></ClearIcon>
                </div>
              )}
              {!isControlEditable && (
                <div className={`clear-button ${disabledClass}`}>
                  <ClearIcon></ClearIcon>
                </div>
              )}
            </div>
          </div>
          <div className='progress-container'>
            {fileUploadState?.state === 'Loading' && (
              <ProgressBar
                min={0}
                max={selectedFile?.file?.size ?? 100}
                value={fileUploadState.progress ?? 0}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
