import { TagBox } from 'devextreme-react'
import { USState } from '../state-manager/state-manager-utils'
import './investor-state-selections.scss'
import { SelectionChangedEvent } from 'devextreme/ui/tag_box'

export interface InvestorStateDisplayProps {
  states: USState[]
  selectedStateCodes: string[] | undefined
  isDisable?: boolean
  height?: string | number
  updateSelectedKeys: (selectedKeys: string[]) => void
}

/** Display states  using  TagBox control*/
export const InvestorStateDisplay = ({
  states,
  selectedStateCodes,
  isDisable,
  height,
  updateSelectedKeys,
}: InvestorStateDisplayProps) => {
  /** Remove the state code from the state when user click on remove icon */
  const onSelectionChanged = (e: SelectionChangedEvent) => {
    if (e.removedItems && e.removedItems.length > 0) {
      updateSelectedKeys(
        selectedStateCodes?.filter(
          (stateCode) => stateCode !== e.removedItems[0].stateCode
        )!
      )
    }
  }
  return (
    <div className={isDisable ? 'tag-box-disabled' : 'tag-box-enabled'}>
      <TagBox
        dataSource={states}
        height={height}
        valueExpr='stateCode'
        displayExpr='name'
        multiline={true}
        value={selectedStateCodes}
        onSelectionChanged={onSelectionChanged}
        placeholder='No Records'
        disabled={isDisable}
      />
    </div>
  )
}
