import { useMainNav } from '../../../hooks/use-main-nav'
import { IconsType, NavItemProps } from './LeftNav'
import { NavList } from './navList'
import { NavLink } from './nav-link'

export const materialIconsType = (type: IconsType) =>
  type === 'filled' ? 'material-icons' : 'material-icons-outlined'

//Build NavTree for SideNav
export const NavTree = () => {
  // get navdata from a hook
  const navData = useMainNav()

  //if navData undefined then stop here
  if (!navData) return null

  //if some data available in the nav data then start here
  const { default: defaultRoute, menu } = navData

  //make new array with menu items which are not having property "visible === false"
  const menuVisible = menu.filter((each) => each.visible !== false)

  //sub menu
  const subMenu = menuVisible.map((i: NavItemProps, index: number) => (
    <li key={index}>
      {/** Some front-end nav-icons may lack a 'path'. For a smooth user experience,
       * ensure the 'to' attribute always has a valid value and avoids 'null' or 'undefined'.
       * We're using '!' as an alternative to preserve the just-prev-value.
       */}
      <NavLink
        to={i.path!}
        children={
          <>
            <span className={materialIconsType(defaultRoute.iconsType)}>
              {i.icon}
            </span>
            <span className='nav-name'>{i.title}</span>
          </>
        }
      ></NavLink>
      {i.menu && NavList(i.menu, i.title, defaultRoute.iconsType)}
    </li>
  ))

  return (
    <nav>
      <ul className='nav-list nav-list_lvl-one'>
        <li>
          {/** Some front-end nav-icons may lack a 'path'. For a smooth user experience,
           * ensure the 'to' attribute always has a valid value and avoids 'null' or 'undefined'.
           * We're using '!' as an alternative to preserve the just-prev-value.
           */}
          <NavLink
            to={defaultRoute.path!}
            children={
              <>
                <span className={materialIconsType(defaultRoute.iconsType)}>
                  {defaultRoute.icon}
                </span>
                <span className='nav-name'>{defaultRoute.title}</span>
              </>
            }
          ></NavLink>
        </li>
        {subMenu}
      </ul>
    </nav>
  )
}
