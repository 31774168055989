import moment from "moment"
import { EmailStatusTypes, EmailTemplate } from "../../api-client/investor-portal-client"
import { DateFormat } from '../../model/date-formats'
import {  CommonFilterHeader } from '../../client-models/clientmodels'

/** Tab properties */
export interface ITabDetail {
  id: number
  text: string
  content: any
}

/** State manager component modified or not */
export interface SMDirty {
  isStateSelectionDirty: boolean
  isInvestorSelectionDirty: boolean
  isGeneralDirty: boolean
  isNotificationDirty: boolean
}

/** Get default SM dirty flag */
export const getDefaultSMDirtyFlag = () => ({
  isStateSelectionDirty: false,
  isInvestorSelectionDirty: false,
  isGeneralDirty: false,
  isNotificationDirty: false
} as SMDirty)

/** Function to check Arrays are same */
export function areArraysEqual(array1: any[], array2: any[]): boolean {
  if (array1.length !== array2.length) {
    return false
  }
  return array1.every(x => array2.includes(x))
}

// interface for all US state
export interface USState {
  name: string
  stateCode: string
  isSelected: boolean
}

//All US States with the stateCode and name and their selected status
export const usStatesData: USState[] = [
  { stateCode: 'AL', name: 'Alabama', isSelected: false },
  { stateCode: 'AK', name: 'Alaska', isSelected: false },
  { stateCode: 'AZ', name: 'Arizona', isSelected: false },
  { stateCode: 'AR', name: 'Arkansas', isSelected: false },
  { stateCode: 'CA', name: 'California', isSelected: false },
  { stateCode: 'CO', name: 'Colorado', isSelected: false },
  { stateCode: 'CT', name: 'Connecticut', isSelected: false },
  { stateCode: 'DC', name: 'Washington DC', isSelected: false },
  { stateCode: 'DE', name: 'Delaware', isSelected: false }, 
  { stateCode: 'FL', name: 'Florida', isSelected: false },
  { stateCode: 'GA', name: 'Georgia', isSelected: false },
  { stateCode: 'HI', name: 'Hawaii', isSelected: false },
  { stateCode: 'ID', name: 'Idaho', isSelected: false },
  { stateCode: 'IL', name: 'Illinois', isSelected: false },
  { stateCode: 'IN', name: 'Indiana', isSelected: false },
  { stateCode: 'IA', name: 'Iowa', isSelected: false },
  { stateCode: 'KS', name: 'Kansas', isSelected: false },
  { stateCode: 'KY', name: 'Kentucky', isSelected: false },
  { stateCode: 'LA', name: 'Louisiana', isSelected: false },
  { stateCode: 'ME', name: 'Maine', isSelected: false },
  { stateCode: 'MD', name: 'Maryland', isSelected: false },
  { stateCode: 'MA', name: 'Massachusetts', isSelected: false },
  { stateCode: 'MI', name: 'Michigan', isSelected: false },
  { stateCode: 'MN', name: 'Minnesota', isSelected: false },
  { stateCode: 'MS', name: 'Mississippi', isSelected: false },
  { stateCode: 'MO', name: 'Missouri', isSelected: false },
  { stateCode: 'MT', name: 'Montana', isSelected: false },
  { stateCode: 'NE', name: 'Nebraska', isSelected: false },
  { stateCode: 'NV', name: 'Nevada', isSelected: false },
  { stateCode: 'NH', name: 'New Hampshire', isSelected: false },
  { stateCode: 'NJ', name: 'New Jersey', isSelected: false },
  { stateCode: 'NM', name: 'New Mexico', isSelected: false },
  { stateCode: 'NY', name: 'New York', isSelected: false },
  { stateCode: 'NC', name: 'North Carolina', isSelected: false },
  { stateCode: 'ND', name: 'North Dakota', isSelected: false },
  { stateCode: 'OH', name: 'Ohio', isSelected: false },
  { stateCode: 'OK', name: 'Oklahoma', isSelected: false },
  { stateCode: 'OR', name: 'Oregon', isSelected: false },
  { stateCode: 'PA', name: 'Pennsylvania', isSelected: false },
  { stateCode: 'RI', name: 'Rhode Island', isSelected: false },
  { stateCode: 'SC', name: 'South Carolina', isSelected: false },
  { stateCode: 'SD', name: 'South Dakota', isSelected: false },
  { stateCode: 'TN', name: 'Tennessee', isSelected: false },
  { stateCode: 'TX', name: 'Texas', isSelected: false },
  { stateCode: 'UT', name: 'Utah', isSelected: false },
  { stateCode: 'VT', name: 'Vermont', isSelected: false },
  { stateCode: 'VA', name: 'Virginia', isSelected: false },
  { stateCode: 'WA', name: 'Washington', isSelected: false },
  { stateCode: 'WV', name: 'West Virginia', isSelected: false },
  { stateCode: 'WI', name: 'Wisconsin', isSelected: false },
  { stateCode: 'WY', name: 'Wyoming', isSelected: false },
]

/**compare the email templates subject and body with original object's subject and body and return in boolean */
export const isEmailTemplateChangeFound = (emailtemplateItem: EmailTemplate | undefined,
  updatedEmailTemplateItem: EmailTemplate | undefined) =>
  emailtemplateItem?.subject !== updatedEmailTemplateItem?.subject || emailtemplateItem?.body !== updatedEmailTemplateItem?.body


// font size selection option for HTML Editor
export const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']

//Toolbar items assigned in Html Editor
export const toolbarItems = [
  {
    name: 'size',
    icon: 'size',
    acceptedValues: sizeValues,
    options: { height: 30, width: 75 },
  },
  { name: 'separator', icon: 'separator' },
  { name: 'bold', icon: 'bold' },
  { name: 'color', icon: 'color' },
  { name: 'separator', icon: 'separator' },
  { name: 'alignleft', icon: 'alignleft' },
  { name: 'alignCenter', icon: 'alignCenter' },
  { name: 'alignRight', icon: 'alignRight' },
  { name: 'alignJustify', icon: 'alignJustify' },
  { name: 'orderedList', icon: 'orderedList' },
  { name: 'bulletList', icon: 'bulletList' },
  { name: 'separator', icon: 'separator' },
  { name: 'link', icon: 'link' },
]

/** Checkbox Change event */
export interface ICheckBoxEvent {
  value?: any
  previousValue?: any
  element: any
}


//formatting the date and returning 'never' when 'undefined' or in provided date format
export function getFormattedDateWithNeverForEmailSent(lastActivityDate: Date | undefined, format: DateFormat) {
  if (lastActivityDate) {
    const activityDate = new Date(lastActivityDate)
    return activityDate.getFullYear() <= 1
      ? 'never'
      : moment(activityDate.toLocaleString())
        .local()
        .format(format)
  } else {
    return 'never'
  }
}

// Function to format date filters to MM/DD/YYYY. 
// These dates list might not match with db dates as we are converting the date to local date.
export function formatDateFiltersWithNeverForEmailSent(lastActivityDate: string , format: DateFormat) {
   
    const date = new Date(lastActivityDate);
    
    if (isNaN(date.getTime())) {
      // Handle invalid date text, e.g., "never"
      return lastActivityDate
    }    
    const formattedDate = moment(date.toLocaleString().split('T')[0]).format(DateFormat.shortDateFormat)
    return  formattedDate ;
  }


/** Returns the display text for a specified EmailStatusTypes value. */
export const getEmailStatusText = (status: EmailStatusTypes) => {
  switch (status) {
    case EmailStatusTypes.New:
      return 'New'
    case EmailStatusTypes.Building:
      return 'Building Email'
    case EmailStatusTypes.ReadyToSend:
      return 'Waiting To Send'
    case EmailStatusTypes.Sending:
      return 'Sending Email'
    case EmailStatusTypes.ErrorSending:
      return 'Error Sending'
    case EmailStatusTypes.ErrorBuilding:
      return 'Error Building'
    case EmailStatusTypes.Complete:
      return 'Complete'
    default:
      throw new Error(`Unexpected email status type: ${status}`)
  }
}
