
// Since some nav widget levels are dependent on others, we specify them here as constants
//  and then include them into the navWidgetLevels object for convenience.

/** The level to list the client groups that the user has access to. */
export const CLIENT_GROUP_LIST_LEVEL = 0
/** The level to place the menu for the client groups. */
export const CLIENT_GROUP_MENU_LEVEL = CLIENT_GROUP_LIST_LEVEL + 1
/** Level to list the partnerships of the currently selected client group. */
export const PARTNERSHIP_LIST_LEVEL = CLIENT_GROUP_MENU_LEVEL + 1
/** The level to show the list of child files for a specific parent file. */
export const PARENT_FILE_LIST_LEVEL = PARTNERSHIP_LIST_LEVEL + 1
