import produce from 'immer'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { filesClientApi } from '../../../api-client/investor-portal-client-runtime'
import {
  clientAndPartnershipStateAtom,
  entityGroupPartnershipStateAtom,
  PartnershipDetailsList,
} from '../../../state/atom'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'
import { getParentFileListRoute } from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { NavWidgetOption } from '../nav-widget-models'
import { PARTNERSHIP_LIST_LEVEL } from '../widget-levels'

export const usePartnershipsNavWidget = () => {
  const entityGroupPartnership = useRecoilValue(entityGroupPartnershipStateAtom)
  const clientAndPartnerships = useRecoilValue(clientAndPartnershipStateAtom)
  const [partnershipList, setPartnershipList] =
    useState<PartnershipDetailsList>({
      details: [],
      isLoading: false,
    })

  // We need to update our local state of the partnerships for the current
  //  client group.  We cannot use the global state version of this, because
  //  it may be filtered by date, and not show the entire list.
  useEffect(() => {
    const abortController = new AbortController()

    setPartnershipList(
      produce((draft) => {
        draft.isLoading = true
        draft.details = []
      })
    )

    if (!clientAndPartnerships.clientGroupId) {
      return
    }

    filesClientApi
      .entityGroups_GetEntityGroupDetails(
        clientAndPartnerships.clientGroupId,
        undefined,
        abortController.signal
      )
      .then((response) => {
        setPartnershipList({ details: response, isLoading: false })
      })

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [clientAndPartnerships.clientGroupId])

  const navItems: NavWidgetOption[] = useMemo(
    () => {
      if (!clientAndPartnerships.clientGroupId) {
        return []
      }

      return partnershipList.details
        .map((p) => {
          // Create the route that this item will navigate to,
          //  and be selected on.
          const navRoute = getParentFileListRoute(
            clientAndPartnerships.clientGroupId!,
            p.partnershipId!
          )

          return {
            label: p.partnershipName!,
            level: PARTNERSHIP_LIST_LEVEL,
            visibilityPaths: [
              '/admin/groups/:groupId/partnerships/:partnershipId/*',
              '/admin/groups/:groupId/partnerships/:partnershipId',
            ],
            navigationPath: navRoute,
            selectionPath: navRoute + '/*',
          }
        })
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
        )
    },
    // clientAndPartnerships changes with every call so cannot be part of the dependency list.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      partnershipList,
      clientAndPartnerships.clientGroupId,
      clientAndPartnerships.partnershipId,
      entityGroupPartnership,
    ]
  )

  useNavWidgetPageItems(navItems)
}
