import { SignalRController } from "./signalr-controller";

/** Implementation of the SignalRController, specifically for InvestorPortal. */
export class InvestorPortalSignalRController extends SignalRController {

    /** Subscribes to updates for emails to a specified StateManager GeneralOptions on the server. */
    async subscribeToGeneralOptionsEmailUpdates(generalOptionsId: number): Promise<void> {
        await this.connection.send('SubscribeToGeneralOptionsEmailUpdates', generalOptionsId)
    }

    /** Unsubscribes from updates for emails to a specified StateManager GeneralOptions on the server. */
    async unSubscribeFromGeneralOptionsEmailUpdates(generalOptionsId: number): Promise<void> {
        await this.connection.send('UnSubscribeFromGeneralOptionsEmailUpdates', generalOptionsId)
    }
}