import {
  Form,
  GroupItem,
  Item,
  PatternRule,
  RequiredRule,
} from 'devextreme-react/form'
import { ButtonFunction, Modal } from '../../modal/modalBase'
import { useRef } from 'react'
import './add-tax-year-modal.scss'
import { TaxYear } from '../../../api-client/investor-portal-client'

interface AddTaxYearModalProps {
  onCancel: () => void
  onSave: (year: TaxYear) => void
}

/** Add new tax year to Tax year dropdown */
export const AddTaxYearModal = ({ onCancel, onSave }: AddTaxYearModalProps) => {
  const formRef = useRef<Form>(null)
  const taxYear: TaxYear = {}

  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        onCancel()
      },
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Save',
      onClick: () => {
        handleSaveClick()
      },
      visible: true,
      buttonProps: {
        useSubmitBehavior: false,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'save-button',
      },
    },
  ]

  /*** Validate and Call onSave */
  const handleSaveClick = () => {
    // validate form , if its validate then save
    if (!formRef.current?.instance?.validate().isValid) {
      return
    }
    onSave({
      taxyear: taxYear.taxyear,
      isLocked: false,
    })
  }

  return (
    <Modal
      visible={true}
      title='Add Tax Year'
      buttonFunctions={buttonFunctions}
      maxWidth={300}
      height='auto'
      showCloseButtonInTitle={true}
      className='add-tax-year-container'
    >
      <div data-testid='add-tax-year'>
        <Form
          formData={taxYear}
          readOnly={false}
          showColonAfterLabel={false}
          showValidationSummary={false}
          ref={formRef}
        >
          <GroupItem colSpan={6}>
            <Item
              dataField='taxyear'
              data-testid='taxYear'
              editorType='dxTextBox'
              label={{ text: ' ' }}
              editorOptions={{
                width: 120,
                maxLength: 4,
                onEnterKey: () => {
                  handleSaveClick()
                },
              }}
            >
              <RequiredRule message='Tax year is required' />
              <PatternRule
                ignoreEmptyValue={false}
                message='Enter valid tax year'
                pattern='^20\d{2}$'
              />
            </Item>
          </GroupItem>
        </Form>
      </div>
    </Modal>
  )
}
