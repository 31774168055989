import { Button } from 'devextreme-react'
import { getLandingPageRoute } from '../utility/route-creation'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { EnabledFeature } from '../authentication/enabledFeature'
import { entityGroupPartnershipStateAtom } from '../../state/atom'
import { Grid } from '@mui/material'
import { useNavigate } from '../../hooks/useNavigationGuard'

export function StateManagerGlobalPage() {
  const navigate = useNavigate()
  const { groupId } = useParams() as { groupId: string }
  const groupIdNumber = parseInt(groupId)

  //Enabling StateManager Page with State Elections flag from recoil state
  const EntityClientGroup = useRecoilValue(entityGroupPartnershipStateAtom)
  const clientState =
    EntityClientGroup && typeof groupIdNumber === 'number'
      ? EntityClientGroup[groupIdNumber]
      : undefined
  const elections =
    clientState && clientState.featureFlag
      ? clientState.featureFlag?.stateElections
      : false

  return (
    <EnabledFeature isFeatureEnabled={elections!}>
      <Grid container spacing={1}>
        <Grid item xs={0.5} className='status-button-container'>
          <Button
            onClick={() => navigate(getLandingPageRoute())}
            stylingMode='outlined'
            data-testid='Backbutton'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={1.5}>
          State Manager
        </Grid>
      </Grid>
    </EnabledFeature>
  )
}
