import { Grid } from '@mui/material'
import { Button } from 'devextreme-react'
import { InvestorElectionDetails } from './investor-election-details'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  investorRelationsAtom,
  smGeneralElectionInfoAtom,
  smSubmissionStatusAtom,
} from '../../../state/atom'
import { useState } from 'react'
import { useStateManagerApi } from '../../../hooks/use-state-manager-api'
import { ApiSpinnerContent } from '../../state-manager/global-election-settings'
import produce from 'immer'
import { SpinnerModal } from '../../modal/spinnerModal'
import { useCommonDialogs } from '../../modal/commonDialog/common-dialog-operations'
import { useParams } from 'react-router'
import './investor-election-review.scss'
import { EntityType } from '../../../model/entity-types'
export interface ElectionReviewProps {
  showElectionReview: (isShowReview: boolean) => void
}

export const ElectionReview = ({ showElectionReview }: ElectionReviewProps) => {
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()
  const [hideClose, setHideClose] = useState<boolean>(false)
  const [generalElectionInfo, setGeneralElectionInfo] = useRecoilState(
    smGeneralElectionInfoAtom
  )
  const [submissionStatus, setSubmissionStatus] = useRecoilState(
    smSubmissionStatusAtom
  )
  const { investorEntities } = useRecoilValue(investorRelationsAtom)
  const stateManagerApi = useStateManagerApi()
  const commonDialog = useCommonDialogs()
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )
  const setInvestorDetails = useSetRecoilState(investorRelationsAtom)
  const { partnerId, taxYear, groupId } = useParams() as {
    partnerId: string
    taxYear: string
    groupId: string
  }
  const submissionStatuses = submissionStatus.submissionStatuses

  /**checking all the tab General, Composite and Withholding are submitted and submission date is not present
   * if not then showing the alert message
   * */
  const isNotSubmitted = submissionStatuses
    ? submissionStatuses.isGeneralInfoComplete === true &&
      submissionStatuses.isWithholdingsComplete === true &&
      (submissionStatuses.isCompositeComplete === true ||
        submissionStatuses.isCompositeHasEligibleElections === false) &&
      submissionStatuses.submissionDate === undefined
    : false

  // Ensuring the Edit and Submit button should not be visible if there is submittedDate
  const isButtonVisible = !electionsInfo?.submittedDate
  //Get the current investor info from investor entities
  const investorClientEntity = investorEntities?.clientGroupEntities?.find(
    (i) => i.id === parseInt(partnerId)
  )

  // InvestorName as Entity's LastName and FirstName or entityName
  const investorName =
    investorClientEntity?.entityType === EntityType.Business
      ? `${investorClientEntity.entityName}`
      : `${investorClientEntity?.firstName!} ${investorClientEntity?.lastName!}`


  // showing the confirmation model before submit
  const handleSubmit = async () => {
    commonDialog.showDialog({
      dialogType: 'general',
      title: `Submitting ${taxYear} Elections`,
      content: `You are submitting your ${taxYear} tax elections for ${investorName}. Submissions are final. Would you like to proceed?`,
      omitDefaultButton: true,
      buttonFunctions: [
        {
          label: 'Cancel',
          isCloseAction: true,
          isDefaultAction: true,
          onClick: () => {},
          buttonProps: {
            stylingMode: 'contained',
            type: 'normal',
            width: 100,
          },
        },
        {
          label: 'Proceed',
          isCloseAction: true,
          onClick: () => {
            submitElections()
          },
          buttonProps: {
            stylingMode: 'contained',
            type: 'default',
            width: 110,
          },
        },
      ],
    })
  }

  // Post submition updating the submitted date to recoil state
  const updateSubmissionStatesAndElectionInfo = () => {
    // updating the general election info submittedDate
    setGeneralElectionInfo(
      produce((draft) => {
        draft.electionsInfo!.submittedDate = new Date()
      })
    )
    // updating the submission statue submittedDate
    setSubmissionStatus(
      produce((draft) => {
        draft.submissionStatuses!.submissionDate = new Date()
      })
    )

    // Set isLocked to true for the submitted election 
    const groupIdValue = parseInt(groupId)
    const investorIdValue = parseInt(partnerId)
    setInvestorDetails(
      produce((draft) => {
        draft.investorEntities?.stateManagerTaxYears?.forEach((sm) => {
          if (
            sm.clientGroupId === groupIdValue &&
            sm.investorId === investorIdValue
          ) {
            sm.taxYears?.forEach((year) => {
              if (year.taxyear === taxYear) {
                year.isLocked = true
              }
            })
          }
        })
      })
    )
  }

  // Submitting the election
  const submitElections = async () => {
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Submitting Elections'
        draft.inProgressMessage = 'Elections are being saved'
        draft.errorTitle = 'Error Submitting'
      })
    )
    setHideClose(false)
    setShowApiSpinner(true)
    // callin the API to update the submitted date in electionInfo table
    const updatePromise = stateManagerApi
      .submitElection(electionsInfo?.id!)
      .then(() => {
        updateSubmissionStatesAndElectionInfo()
      })
    setSpinnerPromise(updatePromise)
  }

  return (
    <div className='investor-election-review-container'>
      {/** Page Header */}
      <Grid className='page-header-items'>
        <Grid item xs={1} md={0.4} className='back-button'>
          <Button
            onClick={() => {
              showElectionReview(false)
            }}
            data-testId='back-button'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={11} md={11.6}>
          <p className='state-manager-text'>State Manager</p>
        </Grid>
      </Grid>
      <Grid item xs={11} md={11.6} className='sub-header-grid'>
        <span className='sub-header-text'>Review Election Submission</span>
      </Grid>
      {!isNotSubmitted && (
        <div className='center'>
          No review available because this investor has not finished making
          their elections
        </div>
      )}
      {isNotSubmitted && (
        <div>
          <InvestorElectionDetails
            investorElectionsInfoId={electionsInfo?.id}
          />
          <div className='button-grid'>
            <Button
              onClick={() => {
                showElectionReview(false)
              }}
              visible={isButtonVisible}
              text='Edit'
              type='normal'
              data-testId='edit-button'
            ></Button>
            <div className='review-and-save-button'>
              <Button
                onClick={() => {
                  handleSubmit()
                }}
                style={{ color: 'white' }}
                text='Submit'
                type={'default'}
                visible={isButtonVisible}
                data-testId='submit-button'
              ></Button>
            </div>
          </div>
        </div>
      )}
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowApiSpinner(false)}
        apiAction={spinnerPromise}
        hideCloseButton={hideClose}
        closeOnSuccess={true}
      />
    </div>
  )
}
