import { Popover } from 'devextreme-react'
import React, { useState, useCallback, useMemo } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './simpleTooltip.scss'

interface SimpleTooltipProps {
  displayText?: React.ReactNode | string
  popupMessage?: string[] | string
}

/**
 * The SimpleTooltip component is a reusable React component that provides a tooltip functionality.
 * Props:
 *   @displayText - React.ReactNode | string
 *       If provided, this content will be shown instead of the default InfoOutlinedIcon.
 *
 *   @popupMessage - string[] | string
 *       The content to be displayed inside the tooltip when it is triggered. It can be either a string or
 *       an array of strings. If it's an array, each element will be displayed in a separate line.
 **/
const SimpleTooltip = ({ displayText, popupMessage }: SimpleTooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  // We need a unique ID so we can reference it inside this component.
  //  It doesn't matter what it is though.
  const controlId = useMemo(
    () => 'popup_' + Math.floor(Math.random() * 100000),
    []
  )

  // Callback to render the tooltip content based on popupMessage
  const tooltipTemplate = useCallback(
    () => (
      <div>
        {Array.isArray(popupMessage) ? (
          popupMessage.map((name, index) => <div key={index}>{name}</div>)
        ) : (
          <div>{popupMessage}</div>
        )}
      </div>
    ),
    [popupMessage]
  )

  return (
    <span
      className='info-popup'
      id={controlId}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {/* If display text is provided, render it, otherwise render InfoOutlinedIcon */}
      {displayText ? (
        <>{displayText}</>
      ) : (
        <InfoOutlinedIcon className='popup-icon' />
      )}
      {/* Popover component for displaying the tooltip */}
      <Popover
        target={'#' + controlId}
        visible={isTooltipVisible}
        contentRender={tooltipTemplate}
        className='popup-text'
      ></Popover>
    </span>
  )
}

export default SimpleTooltip
