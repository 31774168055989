import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { useCallback } from 'react'
import {
  getInvestorPageRoute,
  getStateManagerRouteInvestor,
} from '../utility/route-creation'
import './investorNameCard.scss'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { investorRelationsAtom, userProfileFromIdmAtom } from '../../state/atom'
import { useRecoilValue, useRecoilState } from 'recoil'
import produce from 'immer'
import { FavoriteInvestorClientGroupView } from '../../api-client/entity-manager-client-v3'
import Tile from '../landingPage/tile'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { ContextMenu } from 'devextreme-react'
import { MenuProps } from '../../model/file-model'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'

interface Props {
  id: number
  name: string
  logo: string
  isFavorite: boolean
  partnerEntityId: number
  list?: Array<string>
  onEditClient: (entityGroupId: number) => void
}

/** Investor Card Component */
export const InvestorNameCard = ({
  id,
  name,
  logo,
  isFavorite,
  partnerEntityId,
  onEditClient,
}: Props) => {
  const [investorRelation, setInvestorRelation] = useRecoilState(
    investorRelationsAtom
  )
  const entityClientApi = useEntityClientApi()
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)
  const navigate = useNavigate()

  //star icon click, if not favorite then make favorite else vice-versa
  const favoriteClickHandler = async () => {
    const favoriteclientInfo: FavoriteInvestorClientGroupView = {
      userProfileId: userProfileResult.id,
      clientGroupId: id,
      isFavorite: isFavorite,
      partnerEntityId: partnerEntityId,
    }
    // Add/Remove favorite in DB
    await entityClientApi.addInvestorFavoriteClientGroup(favoriteclientInfo)

    // Update favorite client based on client group id and partner entity id
    setInvestorRelation(
      produce((draft) => {
        const index = draft.investorEntities.favoriteClients?.findIndex(
          (f) => f.clientGroupId === id && f.partnerEntityId === partnerEntityId
        )
        // unfavorite - Remove favorite investor client
        if (index !== undefined && index >= 0) {
          draft.investorEntities.favoriteClients?.splice(index, 1)
        } else {
          // Add favorite investor client
          const favoriteClient: FavoriteInvestorClientGroupView = {
            userProfileId: userProfileResult.id,
            clientGroupId: id,
            partnerEntityId: partnerEntityId,
          }
          draft.investorEntities.favoriteClients?.push(favoriteClient)
        }
      })
    )
  }

  // navigate to investor files page
  const onClickHandler = useCallback(() => {
    navigate(getInvestorPageRoute(id, partnerEntityId))
  }, [id, partnerEntityId])

  /** Set context menu text and css class */
  const renderMenuItem = (e: MenuProps) => {
    let icon = <span className='material-icons-outlined'>{e.icon}</span>
    switch (e.icon) {
      case 'statemanager':
        icon = <PlaceOutlinedIcon />
        break
      case 'documents':
        icon = <FolderOutlinedIcon />
        break
    }
    return (
      <>
        {icon}
        {e.items ? <span className='dx-icon-spinright' /> : null}
        {e.text}
      </>
    )
  }

  const navigateContextMenu: MenuProps[] = [
    {
      text: 'Documents',
      icon: 'documents',
      action: () => {
        onClickHandler()
      },
    },
  ]

  // Add State manager option in context menu if state manager is enabled
  const taxYears = investorRelation.investorEntities.stateManagerTaxYears?.find(
    (s) => s.clientGroupId === id && s.investorId === partnerEntityId
  )
  if (taxYears && taxYears.taxYears?.length! > 0) {
    navigateContextMenu.push({
      text: 'State Manager',
      icon: 'statemanager',
      action: () => {
        navigate(getStateManagerRouteInvestor(id, partnerEntityId))
      },
    })
  }

  return (
    <tr key={`client-group-tile-${id}`} className='client-group-tile'>
      <td>
        <Tile
          onEditClient={onEditClient}
          title={name}
          id={id}
          isContextMenuVisible={false}
          clientMenus={[]}
        >
          <div
            className='cui-c_tile-image align-center client-group-image'
          >
            {logo && <img src={logo} alt='investor group img' />}
            {!logo && <ImageOutlinedIcon className='tile-placeholder-image' />}
          </div>
          <div className='cui-c_tile-content'>
            <div className='cui-c_card-group'>
              <div className='cui-c_card placeholder-align-left'>
                <h3>
                  <span className='navigate-font'>Navigate To:</span>
                  <ArrowDropDownCircleOutlinedIcon
                    style={{ color: 'black', position: 'relative', top: '5px', cursor: 'pointer' }}
                    id={`navigateToActionBtn_${id}_${partnerEntityId}`}
                  ></ArrowDropDownCircleOutlinedIcon>
                  <ContextMenu
                    dataSource={navigateContextMenu}
                    itemRender={renderMenuItem}
                    showEvent='click'
                    target={`#navigateToActionBtn_${id}_${partnerEntityId}`}
                    onItemClick={(e) => {
                      const item = e.itemData as MenuProps | undefined
                      if (item?.action) {
                        item.action()
                      }
                    }}
                  />
                </h3>
              </div>
              <div className='cui-c_card placeholder-align-right'>
                <h3>
                  {!isFavorite ? (
                    <StarBorderOutlinedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        favoriteClickHandler()
                      }}
                    />
                  ) : (
                    <StarIcon
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        favoriteClickHandler()
                      }}
                    />
                  )}
                </h3>
              </div>
            </div>
          </div>
        </Tile>
      </td>
    </tr>
  )
}
