/** Promise Limiter - Helps to limit number of the API calls occuring at the same time,
 * based on the maxRunningActionCount value. This class will help to avoid API flooded with
 * too many API calls
 */
export class PromiseLimiter {
  readonly maxRunningActionCount: number = 5
  private actionQueue: (() => Promise<any>)[] = []
  private isAborted: boolean = false
  private promiseQueue: Promise<any>[] = []

  /** Add the function to the action queue for processing  */
  registerAction(newAction: () => Promise<any>) {
    // If its already aborted then throw error
    if (this.isAborted) {
      throw new Error(`Already aborted`)
    }
    this.actionQueue.push(newAction)
    this.processActions()
  }

  /** Make API call based on the  number of pending promises,
   * if pending promises count < maxRunningActionCount then make API call
   * otherwise wait for the pending promise to complete
   */
  processActions() {
    // If its already aborted then return
    if (this.isAborted) {
      return
    }

    // Check any item available in the action queue for processing &
    // Number of pending promises less than maximum allowed number of API calls simulatneously
    const creationCount = Math.min(
      this.actionQueue.length,
      this.maxRunningActionCount - this.promiseQueue.length
    )
    for (let index = 0; index < creationCount; index++) {
      const actionPromise = this.actionQueue[0]().then(() => {
        // Remove completed promise from the promiseQueue
        const promiseIndex = this.promiseQueue.indexOf(actionPromise)
        this.promiseQueue.splice(promiseIndex, 1)

        // if its not aborted then call processActions to process next set of API calls
        if (!this.isAborted) {
          this.processActions()
        }
      })
      
      // Remove processed item from the actionQueue
      this.actionQueue.splice(0, 1)
      this.promiseQueue.push(actionPromise)
    }
  }

  /** Abort function to set isAborted flag and clear the actionQueue items */
  abort() {
    this.isAborted = true
    this.actionQueue = []
  }
}
