import {
  PropsWithChildren,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react'
import { FormattedDialog, FormattedDialogTypes } from './formattedDialog'

export interface ModalProps {
  visible: boolean
  inProgressTitleMessage: string
  inProgressMessage: ReactNode
  successTitleMessage: string
  successMessage: ReactNode
  errorTitleMessage: string
  errorMessage?: ReactNode
  onClose: (success?: boolean) => void
  apiAction: Promise<void> | Promise<any> | undefined
  width?: number
  height?: number
  /** Set to true then spinner close automatically when API call completed successfully
   * by default the value is false
   */
  closeOnSuccess?: boolean
  /** Hide the close button if the value is true by default the value is false */
  hideCloseButton?: boolean
}

export const SpinnerModal = ({
  visible,
  inProgressTitleMessage,
  inProgressMessage,
  successTitleMessage,
  successMessage,
  errorTitleMessage,
  errorMessage,
  onClose,
  apiAction,
  width,
  height,
  closeOnSuccess,
  hideCloseButton,
}: PropsWithChildren<ModalProps>) => {
  const [dialogState, setDialogState] =
    useState<FormattedDialogTypes>('in-progress')
  const [errorDetail, setErrorDetail] = useState(errorMessage)
  const [successFlag, setSuccessFlag] = useState(false)
  const [hideButtonClose, setHideButtonClose] = useState(hideCloseButton)

  useEffect(() => {
    // Initialize the states.
    setDialogState('in-progress')
    setErrorDetail(errorMessage)

    if (apiAction) {
      apiAction
        .then((response) => {
          // Display success mesage only if success title and message have value
          if (successTitleMessage && successMessage && !closeOnSuccess) {
            setDialogState('success')
            setSuccessFlag(true)
          } else {
            // Close the modal
            onClose(true)
          }
        })
        .catch((e) => {
          // Show Close button for error
          if (hideButtonClose) {
            setHideButtonClose(false)
          }

          setSuccessFlag(false)
          if (errorMessage) {
            //if error got an object and failed count in it
            setErrorDetail(errorMessage)
          } else if (typeof e === 'string') {
            setErrorDetail(e.replace(/^"(.*)"$/, '$1')) //Remove double quotes if any
          } else if (e.response) {
            setErrorDetail(
              e.response.replace('\\n', '').replace(/^"(.*)"$/, '$1')
            ) //Remove double quotes or \n if any
          } else {
            setErrorDetail(e.message)
          }
          setDialogState('error')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAction])

  // If the error message changes AFTER we've already caught an API error
  //  then we want to make sure the new error message gets honored.
  useEffect(() => {
    if (dialogState === 'error') {
      setErrorDetail(errorMessage)
    }
    // We only want to update this CURRENTLY in the error state - not if it changes.  (omit dialogState from the dep-list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])

  // Determine the message and title to display.
  const content = useMemo(() => {
    switch (dialogState) {
      case 'in-progress':
        return {
          message: inProgressMessage,
          title: inProgressTitleMessage,
        }
      case 'success':
        return {
          message: successMessage,
          title: successTitleMessage,
        }
      case 'error':
        return {
          message: errorDetail,
          title: errorTitleMessage,
        }

      default:
        throw new Error(`Unhandled dialog state: ${dialogState}`)
    }
  }, [
    errorTitleMessage,
    errorDetail,
    dialogState,
    successMessage,
    successTitleMessage,
    inProgressMessage,
    inProgressTitleMessage,
  ])

  return (
    <FormattedDialog
      visible={visible}
      dialogType={dialogState}
      onCloseClicked={() => onClose(successFlag)}
      title={content.title}
      maxWidth={width}
      maxHeight={height}
      hideCloseButton={hideButtonClose}
    >
      {content.message}
    </FormattedDialog>
  )
}
