import { Button, Popover } from 'devextreme-react'
import { InvestorStateSelector } from './investor-state-selector'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usStatesData } from '../state-manager/state-manager-utils'
import './investor-state-selections.scss'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import {
  InvestorStateElectionModel,
  SMInvestorStateElection,
} from '../../api-client/investor-portal-client'
import { InvestorStateDisplay } from './investor-state-display'
import { ApiSpinnerContent } from '../state-manager/global-election-settings'
import { SpinnerModal } from '../modal/spinnerModal'
import produce from 'immer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

export interface InvestorStateSelectionsProps {
  investorElectionsInfoId: number
  entityOptionsId: number | undefined
  updateCanSave: (isUpdated: boolean) => void
  onDirtyStateChange: (isDirty: boolean) => void
  setElectionAvailable: (flag: boolean) => void
  setParentButtons: (buttons: React.ReactNode) => void
}

/** Investor State Selections */
export const InvestorStateSelections = ({
  investorElectionsInfoId,
  entityOptionsId,
  updateCanSave,
  onDirtyStateChange,
  setElectionAvailable,
  setParentButtons,
}: InvestorStateSelectionsProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>()
  const [eligibleStateKeys, setEligibleStateKeys] = useState<string[]>([])
  const [inEligibleStates, setIneligibleStates] = useState<string[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isDirtyFlag, setIsDirtyFlag] = useState<boolean>(false)
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [hideClose, setHideClose] = useState<boolean>(false)
  const [isElectionAvailable, setIsElectionAvailable] = useState<boolean>(true)
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()
  const [investorState, setInvestorState] = useState<string[] | undefined>()
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false)
  const [popOverTarget, setPopOverTarget] = useState<string>()
  const [popOverVisible, setPopOverVisible] = useState<boolean>(false)
  const [popOverText, setPopOverText] = useState<string>()
  const commonDialog = useCommonDialogs()
  const stateManagerApi = useStateManagerApi()
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )

  // popover id for  withholding elections
  const ineligibleElectionPopoverId =
    'ineligibleelection' + investorElectionsInfoId + entityOptionsId

  useEffect(() => {
    const abortController = new AbortController()

    /** Load composites data based on the investorElectionsInfoId & entityOptionsId
     * Load eligible states based on the investorElectionsInfoId & entityOptionsId
     */
    const loadCompositeData = async () => {
      setSelectedKeys([])
      /** Load the investor state elections data from the API */
      const investorStateElections: SMInvestorStateElection[] =
        await stateManagerApi.getInvestorElections(
          investorElectionsInfoId,
          entityOptionsId,
          abortController.signal
        )

      // check investorStateElection is not saved then display 'Save' & 'Cancel' buttons
      if (!investorStateElections || investorStateElections.length === 0) {
        setIsEditMode(true)
        setIsDirtyFlag(false)
        setIsDataAvailable(false)
      } else {
        setIsDataAvailable(true)
        
        // set selected state codes in local state
        const stateCodes = investorStateElections.map((x) => x.stateCode!)
        setInvestorState(stateCodes)
        setSelectedKeys(stateCodes)
      }

      // load eligible states based on investorElectionsInfoId & entityOptionsId
      const eligibleStates: string[] = await stateManagerApi.getEligibleStates(
        investorElectionsInfoId,
        entityOptionsId,
        abortController.signal
      )

      // if eligibleStates are available, then set the state codes in the state
      if (eligibleStates.length > 0) {
        setIsElectionAvailable(true)
        setElectionAvailable(true)
        // set eligible state codes
        setEligibleStateKeys(eligibleStates)
        // Other than eligible state codes, considered as ineligible states
        setIneligibleStates(
          usStatesData
            .filter((x) => !eligibleStates?.includes(x.stateCode))
            .map((x) => x.stateCode)
        )
      } else {
        // Display no elections message
        setIsElectionAvailable(false)
        setElectionAvailable(false)
      }
    }

    /** If  load the investor state elections data from the API */
    setIsEditMode(false)
    setShowApiSpinner(true)
    setHideClose(true)
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'State Election'
        draft.inProgressMessage = 'Loading States...'
        draft.errorTitle = 'Error loading.'
      })
    )
    // Set API call promise to display spinner modal
    setSpinnerPromise(loadCompositeData())

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [investorElectionsInfoId, entityOptionsId])

  /** Set inEligible states display data source */
  const inEligibleDataSource = useMemo(() => {
    return usStatesData.filter((x) => inEligibleStates?.includes(x.stateCode))
  }, [inEligibleStates])

  /**  Set selected states based on the selected state codes */
  const selectedStateDataSource = useMemo(() => {
    return usStatesData.filter((x) => selectedKeys?.includes(x.stateCode))
  }, [selectedKeys])

  /** Cancel edit - if there's any unsaved changes then  display warning message
   * otherwise set edit mode to false to hide save & cancel buttons
   */
  const cancelEditing = useCallback(() => {
    if (isDirtyFlag) {
      commonDialog.showDialog({
        dialogType: 'general',
        title: ' Unsaved Changes',
        content:
          'You have unsaved changes. If you continue changes will be lost. Would you like to proceed?',
        omitDefaultButton: true,
        buttonFunctions: [
          {
            label: 'Cancel',
            isCloseAction: true,
            isDefaultAction: true,
            onClick: () => {
              setIsEditMode(true)
            },
            buttonProps: {
              stylingMode: 'contained',
              type: 'normal',
              width: 100,
              'data-testid': 'cancel-button',
            },
          },
          {
            label: 'Proceed',
            isCloseAction: true,
            onClick: () => {
              if (isDataAvailable) {
                setIsEditMode(false)
              }
              setIsDirtyFlag(false)
              setSelectedKeys(investorState)
              onDirtyStateChange(false)
            },
            buttonProps: {
              stylingMode: 'contained',
              type: 'default',
              width: 110,
              'data-testid': 'ok-button',
            },
          },
        ],
      })
    } else {
      // set edit mode to false to hide save & cancel buttons
      setIsEditMode(false)
      updateCanSave(true)
    }
  }, [investorState, isDirtyFlag, onDirtyStateChange, updateCanSave])

  /** Check and set dirty flag for changed values */
  const checkAndSetDirty = (updatedCodes: string[]) => {
    setSelectedKeys(updatedCodes)
    const isChanged = updatedCodes.toString() !== investorState?.toString()
    setIsDirtyFlag(isChanged)
    onDirtyStateChange(isChanged)
  }

  /** Edit click warning Dialog */
  const handleEditClick = () => {
    setIsEditMode(true)
  }

  /** API call to save slected states in DB  */
  const saveStateInfo = useCallback(
    async (stateCode: string | undefined) => {
      setShowApiSpinner(true)
      setHideClose(false)
      setApiSpinnerContent(
        produce((draft) => {
          draft.inProgressTitle = 'Saving States'
          draft.inProgressMessage = 'State selection is being saved'
          draft.successTitle = ''
          draft.successMessage = ''
          draft.errorTitle = 'Error Saving'
        })
      )
      let stateCodes = selectedKeys
      // Set NONE as state code if none of the states are selected
      if (stateCode) {
        stateCodes = [stateCode]
        setSelectedKeys([stateCode])
      }
      // If 'NONE' available then we have to remove if any other state is available
      else if (selectedKeys && selectedKeys.some((x) => x === 'NONE')) {
        setSelectedKeys(
          produce(selectedKeys, (draft) => {
            draft.splice(0, 1)
          })
        )
      }
      const investorStateElection: InvestorStateElectionModel = {
        investorElectionsInfoId: investorElectionsInfoId,
        entityOptionsId: entityOptionsId,
        stateCodes: stateCodes,
      }
      // API Call to update selected states
      await stateManagerApi.updateInvestorElections(investorStateElection)
      setIsDirtyFlag(false)
      setIsEditMode(false)
      onDirtyStateChange(false)
      updateCanSave(true)
      setInvestorState(selectedKeys)
      setIsDataAvailable(true)
    },
    [
      entityOptionsId,
      investorElectionsInfoId,
      onDirtyStateChange,
      selectedKeys,
      updateCanSave,
    ]
  )

  /** Check isDirty and Set investor info promise and API Spinner */
  const handleSave = useCallback(async () => {
    if (!isDirtyFlag && isDataAvailable) {
      setIsEditMode(false)
      return
    }

    /** Check the selected values, it should not be null or un-selected */
    if (!selectedKeys || selectedKeys?.length === 0) {
      return commonDialog.showDialog({
        dialogType: 'general',
        title: 'No Selection',
        content: entityOptionsId
          ? 'You have not selected any States. If you proceed, you are indicating that you wish to have no Withholding exemption in place for this Entity. Would you like to proceed?'
          : 'You have not selected any States. If you proceed, you are indicating that you wish to have no composite tax filings made on your behalf . Would you like to proceed?',
        buttonFunctions: [
          {
            label: 'Cancel',
            isCloseAction: true,
            isDefaultAction: true,
            onClick: () => {},
            buttonProps: {
              stylingMode: 'contained',
              type: 'normal',
              width: 100,
              'data-testid': 'cancel-button',
            },
          },
          {
            label: 'Proceed',
            onClick: () => {
              // set 'NONE' to identify that user not selected any state for electections
              setSpinnerPromise(saveStateInfo('NONE'))
            },
            isCloseAction: true,
            buttonProps: {
              stylingMode: 'contained',
              type: 'default',
              width: 120,
            },
          },
        ],
        omitDefaultButton: true,
      })
    }
    setSpinnerPromise(saveStateInfo(undefined))
  }, [
    entityOptionsId,
    isDataAvailable,
    isDirtyFlag,
    saveStateInfo,
    selectedKeys,
  ])

  /** remove 'NONE' state code from the list to display 'No Records' */
  const getStateCodes = () => {
    if (
      selectedKeys &&
      selectedKeys.length === 1 &&
      selectedKeys[0] === 'NONE'
    ) {
      return []
    }
    return selectedKeys
  }

  /**Render tooltip text and align left */
  const renderTooltip = () => {
    return <div className='content-left'>{popOverText}</div>
  }

  /** Set PopOver configuration to display */
  const setPopOverDisplay = (
    showPopOver: boolean,
    text: string,
    target: string
  ) => {
    setPopOverVisible(showPopOver)
    setPopOverText(text)
    setPopOverTarget(target)
  }

  const isComposite = entityOptionsId === undefined

  useEffect(() => {
    setParentButtons(
      <div>
        <Button
          visible={isEditMode && (isDirtyFlag || isDataAvailable)}
          onClick={() => cancelEditing()}
          text='Cancel'
          type='normal'
          className='cancel-btn-State'
          data-testid='state-cancel-button'
        ></Button>
        <Button
          text='Edit'
          visible={!isEditMode}
          type='default'
          stylingMode='contained'
          className='clear-btn-State'
          onClick={() => handleEditClick()}
          data-testid='state-edit-button'
        ></Button>
        <Button
          text='Save'
          visible={isEditMode}
          type='default'
          stylingMode='contained'
          className='save-btn-State'
          onClick={() => handleSave()}
          data-testid='state-Save-button'
        ></Button>
      </div>
    )
  }, [isEditMode, isDataAvailable, setParentButtons, cancelEditing, handleSave])

  return (
    <div className='investor-state-selections'>
      {!isElectionAvailable && (
        <div className='center'>No elections available for this year</div>
      )}
      {isElectionAvailable && (
        <div>
          <div className='content-area'>
            <div className='col'>
              <span className='header-display'>State Selection</span>
              <InvestorStateSelector
                eligibleStateKeys={eligibleStateKeys}
                updateSelectedKeys={(codes) => {
                  checkAndSetDirty(codes)
                }}
                stateSelectionsKeys={selectedKeys}
                height={'calc(73vh - 275px)'}
                isEditable={isEditMode}
              ></InvestorStateSelector>
            </div>
            <div className='col'>
              <div className='align-tagbox header-display'>
                Selected States
                <InvestorStateDisplay
                  selectedStateCodes={getStateCodes()}
                  states={selectedStateDataSource}
                  updateSelectedKeys={(codes) => {
                    checkAndSetDirty(codes)
                  }}
                  isDisable={!isEditMode}
                ></InvestorStateDisplay>
              </div>
              <div className='align-tagbox header-display'>
                Ineligible States
                <span
                  className='align-icon'
                  id={ineligibleElectionPopoverId}
                  onMouseEnter={() => {
                    if (isComposite) {
                      setPopOverDisplay(
                        true,
                        'Specific states are ineligible for selection if one or more of the following is true: 1) State law does not allow your entity type to participate in a composite return, 2) the specific state is your state of residency, 3) your investment is not planning to file a return in the state.',
                        '#' + ineligibleElectionPopoverId
                      )
                    } else {
                      setPopOverDisplay(
                        true,
                        'States are ineligible if not chosen by your investment or if precluded by State law',
                        '#' + ineligibleElectionPopoverId
                      )
                    }
                  }}
                  onMouseLeave={() => setPopOverDisplay(false, '', '')}
                >
                  <InfoOutlinedIcon sx={{ fontSize: 16 }} />
                </span>
                <InvestorStateDisplay
                  selectedStateCodes={inEligibleStates}
                  states={inEligibleDataSource}
                  updateSelectedKeys={setSelectedKeys}
                  isDisable={true}
                ></InvestorStateDisplay>
              </div>
            </div>
          </div>

          <SpinnerModal
            visible={showApiSpinner}
            errorTitleMessage={apiSpinnerContent.errorTitle}
            inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
            inProgressMessage={apiSpinnerContent.inProgressMessage}
            successTitleMessage={apiSpinnerContent.successTitle}
            successMessage={apiSpinnerContent.successMessage}
            onClose={() => setShowApiSpinner(false)}
            apiAction={spinnerPromise}
            hideCloseButton={hideClose}
            closeOnSuccess={true}
          />
          <Popover
            target={popOverTarget}
            visible={popOverVisible}
            width={550}
            contentRender={renderTooltip}
          />
        </div>
      )}
    </div>
  )
}
