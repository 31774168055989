import { useState } from 'react'
import {
  FormattedDialog,
} from '../../modal/formattedDialog'

import { DownloadReportInfo } from '../../../model/report-models'
import { ReportDownloadProgress } from './download-progress'

export interface StateManagerReportStatusProps {
  isVisible: boolean
  downloadReportInfo: DownloadReportInfo[]
  onClose: () => void
}

/** StateManager ReportStatus report status dialog */
export const StateManagerReportStatus = ({
  isVisible,
  downloadReportInfo,
  onClose,
}: StateManagerReportStatusProps) => {
  const [isVisibleModel, setIsVisibleModel] = useState(isVisible)
  // when close the model reset visible
  const closeDownloadReportModal = () => {
    setIsVisibleModel(false)
    onClose()
  }

  return (
    <FormattedDialog
      title='Download Report(s)'
      visible={isVisibleModel}
      dialogType={'general'}
      onCloseClicked={closeDownloadReportModal}
    >
      {downloadReportInfo.map((reportInfo) => {
        return <ReportDownloadProgress reportInfo={reportInfo} />
      })}
    </FormattedDialog>
  )
}
