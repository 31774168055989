import './helpPage.scss'
import { IsAuthorized } from '../authentication/isAuthorized'
import * as Permissions from '../../model/permissions'
import { permissionRequestAny } from '../../access-manager/permission-request-creation'

/** Help Page for Admin and Investor User */
export const HelpPage = () => {
  return (
    <div>
      <IsAuthorized permissions={[permissionRequestAny(Permissions.INTERNAL)]}>
        <div className='help-page-container' data-testid='admin-help'>
          <div className='cui-c_card item-text-style'>
            <h4>Phone Assistance:</h4>
            <p>
              Please call<span className='span-item'>1-877-462-7573</span>for
              assistance.
            </p>
          </div>
        </div>
      </IsAuthorized>
      <IsAuthorized permissions={[permissionRequestAny(Permissions.INVESTOR)]}>
        <div className='help-page-container' data-testid='investor-help'>
          <div className='cui-c_card item-text-style'>
            <h4>Phone Assistance:</h4>
            <p>
              Please call<span className='span-item'>1-833-518-3394</span>for
              assistance.
            </p>
          </div>
          <div className='cui-c_card item-text-style'>
            <h4>Email Assistance:</h4>
            <p>
              Please email
              <span className='span-item'>RSMServicedesk@rsmus.com</span>
              for assistance.
            </p>
          </div>
        </div>
      </IsAuthorized>
    </div>
  )
}
