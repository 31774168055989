import { useEffect } from 'react'
import { useNavigate } from '../hooks/useNavigationGuard'
import {
  getClientsPageRoute,
  getManageExistingInvestorsRoute,
  getInvestorLandingRoute,
  getLandingPageRoute,
  getErrorPageRoute,
} from '../components/utility/route-creation'
import { PermissionRequestSet } from './permission-models'
import {
  PermissionCheckTypes,
  useIsPermissionsLoaded,
  usePermissions,
} from './use-permission'
import { permissionRequestAny } from './permission-request-creation'
import {
  INVESTOR,
  PAGE_VISIBILITY_CLIENTSADMIN,
  PAGE_VISIBILITY_CLIENTSTATUS,
  PAGE_VISIBILITY_MANAGEXISTING,
} from '../model/permissions'

/** Redirects the User Based on their permissions */
export const useAuthorizedPermission = (
  permissionRequest: PermissionRequestSet,
  checkType: PermissionCheckTypes = 'all'
) => {
  const isPermissionsLoaded = useIsPermissionsLoaded()
  const navigate = useNavigate()

  // Use the method to check if the user has the required permissions
  const hasPermission = usePermissions(permissionRequest)

  // Check if the user has the permissions for admin or client roles
  const isClientStatusAdmin = usePermissions([
    permissionRequestAny(PAGE_VISIBILITY_CLIENTSTATUS),
  ])
  const isClientsPageAdmin = usePermissions([
    permissionRequestAny(PAGE_VISIBILITY_CLIENTSADMIN),
  ])
  const isAdminProvisionInvestor = usePermissions([
    permissionRequestAny(PAGE_VISIBILITY_MANAGEXISTING),
  ])
  const isInvestor = usePermissions([permissionRequestAny(INVESTOR)])

  useEffect(() => {
    //ensuring the permissions are loaded
    if (!isPermissionsLoaded) {
      return
    }

    // If the user has permission to the page, navigate to the page directly
    if (!hasPermission) {
      // Redirect based on specific permissions the user does have
      if (isClientStatusAdmin) {
        navigate(getLandingPageRoute())
      } else if (isClientsPageAdmin) {
        navigate(getClientsPageRoute())
      } else if (isAdminProvisionInvestor) {
        navigate(getManageExistingInvestorsRoute())
      } else if (isInvestor) {
        navigate(getInvestorLandingRoute())
      } else {
        navigate(getErrorPageRoute())
      }
    }
  }, [
    isPermissionsLoaded,
    hasPermission,
    isClientStatusAdmin,
    isClientsPageAdmin,
    isAdminProvisionInvestor,
    isInvestor,
  ])
}
