import { SelectBox } from 'devextreme-react'
import { SelectBoxOptions } from '../../../../types/types'

export interface CurrentViewProps {
  isDisabled?: boolean //While Loading the Data from Recoil State update IsDisabled
  className?: string
  currentViewValue: string
  onValueChange: (value: string) => void
  viewItems: SelectBoxOptions[]
}

/** Current View dropdown component  */
export const CurrentViewControl = ({
  className: styleName,
  currentViewValue,
  onValueChange,
  viewItems,
}: CurrentViewProps) => {

  /** Set changed view value in Document Status and State Manager*/
  const onValueChanged = (value: string) => {
    onValueChange(value)
  }
  
  return (
    <div className='current-view-control'>
      <div
        className={
          styleName +
          ' annotated-current-view-control-select current-view-control-spacing'
        }
      >
        <SelectBox
          items={viewItems}
          displayExpr='label'
          valueExpr='value'
          value={currentViewValue}
          onValueChange={onValueChanged}
        />
      </div>
    </div>
  )
}
