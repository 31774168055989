import { useEffect } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  clientAndPartnershipStateAtom,
  parentFileListAtom,
  searchTextAtom,
} from '../state/atom'

export function useFileSearchReset() {
  const clientAndPartnerships = useRecoilValue(clientAndPartnershipStateAtom)
  const parentFileListState = useRecoilValue(parentFileListAtom)
  const [searchTerm, setSearchTerm] = useRecoilState(searchTextAtom)
  const location = useLocation()

  useEffect(() => {
    // Parent and Child files route
    const routes = [
      { path: 'admin/groups/:groupId/partnerships/:partnershipId' },
      { path: 'admin/groups/:groupId/partnerships/:partnershipId/children/:parentFileId' },
    ]
    const route = matchRoutes(routes, location)
    // Clear search text in recoil state if there's searchTerm not null and route is null
    // Current page should not be Parent & Child file page
    if (
      searchTerm !== '' &&
      route === null
    ) {
      setSearchTerm('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAndPartnerships.partnershipId, parentFileListState.partnershipId, location])
}
