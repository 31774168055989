import { getAccessToken } from '@rsmus/react-auth';
import { Subject, Observable } from 'rxjs'
import { VerifyUserRequest, VerifyUserViewModel } from '../../api-client/investor-portal-client';
import { REACT_APP_INVESTORPORTALURL } from '../../envVariables';

/** Attempts to parse a specified buffer string for JSON objects.  When found
 *   they are sent through the specified subject.  A revised buffer is returned
 *   with the remainder of the buffer that did not amount to an object.
 */
function parseBuffer(buffer: string, subject: Subject<VerifyUserViewModel>): string {
    // If the buffer is empty, then handle that specially.
    if (!buffer || buffer.trim().length < 1) {
        return ''
    }

    // Flag indicating that we've found an opening brace,
    //  And that we're reading through it.
    let isOpen = false

    // Holds the location of the place in the buffer
    //  that has not been converted to a JSON object.
    let remainderStartLocation = 0

    // The current object being processed.
    let currentBuffer = ''

    for (let i = 0; i < buffer.length; i++) {
        // Get the current character in the buffer.
        const curChar = buffer[i]

        // Place the next object in the current buffer.
        currentBuffer += curChar

        switch (curChar) {
            case '{':
                isOpen = true
                break
            case '}':
                // Verify that we have an open brace.
                if (!isOpen) {
                    subject.error(new Error('Unmatched closing brace found.'))
                    throw new Error(`Unmatched closing brace found.`)
                }

                isOpen = false
                remainderStartLocation = i + 1
                try {
                    const nextObject = JSON.parse(currentBuffer)
                    currentBuffer = ''
                    subject.next(nextObject)
                } catch (err) {
                    // Make sure the error is sent through the subject for observers to see.
                    subject.error(err)
                    throw err
                }
                break
        }
    }

    // Return the remainder of the buffer.
    if (remainderStartLocation < buffer.length - 1) {
        return buffer.slice(remainderStartLocation)
    } else {
        return ''
    }
}

export async function verifyUserApiRequest(email: string): Promise<{ data: Observable<VerifyUserViewModel>, cancelation: AbortController }> {
    // Create a subject to fire the result values as they come in.
    const resultSubject = new Subject<VerifyUserViewModel>()

    // Create a cancelation token so we can cancel our request if we're done with it.
    const cancelation = new AbortController()

    // Create a text decoder to decode the values from the stream.
    const decoder = new TextDecoder()

    // Collects the string data as it comes in, since each
    //  chunk may be more or less than a single object.
    let responseBuffer = ''

    fetch(`${REACT_APP_INVESTORPORTALURL}/user/verify/stream`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken().then((at) => 'Bearer ' + at),
        },
        body: JSON.stringify({ emailAddress: email } as VerifyUserRequest),
        signal: cancelation.signal
    })
        .then(response => {
            // Create a ReadableStream from the response body.
            const stream = response.body!.getReader();

            // Define a function to handle each chunk of data that's received.
            async function processChunk() {
                const { done, value } = await stream.read()

                if (done) {
                    // If the stream has ended, cleanup the subject and exit the function.
                    resultSubject.complete()
                    return;
                }

                try {

                    // Get the full response as a string.
                    let fullValue = decoder.decode(value)
                    responseBuffer += fullValue

                    // Try to parse the buffer, with its current contents.
                    responseBuffer = parseBuffer(responseBuffer, resultSubject)

                } catch (err) {
                    console.log(`Error processing: ${err}`)
                    resultSubject.error(err)
                    // We'll let the observer handle cancelling the request.
                }

                // Call processChunk() again to read the next chunk of data.
                await processChunk()
            }

            // Start processing chunks of data as they arrive.
            return processChunk()
        })
        .catch(error => {
            console.error(error)
            // Send the error through the subject, so observers can see.
            if (!resultSubject.closed) {
                resultSubject.error(error)
            }
        })

    // Return the observable to fire events when we receive objects from the stream.
    return { data: resultSubject.asObservable(), cancelation: cancelation }
}