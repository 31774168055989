import React from 'react'
import { useRecoilPinner } from '../../../signalr/useRecoilPinner'
import { FormattedDialog } from '../formattedDialog'
import { commonDialogs } from './common-dialog-state'

export const CommonDialog = () => {
  const dialogsPinner = useRecoilPinner(commonDialogs)

  return (
    <>
      {dialogsPinner.value.map((d) => {
        return <FormattedDialog {...d} key={d.id}></FormattedDialog>
      })}
    </>
  )
}
