import { Container, Button as BTN } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import DataGrid, {
  SearchPanel,
  Column,
  HeaderFilter,
  Scrolling,
  LoadPanel,
  Toolbar,
  Item,
} from 'devextreme-react/data-grid'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  investorRelationsAtom,
  userProfileFromIdmAtom,
} from '../../../state/atom'
import '../../landingPage/landingPage.scss'
import { IsAuthorized } from '../../authentication/isAuthorized'
import { MenuProps } from '../../../client-models/clientmodels'
import produce from 'immer'
import { ICellData } from '../../../model/file-model'
import { ContextMenu, Button } from 'devextreme-react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { useNavigate } from '../../../hooks/useNavigationGuard'
import {
  getInvestorPageRoute,
  getStateManagerRouteInvestor,
} from '../../utility/route-creation'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { FavoriteInvestorClientGroupView } from '../../../api-client/entity-manager-client-v3'
import { useEntityClientApi } from '../../../hooks/use-entity-api'
import { NameCardProps } from '../investor-utils'
import * as Permissions from '../../../model/permissions'
import { permissionRequestAny } from '../../../access-manager/permission-request-creation'

/** Investor Landing Page - List view */
export const InvestorLandingListView = () => {
  const [investorData, setInvestorData] = useState<NameCardProps[]>([])
  const [sortOrder, setSortOrder] = useState('asc')
  const [investorRelation, setInvestorRelation] = useRecoilState(
    investorRelationsAtom
  )
  const entityClientApi = useEntityClientApi()
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)
  const navigate = useNavigate()

  /** load clients and sort the favorites item to display first */
  useEffect(() => {
    let cards: NameCardProps[] = []
    investorRelation.investorEntities.clientGroupEntities?.forEach((e) => {
      e.clientGroupIds?.forEach((groupId) => {
        const clientGroup =
          investorRelation.investorEntities.clientGroups?.find(
            (group) => group.id === groupId
          )

        const favorite =
          investorRelation.investorEntities.favoriteClients?.some(
            (f) => f.clientGroupId === groupId && f.partnerEntityId === e.id
          )

        if (clientGroup) {
          const groupData: NameCardProps = {
            id: clientGroup.id!,
            name: `${clientGroup.name!} - ${e.name}`,
            logoUrl: clientGroup.logoUrl!,
            isFavorite: favorite!,
            partnerEntityId: e.id!,
            uniqueId: clientGroup?.id! + '-' + e.id!,
          }

          cards.push(groupData)
        }
      })
    })

    // set investor data in recoil state
    setInvestorData(cards)
  }, [investorRelation.investorEntities])

  /** Set context menu text and css class */
  const renderMenuItem = (e: MenuProps) => {
    let icon = <span className='material-icons-outlined'>{e.icon}</span>
    switch (e.icon) {
      case 'statemanager':
        icon = <PlaceOutlinedIcon />
        break
      case 'documents':
        icon = <FolderOutlinedIcon />
        break
    }
    return (
      <>
        {icon}
        {e.items ? <span className='dx-icon-spinright' /> : null}
        {e.text}
      </>
    )
  }

  /* Sort favorite items in alphabetical order and then 
     order non favorite item in alphabetical order */
  const sortedInvestorData = useMemo(() => {
    const favorite = investorData.filter((e) => e.isFavorite)
    const nonFavorite = investorData.filter((e) => !e.isFavorite)
    if (sortOrder === 'desc') {
      favorite.sort((a: NameCardProps, b: NameCardProps) =>
        b.name!.localeCompare(a.name!, undefined, { sensitivity: 'accent' })
      )
      nonFavorite.sort((a: NameCardProps, b: NameCardProps) =>
        b.name!.localeCompare(a.name!, undefined, { sensitivity: 'accent' })
      )
    } else {
      favorite.sort((a: NameCardProps, b: NameCardProps) =>
        a.name!.localeCompare(b.name!, undefined, { sensitivity: 'accent' })
      )
      nonFavorite.sort((a: NameCardProps, b: NameCardProps) =>
        a.name!.localeCompare(b.name!, undefined, { sensitivity: 'accent' })
      )
    }
    //concatenating and returning first fvorite and then non-favorite to keep favorite groups on top
    return favorite.concat(nonFavorite)
  }, [sortOrder, investorData])

  /* Explicit sorting by IsFavorite and Name to keep favorite groups on top*/
  const onPositionSortingChanged = () => {
    sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
  }

  /** Star icon click, if item is not in favorite list then make it as favorite else vice-versa */
  const favoriteClickHandler = async (rowData: NameCardProps) => {
    const favoriteclientInfo: FavoriteInvestorClientGroupView = {
      userProfileId: userProfileResult.id,
      clientGroupId: rowData.id,
      isFavorite: rowData.isFavorite,
      partnerEntityId: rowData.partnerEntityId,
    }

    // API call to Update favorite in DB
    await entityClientApi.addInvestorFavoriteClientGroup(favoriteclientInfo)

    // Update favorite client in store based on client group id and partner entity id
    setInvestorRelation(
      produce((draft) => {
        const index = draft.investorEntities.favoriteClients?.findIndex(
          (f) =>
            f.clientGroupId === rowData.id &&
            f.partnerEntityId === rowData.partnerEntityId
        )

        // unfavorite - Remove favorite investor client
        if (index !== undefined && index >= 0) {
          draft.investorEntities.favoriteClients?.splice(index, 1)
        } else {
          // Add favorite investor client
          const favoriteClient: FavoriteInvestorClientGroupView = {
            userProfileId: userProfileResult.id,
            clientGroupId: rowData.id,
            partnerEntityId: rowData.partnerEntityId,
          }
          draft.investorEntities.favoriteClients?.push(favoriteClient)
        }
      })
    )
  }

  /** Set favorite column icon based on the  isFavorite flag value */
  const setFavoriteColumn = (cellData: ICellData<NameCardProps>) => {
    const selectedRowData = cellData.data!
    return (
      <span>
        {!selectedRowData.isFavorite ? (
          <StarBorderOutlinedIcon
            className='favorite-icon'
            type='success'
            onClick={(e) => {
              favoriteClickHandler(selectedRowData)
            }}
          />
        ) : (
          <StarIcon
            className='favorite-icon'
            onClick={(e) => {
              favoriteClickHandler(selectedRowData)
            }}
          />
        )}
      </span>
    )
  }

  /** Bind  navigate to context menu with the grid column */
  const setNavigateToColumn = (cellData: ICellData<NameCardProps>) => {
    const selectedRowData = cellData.data!

    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnMenuAction-${selectedRowData.id}-${selectedRowData.partnerEntityId}`

    // Buttons for the context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Documents',
        icon: 'documents',
        action: () => {
          navigate(
            getInvestorPageRoute(
              selectedRowData.id,
              selectedRowData.partnerEntityId
            )
          )
        },
      },
    ]

    // Add State manager option in context menu if state manager is enabled
    const taxYears =
      investorRelation.investorEntities.stateManagerTaxYears?.find(
        (s) =>
          s.clientGroupId === selectedRowData.id &&
          s.investorId === selectedRowData.partnerEntityId
      )
    if (taxYears && taxYears.taxYears?.length! > 0) {
      contextMenu.push({
        text: 'State Manager',
        icon: 'statemanager',
        action: () => {
          navigate(
            getStateManagerRouteInvestor(
              selectedRowData.id,
              selectedRowData.partnerEntityId
            )
          )
        },
      })
    }

    // Return the cell content with a button and a menu attached to it
    return (
      <>
        <Button
          id={buttonId}
          icon='spindown'
          data-testid='context-button'
          stylingMode='contained'
        />
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  return (
    <IsAuthorized permissions={[permissionRequestAny(Permissions.INVESTOR)]}>
      <div className='investor-landing-page'>
        <Container maxWidth='xl' className='cui-c_section cui-h_mt'>
          <DataGrid
            id='grid-landing-list-view'
            dataSource={sortedInvestorData}
            keyExpr='uniqueId'
            showBorders={true}
            columnAutoWidth={false}
            repaintChangesOnly={true}
            showColumnLines={false}
            showRowLines={false}
            wordWrapEnabled={true}
            height='calc(100vh - 222px)'
            noDataText={
              !investorRelation.hasLoaded
                ? 'Loading...'
                : investorRelation.hasLoaded &&
                  investorRelation.investorEntities.clientGroups?.length === 0
                ? 'No Data Found.'
                : ''
            }
            width='80%'
          >
            <SearchPanel visible={true} width='99%' />
            <Scrolling mode='virtual' rowRenderingMode='virtual' />
            <LoadPanel enabled={true} />
            <HeaderFilter visible={true} allowSearch={true} width='90%' />
            <Column
              allowSorting={false}
              caption='Favorite'
              allowFiltering={false}
              allowSearch={false}
              width='10%'
              alignment='center'
              cellRender={setFavoriteColumn}
            ></Column>
            <Column
              caption='Navigate To'
              width='30%'
              cellRender={setNavigateToColumn}
              allowSearch={false}
              allowSorting={false}
              allowFiltering={false}
              alignment='center'
            ></Column>
            <Column
              caption='Investment'
              dataField='name'
              allowSearch={true}
              allowFiltering={false}
              allowSorting={false}
              width='60%'
            ></Column>
            <Toolbar>
              <Item location='before'>
                <BTN
                  onClick={onPositionSortingChanged}
                  variant='outlined'
                  size='small'
                  className='button-align'
                >
                  Investment
                  <span
                    className='material-icons-outlined'
                    style={{ fontSize: '12px' }}
                  >
                    {sortOrder === 'desc' ? 'south' : 'north'}
                  </span>
                </BTN>
              </Item>
              <Item name='searchPanel' />
            </Toolbar>
          </DataGrid>
        </Container>
      </div>
    </IsAuthorized>
  )
}
