import { Button } from 'devextreme-react'
import { useRecoilValue } from 'recoil'
import {
  investorRelationsAtom,
  smGeneralElectionInfoAtom,
} from '../../state/atom'
import { useParams } from 'react-router-dom'
import { getInvestorLandingRoute } from '../utility/route-creation'
import './electionSubmissionComplete.scss'
import { Grid } from '@mui/material'
import { EntityType } from '../../model/entity-types'
import { handleContactEmail, renderFormattedDate } from './investor-utils'
import { useNavigate } from '../../hooks/useNavigationGuard'

export interface ElectionSubmissionCompleteProps {
  showElectionOverview: (isShowOverview: boolean) => void
}

export const ElectionSubmissionComplete = ({
  showElectionOverview,
}: ElectionSubmissionCompleteProps) => {
  const { groupId, taxYear, partnerId } = useParams() as {
    groupId: string
    taxYear: string
    partnerId: string
  }
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)
  const { investorEntities } = useRecoilValue(investorRelationsAtom)

  const navigate = useNavigate()

  //Get Client Group from investor entities
  const investorClientGroup = investorEntities?.clientGroups?.find(
    (i) => i.id === parseInt(groupId)
  )
  //Get Investor Name from investor entities
  const investorClientEntity = investorEntities?.clientGroupEntities?.find(
    (i) => i.id === parseInt(partnerId)
  )

  // InvestorName as Entity's LastName and FirstName or entityName
  const investorName =
    investorClientEntity?.entityType === EntityType.Business
      ? `${investorClientEntity.entityName}`
      : `${investorClientEntity?.firstName!} ${investorClientEntity?.lastName!}`

  // get the name from fiterd item
  const investorGroupName = investorClientGroup?.name

  let dateString: string = ''
  if (electionsInfo?.submittedDate) {
    dateString = renderFormattedDate(electionsInfo.submittedDate)
  }

  return (
    <div className='submission-complete-page'>
      <Grid className='page-header-items'>
        <Grid item xs={1} md={0.4} className='back-button'>
          <Button
            onClick={() => navigate(getInvestorLandingRoute())}
            stylingMode='outlined'
            className='back-button'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={11} md={11.6}>
          <p className='state-manager-text'>State Manager</p>
        </Grid>
      </Grid>
      <Grid item xs={11} md={11.6}>
        <span className='sub-header-text'>Submission Completed</span>
      </Grid>
      <h4>Date Submitted : {dateString}</h4>
      <p>
        Thank you for completing your State elections! If you wish to review
        your submission, please click the Submission Overview button below. If
        you believe that there are errors in your submitted elections, please
        contact the Investor Portal administration team by clicking the Contact
        Administration button below.
      </p>
      <Button
        text={'Submission Overview'}
        onClick={() => {
          showElectionOverview(true)
        }}
        className='submission-overview'
      />
      <Button
        text='Contact Administration'
        className='contact'
        onClick={() => {
          handleContactEmail(investorName, investorGroupName!, taxYear)
        }}
      />
    </div>
  )
}
