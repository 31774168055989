import { Button } from 'devextreme-react'
import { handleContactEmailWithBlankSubjectAndBody } from './investor-utils'
import { Grid } from '@mui/material'
import { getInvestorLandingRoute } from '../utility/route-creation'
import { useNavigate } from '../../hooks/useNavigationGuard'
import './investor-no-elections-submitted.scss'

export const InvestorNoElectionsSubmitted = () => {

  const navigate = useNavigate()

  return (
    <div className='investor-no-elections-submitted-container'>
      {/* this button is for Navigating to landing page */}
      <Grid className='page-header-items'>
        <Grid item xs={1} md={0.4} className='back-button'>
          <Button
            onClick={() => {
              navigate(getInvestorLandingRoute())
            }}
            data-testId='back-button'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={11} md={11.6}>
          <p className='state-overview-text'>State Manager</p>
        </Grid>
      </Grid>

      <Grid item xs={11} md={11.6} className='sub-header-grid'>
        <span className='sub-header-text'>Elections Not Submitted</span>
      </Grid>

      <p className='elections-not-submitted-message'>
        Per our records, you did not submit your State elections prior to the
        submission cutoff date. If you believe this is in error, please contact
        the Investor Portal administration team by clicking the Contact
        Administration button below.
      </p>

      {/* This button pops up email to Contact Administration */}
      <div className='contact-administration-button'>
        <Button 
          onClick={() => {
            handleContactEmailWithBlankSubjectAndBody()
          }}
          text="Contact Administration"
          type='normal'
        ></Button>
      </div>
    </div>
  )
}
