import React, { useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { ScrollableMessage } from '../investor-files/scrollableMessage'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { useParams } from 'react-router-dom'
import { SpinnerModal } from '../modal/spinnerModal'

export interface UnlockSelectionDialogProps {
  onCancel: () => void
  onConfirm: () => void
  isVisible: boolean
  sessionId: string | undefined
  investorId: number | undefined
  isSingle: boolean
  onComplete: () => void
}

export const UnlockSelectionDialog = ({
  onCancel,
  onConfirm,
  sessionId,
  isVisible,
  investorId,
  isSingle,
  onComplete,
}: UnlockSelectionDialogProps) => {

  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const stateManagerApi = useStateManagerApi()
  const [unlockSelectionPrmoise, setUnlockSelectionPromise] =
    useState<Promise<any>>()

  /** import values from params */
  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }

  /** Unlock Selections from masterAction menu */
  const onUnlockMultipleInvestors = () => {
    try {
      if (sessionId) {
        setShowSpinner(true)
        const unlockPromise = stateManagerApi
          .unlockSelections(sessionId)
          .then(() => {
            onComplete()
          })
        setUnlockSelectionPromise(unlockPromise)
      }
    } catch (err) {
      throw new Error(
        'Error occourred while unlocking Investor Selections' + err
      )
    }
  }

  /** Unlock Selections from datagrid row */
  const onUnlockSingleInvestor = () => {
    try {
      if (investorId) {
        const unlockPromise = stateManagerApi
          .unlockSelectionsForIndividualInvestor(
            investorId,
            parseInt(groupId),
            parseInt(taxYear),
            sessionId!
          )
          .then(() => {
            onComplete()
          })
        setUnlockSelectionPromise(unlockPromise)
      }
    } catch (err) {
      throw new Error(
        'Error occourred while unlocking Investor Selections' + err
      )
    }
  }

  /**  Create all the buttons, and pick and choose them later.*/
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm',
      onClick: async () => {
        setShowSpinner(true)
        if (isSingle === true) {
          onUnlockSingleInvestor()
        } else {
          onUnlockMultipleInvestors()
        }
      },
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'confirm-unlock-button',
      },
    },
  ]

  return (
    <>
      <Modal
        visible={isVisible}
        title={'Unlock Selections'}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={280}
        disableScrollbar={true}
      >
        <ScrollableMessage
          staticContent={
            <>
              This will remove the submission for the selected investor(s). Do
              you wish to proceed?
            </>
          }
          scrollableContent={''}
        ></ScrollableMessage>
      </Modal>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error Saving'
        inProgressTitleMessage='Unlock Selection'
        inProgressMessage='Selection is being unlocked'
        successTitleMessage='Unlock Successful'
        successMessage='Selection has been unlocked'
        onClose={() => {
          setShowSpinner(false)
          onConfirm()
        }}
        closeOnSuccess={true}
        apiAction={unlockSelectionPrmoise}
      />
    </>
  )
}