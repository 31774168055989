import { CheckBox, DataGrid } from 'devextreme-react'
import { useMemo, useRef } from 'react'
import {
  Column,
  Item,
  Scrolling,
  Toolbar,
  Paging,
} from 'devextreme-react/data-grid'
import { Grid } from '@mui/material'
import './entity-selector.scss'
import { EntityOptionsReport } from '../../../model/report-models'
import { ICheckBoxEvent } from './utils'

export interface AllocatingEntitySelectorProps {
  updateSelectedKeys: (selectedKeys: number[]) => void
  entityOptionsView: EntityOptionsReport[]
  selectedEntity: number[] | undefined
  height: string | number | (() => string | number) | undefined
  isAllocatingEntityLoaded: boolean
}

/** Investor allocating entity selector */
export const AllocatingEntitySelector = ({
  updateSelectedKeys,
  entityOptionsView,
  selectedEntity,
  height,
  isAllocatingEntityLoaded,
}: AllocatingEntitySelectorProps) => {
  const dataGridRef = useRef<DataGrid>(null)
  // If we don't have a entityOptionsView, then the data is not loaded
  //  yet, and we shouldn't show any options for the user to interact with.
  const isLoading = !entityOptionsView || !isAllocatingEntityLoaded

  /** Create a dataSource for the DataGrid*/
  const dataSource = useMemo(() => {
    // Map the data from entityOptionsView and change the isSelected proparty based on selectedEntity
    return entityOptionsView.map(
      (entityOptionsReport: EntityOptionsReport) => ({
        ...entityOptionsReport,
        isSelected:
          selectedEntity?.includes(entityOptionsReport.allocatingEntityId!) ??
          false,
      })
    )
  }, [entityOptionsView, selectedEntity])

  /** Define the handleSelectionChanged function to handle row selection changes */
  const handleSelectionChanged = (e: { selectedRowKeys: number[] }) => {
    updateSelectedKeys(e.selectedRowKeys)
  }

  // Set select all flag if all the entity are selected
  const isAllSelected = dataSource.every(
    (v: EntityOptionsReport) => v.isSelected
  )

  /** For SelectAll - set selected allocating entity in local state
   *  For UnselectAll - Remove all selected allocating entity
   */
  const handleSelectAll = (e: ICheckBoxEvent) => {
    if (e.event) {
      if (isAllSelected) {
        // Deselect All.
        updateSelectedKeys([])
      } else {
        // Select All.
        updateSelectedKeys(entityOptionsView!.map((v) => v.allocatingEntityId!))
      }
    }
  }

  return (
    <div className='entity-selector'>
      <div className='entity-selector-datagrid'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          dataSource={dataSource}
          keyExpr='allocatingEntityId'
          noDataText={isLoading ? 'Loading...' : 'No Data Found.'}
          width='100%'
          onSelectionChanged={handleSelectionChanged}
          columnAutoWidth={true}
          height={height}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={false}
          showColumnHeaders={false}
          data-testid='sm-entity-datagrid'
        >
          <Paging defaultPageSize={20} enabled={false} />
          <Scrolling mode='standard' showScrollbar={true} />
          <Toolbar>
            <Item location='before'>
              <Grid
                className='grid-container'
                container
                item
                xs={11}
                minWidth={550}
                justifyContent={'flex-start'}
              >
                <Grid item xs={4} className='select-all-chkbox'>
                  <div className='checkbox-container'>
                    <CheckBox
                      value={isAllSelected}
                      onValueChanged={handleSelectAll}
                    />
                    <span className='select-all-chkbox-text'>Select All</span>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Toolbar>
          <Column
            dataField='isSelected'
            caption=''
            width={50}
            cellRender={(rowData) => (
              <CheckBox
                value={rowData.data.isSelected}
                onValueChanged={(e) => {
                  const rowIndex = dataSource!.indexOf(rowData.data)
                  dataSource![rowIndex].isSelected = e.value
                  updateSelectedKeys(
                    dataSource!
                      .filter((item) => item.isSelected)
                      .map((item) => item.allocatingEntityId!)
                  )
                }}
              />
            )}
          />
          <Column
            dataField='allocatingEntityName'
            caption='Allocating Entity'
          />
        </DataGrid>
      </div>
    </div>
  )
}