import { SelectBox } from 'devextreme-react'
import { SelectBoxOptions } from '../../../../types/types'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

export interface CurrentViewProps {
  viewOptions: SelectBoxOptions[]
  defaultView: string
}

export const VIEW_SELECTOR_QUERY_PROPERTY = 'CurrentView'

/** Current View dropdown component  */
export const ViewSelectorControl = ({
  viewOptions,
  defaultView,
}: CurrentViewProps) => {
  const [queryString, setQueryString] = useSearchParams()
  const viewSelector = queryString.get(VIEW_SELECTOR_QUERY_PROPERTY)

  /** Method to update the entityManager search query in the URL. */
  const setEntityManagerUrl = useCallback(
    (viewSelectorOption: string | undefined) => {
      setQueryString((x) => {
        const newParams = new URLSearchParams(x)
        newParams.set(VIEW_SELECTOR_QUERY_PROPERTY, viewSelectorOption!)
        return newParams
      })
    },
    [setQueryString]
  )

  useEffect(() => {
    // if no view specified in url , then redirect to default view
    if (!viewSelector) {
      setEntityManagerUrl(defaultView)
    }
  }, [viewSelector, defaultView, setEntityManagerUrl])

  return (
    <div className='current-view-control' data-testid='view-selectbox'>
      <SelectBox
        items={viewOptions}
        displayExpr={'label'}
        valueExpr={'value'}
        value={viewSelector}
        onValueChange={setEntityManagerUrl}
      />
    </div>
  )
}
