import './investor-composite-elections.scss'
import { InvestorStateSelections } from './investor-state-selections'
import { useState } from 'react'
import SimpleTooltip from '../reuasble-components/simpleTooltip'

export interface InvestorCompositeElectionsProps {
  investorElectionsInfoId: number
  updateCanSave: (isUpdated: boolean) => void
  onDirtyStateChange: (isDirty: boolean) => void
  setParentButtons: (buttons: React.ReactNode) => void
}

/** Composites elections tab component */
export const InvestorCompositeElections = ({
  investorElectionsInfoId,
  updateCanSave,
  onDirtyStateChange,
  setParentButtons,
}: InvestorCompositeElectionsProps) => {
  const [isElectionAvailable, setIsElectionAvailable] = useState<boolean>(true)

  return (
    <div className='investor-composite-elections'>
      {isElectionAvailable && (
        <div className='field-label'>
          Select your States for Composite Elections
          <SimpleTooltip popupMessage='Make selections below. Selecting a State below indicates that you are requesting to have State composite filings completed on your behalf' />
        </div>
      )}
      <InvestorStateSelections
        investorElectionsInfoId={investorElectionsInfoId}
        entityOptionsId={undefined}
        onDirtyStateChange={onDirtyStateChange}
        updateCanSave={updateCanSave}
        setElectionAvailable={setIsElectionAvailable}
        setParentButtons={setParentButtons}
      ></InvestorStateSelections>
    </div>
  )
}
