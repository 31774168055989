import { useEffect, useMemo, useState } from 'react'
import { atomFamily, useRecoilState, useResetRecoilState } from 'recoil'
import { FileUploadState } from './file-upload-model'

/*
    A scenario exists where the FileUpload control can be embedded in
    a form, which destroys and re-creates the FileUpload control, and
    loses its state.  This functionality supports that scenario.
 */

/** Holds special-case state for the FileUpload control. */
export const fileUploadAtom = atomFamily<FileUploadState, number>({
  key: 'FILE_UPLOAD_STATE',
  default: {
    state: 'Nothing Selected',
    progress: 0,
  },
})

/** Sets up and returns a unique key (number) for holding on to
 *   state used in the FileUpload control.  This also handles
 *   the disposal of the state when this hook goes out of scope. */
export const useFileUploadState = () => {
  // Create a random number used for the key for this hook call.
  //  We don't want 0 values, so 1 is added to the value.
  const stateKey = useMemo(() => Math.ceil(Math.random() * 1000000) + 1, [])
  // Get the reset function for the state related to this key.
  const resetState = useResetRecoilState(fileUploadAtom(stateKey))

  useEffect(() => {
    // Provide the teardown of the state.
    //  The initial value is already taken care of.
    return () => {
      resetState()
    }
  }, [resetState])

  // Return the key so it can be used in the UploadFile component.
  return stateKey
}

/** Returns get/set state functions, using local state or recoil state, depending
 *   on whether or not a stateKey is provided. */
export const useFileUploadStateInternal = (stateKey?: number) => {
  // Get a local state value.  Hooks cannot be optional, so
  //  we must get a value, regardless of whether or not it's used.
  const localState = useState<FileUploadState>({
    state: 'Nothing Selected',
    progress: 0,
  })

  // Get a global state value.  Hooks cannot be optional, so
  //  we must get a value, regardless of whether or not it's used.
  const globalState = useRecoilState(fileUploadAtom(stateKey ?? 0))

  // Return the state items, based on whether or not we have a key.
  if (stateKey) {
    return globalState
  } else {
    return localState
  }
}
