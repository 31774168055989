import { EmailSentOperationResult } from '../../api-client/investor-portal-client'
import { SignalRWatcher } from './signalr-watcher'

/** Watches for the Email Notifications to Build , Send and Complete and then resolves the promise */
export class SendEmailNotificationWatcher extends SignalRWatcher {
  constructor(
    apiPromise: Promise<EmailSentOperationResult>,
    readonly generalOptionsId: number
  ) {
    super('StateManagerEmailProcessUpdated', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)

    // When the promise resolves, set the notificationOperationId and call operationComplete().
    apiPromise.then((results) => {
      this.notificationOperationId = results.operationId!
      if (this.notificationOperationId === 0) {
        this.operationComplete()
      }
    })
  }

  notificationOperationId?: number

  handlerAction(operationId: number) {
    if (this.notificationOperationId === operationId) {
      this.operationComplete()
    }
  }
}
