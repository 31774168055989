import { InvestorElectionsCard } from './investor-election-card'
import { useEffect, useState } from 'react'
import { useStateManagerApi } from '../../../hooks/use-state-manager-api'
import { InvestorWithholdingEntity } from '../../../api-client/investor-portal-client'
import './investor-withholding-review.scss'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

export interface InvestorElectionDetailsProps {
  investorElectionsInfoId: number | undefined
}

/** This component is responsible for fetch the all entities for investorElectionInfoId and then
 * fetch the all states for each allocatingEntity and investorElectionInfoId ro review */
export const InvestorWithholdingReview = ({
  investorElectionsInfoId,
}: InvestorElectionDetailsProps) => {
  const [investorWithholdingEntities, setInvestorWithholdingEntities] =
    useState<InvestorWithholdingEntity[]>([])
  const stateManagerApi = useStateManagerApi()

  useEffect(() => {
    const abortController = new AbortController()

    /** Load the investor Investor Withholding Entity data based on investorElectionsInfoId*/
    const loadWithHoldingData = async () => {
      let investorWithHoldings: InvestorWithholdingEntity[] =
        await stateManagerApi.getInvestorWithholdingEntities(
          investorElectionsInfoId!,
          abortController.signal
        )
      investorWithHoldings = investorWithHoldings
        .map((x) => ({ sortName: x.name!.toLocaleLowerCase(), name: x }))
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
        .map((x) => x.name)
      setInvestorWithholdingEntities(investorWithHoldings)
    }

    /** Load the investor Investor Withholding Entity data from the API */
    loadWithHoldingData()

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [investorElectionsInfoId])

  return (
    <div className='grid investor-withholding-review-page'>
      <div className='col-12 grid-border border-round-sm margin-for-header'>
        <div className='text-left'>
          <div className='grid'>
            <div className='col-12 bottom-border-style'>
              <div className='text-left font-bold p-2'>
                Withholding Elections
              </div>
            </div>
          </div>
          <div className='grid'>
            {investorWithholdingEntities.map((item) => {
              return (
                <InvestorElectionsCard
                  isComposite={false}
                  investorElectionsInfoId={investorElectionsInfoId}
                  entityOptionsId={item.entityOptionId}
                  entityName={item.name}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
