import react from 'react'
import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { BannerHeader } from '../../common-components/banners/bannerHeader'

export const TermsOfUseBanner = () => {
  return (
    <BannerContainer className='single-title'>
      <BannerHeader>Terms Of Use</BannerHeader>
    </BannerContainer>
  )
}
