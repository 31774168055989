import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modalBase'
import './manageEmailTemplateDialog.scss'
import { RequiredRule } from 'devextreme-react/validator'
import { Form, Item } from 'devextreme-react/form'
import { useFileClientApi } from '../../../hooks/use-file-api'
import { EmailTemplate, File } from '../../../api-client/investor-portal-client'
import { SpinnerModal } from '../spinnerModal'
import { ConfirmationModal } from './confirmationModal'
import produce from 'immer'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  childFileListAtom,
  parentFileListAtom,
  entityGroupPartnershipStateAtom,
  clientAndPartnershipStateAtom,
} from '../../../state/atom'
import { PreviewEmailTemplateDialog } from './previewEmailTemplate'
import { isGenericFile } from '../../investor-files/fileUtilities'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

export interface ManageEmailTemplateDialogProps {
  onCancel: () => void
  partnershipId: number
  entityGroupId: number
  parentFileId: number | undefined
}

//To set Title of Modal
export const customizationLevel = (
  entityGroupId: number,
  partnershipId: number,
  parentFileId: number
) => {
  return partnershipId && parentFileId && entityGroupId
    ? 'File '
    : entityGroupId && partnershipId
    ? 'Entity'
    : entityGroupId
    ? 'Client'
    : 'Default'
}

const baseButtonProps = {
  useSubmitBehavior: true,
  stylingMode: 'contained',
}

export const ManageEmailTemplateDialog = ({
  onCancel,
  partnershipId,
  entityGroupId,
  parentFileId,
}: ManageEmailTemplateDialogProps) => {
  const [parentFiles, setParentFiles] = useRecoilState(parentFileListAtom)
  const [childFiles, setChildFiles] = useRecoilState(childFileListAtom)
  const partnershipsState = useRecoilValue(entityGroupPartnershipStateAtom)
  const { clientGroupId } = useRecoilValue(clientAndPartnershipStateAtom)
  const formRef = useRef<Form>(null)
  const [titleText, setTitleText] = useState<string>('')
  const [showCancelConfirmModal, setShowCancelConfirmModal] =
    useState<boolean>(false)
  const [formData, setFormData] = useState<EmailTemplate>()
  const [emailTemplateData, setEmailTemplateData] = useState({
    subject: '',
    body: '',
    additionalMarkup: '',
  })
  const [formValidate, setFormValidate] = useState<boolean>(false)
  const [savePromise, setSavePromise] = useState<Promise<any>>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [previewDisabled, setPreviewDisabled] = useState(false)

  const [preview, setPreview] = useState(false)
  const parentId: number = parentFileId ?? 0
  const {
    getEmailTemplate,
    saveEmailTemplate,
    updateEmailTemplate,
    deleteEmailTemplate,
  } = useFileClientApi()

  //Setting up Template title ,subject and body from API
  useEffect(() => {
    const abortController = new AbortController()

    getEmailTemplate(
      entityGroupId,
      partnershipId,
      parentFileId,
      abortController.signal
    ).then((response: EmailTemplate) => {
      setFormData(response)
      setEmailTemplateData({
        subject: response?.subject!,
        body: response?.body!,
        additionalMarkup: response?.additionalMarkup!,
      })
      //To set Dynamic title for the Email Template
      setTitleText(
        customizationLevel(
          response?.entityGroupId!,
          response?.partnershipEntityId!,
          response?.parentFileId!
        )
      )
    })

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [entityGroupId, parentFileId, partnershipId, parentId])

  //To Set Fixed Modal Title
  const modalLevel = customizationLevel(entityGroupId, partnershipId, parentId)

  const isFileListForCurrentEntityGroup = useCallback(
    (file: File, isChildFilesCheck: boolean) => {
      if (partnershipsState && typeof clientGroupId === 'number') {
        return (
          // This accounts for when we're on the client staus page, and make changes.
          (partnershipId === 0 &&
            partnershipsState[clientGroupId].clients
              ?.map((c) => c.id)
              .includes(file.partnershipEntityId)) ||
          // This accounts for if we're are on the partnership page, and make changes.
          (partnershipId !== 0 && file.partnershipEntityId === partnershipId) ||
          //This accounts for if we are on child page and to when partnershipEntityId = 0 to make changes
          (isChildFilesCheck && file.partnershipEntityId === 0)
        )
      }

      return false
    },
    [clientGroupId, partnershipsState, partnershipId]
  )

  //Updating and Saving the Email Template
  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()
    if (!formRef.current?.instance?.validate().isValid) {
      return
    }

    setShowSpinner(true)

    const template = formRef.current?.props.formData

    // If our template does not match the props of the component.
    if (
      template.entityGroupId !== entityGroupId ||
      template.partnershipEntityId !== partnershipId ||
      template?.parentFileId !== parentId
    ) {
      // This section is when a new template is being created (not updating an existing template):

      // Update the template to match the props.
      template.entityGroupId = entityGroupId
      template.partnershipEntityId = partnershipId
      template.parentFileId = parentId

      // This will make any changes save the data to the server, and not try to update existing records.
      template.id = 0

      // Call the save API.
      const savePromise = saveEmailTemplate(template)

      // Set the promise on the spinner dialog.
      setSavePromise(savePromise)

      // Wait for the save to complete, and collect the response.
      const response = await savePromise

      setShowSpinner(false)
      setFormData(response)

      // Update state so we can track changes.
      emailTemplateData.subject = template.subject
      emailTemplateData.body = template.body

      //To reset the Title of Modal to existing Level
      setTitleText(modalLevel)

      //Setting Email Template Type in Column of Parent File after Saving the existing Modal
      const newTemplateType: string = customizationLevel(
        template?.entityGroupId!,
        template?.partnershipEntityId!,
        template?.parentFileId!
      )

      //tracking parent ids which are zero while updating entity level template
      setParentFiles(
        produce((draft) => {
          draft.files.forEach((f) => {
            // Check that this file is is supposed to be updated.
            //  We may not want to update our file store if the files
            //  do not belong to the current ClientGroup and/or Partnership
            if (isFileListForCurrentEntityGroup(f, false)) {
              if (template?.parentFileId && f.id === template?.parentFileId) {
                f.emailTemplate = newTemplateType
              } else if (
                !template?.parentFileId &&
                (f.emailTemplate === 'Client' || f.emailTemplate === 'Default')
              ) {
                f.emailTemplate = newTemplateType
              }
            }
          })
        })
      )

      let parentIds: number[] = parentFiles.files
        .filter(
          (f) =>
            !template?.parentFileId &&
            (f.emailTemplate === 'Client' || f.emailTemplate === 'Default')
        )
        .map((file) => file.id!)

      updateChildEmailTemplateType(
        template?.parentFileId!,
        newTemplateType,
        parentIds
      )
    } else {
      // Hide the confirmation model.
      setShowCancelConfirmModal(false)

      // This section updates and existing template (not saving new):
      const savePromise = updateEmailTemplate(template.id, template)

      // Set promise on the spinner dialog.
      setSavePromise(savePromise)

      // Wait for the API call to finish, and collect the response.
      const response = await savePromise

      // Close the spinner dialog.
      setShowSpinner(false)

      // Update the form data.
      setFormData(response)

      // Update the change tracking data.
      emailTemplateData.subject = template.subject
      emailTemplateData.body = template.body

      //To reset the Title of Modal to existing Level
      setTitleText(modalLevel)
    }
  }

  //Setting Email Template Type in Column of Child File after Saving the existing Modal
  const updateChildEmailTemplateType = (
    parentId: number | undefined,
    newType: string,
    parentIds: number[]
  ) => {
    if (childFiles) {
      setChildFiles(
        produce((draft) => {
          draft.files.forEach((f) => {
            // Check that this file is is supposed to be updated.
            //  We may not want to update our file store if the files
            //  do not belong to the current ClientGroup and/or Partnership
            if (isFileListForCurrentEntityGroup(f, true)) {
              if (parentId !== 0 && parentId === f.parentId) {
                f.emailTemplate = newType
              }
              if (parentIds.includes(f.parentId!)) {
                f.emailTemplate = newType
              }
            }
          })
        })
      )
    }
  }

  //Handling Delete customisation
  const handleDelete = async () => {
    if (formData?.id) {
      await deleteEmailTemplate(formData?.id!)
      setDeleteModal(true)
    }
  }

  //Setting Email Template Type in Column of Parent File after Deleting the existing Modal
  const templateTypeAfterDelete = async () => {
    const response = await getEmailTemplate(
      entityGroupId,
      partnershipId,
      parentFileId
    )

    //Setting Email Template Type in Column of Parent File after Deleting the existing Modal
    const newTemplateType: string = customizationLevel(
      response?.entityGroupId!,
      response?.partnershipEntityId!,
      response?.parentFileId!
    )

    //tracking parent ids which are zero while updating entity level template
    setParentFiles(
      produce((draft) => {
        draft.files.forEach((f) => {
          // Skip "Generic" files.
          if (isGenericFile(f)) {
            return
          }

          // Check that this file is is supposed to be updated.
          //  We may not want to update our file store if the files
          //  do not belong to the current ClientGroup and/or Partnership
          if (isFileListForCurrentEntityGroup(f, false)) {
            if (f.id === parentFileId) {
              f.emailTemplate = newTemplateType
            }
            if (f.emailTemplate !== 'File') {
              f.emailTemplate = newTemplateType
            }
          }
        })
      })
    )

    let parentIds: number[] = parentFiles.files
      .filter((f) => f.emailTemplate !== 'File')
      .map((file) => file.id!)
    updateChildEmailTemplateType(parentFileId, newTemplateType, parentIds)
  }

  //Boolean used to Disable Delete button
  const canDelete = modalLevel === titleText
  //Checking changes with prior editing to execute cancel button
  const checkChanges = () => {
    const template = formRef.current?.props.formData
    if (
      template?.subject !== emailTemplateData.subject ||
      template?.body !== emailTemplateData.body
    ) {
      setShowCancelConfirmModal(true)
    } else {
      onCancel()
    }
  }

  const checkChangesforSave = async () => {
    const template = formRef.current?.props.formData
    if (
      template?.subject !== emailTemplateData.subject ||
      template?.body !== emailTemplateData.body
    ) {
      await handleSubmit()
    }
    setPreview(true)
  }
  //Validating the Form
  useEffect(() => {
    // Fire form validation if form is invalid
    if (formValidate) {
      formRef.current?.instance.validate()
    }
  }, [formValidate])

  function formRefresh() {
    const formValid = !formRef.current?.instance.validate().isValid
    //Set flag to call form validation explicitly (workaround to fire form validation)
    setFormValidate(formValid)
    setSaveDisabled(formValid)
    setPreviewDisabled(formValid)
  }

  // Create the buttons for the modal.
  const buttonFunctions = useMemo(() => {
    const buttons = [
      {
        label: 'Preview Email',
        display: true,
        isDisabled: previewDisabled,
        alignment: 'left',
        buttonProps: {
          className: 'preview-mail-btn',
          ...baseButtonProps,
          'data-testid': 'preview-mail-button',
        },
        onClick: () => {
          checkChangesforSave()
        },
      },
      {
        label: 'Cancel',
        display: true,
        isDefaultAction: true,
        buttonProps: {
          className: 'cancel-btn',
          ...baseButtonProps,
          'data-testid': 'cancel-button',
        },
        onClick: () => checkChanges(),
      },
      canDelete && {
        label: 'DELETE CUSTOMIZATION',
        display: true,
        buttonProps: {
          className: 'delete-cust-btn',
          ...baseButtonProps,
          'data-testid': 'delete-cust-button',
        },
        onClick: () => setDeleteModal(true),
      },
      {
        label: 'Save',
        display: true,
        isDisabled: saveDisabled,
        buttonProps: {
          className: 'save-btn',
          ...baseButtonProps,
          'data-testid': 'save-email-template-button',
          type: 'default',
        },
        onClick: () => {
          handleSubmit()
        },
      },
    ] as (ButtonFunction & { display?: boolean })[]

    // Return the buttons we need, based on their display property.
    return buttons.filter((x) => !!x.display)
  }, [checkChanges])

  //Values assigned in Html Editor
  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']
  const toolbarItems = [
    {
      name: 'size',
      icon: 'size',
      acceptedValues: sizeValues,
      options: { height: 30, width: 75 },
    },
    { name: 'separator', icon: 'separator' },
    { name: 'bold', icon: 'bold' },
    { name: 'color', icon: 'color' },
    { name: 'separator', icon: 'separator' },
    { name: 'alignleft', icon: 'alignleft' },
    { name: 'alignCenter', icon: 'alignCenter' },
    { name: 'alignRight', icon: 'alignRight' },
    { name: 'alignJustify', icon: 'alignJustify' },
    { name: 'orderedList', icon: 'orderedList' },
    { name: 'bulletList', icon: 'bulletList' },
    { name: 'separator', icon: 'separator' },
    { name: 'link', icon: 'link' },
  ]
  const htmlEditorOptions = {
    height: 330,
    maxLength: 200,
    toolbar: {
      items: toolbarItems,
    },
    onValueChanged: () => {
      formRefresh()
    },
  }

  return (
    <div>
      <Modal
        visible={!preview}
        title={'Edit > ' + titleText + ' Template'}
        buttonFunctions={buttonFunctions}
        maxWidth={750}
        maxHeight={600}
        showCloseButtonInTitle={true}
        className='edit-email-modal'
        testId='manage-email-template-dialog'
      >
        <div
          className='form-container form-scroll-horizontal'
          data-testid='form-screen'
        >
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={false}
            showValidationSummary={false}
            ref={formRef}
          >
            <Item
              dataField='subject'
              editorType='dxTextBox'
              label={{ text: 'Subject' }}
              editorOptions={{
                onValueChanged: () => {
                  formRefresh()
                },
              }}
            >
              <RequiredRule message='Subject is required' />
            </Item>
            <Item
              dataField='body'
              editorType='dxHtmlEditor'
              label={{ text: 'Body' }}
              editorOptions={htmlEditorOptions}
            >
              <RequiredRule message='Body is required' />
            </Item>
          </Form>
        </div>
      </Modal>
      {showCancelConfirmModal && (
        <ConfirmationModal
          functionalButton={() => setShowCancelConfirmModal(false)}
          closeEmailTemplate={() => onCancel()}
          title='Unsaved Changes'
          modalMessage='There are unsaved changes to this email template. If you close now,
          these changes will not be saved. Do you wish to proceed?'
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          functionalButton={() => setDeleteModal(false)}
          closeEmailTemplate={async () => {
            onCancel()
            await handleDelete()
            await templateTypeAfterDelete()
          }}
          title='Delete Customization'
          modalMessage='This action is permanent, and cannot be undone. Do you wish to proceed?'
        />
      )}
      {showSpinner && (
        <SpinnerModal
          visible={true}
          errorTitleMessage='Error'
          errorMessage='Save failed'
          inProgressTitleMessage='Saving Changes'
          inProgressMessage='Your changes are being saved'
          successTitleMessage='Saving Successful'
          successMessage='The Email Template has been saved.'
          onClose={() => setShowSpinner(false)}
          apiAction={savePromise}
        />
      )}
      {preview && (
        <PreviewEmailTemplateDialog
          onCancel={() => setPreview(false)}
          title={titleText}
          emailTemplate={formData!}
          additionalMarkUp={emailTemplateData.additionalMarkup!}
        />
      )}
    </div>
  )
}
