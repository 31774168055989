import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { Form, Item, RequiredRule, SimpleItem } from 'devextreme-react/form'
import {
  EditParentFileModel,
  emailTemplates,
  fileDocumentSubTypes,
} from '../../client-models/clientmodels'
import { FileUpload } from '../fileUpload/fileUpload'
import { uploadK1Document } from '../utility/upload-k1-document'
import { Subject } from 'rxjs'
import { FormattedDialog } from '../modal/formattedDialog'
import { getTaxYearValues } from './fileUtilities'
import DataSource from 'devextreme/data/data_source'

export interface AddDocumentDialogProps {
  onCancel: () => void
  onSave: () => void
  isVisible: boolean
  clientId: number
  partnershipId: number
  entityGroupId: number
}

const baseButtonProps = {
  useSubmitBehavior: true,
  stylingMode: 'contained',
  width: 180,
}

export const AddDocumentDialog = ({
  onCancel,
  onSave,
  isVisible,
  clientId,
  partnershipId,
  entityGroupId,
}: AddDocumentDialogProps) => {
  const [fileFormInfo, setFileFormInfo] = useState({
    fileType: fileDocumentSubTypes[0],
    emailTemplate: emailTemplates[0],
  } as Partial<EditParentFileModel> & Pick<EditParentFileModel, 'fileType' | 'emailTemplate'>)
  const [uploadTriggered, setUploadTriggered] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [disableAddDocument, setDisableAddDocument] = useState(false)
  const [uploadTriggerSubject, setTriggerSubject] = useState(
    new Subject<void>()
  )
  const [uploadTrigger, setUploadTrigger] = useState(() =>
    uploadTriggerSubject.asObservable()
  )
  const [errorModalDetails, setErrorModalDetails] = useState({
    visible: false,
    errorMessage: '',
    showGenericError: true,
  })
  const formRef = useRef<Form>(null)

  // We need to reset the inputs if the our visibility changes.
  useEffect(() => {
    if (!isVisible) {
      setSelectedFile(undefined)
      setUploadTriggered(false)
      setDisableAddDocument(true)
      setFileFormInfo({
        fileType: fileDocumentSubTypes[0],
        emailTemplate: emailTemplates[0],
      })
    }
  }, [isVisible])

  /** Boolean value indicating whether or not the form is able to be submitted. */
  const canSubmit = formRef.current?.instance?.validate().isValid ?? false

  const closeErrorModal = useCallback(() => {
    setUploadTriggered(false)
    setErrorModalDetails({
      visible: false,
      errorMessage: '',
      showGenericError: true,
    })
  }, [])

  // Create the buttons for the modal.
  const buttonFunctions = useMemo(() => {
    // Create all of the buttons, and pick and choose them later.
    const buttons = [
      {
        label: 'Cancel',
        display: !uploadTriggered,
        isDefaultAction: true,
        buttonProps: {
          ...baseButtonProps,
          'data-testid': 'cancel-button',
        },
        onClick: () => onCancel(),
      },
      {
        label: 'Add Document',
        display: !uploadTriggered,
        isDisabled:
          uploadTriggered || !selectedFile || !canSubmit || disableAddDocument,
        buttonProps: {
          ...baseButtonProps,
          'data-testid': 'add-document-button',
          type: 'default',
        },
        onClick: () => {
          if (!selectedFile || !formRef.current?.instance.validate().isValid) {
            return
          }
          setUploadTriggered(true)
          uploadTriggerSubject.next()
        },
      },
      {
        label: 'Close',
        isDefaultAction: true,
        display: uploadTriggered,
        buttonProps: {
          ...baseButtonProps,
          'data-testid': 'close-button',
        },
        onClick: () => onSave(),
      },
    ] as (ButtonFunction & { display?: boolean })[]

    // Return the buttons we need, based on their display property.
    return buttons.filter((x) => !!x.display)
  }, [
    uploadTriggered,
    selectedFile,
    canSubmit,
    onCancel,
    uploadTriggerSubject,
    onSave,
    disableAddDocument,
  ])

  /** Make readonly for non-editable fields */
  const disableFormField = {
    readOnly: true,
  }

 /**
   * Keeping standerd documentTypes in store for dropdown options
   * Reason behind keeping in store is to always show the dropdown options while typing the text
   */ 
   const documentTypeDataSource = new DataSource({
    store: {
      data: fileDocumentSubTypes,
      type: 'array',
      key: '',
    },
  })

  /** File type dropdown options */
  const fileTypeEditorOptions = {
    dataSource: documentTypeDataSource,
    value: fileFormInfo.fileType,
    acceptCustomValue: true,
  }

  /** Email template dropdown options */
  const emailTemplateEditorOptions = {
    items: emailTemplates,
    searchEnabled: true,
    readOnly: true,
    value: fileFormInfo.emailTemplate,
    hint: 'Functionality not currently available',
  }

  const taxYearChanged = () => {
    setDisableAddDocument(!formRef.current?.instance.validate().isValid)
  }

  /** Tax year dropdown options */
  const taxYearEditorOptions = {
    items: getTaxYearValues(fileFormInfo.taxYear!, true),
    searchEnabled: true,
    value: fileFormInfo.taxYear,
    onValueChanged: taxYearChanged,
  }
  /** Called by the file upload control if an error occurs during upload.  This will show the error modal. */
  const uploadErrorHandler = useCallback((err: any) => {
    let errorDetail: string = ''
    let isError: boolean = true
    if (err.response) {
      errorDetail = err.response.replace(/^"(.*)"$/, '$1')
      isError = false
    } else {
      errorDetail = err?.toString()
    }
    setErrorModalDetails({
      visible: true,
      errorMessage: errorDetail,
      showGenericError: isError,
    })
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <div>
      <Modal
        visible={isVisible}
        title={'Add Document'}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={750}
        showCloseButtonInTitle={true}
      >
        <div className='ModalConfirmation' data-testid='add-document-dialog'>
          <div className='form-container form-scroll-horizontal'>
            <Form
              formData={fileFormInfo}
              readOnly={false}
              showColonAfterLabel={false}
              showValidationSummary={false}
              ref={formRef}
              disabled={uploadTriggered}
            >
              <Item
                dataField='documentFileName'
                editorOptions={disableFormField}
                editorType='dxTextBox'
              />
              <SimpleItem
                dataField='taxYear'
                data-testid='tax-year-input'
                editorType='dxSelectBox'
                editorOptions={taxYearEditorOptions}
              >
                <RequiredRule message='Tax year is required' />
              </SimpleItem>
              <SimpleItem
                dataField='fileType'
                editorType='dxSelectBox'
                editorOptions={fileTypeEditorOptions}
              >
              <RequiredRule message='File Type is required' />
              </SimpleItem>
              <Item
                dataField='emailTemplate'
                editorType='dxSelectBox'
                editorOptions={emailTemplateEditorOptions}
              />
            </Form>
            <div>Document Upload</div>
            <FileUpload
              uploadFileFn={(progress: (x: number) => void) =>
                uploadK1Document(
                  selectedFile!,
                  clientId,
                  fileFormInfo.fileType.trim(),
                  fileFormInfo.taxYear,
                  partnershipId,
                  entityGroupId,
                  progress
                )
              }
              uploadFileTrigger={uploadTrigger}
              setFileSelection={(file: File | undefined) => {
                setFileFormInfo({
                  ...fileFormInfo,
                  documentFileName: file?.name ?? '',
                })
                setSelectedFile(file)
              }}
              fileAcceptance={{
                acceptTypes: '.pdf, .zip',
                acceptanceMessage: '.pdf or .zip',
              }}
              onUploadComplete={() => onSave()}
              onUploadError={uploadErrorHandler}
            ></FileUpload>
          </div>
        </div>
      </Modal>
      <FormattedDialog
        visible={errorModalDetails.visible}
        dialogType='error'
        onCloseClicked={closeErrorModal}
      >
        {errorModalDetails.showGenericError && (
          <div>The upload could not be completed. Please try again later.</div>
        )}
        {!errorModalDetails.showGenericError && (
          <div>{errorModalDetails.errorMessage}</div>
        )}
      </FormattedDialog>
    </div>
  )
}
