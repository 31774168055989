/** These Flags we are using for unMapped zip upload file below are the description for for each
 * !These FileFlags enum values must match with Investor Portal API's FileFlags Enums available in shared models(Tsa.InvestorPortal\RSM.Tsa.InvestorPortal.Shared.Models\Investor\FileFlags.enum)
 */
export enum FileFlags {
  // When any file is zip with mapping or a PDF file
  NotSet = 0x0,

  // When file is zip with mapping excel sheet
  IsUnMappedZip = 0x1,

  // When file is zip without mapping sheet and user manually verified the files are linked to correct investors
  UnmappedZipVerified = 0x2,
}

/// <summary>
/// ZipUploadFlagProperty enumeration is used to get OR set values for ZipUploadStatus, which is related to RSM.Tsa.InvestorPortal.Api.Data.File type.
/// </summary>
export enum FileFlagProperties {
  // Will check this against the FileFlags if unMappedZip File
  IsUnmappedZipFile = 0x1,

  // When zipFile is verified status
  IsZipVerified = 0x2,
}
