import { REACT_APP_INVESTORPORTALDOCUMENTMANAGERFILESURL, REACT_APP_INVESTORPORTALURL } from "../envVariables";
import { File } from '../api-client/investor-portal-client'
import { saveBlob } from "../components/utility/saveBlobFn";
import { getAccessToken } from "@rsmus/react-auth";

/** url validation to confirm if user is able to connect the server  */
export const verifyAzureBlobStorageServerAccess = async () => {
  try {
    let url_ = REACT_APP_INVESTORPORTALDOCUMENTMANAGERFILESURL

    const request = await fetch(url_, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })

    return request.status === 200 || request.status === 404;
  } catch (err) {
    return false
  }
}

/** Calls the API to get blob storage uri 
 /* directly connect the azure blob storage server using the url and download the file
*/
export const downloadFileFromApi = async (file: File) => {
  // Get the blob data from Investor Portal API 
  const downloadUrl = REACT_APP_INVESTORPORTALURL + `/files/downloadfile/${file.id}`;
  const accessToken = await getAccessToken()
  const request = await fetch(downloadUrl, {
    headers: {
      'Authorization': 'bearer ' + accessToken!
    }
  })

  await saveBlob(await request.blob(), file.externalFileName!)
}

/** Calls the API to get blob storage uri 
 /* directly connect the azure blob storage server using the url and download the file. */
export const downloadFileDirectly = async (sasUri: string, file: File) => {
  // Get the blob data from Investor Portal API 
  const request = await fetch(sasUri)
  await saveBlob(await request.blob(), file.externalFileName!)
}

/** Calls the API to get blob storage uri 
 /* directly connect the azure blob storage server using the url and download the file. */
 export const downloadFileDirectlyByFileName = async (sasUri: string, fileName: string) => {
  // Get the blob data from Investor Portal API 
  const request = await fetch(sasUri)
  await saveBlob(await request.blob(), fileName)
}

/** Calls the API to get blob storage uri 
 /* directly connect the azure blob storage server using the url and download the file
*/
export const downloadPublicFileFromApi = async (fileName: string) => {
  // Get the blob data from Investor Portal API 
  const downloadUrl = REACT_APP_INVESTORPORTALURL + `/files/GetPublicFile/${fileName}`;
  const accessToken = await getAccessToken()
  const request = await fetch(downloadUrl, {
    headers: {
      'Authorization': 'bearer ' + accessToken!
    }
  })

  await saveBlob(await request.blob(), fileName)
}

/** Calls the API to get blob storage uri and file name
 /* directly connect the azure blob storage server using the url and download the file. */
 export const downloadFileFromBlobStorage = async (fileUri: string, fileName: string) => {
  // Get the blob data from Investor Portal API 
  const request = await fetch(fileUri)
  await saveBlob(await request.blob(), fileName)
}

