import { useRecoilValue } from 'recoil'
import { smGeneralElectionInfoAtom } from '../../../state/atom'
import { usStatesData } from '../../state-manager/state-manager-utils'
import { ForeignCountryEnum } from '../investor-utils'

/** This component will show the general information(stateOfResidency and entityType) in the review page */
export const InvestorGeneralReview = () => {
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)
  /**Find the state name from the static state data
   * If stateOfResidency is 'Foreign' then keep as 'Foreign Country' */ 

  const stateName = electionsInfo?.stateOfResidency === ForeignCountryEnum.StateCode
      ? ForeignCountryEnum.Name
      : usStatesData.find(
          (x) => x.stateCode === electionsInfo?.stateOfResidency
        )?.name

  return (
    <>
      <div className='col-6 grid-border border-round-sm'>
        <div className='text-left'>
          <div className='grid'>
            <div className='col-12 bottom-border-style'>
              <div className='text-left font-bold p-2'>General</div>
            </div>
          </div>
          <div className='grid'>
            <div className='col-4'>
              <div className='text-left font-light p-2'>Entity Type:</div>
              <div className='text-left font-light p-2'>
                State of Residency:
              </div>
            </div>
            <div className='col-8'>
              <div className='text-right font-light p-2'>
                {electionsInfo?.entityType}
              </div>
              <div className='text-right font-light p-2'>{stateName}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
