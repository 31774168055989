

//instance of prefix of domain in email ex:'abcdef+123' of abcdef+123@gmail.com
const em_token = '([\\w!#$%&\\.\'*+\\-\\/=?\\^_`{|}~]+)'

const domain_token = '([0-9a-zA-Z\\.\\-]+)'

const em_words = '[0-9a-zA-Z]'

/** Pattern to delimitate between email addresses. */
const em_delim = '(\\s*;\\s*)'

/** Pattern to identify just a domain, without the email address. */
export const domainWildcardPattern = `(@${domain_token}\\.${em_words}+)`

/** Pattern for a domain (i.e. @domain.com) OR an email. */
export const domainOrEmailPattern = `(${em_token}?@${domain_token}\\.${em_words}+)`

/** Pattern for a full email address. */
export const emailPattern = `(${em_token}@${domain_token}\\.${em_words}+)`

/** Pattern for a single full email address. */
export const singleEmailPattern = `^\\s*${emailPattern}\\s*$`

/** Pattern for a multiple full email address with delimiter and doesnot support wildcard pattern */
export const multipleEmailWithoutWildcardPattern = `^\\s*${emailPattern}(${em_delim}${emailPattern})*\\s*$`

//arranging token separated by delimiter
export const multipleEmailOrDomainPattern = `^\\s*${domainOrEmailPattern}(${em_delim}${domainOrEmailPattern})*\\s*$` //arranging token seperated by delimiter

/** Pattern for identifying multiple domain-only email inputs.  (I.e. @domain.com;@domain2.com) */
export const multipleDomainOnly = `^\\s*${domainWildcardPattern}(${em_delim}${domainWildcardPattern})+`

/** Negation of multipleDomainOnly. */
const pat = `(${domainWildcardPattern}(${em_delim}${domainWildcardPattern})+)`
export const notMultipleDomainOnly = `^(?!\\s*${pat}).*$`
