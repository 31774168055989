import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { SignalRWatcher } from './signalr-watcher'

/** Watches for Provision Users by Email, and resolves/rejects when all expected users have been provisioned.*/
export class ProvisionUserByEmailWatcher extends SignalRWatcher<ProvisionUserHeader> {
  // Update the provision status in store
  updateProvisionStatus: (header: ProvisionUserHeader) => void
  constructor(
    apiPromise: Promise<any>,
    updateStatus: (header: ProvisionUserHeader) => void,
    private readonly operationId: string
  ) {
    super('SendUpdatedProvisionUserHeader', apiPromise)
    this.handlerAction = this.handlerAction.bind(this)
    this.updateProvisionStatus = updateStatus
  }
  /** SignalR handler to process each file received in the messages. */
  handlerAction(header: ProvisionUserHeader, uniqueOperationId: string) {
    if (uniqueOperationId !== this.operationId) {
      return
    }
    if (header != null && header.status === 'COMPLETE') {
      this.operationComplete(header)
    } else {
      // Update the provision status in store for the provisioned records
      this.updateProvisionStatus(header)
    }
  }
}
