import { InvestorClientGroupEntity } from '../../api-client/investor-portal-client'
import { EntityType } from '../../model/entity-types'


export const getEntityName = (entity: InvestorClientGroupEntity): string => {
  if (entity.entityType === EntityType.Individual) {
    return `${entity.firstName} ${entity.lastName}`
  } else {
    return `${entity.entityName}`
  }
}
