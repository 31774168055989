import { Button, CheckBox, ContextMenu, DataGrid } from 'devextreme-react'
import './state-selection.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Column,
  Item,
  Scrolling,
  SearchPanel,
  Toolbar,
  Paging,
} from 'devextreme-react/data-grid'
import { Grid } from '@mui/material'
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp'
import { MenuProps } from '../../model/file-model'
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import { usStatesData } from './state-manager-utils'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { SortOrderTypes } from '../../api-client/investor-portal-client'
import { NativeEventInfo } from 'devextreme/events'
import dxCheckBox from 'devextreme/ui/check_box'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'

type ViewStatesTypes = 'all' | 'selected' | 'unselected'
type SortDirection = SortOrderTypes

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'AZ':
      icon = <SortByAlphaOutlinedIcon />
      break
    case 'ZA':
      icon = <SortByAlphaOutlinedIcon />
      break
    case 'checked':
      icon = <CheckBoxOutlinedIcon />
      break
    case 'unchecked':
      icon = <CheckBoxOutlineBlankOutlinedIcon />
      break
  }
  return (
    <div>
      <span style={{ fontSize: '6px', width: '4px', height: '4px' }}>
        {icon}
      </span>
      {e.text}
    </div>
  )
}
export interface StateSelectionGlobalProps {
  updateSelectedKeys: (selectedKeys: string[]) => void
  stateSelectionsKeys: string[] | undefined
  onSave: () => void
  useGlobalSettings?: boolean
  height: string | number | (() => string | number) | undefined
}

/** State Selections Global Page */
export const StateSelection = ({
  updateSelectedKeys,
  stateSelectionsKeys,
  onSave,
  useGlobalSettings,
  height,
}: StateSelectionGlobalProps) => {
  const [currentView, setCurrentView] = useState<ViewStatesTypes>('all')
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortOrderTypes.Asc
  )
  const [isSearching, setIsSearching] = useState(false)
  const dataGridRef = useRef<DataGrid>(null)
  const commonDialog = useCommonDialogs()
  //Disable SelectAll when Filter is enabled or Search is Enabled
  const disableSelectAll =
    currentView !== 'all' || isSearching || useGlobalSettings
  // If we don't have a stateSelectionsKeys, then the data is not loaded
  //  yet, and we shouldn't show any options for the user to interact with.
  const isLoading = !stateSelectionsKeys

  /** Create a dataSource for the DataGrid*/
  const dataSource = useMemo(() => {
    // Map the data from usStatesData and include an 'isSelected' property
    let result = usStatesData.map((state) => ({
      ...state,
      isSelected: stateSelectionsKeys?.includes(state.stateCode) ?? false,
    }))

    // Sort the dataSource based on the selected sortDirection
    result.sort((a, b) => {
      const multiplier = sortDirection === SortOrderTypes.Asc ? 1 : -1
      return a.name.localeCompare(b.name) * multiplier
    })

    return result
  }, [stateSelectionsKeys, sortDirection])

  useEffect(() => {
    switch (currentView) {
      case 'all':
        dataGridRef.current?.instance.clearFilter('dataSource')
        break
      case 'selected':
        dataGridRef.current?.instance.filter(['isSelected', '=', true])
        break
      case 'unselected':
        dataGridRef.current?.instance.filter(['isSelected', '=', false])
        break
      default:
        throw new Error(`Unexpected currentView value: ${currentView}`)
    }
  }, [currentView, isSearching])

  //** Define the handleSelectionChanged function to handle row selection changes */
  const handleSelectionChanged = (e: { selectedRowKeys: string[] }) => {
    updateSelectedKeys(e.selectedRowKeys)
  }

  const isAllSelected = dataSource.every((v) => v.isSelected)

  /**  Define the handleSelectAll function to select or deselect all rows */
  const handleSelectAll = (
    e: NativeEventInfo<dxCheckBox, Event> & ValueChangedInfo
  ) => {
    // If there's no event object (for example, when this function is called without a click event),
    // it might trigger when the 'Select All' functionality is disabled,so return in this case
    if (!e.event) {
      return
    }
    if (isAllSelected) {
      // Deselect All.
      updateSelectedKeys([])
    } else {
      // Select All.
      updateSelectedKeys(usStatesData.map((x) => x.stateCode))
    }
  }

  /** Define the clearSelections function to clear all selections*/
  const clearSelections = () => {
    // Deselect All.
    updateSelectedKeys([])
  }

  /**  Define the clearFilterButton to see all states*/
  const clearFilterButton = () => {
    setCurrentView('all')
  }

  /** Define the handleSearch function to handle input changes */
  const handleSearch = useCallback((value: string) => {
    setIsSearching(!!value)
  }, [])

  const masterContextMenu: MenuProps[] = [
    {
      text: 'Alphabetical A-Z',
      icon: 'AZ',
      action: () => {
        setSortDirection(SortOrderTypes.Asc)
      },
    },
    {
      text: 'Alphabetical Z-A',
      icon: 'ZA',
      action: () => {
        setSortDirection(SortOrderTypes.Desc)
      },
    },
    {
      text: 'Selected',
      icon: 'checked',
      action: () => {
        setCurrentView('selected')
      },
    },
    {
      text: 'Not Selected',
      icon: 'unchecked',
      action: () => {
        setCurrentView('unselected')
      },
    },
  ]

  return (
    <div className='state-selector-container'>
      <div className='stateselector-datagrid'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          dataSource={dataSource}
          keyExpr='stateCode'
          noDataText={isLoading ? 'Loading...' : 'No Data Found.'}
          width='100%'
          onSelectionChanged={handleSelectionChanged}
          columnAutoWidth={true}
          height={height}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={false}
          showColumnHeaders={false}
          data-testid='sm-state-datagrid'
        >
          <Paging defaultPageSize={50} />
          <SearchPanel
            visible={true}
            width='200px'
            onTextChange={handleSearch}
          />
          <Scrolling mode='virtual' showScrollbar={true} />
          <Toolbar>
            <Item location='before'>
              <Grid
                className='grid-container'
                container
                item
                xs={11}
                minWidth={550}
                justifyContent={'flex-start'}
              >
                <Grid item xs={4} className='select-all-chkbox'>
                  <div className='checkbox-container'>
                    <CheckBox
                      disabled={disableSelectAll}
                      value={isAllSelected}
                      onValueChanged={handleSelectAll}
                    />
                    <span className='select-all-chkbox-text'>Select All</span>
                  </div>
                </Grid>
                <Grid item xs={8} paddingLeft={7}>
                  <Button
                    onClick={() => clearFilterButton()}
                    type='default'
                    stylingMode='text'
                    data-testid='sm-clear-filter'
                    text='Clear Filter'
                  />
                </Grid>
              </Grid>
            </Item>
            <Item name='searchPanel' />
            <Item location='after'>
              <Grid container minWidth={50} justifyContent={'flex-end'}>
                <ContextMenu
                  width={120}
                  dataSource={masterContextMenu}
                  showEvent='mouseenter'
                  target='#stateMasterActionBtn'
                  itemRender={renderMenuItem}
                  onItemClick={(e) => {
                    const item = e.itemData as MenuProps | undefined
                    if (item?.action) {
                      item.action()
                    }
                  }}
                />
                <Button id='stateMasterActionBtn' height={35}>
                  <FilterListSharpIcon />
                </Button>
              </Grid>
            </Item>
          </Toolbar>
          <Column
            dataField='isSelected'
            caption=''
            width={50}
            cellRender={(rowData) => (
              <CheckBox
                value={rowData.data.isSelected}
                onValueChanged={(e) => {
                  const updatedData = dataSource
                  const rowIndex = dataSource.indexOf(rowData.data)
                  updatedData[rowIndex].isSelected = e.value
                  updateSelectedKeys(
                    updatedData
                      .filter((item) => item.isSelected)
                      .map((item) => item.stateCode)
                  )
                }}
                disabled={useGlobalSettings}
                className={useGlobalSettings ? 'disabled-checkbox' : ''}
              />
            )}
          />
          <Column dataField='name' caption='State Name' />
        </DataGrid>
        <div className='state-selector-buttons'>
          <Button
            disabled={useGlobalSettings}
            text='Clear Selection'
            type='default'
            stylingMode='contained'
            className='clear-btn-State'
            onClick={() =>
              commonDialog.showDialog({
                dialogType: 'general',
                title: 'Clearing Selection',
                content: 'Are you sure you want to clear selections?',
                omitDefaultButton: true,
                buttonFunctions: [
                  {
                    label: 'Cancel',
                    isCloseAction: true,
                    isDefaultAction: true,
                    onClick: async () => {},
                    buttonProps: {
                      stylingMode: 'contained',
                      type: 'normal',
                      width: 100,
                      'data-testid': 'cancel-button',
                    },
                  },
                  {
                    label: 'Proceed',
                    isCloseAction: true,
                    onClick: async () => {
                      clearSelections()
                    },
                    buttonProps: {
                      stylingMode: 'contained',
                      type: 'default',
                      width: 110,
                      'data-testid': 'proceed-button',
                    },
                  },
                ],
              })
            }
            data-testid='sm-clear-button'
          ></Button>
          <Button
            text='Save Selection'
            type='default'
            stylingMode='contained'
            className='save-btn-State'
            onClick={() => onSave()}
            data-testid='sm-Save-button'
          ></Button>
        </div>
      </div>
    </div>
  )
}
