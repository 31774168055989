import { FileStatus, File } from '../../api-client/investor-portal-client'
import { ClientChildFileList, ClientFileList } from '../../state/atom'
import { SignalRWatcher } from './signalr-watcher'
import { RecoilPinner } from '../useRecoilPinner'
import produce from 'immer'

/** Watches for File EMail messages, and resolves/rejects when all expected emails have been processed.*/
export class FileEmailMessageWatcher extends SignalRWatcher {
  /** Copy of the file IDs passed to the ctor, which will be "checked off"
   *   as messages arrive for each file found.
   */
  private _remainingFileIds: Array<number> = []

  /** Boolean value indicating whether or not an email failure has occurred. */
  private _fileFailure = false

  constructor(
    apiPromise: Promise<any>,
    readonly chileFileApiPinner: RecoilPinner<ClientChildFileList>,
    readonly parentFileApiPinner: RecoilPinner<ClientFileList>,
    readonly ids: number[]
  ) {
    super('EmailSent', apiPromise)

    // Copy the file IDs passed to the ctor, so we can watch them and alter the array.
    this._remainingFileIds = ids.slice()

    this.handlerAction = this.handlerAction.bind(this)
  }

  /** SignalR handler to process each file received in the messages. */
  handlerAction(childFiles: File[], parentFiles: File[]) {
    // Update the child file list.
    this.chileFileApiPinner.set((curVal) =>
      produce(curVal, (draft) => {
        childFiles.forEach((file) => {
          const fileIndex = draft.files.findIndex((c) => c.id === file.id)
          if (fileIndex >= 0) {
            // Set email template if its empty from API
            if (!file.emailTemplate || file.emailTemplate.length === 0) {
              file.emailTemplate = draft.files[fileIndex].emailTemplate
            }
            // Replace the existing item.
            draft.files[fileIndex] = file
          }
          // Get the index of the ID in our file list.
          const index = this._remainingFileIds.indexOf(file.id!)
          // If this index is in the list, then process the message.
          if (index >= 0) {
            // Remove the update from the list.
            this._remainingFileIds.splice(index, 1)
            // If there's an email error, then we need to remember that.
            if (file.status === FileStatus.EmailError) {
              this._fileFailure = true
            }
          }
        })
      })
    )

    //Update the recoil state of ParentFiles 
    this.parentFileApiPinner.set((curVal) =>
      produce(curVal, (draft) => {
        parentFiles.forEach((parentFile) => {
          // Find the index of the file with the same ID as the parentFile
          const parentFileIndex = draft.files.findIndex(
            (f) => f.id === parentFile.id
          )
          if (parentFileIndex !== -1) {
            // Update the recoil state for the file
            draft.files[parentFileIndex] = parentFile
          }
        })
      })
    )

    // If we have no more file IDs to watch for, this watcher is done.
    if (this._remainingFileIds.length === 0) {
      // Handle the completion appropriately for failure or success.
      if (this._fileFailure) {
        this.operationFailed('Error Occured: Email was not sent.')
      } else {
        this.operationComplete()
      }
    }
  }
}
