import { View, StyleSheet, Text, Document, Page } from '@react-pdf/renderer'
import { PdfHeader } from './pdf-investor-header'
import { PdfGeneralInfo } from './pdf-general-info'
import { SMInvestorElectionsInfo } from '../../../../api-client/investor-portal-client'
import { PdfElection } from './pdf-election'
import { usStatesData } from '../../../state-manager/state-manager-utils'
import { ForeignCountryEnum } from '../../investor-utils'
import { ElectionDetail } from './pdf-utils'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    family: 'Arial',
    fontWeight: 'normal',
    margin: '12mm',
    paddingRight: '20mm',
    paddingBottom: '32mm',
  },
  headerSection: {
    marginTop: 25,
  },
  otherSection: {
    marginTop: 85,
  },
})

interface PrintSubmissionOverviewProps {
  taxYear: string
  electionsInfo: SMInvestorElectionsInfo
  title: string
  compositeData: ElectionDetail
  withHoldingData: ElectionDetail[]
}

/** Submission Overview PDF Document page */
export const PrintSubmissionOverview = ({
  taxYear,
  electionsInfo,
  title,
  compositeData,
  withHoldingData,
}: PrintSubmissionOverviewProps) => {
  /**Find the state name from the static state data
   * If stateOfResidency is 'Foreign' then keep as 'Foreign Country' */
  const stateName =
    electionsInfo?.stateOfResidency === ForeignCountryEnum.StateCode
      ? ForeignCountryEnum.Name
      : usStatesData.find(
          (x) => x.stateCode === electionsInfo?.stateOfResidency
        )?.name

  return (
    <Document>
      <Page size='A4' style={styles.page} wrap={true} break={true}>
        <View style={styles.headerSection}>
          <PdfHeader taxYear={taxYear} title={title} />
        </View>
        <View style={styles.otherSection}>
          <PdfGeneralInfo
            entityType={electionsInfo?.entityType!}
            stateName={stateName!}
          />
          <Text> {'\n'}</Text>
          <PdfElection electionDetail={compositeData}></PdfElection>
          <Text> {'\n'}</Text>
          <Text style={{ fontSize: 13, fontWeight: 500 }}>
            Withholding Elections
          </Text>
          {withHoldingData.map(
            (withHoldingitem: ElectionDetail, index: number) => {
              return (
                <View>
                  {index > 0 && <Text> {'\n'}</Text>}
                  <PdfElection
                    electionDetail={withHoldingitem}
                  ></PdfElection>
                </View>
              )
            }
          )}
        </View>
      </Page>
    </Document>
  )
}