import { useEffect, useState } from 'react'
import produce from 'immer'
import { CircularProgress } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { PromiseState, ReportDownloadMessage, ReportDownloadStatus } from './utils'
import { DownloadReportInfo } from '../../../model/report-models'
import './download-progress.scss'

export interface ReportDownloadProgressProps {
  reportInfo: DownloadReportInfo 
}

/** Show State-Manager Report Download Progress status and messages */
export const ReportDownloadProgress = ({
  reportInfo
}: ReportDownloadProgressProps) => {

  const [reportStatus, setReportStatus] = useState<PromiseState>({
    status: ReportDownloadStatus.inProgress,
    message: ReportDownloadMessage.inProgress,
  })

  // Checking the promise state and updating the download status and download message
  useEffect(() => {
    reportInfo
      .reportPromise!.then(() => {
        setReportStatus(
          produce((draft) => {
            draft.status = ReportDownloadStatus.success
            draft.message = ReportDownloadMessage.success
          })
        )
      })
      .catch((err) => {
        setReportStatus(
          produce((draft) => {
            draft.status = ReportDownloadStatus.error
            draft.message = ReportDownloadMessage.error
          })
        )
      })
  }, [])

  // Get the status icon inproress,success and error based on download status
  const getStatusIcon = (iconStatus: string) => {
    if (iconStatus === ReportDownloadStatus.inProgress) {
      return  <CircularProgress color='info' size='20px' style={{ marginRight: '2px' }} />      
    } else if (iconStatus === ReportDownloadStatus.success) {
      return <CheckCircleOutlinedIcon />
    } else if (iconStatus === ReportDownloadStatus.error) {
      return <ErrorOutlineOutlinedIcon />
    }
  }

  return (
    <div className='download-report-status'>
      <div className='message-container'>
        <div
          className={
            reportStatus.status === ReportDownloadStatus.error
              ? 'status-icon-error'
              : 'status-icon-success'
          }
        >
          {getStatusIcon(reportStatus.status)}
        </div>
        <div className='message-container'>
          {reportInfo.reportName} {reportStatus.message}
        </div>
      </div>     
    </div>
  )
}
