import { EntityGroupPartnershipState } from '../state/atom'

//Gets the parent file Id, given group Id
export const getClientId = (
  clients: EntityGroupPartnershipState,
  groupId: number,
  partnershipId: number
) => {
  return clients[groupId]?.clients?.find((x) => x.id === partnershipId)
    ?.clientId!
}

/** Attempts to return a number for a specified value.  If unable to convert to a number,
 *   then returns undefined.
 */
export const ensureNumberOrUndefined = (value: any): number | undefined => {
  // Get the type of the value for reuse.
  const valueType = typeof value
  if (valueType === 'number') {
    // If the value is already a number, then we'll use it.
    return value
  } else if (valueType === 'string') {
    // If the value is a string, we'll try to parse it, and only return
    // the value if it's a number.
    const numResult = parseInt(value)
    if (!isNaN(numResult)) {
      return numResult
    }
  }

  // Not a valid number so return undefined.
  return undefined
}

/** Creates a random number
 */
export const generateRandomNumber = (): number => {
  return Math.random() * 100
}

/** Creates an array with random numbers
 *   by using above function "generateRandomNumber".
 */
export const arrayWithRandomNumbers = (length: number): number[] => {
  const arr: number[] = []
  for (let i = 0; i < length; i++) {
    const randomNumber = generateRandomNumber()
    arr.push(randomNumber)
  }
  return arr
}
