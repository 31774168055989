import React, { useEffect, useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import './sendEmailConfirmation.scss'
import { File } from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'
import { useNotificationClientApi } from '../../hooks/use-notification-api'
import { useRecoilPinner } from '../../signalr/useRecoilPinner'
import { childFileListAtom, parentFileListAtom } from '../../state/atom'
import { FileEmailMessageWatcher } from '../../signalr/watcher/file-message-watcher'
import { CheckBox } from 'devextreme-react/check-box'
import produce from 'immer'
import { ScrollableMessage } from './scrollableMessage'

export interface SendEmailConfirmationProps {
  onConfirm: () => void
  onCancel: () => void
  files: File[]
  isVisible: boolean
  entityGroupId: number
}

interface EmailAddress {
  emailAddress: string
  checked: boolean
  key: number
}

/** Delete Child File confirmation modal */
export const SendEmailConfirmation = ({
  onConfirm,
  onCancel,
  files,
  isVisible,
  entityGroupId,
}: SendEmailConfirmationProps) => {
  // Get the send email function from our API hook.
  const { sendInvestorEmails } = useNotificationClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [mailPromise, setMailPromise] = useState<Promise<any>>()
  const childFileListPinner = useRecoilPinner(childFileListAtom)
  const parentFileRecoilPinner = useRecoilPinner(parentFileListAtom)
  const [emailAddresses, setEmailAddresses] = useState<EmailAddress[]>([])
  const [disableSendEmail, setDisableSendEmail] = useState<boolean>(false)
  //Set investor is having singe email or multiple emails
  const [singleEmail, setSingleEmail] = useState<boolean>(false)

  useEffect(() => {
    //Set initial value for email addresses
    setEmailAddresses([])
    if (isVisible) {
      //Set multiple emails flag to display appropriate modal content
      if (files.length === 1 && files[0].emails && files[0].emails.length > 1) {
        const uniqueEmailAddresses: EmailAddress[] =
          getUniqueEmailAddresses(files[0].emails).map((x, i) => ({
            emailAddress: x,
            checked: false,
            key: i,
          })) || []

        if (uniqueEmailAddresses.length === 1) {
          setSingleEmail(true)
          setDisableSendEmail(false)
        } else {
          setSingleEmail(false)
          setDisableSendEmail(true)
          setEmailAddresses(uniqueEmailAddresses)
        }
      } else {
        setSingleEmail(true)
        setDisableSendEmail(false)
      }
    }
  }, [isVisible])

  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'SEND EMAILS',
      isDisabled: disableSendEmail,
      onClick: async () => {
        setShowSpinner(true)
        files = files.filter((f) => f.emails && f.emails.length > 0)
        const fileIds: number[] = files.map((file) => file.id!)
        // Filter selected email addresses
        const emails = emailAddresses
          ?.filter((x) => x.checked)
          ?.map((e) => e.emailAddress)
        let mailPromise = sendInvestorEmails(fileIds, emails, entityGroupId)

        let emailMessageWatcher = new FileEmailMessageWatcher(
          mailPromise,
          childFileListPinner,
          parentFileRecoilPinner,
          fileIds
        )
        emailMessageWatcher.initialize()
        setMailPromise(emailMessageWatcher.promise)
        onConfirm()
        await mailPromise
      },
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 160,
        'data-testid': 'send-mail-button',
      },
    },
  ]

  /*** Set selected email addresses and enable or disable send email button */
  const handleEmailChecked = (index: number) => {
    const emails = produce(emailAddresses, (draft) => {
      draft[index].checked = !draft[index].checked
    })
    setEmailAddresses(emails)
    const selectedEmails = emails?.filter((x) => x.checked)
    setDisableSendEmail(selectedEmails.length > 0 ? false : true)
  }

  // Remove duplicates, and return the results.
  const getUniqueEmailAddresses = (emailAddresses: string[]) => {
    const uniqueEmails = new Array<string>()
    emailAddresses.forEach((e) => {
      if (!uniqueEmails.includes(e)) {
        uniqueEmails.push(e)
      }
    })
    return uniqueEmails
  }

  return (
    <>
      <Modal
        visible={isVisible}
        title='Send Email'
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={370}
        disableScrollbar={true}
        className={'SendEmailConfirmation'}
      >
        <ScrollableMessage
          staticContentPosition={!singleEmail ? 'top' : 'bottom'}
          staticContent={
            <>
              {singleEmail && files.length === 1 && (
                <div className='content-alignment'>
                  You are about to send an email to the investor. Do you wish to
                  proceed?
                </div>
              )}
              {singleEmail && files.length > 1 && (
                <div className='content-alignment'>
                  You are about to send an email to the investors. Do you wish
                  to proceed?
                </div>
              )}
              {!singleEmail && (
                <div>
                  Select from the list of emails below to send K-1 ready
                  notification. Do you wish to proceed?
                </div>
              )}
            </>
          }
          scrollableContent={
            <div>
              {emailAddresses.map((x, index) => {
                return (
                  <div key={x.key} className='email-column-alignment'>
                    <CheckBox
                      onValueChange={() => handleEmailChecked(index)}
                      name={`checkbox${index}`}
                      defaultValue={x.checked}
                    />
                    <span className='email-margin-left'>{x.emailAddress}</span>
                  </div>
                )
              })}
            </div>
          }
        ></ScrollableMessage>
      </Modal>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Send email failed'
        inProgressTitleMessage='Send Email'
        inProgressMessage='Please wait while your email is sending...'
        successTitleMessage='Email Success'
        successMessage='Email sent to selected investor(s).'
        onClose={() => setShowSpinner(false)}
        apiAction={mailPromise}
      />
    </>
  )
}
