import { PermissionInfo } from "../model/permissions"
import { PermissionRequest, PermissionRequestLevels } from "./permission-models"

/** Returns a PermissionRequest, of type any, for a specified PermissionInfo. */
export const permissionRequestAny = (permissionInfo: PermissionInfo): PermissionRequest => {
    return {
        level: PermissionRequestLevels.Any,
        permissionName: permissionInfo.name
    }
}

/** Returns a PermissionRequest, of type Client, for a specified PermissionInfo and client group ID. */
export const permissionRequestClientById = (permissionInfo: PermissionInfo, clientGroupId: number): PermissionRequest => {
    // Validate the type of the permission.
    if (permissionInfo.type !== PermissionRequestLevels.Client) {
        throw new Error(`${permissionInfo.name} is not an Client permission type.`)
    }
    return {
        level: PermissionRequestLevels.Client,
        permissionName: permissionInfo.name,
        entityOrClientId: clientGroupId
    }
}

/** Returns a PermissionRequest, of type Client, for a specified PermissionInfo and client group IPM number. */
export const permissionRequestClientByIpm = (permissionInfo: PermissionInfo, clientGroupIpm: number): PermissionRequest => {
    // Validate the type of the permission.
    if (permissionInfo.type !== PermissionRequestLevels.Client) {
        throw new Error(`${permissionInfo.name} is not an Client permission type.`)
    }
    return {
        level: PermissionRequestLevels.Client,
        permissionName: permissionInfo.name,
        ipmNumber: clientGroupIpm
    }
}


/** Returns a PermissionRequest, of type Client, for a specified PermissionInfo and allocating entity ID. */
export const permissionRequestEntityById = (permissionInfo: PermissionInfo, entityId: number): PermissionRequest => {
    // Validate the type of the permission.
    if (permissionInfo.type !== PermissionRequestLevels.Entity) {
        throw new Error(`${permissionInfo.name} is not an Entity permission type.`)
    }
    return {
        level: PermissionRequestLevels.Entity,
        permissionName: permissionInfo.name,
        entityOrClientId: entityId
    }
}

/** Returns a PermissionRequest, of type Client, for a specified PermissionInfo and allocating entity IPM number (aka clientGroupId). */
export const permissionRequestEntityByIpm = (permissionInfo: PermissionInfo, entityIpm: number): PermissionRequest => {
    // Validate the type of the permission.
    if (permissionInfo.type !== PermissionRequestLevels.Entity) {
        throw new Error(`${permissionInfo.name} is not an Entity permission type.`)
    }
    return {
        level: PermissionRequestLevels.Entity,
        permissionName: permissionInfo.name,
        ipmNumber: entityIpm
    }
}