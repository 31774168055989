import React, { useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { File } from '../../api-client/investor-portal-client'
import { SpinnerModal } from '../modal/spinnerModal'
import { RelinkFileWatcher } from '../../signalr/watcher/relink-files-watcher'
import { useRecoilPinner } from '../../signalr/useRecoilPinner'
import { parentFileListAtom } from '../../state/atom'

export interface RelinkParentFilesProps {
  onConfirm: () => void
  onCancel: () => void
  isVisible: boolean
  files: File[]
  entityGroupId: number
}

/** Relink Parent File(s) */
export const RelinkParentFiles = ({
  onConfirm,
  files,
  isVisible,
  entityGroupId,
}: RelinkParentFilesProps) => {
  // Get the relink files function from our API hook.
  const { relinkFiles } = useFileClientApi()
  const parentFileRecoilPinner = useRecoilPinner(parentFileListAtom)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [reLinkPrmoise, setRelinkPromise] = useState<Promise<any>>()

  /** Call Relink API method */
  useEffect(() => {
    const relinkParentFiles = async () => {
      setShowSpinner(true)
      let relinkPromise = relinkFiles(
        files.map((f) => f.id!),
        entityGroupId
      )
      let relinkFileWatcher = new RelinkFileWatcher(
        relinkPromise,
        parentFileRecoilPinner,
        files.map((f) => f.id!)
      )
      relinkFileWatcher.initialize()
      setRelinkPromise(relinkFileWatcher.promise)
      onConfirm()
      await relinkPromise
    }
    if (isVisible) {
      relinkParentFiles()
    }
  }, [isVisible])

  return (
    <div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Re-link investors failed.'
        inProgressTitleMessage='Re-link investors'
        inProgressMessage='Re-link investors in-progress...'
        successTitleMessage='Re-link investors success'
        successMessage='Re-link investors success.'
        onClose={() => {
          setShowSpinner(false)
          onConfirm()
        }}
        apiAction={reLinkPrmoise}
      />
    </div>
  )
}
