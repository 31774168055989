import { ReactNode, useEffect, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { useRecoilValue } from 'recoil'
import './provisionUsers.scss'
import { ProvisionUserHeader } from '../../api-client/investor-portal-client'
import { clientAndPartnershipStateAtom } from '../../state/atom'
import { ModalDimensionProps, ProvisionUsersProps } from './provisionUsers'
import { SpinnerModal } from '../modal/spinnerModal'
import { provisionUsersStatusMessages } from './provision-users-common-items'
import { ProvisionUsersFailedContent } from './provisionUsersFailedContent'
import { isProvisionUserHeader } from '../utility/type-guards'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

/** Relink Parent File(s) */
export const ProvisionUsersStatus = ({
  isVisible,
  entityGroupId,
  clientId,
  onClose,
}: ProvisionUsersProps) => {
  // Get the provisionUsers function from our API hook.
  const { getLatestProvisionUsersStatus } = useFileClientApi()
  const { partnership } = useRecoilValue(clientAndPartnershipStateAtom)
  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const [errorTitle, setErrorTitle] = useState<string>(
    provisionUsersStatusMessages.errorTitleMessage
  )
  const [errorDetail, setErrorDetail] = useState<ReactNode | undefined>()
  const [modalDimension, setModalDimension] = useState<ModalDimensionProps>({})

  //update Modal after processing the provision
  const updateErrorForPartialFailure = (
    provisionUserHeader: ProvisionUserHeader
  ) => {
    //set modal dimension for partial failure
    setModalDimension({
      width: 800,
      height: 400,
    })
    // setErrorTitle(provisionUsersStatusMessages.errorTitleMessageOnPartialFailure)
    setErrorTitle(
      provisionUsersStatusMessages.errorTitleMessageOnPartialFailure
    )
    //set ui for failed list
    setErrorDetail(
      <ProvisionUsersFailedContent
        provisionUserHeader={provisionUserHeader}
        partnership={partnership}
      />
    )
  }

  //begin: do provision on users ( doProvisionUsers )
  const doProvisionUsers = async (abortSignal?: AbortSignal) => {
    try {
      //reset modal dimension for default view
      setModalDimension({
        width: undefined,
        height: undefined,
      })

      // Invoke the API call to get the provision users status from the server for the last provision call.
      let provisionUsersPromise = getLatestProvisionUsersStatus(
        entityGroupId,
        clientId,
        abortSignal
      )

      // In order for the spinner to show as an error in a partial failure
      //  (where some provision records failed), we must provide our own rejection
      //  on the promise for the spinner to pick up on.
      provisionUsersPromise = provisionUsersPromise.then((response) => {
        // If there's a failedCount, then that means we have a partial failure.
        //  Throw the provision user header so it's processed as an error below.
        if (response.failedCount) {
          throw response
        }

        // Return the response as normal, since there's no partial failure.
        return response
      })

      // Provide the promise from our watcher to our spinner, so it can observe its progress.
      setProvisionPromise(provisionUsersPromise)

      // Wait for the promise to complete.  This is necessary
      //  for the catch portion of this block to function.
      await provisionUsersPromise
    } catch (err: any) {
      // If this is from an abort, then we don't want to do anything.
      if (err?.name === 'AbortError') {
        return
      }

      // If the watch throws an error with a ProvisionUserHeader, then
      //  this indicates a partial failure, and we need to set the
      //  spinner's error content appropriately.
      if (isProvisionUserHeader(err)) {
        updateErrorForPartialFailure(err)

        //reset modal dimension for partial failure
        setModalDimension({
          width: 800,
          height: 400,
        })
      } else {
        // Since we don't have ProvisionUserHeader, that means some other error happened
        //  and we want the Spinner to reflect that.  Set the spinner's promise so it will show the error.
        setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
        setErrorDetail(`Error: ${err.message}`)
        setProvisionPromise(Promise.reject(err))
      }
    }
  }

  /** Call ProvisionUsers API method */
  useEffect(() => {
    const abortController = new AbortController()

    // We only want to execute the provisioning of users when the
    //  dialog is visible, since the control will likely always exist
    //  on the page, even if hidden due to isVisible === false.
    if (isVisible) {
      //invoke doProvisionUsers
      doProvisionUsers(abortController.signal)
      // setTimeout(doProvisionUsers, 2000)
    } else {
      setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
      //reset ui for error state
      setErrorDetail(undefined)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [isVisible, entityGroupId, clientId])

  return (
    <SpinnerModal
      visible={isVisible}
      inProgressTitleMessage={
        provisionUsersStatusMessages.inProgressTitleMessage
      }
      inProgressMessage={provisionUsersStatusMessages.inProgressMessage}
      successTitleMessage={provisionUsersStatusMessages.successTitleMessage}
      successMessage={provisionUsersStatusMessages.successMessage}
      errorTitleMessage={errorTitle}
      errorMessage={errorDetail}
      width={modalDimension.width}
      height={modalDimension.height}
      onClose={() => {
        onClose()
      }}
      apiAction={provisionPromise}
    />
  )
}
