import { Grid } from '@mui/material'
import { Button } from 'devextreme-react'
import { useParams } from 'react-router-dom'
import { getInvestorLandingRoute } from '../utility/route-creation'
import { useCallback, useEffect, useState } from 'react'
import { Tabs } from 'devextreme-react/tabs'
import { ITabDetail } from '../state-manager/state-manager-utils'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { CommonDialogButtonFunction } from '../modal/commonDialog/common-dialog-state'
import './investorElectionsPage.scss'
import { InvestorGeneralElectionInfo } from './investorGeneralElectionInfo'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  LoadingStateEnum,
  smGeneralElectionInfoAtom,
  smSubmissionStatusAtom,
} from '../../state/atom'
import { InvestorCompositeElections } from './investor-composite-elections'
import { InvestorWithholdingElections } from './investor-witholding-elections '
import produce from 'immer'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ItemInfo } from 'devextreme/events'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { ApiSpinnerContent } from '../state-manager/global-election-settings'
import { SpinnerModal } from '../modal/spinnerModal'
import {
  useNavigationGuard,
  useNavigate,
  useNavigation,
} from '../../hooks/useNavigationGuard'

export interface InvestorElectionsPageProps {
  showElectionReview: (isShowReview: boolean) => void
}

export const InvestorElectionsPage = ({
  showElectionReview,
}: InvestorElectionsPageProps) => {
  const navigate = useNavigate() // Navigate with guard
  const navigation = useNavigation() // Navigate without guard
  const [tabValue, setTabValue] = useState(0)
  const { loadingState, electionsInfo } = useRecoilValue(
    smGeneralElectionInfoAtom
  )
  const [submissionStatus, setSubmissionStatus] = useRecoilState(
    smSubmissionStatusAtom
  )
  const commonDialogApi = useCommonDialogs()
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [hideClose, setHideClose] = useState<boolean>(true)
  const [spinnerPromise, setSpinnerPromise] = useState<Promise<any>>()

  const stateManagerApi = useStateManagerApi()
  const [apiSpinnerContent, setApiSpinnerContent] = useState<ApiSpinnerContent>(
    {
      inProgressTitle: '',
      inProgressMessage: '',
      successTitle: '',
      successMessage: '',
      errorTitle: '',
      errorMessage: '',
    }
  )
  //Set Tabs buttons individually in each component passing as React node
  const [tabButtons, setTabButtons] = useState<React.ReactNode>(null)

  const { taxYear } = useParams() as {
    taxYear: string
  }

  const dirtyData =
    submissionStatus.isDirtyFlags.isGeneralDirty ||
    submissionStatus.isDirtyFlags.isCompositeDirty ||
    submissionStatus.isDirtyFlags.isWithholdingDirty

  /** Returns dirty flag */
  const getDirty = useCallback(() => {
    return dirtyData
  }, [dirtyData])

  // Display unsaved alert message when there's any of the tab data changed
  useNavigationGuard(
    getDirty,
    'You have unsaved changes. In order to proceed, you must first cancel or save changes.'
  )

  const submissionStatuses = submissionStatus.submissionStatuses
  /** If year change occur then user should see the General Tab */
  useEffect(() => {
    //Show the general tab
    setTabValue(0)
  }, [taxYear])

  /**checking all the tab General, Composite and Withholding are submitted and submission date is not present*/
  const isReviewAndSubmitEnable = submissionStatuses
    ? submissionStatuses.isGeneralInfoComplete &&
      !submissionStatus.isDirtyFlags.isGeneralDirty &&
      submissionStatuses.isWithholdingsComplete &&
      !submissionStatus.isDirtyFlags.isWithholdingDirty &&
      submissionStatuses.isCompositeComplete &&
      !submissionStatus.isDirtyFlags.isCompositeDirty &&
      submissionStatuses.submissionDate === undefined
    : false

  const updateCanSaveGeneral = useCallback(
    (value: boolean) => {
      // Check for submissionStatuses available and then set the complete flag
      if (submissionStatus.submissionStatuses) {
        setSubmissionStatus(
          produce((draft) => {
            draft.submissionStatuses!.isGeneralInfoComplete = value
          })
        )
      } else {
        setSubmissionStatus(
          produce((draft) => {
            draft.isGeneralInfoSubmitted = value
          })
        )
      }
    },
    [setSubmissionStatus, submissionStatus.submissionStatuses]
  )

  const updateCanSaveComposite = useCallback(
    (value: boolean) => {
      setSubmissionStatus(
        produce((draft) => {
          draft.submissionStatuses!.isCompositeComplete = value
        })
      )
    },
    [setSubmissionStatus]
  )

  const updateCanSaveWithholding = useCallback(
    (value: boolean) => {
      setSubmissionStatus(
        produce((draft) => {
          draft.submissionStatuses!.isWithholdingsComplete = value
        })
      )
    },
    [setSubmissionStatus]
  )

  const updateIsDirtyGeneral = useCallback(
    (value: boolean) => {
      setSubmissionStatus(
        produce((draft) => {
          draft.isDirtyFlags.isGeneralDirty = value
        })
      )
    },
    [setSubmissionStatus]
  )

  const updateIsDirtyComposite = useCallback(
    (value: boolean) => {
      setSubmissionStatus(
        produce((draft) => {
          draft.isDirtyFlags.isCompositeDirty = value
        })
      )
    },
    [setSubmissionStatus]
  )

  const updateIsDirtyWithholding = useCallback(
    (value: boolean) => {
      setSubmissionStatus(
        produce((draft) => {
          draft.isDirtyFlags.isWithholdingDirty = value
        })
      )
    },
    [setSubmissionStatus]
  )

  /** Reset dirty flags in all tabs */
  const resetDirtyFlags = () => {
    setSubmissionStatus(
      produce((draft) => {
        draft.isDirtyFlags.isGeneralDirty = false
        draft.isDirtyFlags.isCompositeDirty = false
        draft.isDirtyFlags.isWithholdingDirty = false
      })
    )
  }

  /** Trigger dailogue model when user tries to cancel button */
  const globalCancelClick = () => {
    if (
      submissionStatus.isDirtyFlags.isGeneralDirty ||
      submissionStatus.isDirtyFlags.isCompositeDirty ||
      submissionStatus.isDirtyFlags.isWithholdingDirty
    ) {
      commonDialogApi.showDialog({
        dialogType: 'general',
        title: 'Unsaved Changes',
        content:
          'You have unsaved changes. If you continue changes will be lost. Would you like to proceed?',
        buttonFunctions: commanDailogueButtonFunctions,
        omitDefaultButton: true,
      })
    } else {
      navigate(getInvestorLandingRoute())
    }
  }

  /** When user navigate between the tab, check for dirty flag,
   * if its set then display warning and stay in the same tab
   * otherwise navigate to the new tab */
  const onNavigating = (setSelectedTab: () => void) => {
    // check for all dirty flag
    if (
      submissionStatus.isDirtyFlags.isGeneralDirty ||
      submissionStatus.isDirtyFlags.isCompositeDirty ||
      submissionStatus.isDirtyFlags.isWithholdingDirty ||
      !submissionStatus.isGeneralInfoSubmitted
    ) {
      commonDialogApi.showDialog({
        dialogType: 'general',
        title: 'Unsaved Changes',
        content:
          'You have unsaved changes. In order to proceed to another tab, you must first cancel or save changes',
        buttonFunctions: [
          {
            label: 'CLOSE',
            onClick: () => {},
            isCloseAction: true,
            buttonProps: {
              className: 'cancel-btn-modal',
              stylingMode: 'contained',
              type: 'default',
              width: 100,
            },
          },
        ],
        omitDefaultButton: true,
      })
    } else {
      // call callback method
      setSelectedTab()
    }
  }

  /** Set tab name and completed icon based on the tab completion status */
  const onTabItemRender = (e: ITabDetail) => {
    const isCompleted =
      e.id === 0
        ? (submissionStatus.isGeneralInfoSubmitted ||
            submissionStatuses?.isGeneralInfoComplete) &&
          !submissionStatus.isDirtyFlags.isGeneralDirty
        : e.id === 1
        ? submissionStatuses?.isCompositeComplete &&
          !submissionStatus.isDirtyFlags.isCompositeDirty
        : submissionStatuses?.isWithholdingsComplete &&
          !submissionStatus.isDirtyFlags.isWithholdingDirty
    return (
      <div>
        {e.text}
        {isCompleted && (
          <span className='tab-icon-align'>
            <CheckCircleIcon color='info' fontSize='small' />
          </span>
        )}
      </div>
    )
  }

  /** On tab click validate dirty flags & set the selected tab value */
  const onTabClick = (e: ItemInfo<ITabDetail>) => {
    // Show alert message if user clicked Composite or Withholdings tabs without saving 'General' tab
    if (e.itemIndex > 0 && electionsInfo?.id === 0) {
      commonDialogApi.showDialog({
        dialogType: 'general',
        title: 'General information',
        content:
          'In order to proceed to another tab, you must first save General information',
        buttonFunctions: [
          {
            label: 'CLOSE',
            onClick: () => {},
            isCloseAction: true,
            buttonProps: {
              className: 'cancel-btn-modal',
              stylingMode: 'contained',
              type: 'default',
              width: 100,
            },
          },
        ],
        omitDefaultButton: true,
      })
    } else {
      onNavigating(() => {
        setTabValue(e.itemIndex)
      })
    }
  }

  //reset data for withholding and composite
  const clearData = useCallback(() => {
    // reset submission statuses
    setSubmissionStatus(
      produce((draft) => {
        draft.submissionStatuses!.isCompositeComplete = false
        draft.submissionStatuses!.isWithholdingsComplete = false
      })
    )
  }, [setSubmissionStatus])

  /** Tab Contents and Ids are the Tab value in State  */
  const tabItems: ITabDetail[] = [
    {
      id: 0,
      text: 'General',
      content: (
        <InvestorGeneralElectionInfo
          updateCanSave={updateCanSaveGeneral}
          generalTabDirty={updateIsDirtyGeneral}
          clearData={clearData}
          setParentButtons={setTabButtons}
        />
      ),
    },
    {
      id: 1,
      text: 'Composites',
      content: (
        <InvestorCompositeElections
          investorElectionsInfoId={electionsInfo?.id!}
          updateCanSave={updateCanSaveComposite}
          onDirtyStateChange={updateIsDirtyComposite}
          setParentButtons={setTabButtons}
        ></InvestorCompositeElections>
      ),
    },
    {
      id: 2,
      text: 'Withholdings',
      content: (
        <InvestorWithholdingElections
          investorElectionsInfoId={electionsInfo?.id!}
          updateCanSave={updateCanSaveWithholding}
          onDirtyStateChange={updateIsDirtyWithholding}
          setParentButtons={setTabButtons}
        ></InvestorWithholdingElections>
      ),
    },
  ]
  /** Common dialog modal button functions */
  const commanDailogueButtonFunctions: CommonDialogButtonFunction[] = [
    {
      label: 'CANCEL',
      onClick: () => {},
      isCloseAction: true,
      buttonProps: {
        className: 'cancel-btn-modal',
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
      },
    },
    {
      label: 'Proceed',
      onClick: () => {
        resetDirtyFlags()
        // Navigate to landing page without any dirty flag check
        navigation(getInvestorLandingRoute())
      },
      isCloseAction: true,
      buttonProps: {
        className: 'cancel-btn-modal',
        stylingMode: 'contained',
        type: 'default',
        width: 120,
      },
    },
  ]

  // review and submit button click
  const handleReviewAndSubmitClick = async () => {
    setShowApiSpinner(true)
    setApiSpinnerContent(
      produce((draft) => {
        draft.inProgressTitle = 'Contacting Server'
        draft.inProgressMessage = 'Connecting with the server, please wait...'
        draft.errorTitle = 'Error while auto saving'
      })
    )
    //save the inEligibleElections
    let promise = stateManagerApi.autoSaveIneligibileElections(
      electionsInfo!.id!
    )
    setSpinnerPromise(promise)
    promise
      .then(() => {
        showElectionReview(true)
      })
      .catch(() => {
        setHideClose(false)
      })
  }

  return (
    <div className='investor-election-page-container'>
      {/** Page Header */}
      <Grid className='page-header-items'>
        <Grid item xs={1} md={0.4} className='back-button'>
          <Button
            onClick={() => {
              globalCancelClick()
            }}
            data-testId='back-button'
          >
            <span className='dx-icon-arrowleft'></span>
          </Button>
        </Grid>
        <Grid item xs={11} md={11.6}>
          <p className='state-manager-text'>State Elections</p>
        </Grid>
      </Grid>

      {/** Page Text*/}
      <Grid className='text-paragraph-scaleing'>
        <p className='text-paragraph'>
          Welcome to the State Manager. Begin by selecting your state of
          residency and entity type using the 'General' tab. Completion of this
          section will enable you to perform your composite and withholding
          elections.
        </p>
      </Grid>

      {/** Tabs */}
      <div className='tab-component'>
        <Tabs
          width={'460px'}
          dataSource={tabItems}
          selectedIndex={tabValue}
          onItemClick={onTabClick}
          itemRender={onTabItemRender}
          scrollingEnabled={true}
        />
        {/** Tab Contents*/}
        {loadingState === LoadingStateEnum.Loaded ? (
          <div className='tab-content'>{tabItems[tabValue].content}</div>
        ) : (
          <div className='loading-title'>
            <span> Loading...</span>
          </div>
        )}
      </div>

      <div className='all-buttons-grid'>
        {/** Default buttons for this page*/}

        <div className='button-grid'>
          <div className='state-tab-buttons'>{tabButtons}</div>

          <div className='review-and-save-button'>
            <Button
              onClick={() => {
                handleReviewAndSubmitClick()
              }}
              style={{ color: 'white' }}
              className={
                isReviewAndSubmitEnable ? 'review-and-submit-button' : ''
              }
              text='Review & Submit'
              type={'default'}
              disabled={!isReviewAndSubmitEnable}
              data-testId='review-and-save-button'
            ></Button>
          </div>
        </div>
      </div>
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage={apiSpinnerContent.errorTitle}
        inProgressTitleMessage={apiSpinnerContent.inProgressTitle}
        inProgressMessage={apiSpinnerContent.inProgressMessage}
        successTitleMessage={apiSpinnerContent.successTitle}
        successMessage={apiSpinnerContent.successMessage}
        onClose={() => setShowApiSpinner(false)}
        apiAction={spinnerPromise}
        hideCloseButton={hideClose}
        closeOnSuccess={true}
      />
    </div>
  )
}
