import { View, Text } from '@react-pdf/renderer'
import { ElectionDetail, styles } from './pdf-utils'

interface PdfElectionProps {
  electionDetail: ElectionDetail
}

/** To render composite & withholdings  election */
export const PdfElection = ({ electionDetail }: PdfElectionProps) => (
  <View style={styles.table}>
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>{electionDetail.entityName}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableLeftCol}>
        <Text style={styles.tableCell}>Selected States</Text>
      </View>
      <View style={styles.tableRightCol}>
        <Text style={styles.tableCell}>{electionDetail.states}</Text>
      </View>
    </View>
    {electionDetail.compositeList && (
      <View style={styles.tableRow}>
        <View style={styles.tableLeftCol}>
          <Text style={styles.tableCell}>Applicable Entities</Text>
        </View>
        <View style={styles.tableRightCol}>
          {electionDetail.compositeList.map((item: string) => (
            <Text style={styles.tableCellMultipleLine}>{item}</Text>
          ))}
        </View>
      </View>
    )}
  </View>
)
