import { useParams } from 'react-router-dom'
import { useStateManagerApi } from './use-state-manager-api'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { smGeneralOptionsAtom } from '../state/atom'
import produce from 'immer'

// State Manager general option hook
export function useSMGeneralOption() {
  const stateManagerApi = useStateManagerApi()
  const { groupId, taxYear } = useParams() as {
    groupId: string | undefined
    taxYear: string | undefined
  }
  const [generalOptions, setGeneralOptions] =
    useRecoilState(smGeneralOptionsAtom)
  useEffect(() => {
    // Check for empty
    if (groupId !== undefined && taxYear !== undefined) {
      const groupIdValue = parseInt(groupId)
      const taxYearValue = parseInt(taxYear)
      if (groupIdValue > 0 && taxYearValue > 0) {
        // API call to get general options and update recoil state
        stateManagerApi.getGeneralOptions(taxYearValue, groupIdValue)
      }
    } else if (generalOptions.taxYear > 0) {
      // Clear general options from recoil state
      // when user navigates away from state elections page
      setGeneralOptions(
        produce((draft) => {
          draft.generalOptions = {}
          draft.taxYear = 0
          draft.entityGroupId = 0
        })
      )
    }
  }, [groupId, taxYear])
}
