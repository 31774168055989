import { CheckBox, DataGrid } from 'devextreme-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Column,
  Item,
  Scrolling,
  Toolbar,
  Paging,
} from 'devextreme-react/data-grid'
import { Grid } from '@mui/material'
import { USState, usStatesData } from '../state-manager/state-manager-utils'
import './investor-state-selector.scss'

export interface StateSelectionGlobalProps {
  updateSelectedKeys: (selectedKeys: string[]) => void
  stateSelectionsKeys: string[] | undefined
  eligibleStateKeys: string[] | undefined
  height: string | number | (() => string | number) | undefined
  isEditable: boolean
}

/** Textbox Change event */
interface ICheckBoxEvent {
  event?: any
}

/** Investor State Selector */
export const InvestorStateSelector = ({
  updateSelectedKeys,
  stateSelectionsKeys,
  eligibleStateKeys,
  height,
  isEditable,
}: StateSelectionGlobalProps) => {
  const dataGridRef = useRef<DataGrid>(null)
  const [eligibleStates, setEligibleStates] = useState<USState[]>([])
  // If we don't have a stateSelectionsKeys, then the data is not loaded
  //  yet, and we shouldn't show any options for the user to interact with.
  const isLoading = !stateSelectionsKeys

  // Set eligible state data source
  useEffect(() => {
    setEligibleStates(
      usStatesData.filter((state) =>
        eligibleStateKeys?.includes(state.stateCode)
      )
    )
  }, [eligibleStateKeys])

  /** Create a dataSource for the DataGrid*/
  const dataSource = useMemo(() => {
    // Map the data from usStatesData and include an 'isSelected' property
    let result = eligibleStates.map((state: USState) => ({
      ...state,
      isSelected: stateSelectionsKeys?.includes(state.stateCode) ?? false,
    }))
    return result
  }, [stateSelectionsKeys, eligibleStates])

  /** Define the handleSelectionChanged function to handle row selection changes */
  const handleSelectionChanged = (e: { selectedRowKeys: string[] }) => {
    updateSelectedKeys(e.selectedRowKeys)
  }
// Set select all flag if all the states are selected
  const isAllSelected = dataSource.every((v: USState) => v.isSelected)

  /** For SelectAll - set selected state codes in local state
   *  For UnselectAll - Remove all selected state codes
  */
  const handleSelectAll = (e: ICheckBoxEvent) => {
    if (e.event) {
      if (isAllSelected) {
        // Deselect All.
        updateSelectedKeys([])
      } else {
        // Select All.
        updateSelectedKeys(dataSource.map((x: USState) => x.stateCode))
      }
    }
  }

  return (
    <div className='state-selector'>
      <div className='stateselector-datagrid'>
        <DataGrid
          ref={dataGridRef}
          showBorders={true}
          dataSource={dataSource}
          keyExpr='stateCode'
          noDataText={isLoading ? 'Loading...' : 'No Data Found.'}
          width='100%'
          onSelectionChanged={handleSelectionChanged}
          columnAutoWidth={true}
          height={height}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={false}
          showColumnHeaders={false}
          data-testid='sm-state-datagrid'
        >
          <Paging defaultPageSize={20} enabled={false} />
          <Scrolling mode='standard' showScrollbar={true} />
          <Toolbar>
            <Item location='before'>
              <Grid
                className='grid-container'
                container
                item
                xs={11}
                minWidth={550}
                justifyContent={'flex-start'}
              >
                <Grid item xs={4} className='select-all-chkbox'>
                  <div className='checkbox-container'>
                    <CheckBox
                      disabled={!isEditable}
                      value={isAllSelected}
                      onValueChanged={handleSelectAll}
                    />
                    <span className='select-all-chkbox-text'>Select All</span>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Toolbar>
          <Column
            dataField='isSelected'
            caption=''
            width={50}
            cellRender={(rowData) => (
              <CheckBox
                value={rowData.data.isSelected}
                onValueChanged={(e) => {
                  const updatedData = dataSource
                  const rowIndex = dataSource.indexOf(rowData.data)
                  updatedData[rowIndex].isSelected = e.value
                  updateSelectedKeys(
                    updatedData
                      .filter((item: USState) => item.isSelected)
                      .map((item: USState) => item.stateCode)
                  )
                }}
                disabled={!isEditable}
                className={isEditable ? '' : 'disabled-checkbox'}
              />
            )}
          />
          <Column dataField='name' caption='State Name' />
        </DataGrid>
        <div className='state-selector-buttons'></div>
      </div>
    </div>
  )
}
