import { Button, CheckBox } from 'devextreme-react'
import { ButtonFunction, Modal } from '../../modal/modalBase'
import './state-manager-reports.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { smEntityOptionsAtom } from '../../../state/atom'
import {
  EntityOptionsReport,
  DownloadReportInfo,
} from '../../../model/report-models'
import { useRecoilValue } from 'recoil'
import { AllocatingEntitySelector } from './entity-selector'
import { AllocatingEntitiesDisplay } from './entity-display'
import produce from 'immer'
import { StateManagerReportStatus } from './state-manager-report-status'
import { ICheckBoxEvent, ReportType, generateUniqueOperationId } from './utils'
import { useStateManagerApi } from '../../../hooks/use-state-manager-api'
import { downloadReport } from '../../../signalr/watcher-utilities/download-report'
import { SelectableObjectCacheSelectionState } from '../../../api-client/investor-portal-client'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

export interface StateManagerReportsProps {
  isVisible: boolean
  allocatingEntityIds: number[] | undefined
  selectionCacheId?: string
  investorId: number | undefined
  groupId: number
  taxYear: number
  cacheSelectionState: SelectableObjectCacheSelectionState
  onCancel: () => void
}

/**State Manager Reports model */
export const StateManagerReports = ({
  isVisible,
  allocatingEntityIds,
  selectionCacheId,
  investorId,
  groupId,
  taxYear,
  cacheSelectionState,
  onCancel,
}: StateManagerReportsProps) => {
  const [alloctingEntities, setAlloctingEntities] = useState<
    EntityOptionsReport[]
  >([])
  const [selectedKeys, setSelectedKeys] = useState<number[] | undefined>()
  const [isReportStatusVisible, setIsReportStatusVisible] =
    useState<boolean>(false)
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true)
  const entityOptions = useRecoilValue(smEntityOptionsAtom)
  const stateManagerApi = useStateManagerApi()
  const [downloadReportsInfo, setDownloadReportInfo] = useState<
    DownloadReportInfo[]
  >([])
  const [downloadReportType, setDownloadReportType] = useState<ReportType>({
    partnerSight: true,
    clientFriendly: true,
    investorDemographicChanges: true,
    investorTable: true,
    includeNotSubmitted: false
  })
  const [isAllocatingEntityLoaded, setIsAllocatingEntityLoaded] =
    useState<boolean>(true)

  const isInvestorTableVisible = investorId === undefined
  // Setting custom height for EntitySelector dataGrid and entity-display tagbox
  const customHeight = 'calc(67vh - 300px)'

  /** Load allocating entities based on the  SelectableObjectCacheSelectionState
   * if its 'Some' then call api and get the allocating entity Id's
   * Otherwise pick the allocating entity Id's from cache or row level
   */
  useEffect(() => {
    const abortController = new AbortController()

    if (cacheSelectionState === SelectableObjectCacheSelectionState.Some) {
      // Set loaded flag to false
      setIsAllocatingEntityLoaded(false)

      // API Call to get allocating entity Ids based on the cache id
      stateManagerApi
        .getSelectedInvestorAllocatingEntityIds(
          selectionCacheId!,
          abortController.signal
        )
        .then((response) => {
          // Set loaded flag to true
          setIsAllocatingEntityLoaded(true)
          allocatingEntityIds = response!

          // Set allocating entities for display
          setAllocatingEntities()
        })
    } else {
      // Set allocating entities for display
      setAllocatingEntities()
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [])

  /** Preparing the single allocating entity object element */
  const prepareIndividualEntity = (
    element: EntityOptionsReport
  ): EntityOptionsReport => {
    return {
      isSelected: true,
      allocatingEntityId: element.allocatingEntityId,
      allocatingEntityName: element.allocatingEntityName,
    }
  }

  /** Filtering out the allocating entity */
  const setAllocatingEntities = () => {
    let alloctingEntities: EntityOptionsReport[] = []

    // Filter published entity options
    const publishedEntityOptions = entityOptions.entityOptions.filter(
      (e) => e.publishedDate
    )
    publishedEntityOptions.forEach((element) => {
      if (!allocatingEntityIds) {
        alloctingEntities.push(prepareIndividualEntity(element))
      } else {
        if (
          allocatingEntityIds!.find(
            (item) => item === element.allocatingEntityId
          )
        ) {
          alloctingEntities.push(prepareIndividualEntity(element))
        }
      }
    })

    //sort in ascending order by allocating entity name
    alloctingEntities = alloctingEntities
      .map((x) => ({
        sortName: x.allocatingEntityName!.toLocaleLowerCase(),
        entityName: x,
      }))
      .sort((a, b) => a.sortName.localeCompare(b.sortName))
      .map((x) => x.entityName)

    setAlloctingEntities(alloctingEntities)
    setSelectedKeys(alloctingEntities.map((e) => e.allocatingEntityId!))
  }

  // Preparing all the promises and reporttype
  const allReportPromises = () => {
    let allDownloadReportInfo: DownloadReportInfo[] = []

    // if partnerSight check box checked then add the partnerSight promise to allPromise
    if (downloadReportType.partnerSight) {
      //generating random number for operationId
      const uniqueOperationId = generateUniqueOperationId()
      // API call to get the sas-URI
      const reportCompletePrmoise = stateManagerApi.getClientPartnerSightReport(
        groupId,
        taxYear,
        investorId!,
        selectionCacheId!,
        uniqueOperationId,
        selectedKeys!,
        'PartnerSightReportTemplate'
      )
      // call the download report helper method which will download report as well as any operation error
      const downloadReportPromise = downloadReport(
        reportCompletePrmoise,
        uniqueOperationId
      )
      const partnerSightInfo: DownloadReportInfo = {
        reportName: 'PartnerSight',
        reportPromise: downloadReportPromise,
      }
      allDownloadReportInfo.push(partnerSightInfo)
    }

    // if clientFriendly check box checked then add the partnerSight promise to allPromise
    if (downloadReportType.clientFriendly) {
      //generating random number for operationId
      const uniqueOperationId = generateUniqueOperationId()
      // API call to get the sas-URI
      const reportCompletePrmoise = stateManagerApi.getClientPartnerSightReport(
        groupId,
        taxYear,
        investorId!,
        selectionCacheId!,
        uniqueOperationId,
        selectedKeys!,
        'ClientReportTemplate',
        downloadReportType.includeNotSubmitted
      )
      // call the download report helper method which will download report as well as any operation error
      const downloadReportPromise = downloadReport(
        reportCompletePrmoise,
        uniqueOperationId
      )
      const clientFriendlyInfo: DownloadReportInfo = {
        reportName: 'Client Friendly',
        reportPromise: downloadReportPromise,
      }
      allDownloadReportInfo.push(clientFriendlyInfo)
    }

    // if investorDemographicChanges check box checked then add the partnerSight promise to allPromise
    if (downloadReportType.investorDemographicChanges) {
      //generating random number for operationId
      const uniqueOperationId = generateUniqueOperationId()
      // API call to get the sas-URI
      const reportCompletePrmoise = stateManagerApi.getClientPartnerSightReport(
        groupId,
        taxYear,
        investorId!,
        selectionCacheId!,
        uniqueOperationId,
        selectedKeys!,
        'DemographicReportTemplate'
      )
      // call the download report helper method which will download report as well as any operation error
      const downloadReportPromise = downloadReport(
        reportCompletePrmoise,
        uniqueOperationId
      )
      const demographicInfo: DownloadReportInfo = {
        reportName: 'Demographic Changes',
        reportPromise: downloadReportPromise,
      }
      allDownloadReportInfo.push(demographicInfo)
    }

    // if investorDemographicChanges check box checked then add the partnerSight promise to allPromise
    if (downloadReportType.investorTable && isInvestorTableVisible) {
      //generating random number for operationId
      const uniqueOperationId = generateUniqueOperationId()
      // API call to get the sas-URI
      const reportCompletePrmoise = stateManagerApi.getInvestorTableReport(
        groupId,
        taxYear,
        selectionCacheId!,
        uniqueOperationId,
        selectedKeys!,
        'InvestorTableReportTemplate'
      )
      // call the download report helper method which will download report as well as any operation error
      const downloadReportPromise = downloadReport(
        reportCompletePrmoise,
        uniqueOperationId
      )
      const investorTableInfo: DownloadReportInfo = {
        reportName: 'Investor Table',
        reportPromise: downloadReportPromise,
      }
      allDownloadReportInfo.push(investorTableInfo)
    }

    setDownloadReportInfo(allDownloadReportInfo)
  }

  /** checking for the atleast one allocating entity selected and one report type checkbox checked */
  const isDownloadReportEnable =
    selectedKeys?.length &&
    (downloadReportType.clientFriendly ||
      downloadReportType.partnerSight ||
      downloadReportType.investorDemographicChanges ||
      downloadReportType.investorTable)

  /**  Set selected entities based on the selected allocating entity id */
  const selectedAllocatingEntityDataSource = useMemo(
    () =>
      alloctingEntities.filter((x) =>
        selectedKeys?.includes(x.allocatingEntityId!)
      ),
    [alloctingEntities, selectedKeys]
  )

  //Clearing the all selected entity
  const clearSelection = () => {
    setSelectedKeys([])
  }

  /** set allocating entity id for changed values */
  const updateSelectedValues = (updatedEntities: number[]) => {
    setSelectedKeys(updatedEntities)
  }

  /** Button functions of the Model */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Download Report(s)',
      isDisabled: !isDownloadReportEnable,
      onClick: () => {
        setIsReportStatusVisible(true)
        allReportPromises()
      },
      buttonProps: {
        className: 'download-report-button',
        stylingMode: 'contained',
        type: 'default',
        width: 210,
        'data-testid': 'download-report-button',
      },
    },
  ]

  /**  Handle Select All Checkbox Functionality */
  const handleSelectAllCheckbox = (e: ICheckBoxEvent) => {
    if (e.event) {
      const newValue = e.value
      setIsAllSelected(newValue)
      setDownloadReportType(
        produce((draft) => {
          draft.partnerSight = newValue
          draft.clientFriendly = newValue
          draft.investorDemographicChanges = newValue
          draft.includeNotSubmitted = false
          if (isInvestorTableVisible) {
            draft.investorTable = newValue
          }
        })
      )
    }
  }

  /** Effect to handle Select All button */
  useEffect(() => {
    //do not show investor table in action menu
    const isAllSelected = isInvestorTableVisible
      ? downloadReportType.partnerSight &&
        downloadReportType.clientFriendly &&
        downloadReportType.investorDemographicChanges &&
        downloadReportType.investorTable
      : downloadReportType.partnerSight &&
        downloadReportType.clientFriendly &&
        downloadReportType.investorDemographicChanges

    setIsAllSelected(isAllSelected)
  }, [isInvestorTableVisible, downloadReportType])

  // Handling the partner sight checkbox check
  const handlePartnerSightCheckbox = (e: ICheckBoxEvent) => {
    setDownloadReportType(
      produce((draft) => {
        draft.partnerSight = e.value
      })
    )
  }

  // Handling the Client Friendly checkbox check
  const handleClientFriendlyCheckbox = (e: ICheckBoxEvent) => {
    setDownloadReportType(
      produce((draft) => {
        draft.clientFriendly = e.value
        draft.includeNotSubmitted = false
      })
    )
  }

  // Handling the Demographic Changes checkbox check
  const handleDemographicChangesCheckbox = (e: ICheckBoxEvent) => {
    setDownloadReportType(
      produce((draft) => {
        draft.investorDemographicChanges = e.value
      })
    )
  }

  // Handling the Investor Table checkbox check
  const handleInvestorTableCheckbox = (e: ICheckBoxEvent) => {
    setDownloadReportType(
      produce((draft) => {
        draft.investorTable = e.value
      })
    )
  }

  // Handling the Include Not Subitted checkbox check
  const handleIncludeNotSubittedCheckbox = (e: ICheckBoxEvent) => {
    setDownloadReportType(
      produce((draft) => {
        draft.includeNotSubmitted = e.value
      })
    )
  }

  // close download result user modal
  const reportStatusCloseDialog = () => {
    setIsReportStatusVisible(false)
  }

  return (
    <Modal
      visible={isVisible}
      title={'Download Results'}
      maxWidth={800}
      maxHeight={650}
      buttonFunctions={buttonFunctions}
      disableScrollbar={false}
      showCloseButtonInTitle={true}
      preventModalClose={true}
    >
      <div className='allocating-entity-selections'>
        <div className='content-area'>
          <div className='col'>
            <span className='header-display'>Allocating Entity Selection</span>
            <AllocatingEntitySelector
              entityOptionsView={alloctingEntities}
              updateSelectedKeys={(entityIds) => {
                updateSelectedValues(entityIds)
              }}
              selectedEntity={selectedKeys}
              height={customHeight}
              isAllocatingEntityLoaded={isAllocatingEntityLoaded}
            />
          </div>
          <div className='col'>
            <span className='header-display'>Selected Allocating Entities</span>
            <div className='align-tagbox'>
              <AllocatingEntitiesDisplay
                entityOptionsView={selectedAllocatingEntityDataSource}
                selectedEntity={selectedKeys}
                updateSelectedKeys={(entityIds) => {
                  updateSelectedValues(entityIds)
                }}
                height={customHeight}
              />
            </div>
            <div className='entity-tab-buttons'>
              <Button
                onClick={() => {
                  clearSelection()
                }}
                text='Clear Selection'
                type='normal'
                data-testid='entity-cancel-button'
              ></Button>
            </div>
          </div>
        </div>

        <div className='reporttype-content-area'>
          <span className='header-display'>Report Type(s)</span>
          <div>
            <CheckBox
              className='all-reports-box'
              text='Select All Reports'
              value={isAllSelected}
              onValueChanged={handleSelectAllCheckbox}
            />
          </div>
          <div className='report-types'>
            <div className='row'>
              <div className='col'>
                <CheckBox
                  text='PartnerSight'
                  value={downloadReportType.partnerSight}
                  onValueChanged={handlePartnerSightCheckbox}
                />
              </div>
              <div className='col'>
                <CheckBox
                  text='Client Friendly'
                  value={downloadReportType.clientFriendly}
                  onValueChanged={handleClientFriendlyCheckbox}
                />
              </div>
              <div className='col'>
                <CheckBox
                  text='Investor Demographic Changes'
                  value={downloadReportType.investorDemographicChanges}
                  onValueChanged={handleDemographicChangesCheckbox}
                />
              </div>
            </div>
          </div>
          <div className='report-types'>
          <div className='row'>
            <div className='col'>
              <CheckBox
                text='Investor Table'
                visible={isInvestorTableVisible}
                value={downloadReportType.investorTable}
                onValueChanged={handleInvestorTableCheckbox}
              />
            </div>         
            <div className='col'>
            <div className='no-election-margin'>
              {downloadReportType.clientFriendly && <CheckBox
                  text='Include Not Submitted'
                  value={downloadReportType.includeNotSubmitted}
                  onValueChanged={handleIncludeNotSubittedCheckbox}
                />}  
              </div>
            </div>
            <div className='col'></div>
          </div>
          </div>
        </div>
      </div>
      {isReportStatusVisible && (
        <StateManagerReportStatus
          isVisible={isReportStatusVisible}
          downloadReportInfo={downloadReportsInfo}
          onClose={reportStatusCloseDialog}
        />
      )}
    </Modal>
  )
}
