import {
  Button,
  RadioGroup,
  SelectBox,
  Tabs,
  TextBox,
  ValidationGroup,
  Validator,
} from 'devextreme-react'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { EntityView } from '../../api-client/entity-manager-client-v3'
import { Grid } from '@mui/material'
import DataGrid, {
  Column,
  Editing,
  NumericRule,
  PatternRule,
  RangeRule,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { CustomRule } from 'devextreme-react/validator'
import produce from 'immer'
import {
  businessTypeItems,
  countryTypes,
  entityTabs,
  ssnSelectOptions,
  stateTypes,
  taxClassificationTypes,
} from './entity-utils'
import './add-edit-entity.scss'
import ValidationSummary from 'devextreme-react/validation-summary'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { AllocatingEntities } from './allocating-entity'
import {
  EmailColumnValidation,
  QuickEditEmailModel,
} from '../../client-models/clientmodels'
import { RowRemovingInfo, RowValidatingInfo } from 'devextreme/ui/data_grid'
import {
  multipleEmailOrDomainPattern,
  singleEmailPattern,
} from '../../utilities/email-validation'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { SpinnerModal } from '../modal/spinnerModal'
import { clientAndPartnershipStateAtom } from '../../state/atom'
import { useRecoilValue } from 'recoil'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

interface AddEditEntityProps {
  isRsmClient?: boolean
  entityId: number
  groupId: number
  cacheId: string
  onModalClose: (isUpdated?: boolean) => void
}

/** Validate, combining all email address charecter should not be more then 500 */
const getCommaSeperatedEmails = (
  updatedEmailList: QuickEditEmailModel[]
): string => {
  let emailString: string = ''
  updatedEmailList.forEach((e) => {
    if (emailString) {
      emailString = emailString + ';'
    }
    emailString = emailString + e.email
  })
  return emailString
}

// Validate email using regex
const validateEmail = (emailText: string) => {
  return new RegExp(multipleEmailOrDomainPattern).test(emailText)
}

/**Add Edit Entity Modal Component*/
export const AddEditEntity = ({
  isRsmClient,
  entityId,
  groupId,
  cacheId,
  onModalClose,
}: AddEditEntityProps) => {
  const entityManagerApi = useEntityClientApi()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [targetEntity, setTargetEntity] = useState<EntityView>({})
  const [entityLoaded, setEntityLoaded] = useState<boolean>(false)
  const validationRef = useRef<ValidationGroup>(null)
  const [emailList, setEmailList] = useState<QuickEditEmailModel[]>([])
  const [addDisabled, setAddDisabled] = useState(true)
  const [emailIds, setEmailIds] = useState<string | undefined>('')
  const [emailRowValidation, setEmailRowValidation] =
    useState<EmailColumnValidation>({
      isError: false,
      message: '',
    })
  const [showApiSpinner, setShowApiSpinner] = useState<boolean>(false)
  const [savePromise, setSavePromise] = useState<Promise<any>>()
  const [disableSave, setDisableSave] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  const gridRef = useRef<DataGrid>(null)
  const commonDialog = useCommonDialogs()
  const isEditMode = entityId > 0 // if entityId > 0 then set edit mode to true
  const emailMaxLength: number = 500
  const usCountryCode = 'US'

  useEffect(() => {
    const abortController = new AbortController()
    /**Get the Entity data from API based on Id */
    const getEntityById = async (id: number) => {
      try {
        if (id > 0) {
          const response = await entityManagerApi.getEntityById(
            id,
            abortController.signal
          )
          setTargetEntity(response!)

          // Get the email Addresses to show in email datagrid
          if (response?.emailAddress) {
            setEmailListState(response?.emailAddress!)
          }
        } else {
          // Create new entity with default entity type based on isRsmClient
          const newEntity: EntityView = {
            isRSMClient: isRsmClient,
            entityType: isRsmClient ? 'Business' : 'Individual',
          }
          setTargetEntity(newEntity)
        }
      } catch {
        setIsError(true)
      } finally {
        setEntityLoaded(true)
      }
    }
    // Get entity by id
    getEntityById(entityId)

    return () => {
      // Abort any API calls currently in flight.
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [entityId, isRsmClient])

  /** getModalTitle function - returns the modal title based on the current state values. */
  const getModalTitle = useMemo(() => {
    let entityName: string = ''

    // Check if the component is in edit mode and targetEntity isLoaded.
    if (isEditMode && targetEntity) {
      // If targetEntity represents an investor.
      if (targetEntity.entityType === 'Individual') {
        entityName =
          targetEntity.firstName! || targetEntity.lastName!
            ? ` - ${targetEntity.firstName} ${targetEntity.lastName}`
            : ''
      } else {
        // If targetEntity represents an allocating entity.
        entityName = targetEntity.entityName!
          ? `- ${targetEntity.entityName}`
          : ''
      }
    }
    //  modal title based on whether it's an edit or add mode.
    return isEditMode ? `Edit Entity${entityName}` : 'Add Entity'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditMode,
    targetEntity.entityName,
    targetEntity.firstName,
    targetEntity.lastName,
  ])

  /** Tab change event - to set the selected tab index in state */
  const handleTabsSelectionChanged = (e: OptionChangedEventInfo<any>) => {
    const newIndex = e.component.option('selectedIndex')
    if (newIndex !== -1) {
      setSelectedTabIndex(newIndex)
    }
  }

  /** Get updated entity data and reset the below values :
   * if IsRSMClient == false, ensure that the ClientID == undefined
   * if business entity, remove First Name, Middle Name, Last Name
   * if Individual entity, remove Company Name  */
  const getUpdatedEntity = () => {
    // Set or clear value based on the user selection
    const updatedEntity: EntityView = JSON.parse(JSON.stringify(targetEntity))
    updatedEntity.emailAddress = getCommaSeperatedEmails(emailList)

    // Set formatted client id if its allocating entity
    if (updatedEntity.isRSMClient) {
      updatedEntity.formattedClientId = updatedEntity.clientId?.toString()
    } else {
      updatedEntity.clientId = undefined
    }

    // Reset values based on the entity type
    if (updatedEntity.entityType === 'Individual') {
      updatedEntity.entityName = undefined
    } else {
      updatedEntity.firstName = undefined
      updatedEntity.lastName = undefined
      updatedEntity.middleInitial = undefined
    }

    // if tax resident country is not US then reset the state field
    if (updatedEntity.taxResidencyCountry !== usCountryCode) {
      updatedEntity.taxResidencyState = undefined
    }

    // if mailing address country is US then reset foreignProvince field
    if (updatedEntity.country === usCountryCode) {
      updatedEntity.foreignProvince = undefined
    } else {
      // Country is not US then reset state field
      updatedEntity.state = undefined
    }

    // if its Add entity then create new entity group
    if (!updatedEntity.entityGroupId || updatedEntity.entityGroupId === 0) {
      updatedEntity.entityGroupId = groupId
    }

    return updatedEntity
  }

  /** Buttons for Add or Edit entity Modal */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      isDefaultAction: true,
      buttonProps: {
        className: 'cancel-btn-edit-modal',
        stylingMode: 'contained',
        type: 'normal',
      },
      onClick: () => {
        // if any data changed in UI then show warning popup
        if (isDirty) {
          commonDialog.showDialog({
            dialogType: 'general',
            title: 'Are you sure?',
            content:
              targetEntity.id! > 0
                ? 'You have unsaved changes. By proceeding, changes to this entity will not be saved. Are you sure you would like to Proceed?'
                : 'By proceeding, you are confirming that you do not want to create this entity. Are you sure you would like to Proceed?',
            buttonFunctions: [
              {
                label: 'Cancel',
                onClick: () => {},
                isCloseAction: true,
                buttonProps: {
                  stylingMode: 'contained',
                  type: 'normal',
                  width: 100,
                  'data-testid': 'cancel-button',
                },
              },
              {
                label: 'Proceed ',
                onClick: () => {
                  setIsDirty(false)
                  onModalClose()
                },
                isCloseAction: true,
                buttonProps: {
                  useSubmitBehavior: false,
                  stylingMode: 'contained',
                  type: 'default',
                  width: 160,
                  'data-testid': 'proceed-close-button',
                },
              },
            ],
            omitDefaultButton: true,
          })
        } else {
          onModalClose() // close add edit modal
        }
      },
    },
    {
      label: 'Save ',
      buttonProps: {
        className: 'save-btn-edit-modal',
        stylingMode: 'contained',
        disabled: disableSave,
        type: 'default',
      },
      visible: !isError,
      onClick: async () => {
        const isEmailLengthExceeded =
          getCommaSeperatedEmails(emailList).length > emailMaxLength

        //if combined all email length exceeds 500 then show the error model
        if (isEmailLengthExceeded) {
          showMailErrorModel()
        } else if (
          // If all the fields have valid data
          validationRef.current?.instance.validate().isValid &&
          !emailRowValidation.isError
        ) {
          // Perform save if there's any change in data
          // then make API call to save or update entity data
          // otherwise close the modal
          if (isDirty) {
            // Set show spinner to display spinner and make save entity API call
            setShowApiSpinner(true)
            const savePromise = entityManagerApi.insertOrUpdateEntity(
              getUpdatedEntity(),
              cacheId
            )
            setSavePromise(savePromise)

            // Reload the entity list table data
            await savePromise.then(() => onModalClose(true))
          } else {
            onModalClose() // close add edit modal
          }
        } else {
          // set focus to error message
          var element = document.getElementById('divSummary')
          element?.scrollIntoView()
        }
      },
    },
  ]

  /** Update entity property value in state based on the property name */
  const setEntityValue = (propertyName: string, value: any) => {
    // Set dirty flag to validate when user click on cancel without saving changes
    setIsDirty(true)

    setTargetEntity(
      produce((draft) => {
        draft[propertyName as keyof EntityView] = value as any
      })
    )
  }

  /** setting the Email list state to show in the data grid and to maintain adding/removing(in local state)*/
  const setEmailListState = (emailAddresses: string) => {
    const emailData: QuickEditEmailModel[] = []
    // Set the exiting email in a table
    if (emailAddresses) {
      const emailArray = emailAddresses.split(';')
      for (let index = 0; index < emailArray.length; index++) {
        emailData.push({
          id: index + 1,
          email: emailArray[index],
        })
      }
    }
    setEmailList(emailData)
  }

  // setting content for delete email confirmation model
  const setDeleteModelContent = (selectedEmail: string) => {
    return (
      <div>
        {selectedEmail}
        <div className='content-display-align'>
          This action will permanently delete the selected email and cannot be
          reverted. Do you wish to proceed?
        </div>
      </div>
    )
  }

  // Show confirm delete email modal
  const emailRowDelete = (e: RowRemovingInfo<QuickEditEmailModel, any>) => {
    e.cancel = true
    return commonDialog.showDialog({
      dialogType: 'general',
      title: 'Delete Email',
      content: setDeleteModelContent(e.data.email),
      buttonFunctions: [
        {
          label: 'Cancel',
          onClick: () => {},
          isCloseAction: true,
          buttonProps: {
            stylingMode: 'contained',
            type: 'normal',
            width: 100,
            'data-testid': 'cancel-button',
          },
        },
        {
          label: 'Confirm Delete',
          onClick: () => onDelete(e.data),
          isCloseAction: true,
          buttonProps: {
            useSubmitBehavior: false,
            stylingMode: 'contained',
            type: 'default',
            width: 160,
            'data-testid': 'confirm-delete-button',
          },
        },
      ],
      omitDefaultButton: true,
    })
  }

  // Show error model if email charecter count
  const showMailErrorModel = () => {
    return commonDialog.showDialog({
      dialogType: 'error',
      title: 'Error',
      content:
        ' Total email characters exceed maximum allowable amount. Revise/remove email addresses as needed.',
      buttonFunctions: [
        {
          label: 'Close',
          onClick: () => {},
          isCloseAction: true,
          isDefaultAction: true,
          buttonProps: {
            useSubmitBehavior: false,
            stylingMode: 'contained',
            type: 'default',
            width: 100,
            'data-testid': 'close-email-button',
          },
        },
      ],
      omitDefaultButton: true,
    })
  }

  // Delete email from the state
  const onDelete = (emailModel: QuickEditEmailModel) => {
    const index = emailList.findIndex((d) => d.id === emailModel?.id)
    if (index >= 0) {
      emailList.splice(index, 1)
    }
    setEmailList(emailList)
    setIsDirty(true)

    gridRef.current?.instance.refresh()
  }

  /** Validate and add email to the list */
  const addEmailClick = () => {
    setIsDirty(true)

    // Split emails using ;
    let newEmails: string[] = emailIds!.split(';')
    newEmails.forEach((mail) => {
      const oldEmail = emailList.find((v) => v.email === mail)
      if (!oldEmail) {
        emailList.push({
          id: Math.max(...emailList.map((o) => o.id), 0) + 1,
          email: mail,
        })
      }
    })
    setEmailList(emailList)
    //resetting the value of row validation
    setEmailRowValidation(
      produce((draft) => {
        draft.isError = false
        draft.message = ''
      })
    )
    setEmailIds(undefined)
    gridRef.current?.instance.refresh()
  }

  // validating the value while row editing
  // using here any because couldn't found any proper type
  const onRowValidating = (e: RowValidatingInfo<QuickEditEmailModel, any>) => {
    // Check for change in email and set dirty flag
    if (e.newData.email !== e.oldData.email) {
      setIsDirty(true)
    }
  }

  /** Adds a guard to the email editor textboxes to reeneable the save button
   *   after the user's data is validated.  Without this, the user can click the save
   *   button, and potentially save an invalid email address, since the value doesn't get validated.  */
  const addBlurGuard = useCallback(() => {
    let targetElement: HTMLInputElement

    const lostFocusCallback = () => {
      if (targetElement) {
        setDisableSave(false)

        // Remove the listener when the email editor loses focus.
        targetElement.removeEventListener('blur', lostFocusCallback)
        targetElement.addEventListener('focus', gotFocusCallback)
      }
    }

    const gotFocusCallback = () => {
      if (targetElement) {
        setDisableSave(true)

        // Remove the listener when the email editor loses focus.
        targetElement.removeEventListener('focus', gotFocusCallback)
        targetElement.addEventListener('blur', lostFocusCallback)
      }
    }

    // Get All inputs on the page.
    const editorInputs = document.querySelectorAll('.dx-texteditor-input')

    // Find the editor with focus and add the event handler for when it loses focus.
    //  This presumably has the active email editor, which we want to add an event to.
    editorInputs.forEach((elem) => {
      if (document.activeElement === elem) {
        // Set the target element so we can remove the event handler later.
        targetElement = elem as HTMLInputElement
        // Add the event listener to the input element to guard when it looses
        //  focus.  When the user clicks the save button, any value in the
        //  email editor does not get validated, and we can potentially save
        //  invalid email addresses.  This guards against that condition.
        elem.addEventListener('blur', lostFocusCallback)
      }
    })
  }, [])

  return (
    <Modal
      visible={true}
      title={getModalTitle}
      maxHeight={800}
      maxWidth={700}
      showCloseButtonInTitle={true}
      buttonFunctions={buttonFunctions}
      preventModalClose={true}
      disableScrollbar={false}
      className='add-edit-entity-modal'
    >
      {entityLoaded ? (
        <Tabs
          dataSource={entityTabs}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
          onOptionChanged={handleTabsSelectionChanged}
          scrollByContent={false}
          disabled={disableSave}
        />
      ) : (
        <span className='loading-title'> Loading..</span>
      )}
      {isError && <span className='error-display'>Error occurred</span>}
      {entityLoaded && !isError && (
        <ValidationGroup
          ref={validationRef}
          id='entityValidationGroup'
          className='top-align'
        >
          <div
            id='entityInfoDiv'
            className={selectedTabIndex === 0 ? 'tab-active' : 'tab-inactive'}
          >
            <Grid container spacing={1.75}>
              <Grid item xs={8}>
                <span className='header-font'>
                  Entity Type<span className='required-mark'>*</span>
                </span>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup
                  items={businessTypeItems}
                  id='entity-type-radiobutton'
                  data-testid='entity-type-radiobutton'
                  value={targetEntity.entityType}
                  valueExpr='value'
                  displayExpr='label'
                  onValueChanged={(e) => setEntityValue('entityType', e.value)}
                  layout='horizontal'
                  focusStateEnabled={false}
                  validationMessageMode='always'
                  disabled={targetEntity.isRSMClient}
                >
                  <Validator>
                    <RequiredRule message='Entity Type is required' />
                  </Validator>
                </RadioGroup>
              </Grid>
              <Grid item xs={isEditMode ? 6 : 8}>
                <span className='header-font'>Tax Classification</span>
              </Grid>
              {isEditMode && (
                <Grid item xs={4}>
                  <span className='header-font'>Entity ID</span>
                </Grid>
              )}
              <Grid item xs={6}>
                <SelectBox
                  dataSource={taxClassificationTypes}
                  displayExpr='label'
                  valueExpr='value'
                  id='tax-classification-select-box'
                  data-testid='tax-classification-select-box'
                  value={targetEntity.taxClassification}
                  placeholder='--Select Tax Classification--'
                  showClearButton={true}
                  onValueChange={(changedValue) =>
                    setEntityValue('taxClassification', changedValue)
                  }
                />
              </Grid>
              {isEditMode && (
                <Grid item xs={4}>
                  <TextBox
                    id='entity-id-textbox'
                    data-testid='entity-id-textbox'
                    disabled={isEditMode}
                    readOnly={isEditMode}
                    value={targetEntity.id?.toString()}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <span className='header-font'>Entity Information</span>
              </Grid>
            </Grid>
            {targetEntity.entityType === 'Individual' && (
              <Grid container spacing={1.75} className='row-spacing'>
                <Grid item xs={6}>
                  <span>
                    First Name<span className='required-mark'>*</span>
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span>Middle Name</span>
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id='first-name-textbox'
                    data-testid='first-name-textbox'
                    value={targetEntity.firstName}
                    maxLength={1000}
                    onValueChanged={(e) => setEntityValue('firstName', e.value)}
                  >
                    <Validator>
                      <RequiredRule message='First Name is required' />
                    </Validator>
                  </TextBox>
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id='middle-name-textbox'
                    data-testid='middle-name-textbox'
                    maxLength={1000}
                    value={targetEntity.middleInitial}
                    onValueChanged={(e) =>
                      setEntityValue('middleInitial', e.value)
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <span>
                    Last Name<span className='required-mark'>*</span>
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span>Suffix</span>
                </Grid>
                <Grid item xs={8}>
                  <TextBox
                    id='last-name-textbox'
                    data-testid='last-name-textbox'
                    value={targetEntity.lastName}
                    maxLength={1000}
                    onValueChanged={(e) => setEntityValue('lastName', e.value)}
                  >
                    <Validator>
                      <RequiredRule message='Last Name is required' />
                    </Validator>
                  </TextBox>
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    id='suffix-textbox'
                    data-testid='suffix-textbox'
                    maxLength={1000}
                    value={targetEntity.suffix}
                    onValueChanged={(e) => setEntityValue('suffix', e.value)}
                  ></TextBox>
                </Grid>
              </Grid>
            )}
            {targetEntity.entityType === 'Business' && (
              <Grid container spacing={1.75} className='row-spacing'>
                <Grid item xs={8}>
                  Company Name<span className='required-mark'>*</span>
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id='company-name-textbox'
                    data-testid='company-name-textbox'
                    value={targetEntity.entityName}
                    maxLength={1000}
                    onValueChanged={(e) =>
                      setEntityValue('entityName', e.value)
                    }
                  >
                    <Validator>
                      <RequiredRule message='Company Name is required' />
                    </Validator>
                  </TextBox>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1.75} className='extra-row-spacing'>
              <Grid item xs={8}>
                <span>Name Continued</span>
              </Grid>
              <Grid item xs={3}>
                <span>Entity Short Name</span>
              </Grid>
              <Grid item xs={8}>
                <TextBox
                  id='name-continued-textbox'
                  data-testid='name-continued-textbox'
                  maxLength={1000}
                  value={targetEntity.nameContinued}
                  onValueChanged={(e) =>
                    setEntityValue('nameContinued', e.value)
                  }
                ></TextBox>
              </Grid>
              <Grid item xs={4}>
                <TextBox
                  id='short-name-textbox'
                  maxLength={1000}
                  data-testid='short-name-textbox'
                  value={targetEntity.entityShortName}
                  onValueChanged={(e) =>
                    setEntityValue('entityShortName', e.value)
                  }
                ></TextBox>
              </Grid>
              <Grid item xs={6}>
                <span>
                  EIN/SSN<span className='required-mark'>*</span>
                </span>
              </Grid>
              <Grid item xs={6}>
                {targetEntity.isRSMClient === true && (
                  <span>
                    IPM Number <span className='required-mark'>*</span>
                  </span>
                )}
                {targetEntity.isRSMClient === false &&
                  'Allocating Entity Association'}
              </Grid>
              <Grid item xs={6} className='row-bottom-spacing'>
                <SelectBox
                  dataSource={ssnSelectOptions}
                  placeholder='Enter or Select EIN/SSN'
                  displayExpr='label'
                  valueExpr='value'
                  data-testid='ssn-select-box'
                  id='ssn-select-box'
                  value={targetEntity.identificationNumber}
                  showClearButton={true}
                  acceptCustomValue={true}
                  onCustomItemCreating={(e) => {
                    setTargetEntity(
                      produce((draft) => {
                        draft.identificationNumber = e.text
                        draft.searchableIdentificationNumber =
                          e.text?.replaceAll('-', '')
                      })
                    )
                    e.customItem = { label: e.text, value: e.text }
                    // if there's any change then set dirty flag
                    if (
                      targetEntity.identificationNumber &&
                      e.text !== targetEntity.identificationNumber
                    ) {
                      setIsDirty(true)
                    }
                  }}
                  onValueChange={(changedValue) => {
                    setIsDirty(true)
                    setTargetEntity(
                      produce((draft) => {
                        draft.identificationNumber = changedValue
                        draft.searchableIdentificationNumber = changedValue
                      })
                    )
                  }}
                >
                  <Validator>
                    <RequiredRule message='EIN/SSN is required' />
                    <PatternRule
                      ignoreEmptyValue={true}
                      message='EIN/SSN is invalid'
                      /** EID/ SSN pattern rule
                       * i) 9 digit number in these format XX-XXXXXXX or XXX-XX-XXXX or XXXXXXXXX and
                       * ii) allowed possible values are 'Tax Exempt', 'Applied For' & 'Foreign'
                       */
                      pattern='^\d{2}-\d{7}$|^\d{3}-\d{2}-\d{4}$|^\d{9}$|^Tax Exempt$|^Applied For$|^Foreign$'
                    />
                  </Validator>
                </SelectBox>
              </Grid>
              <Grid item xs={6}>
                {targetEntity.isRSMClient === true && (
                  <TextBox
                    id='ipm-number-textbox'
                    data-testid='ipm-number-textbox'
                    disabled={isEditMode}
                    readOnly={isEditMode}
                    value={targetEntity.clientId?.toString()}
                    onValueChanged={(e) => setEntityValue('clientId', e.value)}
                  >
                    <Validator>
                      <RequiredRule message='IPM Number is required' />
                      <NumericRule
                        ignoreEmptyValue={true}
                        message='Enter Valid IPM Number'
                      />
                      <RangeRule
                        ignoreEmptyValue={true}
                        max={9999999}
                        min={1}
                        message='Enter Valid IPM Number'
                      ></RangeRule>
                    </Validator>
                  </TextBox>
                )}
                {targetEntity.isRSMClient === false && (
                  <AllocatingEntities
                    groupId={groupId}
                    selectedValue={targetEntity.entityLinks?.map(
                      (e) => e.parentEntityId!
                    )}
                    placeholderText='--Select Allocating Entity Association--'
                    notifyError={() => setIsError(true)}
                    updateValues={(newItems, removedItems) => {
                      setTargetEntity(
                        produce((draft) => {
                          if (!draft.entityLinks) {
                            draft.entityLinks = []
                          }
                          // Add new selected items to the list
                          newItems.forEach((id) => {
                            draft.entityLinks?.push({ parentEntityId: id })
                          })

                          // Remove the deleted items from the list
                          removedItems.forEach((id) => {
                            const index = draft.entityLinks?.findIndex(
                              (e) => e.parentEntityId === id
                            )
                            draft.entityLinks?.splice(index!, 1)
                          })
                        })
                      )
                      // If there's any add or update then set the dirty flag
                      if (newItems.length > 0 || removedItems.length > 0) {
                        setIsDirty(true)
                      }
                    }}
                  ></AllocatingEntities>
                )}
              </Grid>
            </Grid>
          </div>
          <div
            className={selectedTabIndex === 1 ? 'tab-active' : 'tab-inactive'}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <span className='header-font'>Email Address</span>
              </Grid>
              <Grid item xs={10}>
                <span>To add multiple emails, please separate using ';'</span>
              </Grid>
              <Grid item xs={10}>
                <TextBox
                  id='email-textbox'
                  data-testid='email-textbox'
                  value={emailIds}
                  onOptionChanged={(
                    e: OptionChangedEventInfo<TextBoxInstance>
                  ) => {
                    // Validate the textbox entered value & enable Add button
                    if (e.name === 'text') {
                      setEmailIds(e.value)
                      if (e.value.length > 0 && validateEmail(e.value)) {
                        setAddDisabled(false)
                      } else {
                        setAddDisabled(true)
                      }
                    }
                  }}
                  onEnterKey={() => addEmailClick()}
                >
                  <Validator>
                    <PatternRule
                      ignoreEmptyValue={true}
                      message='Email is invalid'
                      /** check the pattern for each email address seperated by ';'(semicolon) */
                      pattern={multipleEmailOrDomainPattern}
                    />
                    <CustomRule
                      validationCallback={(e: { value?: string }) => {
                        const isMaxLength =
                          !e.value || e.value.length < emailMaxLength
                        setAddDisabled(e.value ? !isMaxLength : isMaxLength)
                        return isMaxLength
                      }}
                      message='Email length exceeds 500 character'
                    />
                  </Validator>
                </TextBox>
              </Grid>
              <Grid item xs={2} className='add-button-grid'>
                <Button
                  disabled={addDisabled}
                  type='default'
                  text='Add'
                  onClick={() => addEmailClick()}
                />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  id='emailGrid'
                  className='emails-data-grid'
                  ref={gridRef}
                  dataSource={emailList}
                  keyExpr='id'
                  repaintChangesOnly={true}
                  showColumnLines={false}
                  showRowLines={true}
                  wordWrapEnabled={false}
                  showBorders={true}
                  showColumnHeaders={true}
                  onRowRemoving={emailRowDelete}
                  height={320}
                  onRowValidating={onRowValidating}
                  onEditingStart={() => {
                    setDisableSave(true)

                    // All other methods of applying this function when the
                    //  email textbox exists have been exhausted without success.
                    //  Wait 100ms to apply the blur guard, so we're sure that the
                    //  textbox exists.  10ms was used, and successful, so 100ms is
                    //  just extra insurance that it will work.
                    // NOTE: All other event handlers regarding the cancellation of editing a cell
                    //  has been explored with no success.  This hack is the only way to have found that works.
                    //  Also, tricks with saving the data when the Save button is clicked have been explored without success.
                    setTimeout(() => {
                      addBlurGuard()
                    }, 100)
                  }}
                >
                  <Editing
                    mode='cell'
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={false}
                    confirmDelete={false}
                  />
                  <Column
                    caption='Id'
                    dataField='id'
                    visible={false}
                    defaultSortOrder='desc'
                  ></Column>
                  <Column caption='Email' dataField='email'>
                    <RequiredRule message='Email is required' />
                    <PatternRule
                      message='Email is invalid'
                      pattern={singleEmailPattern}
                    />
                    <CustomRule
                      validationCallback={(e: any) => {
                        // using the type any because we don't have any altrnate
                        return e.value ? e.value.length < emailMaxLength : true
                      }}
                      message='Email length exceeds 500 character'
                    />
                  </Column>
                </DataGrid>
              </Grid>
            </Grid>
          </div>
          <div
            className={selectedTabIndex === 2 ? 'tab-active' : 'tab-inactive'}
          >
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <span className='header-font'>Country of Residency</span>
              </Grid>
              <Grid item xs={5}>
                <SelectBox
                  dataSource={countryTypes}
                  displayExpr='label'
                  valueExpr='value'
                  id='country-residency-select-box'
                  data-testid='country-residency-select-box'
                  value={targetEntity.taxResidencyCountry}
                  placeholder='--Select Country of Residency--'
                  showClearButton={true}
                  onValueChange={(changedValue) => {
                    setEntityValue('taxResidencyCountry', changedValue)
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <span className='header-font'>State of Residency</span>
              </Grid>
              <Grid item xs={5}>
                {(!targetEntity.taxResidencyCountry ||
                  targetEntity.taxResidencyCountry === usCountryCode) && (
                  <SelectBox
                    dataSource={stateTypes}
                    displayExpr='label'
                    valueExpr='value'
                    id='state-residency-select-box'
                    data-testid='state-residency-select-box'
                    value={targetEntity.taxResidencyState}
                    placeholder='--Select State of Residency--'
                    showClearButton={true}
                    disabled={
                      targetEntity.taxResidencyCountry !== usCountryCode
                    }
                    onValueChange={(changedValue) =>
                      setEntityValue('taxResidencyState', changedValue)
                    }
                  />
                )}
                {targetEntity.taxResidencyCountry &&
                  targetEntity.taxResidencyCountry !== usCountryCode && (
                    <TextBox
                      id='state-residency-textbox'
                      data-testid='state-residency-textbox'
                      maxLength={1000}
                      disabled={true}
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <span className='header-font'>Mailing Address</span>
              </Grid>
              <Grid item xs={12}>
                <span>Street1</span>
              </Grid>
              <Grid item xs={10}>
                <TextBox
                  id='street1-textbox'
                  data-testid='street1-textbox'
                  value={targetEntity.street1}
                  maxLength={1000}
                  onValueChanged={(e) => setEntityValue('street1', e.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <span>Street2</span>
              </Grid>
              <Grid item xs={10}>
                <TextBox
                  id='street2-textbox'
                  data-testid='street2-textbox'
                  value={targetEntity.street2}
                  maxLength={1000}
                  onValueChanged={(e) => setEntityValue('street2', e.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <span>City</span>
              </Grid>
              <Grid item xs={7}>
                <span>Zip</span>
              </Grid>
              <Grid item xs={5}>
                <TextBox
                  id='city-textbox'
                  data-testid='city-textbox'
                  maxLength={1000}
                  value={targetEntity.city}
                  onValueChanged={(e) => setEntityValue('city', e.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextBox
                  id='zip-textbox'
                  data-testid='zip-textbox'
                  maxLength={1000}
                  value={targetEntity.zip}
                  onValueChanged={(e) => setEntityValue('zip', e.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <span>Country</span>
              </Grid>
              <Grid item xs={7}>
                {(!targetEntity.country ||
                  targetEntity.country === usCountryCode) && (
                  <span> State</span>
                )}
                {targetEntity.country &&
                  targetEntity.country !== usCountryCode && (
                    <span> Foreign Province, State or County</span>
                  )}
              </Grid>
              <Grid item xs={5}>
                <SelectBox
                  dataSource={countryTypes}
                  displayExpr='label'
                  valueExpr='value'
                  id='tax-country-select-box'
                  data-testid='tax-country-select-box'
                  value={targetEntity.country}
                  placeholder='--Select Country--'
                  showClearButton={true}
                  onValueChange={(changedValue) => {
                    setEntityValue('country', changedValue)
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                {(!targetEntity.country ||
                  targetEntity.country === usCountryCode) && (
                  <SelectBox
                    dataSource={stateTypes}
                    displayExpr='label'
                    valueExpr='value'
                    id='tax-state-select-box'
                    data-testid='tax-state-select-box'
                    value={targetEntity.state}
                    disabled={targetEntity.country !== usCountryCode}
                    placeholder='--Select State--'
                    showClearButton={true}
                    onValueChange={(changedValue) =>
                      setEntityValue('state', changedValue)
                    }
                  />
                )}
                {targetEntity.country &&
                  targetEntity.country !== usCountryCode && (
                    <TextBox
                      id='foreign-province-textbox'
                      data-testid='foreign-province-textbox'
                      maxLength={1000}
                      value={targetEntity.foreignProvince}
                      onValueChanged={(e) =>
                        setEntityValue('foreignProvince', e.value)
                      }
                      disabled={targetEntity.country === usCountryCode}
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <span>Phone</span>
              </Grid>
              <Grid item xs={10}>
                <TextBox
                  id='phone-textbox'
                  data-testid='phone-textbox'
                  maxLength={1000}
                  value={targetEntity.phone}
                  onValueChanged={(e) => setEntityValue('phone', e.value)}
                />
              </Grid>
            </Grid>
          </div>
          <div
            className={selectedTabIndex === 3 ? 'tab-active' : 'tab-inactive'}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <span>PartnerSight Name</span>
              </Grid>
              <Grid item xs={8}>
                <TextBox
                  id='partnersight-name-textbox'
                  data-testid='partnersight-name-textbox'
                  value={targetEntity.partnerSightName}
                  maxLength={500}
                  onValueChanged={(e) =>
                    setEntityValue('partnerSightName', e.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <span>Matching Criteria</span>
              </Grid>
              <Grid item xs={8}>
                <TextBox
                  id='matching-criteria-textbox'
                  data-testid='matching-criteria-textbox'
                  value={targetEntity.matchingCriteria}
                  maxLength={500}
                  onValueChanged={(e) =>
                    setEntityValue('matchingCriteria', e.value)
                  }
                />
              </Grid>
            </Grid>
          </div>
          <div id='divSummary'>
            <ValidationSummary className='row-spacing' />
            {emailRowValidation.isError && (
              <div className='dx-item dx-validationsummary-item custom-validation-summary'>
                <div className='dx-item-content dx-validationsummary-item-content'>
                  {emailRowValidation.message}
                </div>
              </div>
            )}
          </div>
        </ValidationGroup>
      )}
      <SpinnerModal
        visible={showApiSpinner}
        errorTitleMessage='Error Saving'
        errorMessage=''
        inProgressTitleMessage='Save In Process'
        inProgressMessage='Saving Entity'
        successTitleMessage=''
        successMessage=''
        closeOnSuccess={true}
        hideCloseButton={true}
        onClose={(success?: boolean) => {
          setShowApiSpinner(false)
          // if success then close the modal
          if (success) {
            setIsDirty(false)
            onModalClose()
          }
        }}
        apiAction={savePromise}
      />
    </Modal>
  )
}
