import { ScrollView } from 'devextreme-react'
import React from 'react'
import './scrollableMessage.scss'

export type StaticContentPositionTypes = 'bottom' | 'top'

export interface ScrollableMessageProps {
  scrollableContent: React.ReactNode
  staticContent: React.ReactNode
  staticContentPosition?: StaticContentPositionTypes
}

/** Scrollable message component */
export const ScrollableMessage = ({
  staticContent,
  scrollableContent,
  staticContentPosition,
}: ScrollableMessageProps) => {
  return (
    <div className='scrollable-message-container'>
      {staticContentPosition === 'top' && (
        <div>{staticContent}</div>
      )}
      <ScrollView direction='vertical' className='scroll-view'>
        {scrollableContent}
      </ScrollView>
      {(staticContentPosition === 'bottom' || !staticContentPosition) && (
        <div className='static-content-container'>{staticContent}</div>
      )}
    </div>
  )
}
