import { Button, DataGrid } from 'devextreme-react'
import {
  SearchPanel,
  Scrolling,
  Sorting,
  Column,
  Toolbar,
  Item,
} from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react'
import { ICellData, dgheight } from '../../../model/file-model'
import { useEntityClientApi } from '../../../hooks/use-entity-api'
import { EntityGroup } from '../../../api-client/investor-portal-client'
import './client-group-list.scss'
import { getLandingPageRoute } from '../../utility/route-creation'
import { useNavigate } from '../../../hooks/useNavigationGuard'
import { Grid } from '@mui/material'
import { useCommonDialogs } from '../../modal/commonDialog/common-dialog-operations'
import { ClientGroupUserAccess } from './client-group-user-access'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'

/**Clients Group List Page */
export const ClientsGroupList = () => {
  const entityApi = useEntityClientApi()
  const navigate = useNavigate()
  const commonDialog = useCommonDialogs()
  const [clients, setClients] = useState<EntityGroup[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAddProfileModalOpen, setIsAddProfileModalOpen] =
    useState<boolean>(false)
  const [entityGroupInfo, setEntityGroupInfo] = useState<EntityGroup>({
    id: 0,
    name: '',
  })

  /** Fetch client groups from the API */
  useEffect(() => {
    const abortController = new AbortController()

    const getClientGroups = async () => {
      setIsLoading(true)

      await entityApi
        .getAllClientGroups(abortController.signal)
        .then((response) => {
          setClients(response)
          setIsLoading(false)
        })
        .catch((err) => {
          commonDialog.showDialog({
            title: 'Loading Error',
            dialogType: 'error',
            content: `Error While Loading Clients: ${err}`,
          })
        })
    }

    getClientGroups()

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [])

  /**  */
  const clientGroupClickAction = (cellData: ICellData<EntityGroup>) => {
    return (
      <Button
        data-role='link-button'
        onClick={() => {
          setIsAddProfileModalOpen(true)
          setEntityGroupInfo({
            id: cellData.data?.id,
            name: cellData.data?.name,
          })
        }}
        style={{ textTransform: 'none' }}
        stylingMode='text'
      >
        {cellData.data?.name}
      </Button>
    )
  }
  const userProfileModalClose = () => {
    setIsAddProfileModalOpen(false)
    setEntityGroupInfo({
      id: 0,
      name: '',
    })
  }

  return (
    <div className='client-list-page'>
      <div className='client-button-container'>
        <Grid container item xs={5} minWidth={50} justifyContent={'flex-start'}>
          <Grid item xs={3} className='button-container'>
            <Button
              onClick={() => navigate(getLandingPageRoute())}
              stylingMode='outlined'
              data-testid='btnBack'
            >
              <span className='dx-icon-arrowleft'></span>
            </Button>
          </Grid>
          <Grid item xs={2} className='label-container'>
            <span>Clients</span>
          </Grid>
        </Grid>
      </div>
      <div className='client-groups-grid ip-table'>
        <DataGrid
          showBorders={true}
          dataSource={clients}
          keyExpr='id'
          noDataText={isLoading ? 'Loading...' : ''}
          columnAutoWidth={false}
          height={dgheight}
          allowColumnResizing={true}
        >
          <SearchPanel visible={true} width='200px' />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Sorting mode='single' />
          <Toolbar>
            <Item
              name='searchPanel'
              locateInMenu='auto'
              location='before'
            ></Item>
          </Toolbar>
          <Column
            dataField='name'
            caption='Client Name'
            cellRender={clientGroupClickAction}
            allowSorting={true}
            defaultSortOrder='asc'
            minWidth={'20%'}
          ></Column>
        </DataGrid>
      </div>
      {isAddProfileModalOpen && (
        <ClientGroupUserAccess
          clientGroupId={entityGroupInfo.id!}
          clientName={entityGroupInfo.name!}
          onModalClose={userProfileModalClose}
        />
      )}
    </div>
  )
}
