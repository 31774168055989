import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import Notify from 'devextreme/ui/notify'

interface Props {
  text: string
  targetControl: string
  tooltipText: string
  width: number
}
/** Copy text to clipboard */
export const CopyClipboard = ({
  text,
  targetControl,
  tooltipText,
  width,
}: Props) => {
  // Display tooltip information
  const notifyMessage = () => {
    Notify({
      message: tooltipText,
      minWidth: width,
      maxWidth: width,
      type: 'info',
      displayTime: 200,
      position: {
        my: 'center top',
        of: targetControl,
      },
    })
  }
  return (
    <CopyToClipboard text={text}>
      <IconButton
        style={{ paddingLeft: 0 }}
        onClick={() => {
          notifyMessage()
        }}
        title='Click to Copy'
      >
        <ContentCopyIcon
          sx={{
            color: '#5A5A5A',
            fontSize: 18,
            position: 'relative',
          }}
        />
      </IconButton>
    </CopyToClipboard>
  )
}
