import { BannerContainer } from '../../common-components/banners/bannerContainer'
import { ViewSelector } from './view-selector'
import { SelectBoxOptions } from '../../../types/types'
import './landing-page-banner.scss'

export const LandingPageBanner = () => {
  /** Landing page View Options */
  const landingPageViewOptions: SelectBoxOptions[] = [
    {
      label: 'Tile',
      value: 'tile',
    },
    {
      label: 'List',
      value: 'list',
    },
  ]

  return (
    <BannerContainer className='landing-page-container'>
      <div className='view-selector landing-page-banner  annotated-select-text-view-selector'>
        <ViewSelector
          appStateKey='InvestorLandingPageView'
          defaultView='tile'
          viewOptions={landingPageViewOptions}
        ></ViewSelector>
      </div>
    </BannerContainer>
  )
}
