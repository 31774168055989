import React, { useCallback, useRef, useState } from 'react'
import { useFileClientApi } from '../../hooks/use-file-api'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { File } from '../../api-client/investor-portal-client'
import { CircularProgress } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useSetRecoilState } from 'recoil'
import { parentFileListAtom } from '../../state/atom'
import produce from 'immer'
import './editParentFileTable.scss'
import { Form, Item, SimpleItem, RequiredRule } from 'devextreme-react/form'
import {
  EditParentFileModel,
  emailTemplates,
  fileDocumentSubTypes,
} from '../../client-models/clientmodels'
import { getTaxYearValues } from './fileUtilities'
import { useParams } from 'react-router-dom'
import { FormattedDialog } from '../modal/formattedDialog'
import DataSource from 'devextreme/data/data_source';

export interface EditParentFileProps {
  onCancel: () => void
  onSave: () => void
  selectedFile: EditParentFileModel
  isVisible: boolean
}

export const EditParentFile = ({
  onCancel,
  onSave,
  selectedFile,
  isVisible,
}: EditParentFileProps) => {
  // Get the Publish Files function from our API hook.
  const { updateParentFile } = useFileClientApi()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [saveSucceeded, setSaveSucceeded] = useState(false)
  const [saveFailed, setSaveFailed] = useState(false)
  const [saveFailedDuplicate, setSaveFailedDuplicate] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const setParentFiles = useSetRecoilState(parentFileListAtom)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const formRef = useRef<Form>(null)

  const { partnershipId } = useParams() as {
    partnershipId: string
  }

  //closing the error model
  const closeErrorModal = useCallback(() => {
    setSaveFailedDuplicate(false)
  }, [])

  /** Update published flag in the store*/
  const updateFileDetail = (file: File) => {
    setParentFiles(
      produce((draft) => {
        const fileIndex = draft.files.findIndex((v) => v.id === file.id)
        if (fileIndex >= 0) {
          draft.files[fileIndex].tags = file.tags
        }
      })
    )
  }

  /** Validate form and Call api method to save file data */
  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault()
    if (!formRef.current?.instance?.validate().isValid) {
      return
    }

    setSaveFailed(false)
    setSaveFailedDuplicate(false)
    setSaveSucceeded(false)
    setShowSpinner(true)
    setSaveDisabled(true)
    setErrorMessage('')
    /*** Call api to update file data using file id
     * Update updated file details in the local store
     */
    let apiAction = updateParentFile(
      selectedFile.fileId,
      Number(selectedFile.taxYear),
      selectedFile.fileType.trim(),
      selectedFile.emailTemplate,
      Number(partnershipId)
    )
    apiAction
      .then((response: File) => {
        setShowSpinner(false)
        setSaveSucceeded(true)
        updateFileDetail(response) // Update the local store.
        onSave()
      })
      .catch((err) => {
        setShowSpinner(false)
        setSaveSucceeded(false)
        setSaveDisabled(false)
        if (err.status === 409) {
          setSaveFailedDuplicate(true)
          setErrorMessage(err.response)
        } else {
          setSaveFailed(true)
        }
      })
  }

  // Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: saveDisabled ? 'Close' : 'Cancel',
      onClick: () => onCancel(),
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Save',
      isDisabled: saveDisabled,
      onClick: () => handleSubmit(),
      visible: true,
      buttonProps: {
        useSubmitBehavior: true,
        stylingMode: 'contained',
        type: 'default',
        width: 100,
        'data-testid': 'save-button',
        disabled: saveDisabled,
      },
    },
  ]

  /** Set modal title */
  const title = (
    <div className='modal-title-font'>
      <span className='material-icons-outlined modal-title-alignment'></span>
      Edit Document
    </div>
  )

  /** Make readonly for non-editable fields */
  const disableFormField = {
    readOnly: true,
  }

  /**
   * Keeping standerd documentTypes in store for dropdown options
   * Reason behind keeping in store is to always show the dropdown options while typing the text
   */   
  const documentTypeDataSource = new DataSource({
    store: {
      data: fileDocumentSubTypes,
      type: 'array',
      key: '',
    },
  })

  /** File type dropdown options */
  const fileTypeEditorOptions = {
    dataSource: documentTypeDataSource,
    value: selectedFile.fileType,
    acceptCustomValue: true,
  }

  /** Email template dropdown options */
  const emailTemplateEditorOptions = {
    items: emailTemplates,
    searchEnabled: true,
    readOnly: true,
    value: selectedFile.emailTemplate,
    hint: 'Functionality not currently available',
  }

  /** Tax year dropdown options */
  const taxYearEditorOptions = {
    items: getTaxYearValues(selectedFile.taxYear, true),
    searchEnabled: true,
    value: selectedFile.taxYear,
  }

  if (!isVisible) {
    return null
  }

  return (
    <div>
      <Modal
        visible={isVisible}
        title={title}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        height='auto'
        showCloseButtonInTitle={true}
      >
        <div className='ModalConfirmation' data-testid='edit-parent-files'>
          <div className='form-container form-scroll-horizontal'>
            <Form
              formData={selectedFile}
              readOnly={false}
              showColonAfterLabel={false}
              showValidationSummary={false}
              ref={formRef}
            >
              <Item
                dataField='documentFileName'
                editorOptions={disableFormField}
                data-testid='document-file-name'
                editorType='dxTextBox'
              />
              <SimpleItem
                dataField='taxYear'
                editorType='dxSelectBox'
                data-testid='tax-year-input'
                editorOptions={taxYearEditorOptions}
              >
                <RequiredRule message='Tax year is required' />
              </SimpleItem>
              <SimpleItem
                dataField='fileType'
                editorType='dxSelectBox'
                data-testid='file-type-input'
                editorOptions={fileTypeEditorOptions}
              >
                <RequiredRule message='File Type is required' />
              </SimpleItem>
              <Item
                dataField='emailTemplate'
                editorType='dxSelectBox'
                data-testid='email-template-input'
                editorOptions={emailTemplateEditorOptions}
              />
              <Item
                dataField='addedBy'
                editorType='dxTextBox'
                editorOptions={disableFormField}
              />
              <Item
                dataField='dateAdded'
                editorType='dxDateBox'
                editorOptions={disableFormField}
              />
              <Item>
                {saveFailed && (
                  <div>
                    <ErrorOutlineIcon color='error' fontSize='medium' />
                    <span className='modal-spinner-alignment'>
                      Update document failed.
                    </span>
                  </div>
                )}
                {showSpinner && (
                  <div>
                    <div>
                      <CircularProgress color='info' size='30px' />
                      <span className='modal-spinner-alignment'>
                        Saving document...
                      </span>
                    </div>
                  </div>
                )}
                {saveSucceeded && (
                  <div>
                    <div>
                      <CheckCircleOutlineIcon color='info' fontSize='medium' />
                      <span className='modal-spinner-alignment'>
                        Document updated successfully.
                      </span>
                    </div>
                  </div>
                )}
              </Item>
            </Form>
          </div>
        </div>
      </Modal>
      <FormattedDialog
        visible={saveFailedDuplicate}
        dialogType='error'
        onCloseClicked={closeErrorModal}
      >
        <div>{errorMessage.replace('"', '').replace('"', '')}</div>
      </FormattedDialog>
    </div>
  )
}
