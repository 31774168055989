import { SignalREventHandler } from '../signalr-models'
import { signalR } from '../signalr-runtime'

/** Abstract class that watches and evaluates SignalR messages, and returns a promise
 *   controlled by derived classes to resolve/reject when the derived logic deems appropriate.
 */
export abstract class SignalRWatcher<T = void> {
  constructor(readonly eventName: string, private apiPromise?: Promise<any>) { }

  /** Event action that is called when a message is received with the specific eventName. */
  protected abstract handlerAction(...args: any[]): void

  /** Resolve function of the promise, to be called by the class when the operation is complete. */
  private _promiseResolver: ((value: T | PromiseLike<T>) => void) | undefined

  /** Reject function of the promise, to be called if the operation fails. */
  private _promiseRejector: ((reason?: any) => void) | undefined

  private _isInitialized = false
  /** Boolean value indicating whether or not the initialize function has been called. */
  public get isInitialized() {
    return this._isInitialized
  }

  private _isComplete: boolean = false
  /** Boolean value indicating whether or not this watcher has finished watching
   *   for its events, and resolved/rejected its promise.
   */
  public get isComplete() {
    return this._isComplete
  }

  private _isRejected: boolean = false
  /** Boolean value indicating whether or not the operation failed, and the promise
   *   ws rejected.
   */
  public get isRejected() {
    return this._isRejected
  }

  /** Event handler registered in SignalR. */
  private _eventHandler?: SignalREventHandler

  /** Called by the derived class when this watcher has completed successfully
   *   and unregisters the handler from SignalR.
   */
  protected operationComplete(value?: T) {
    if (!this.isInitialized) {
      throw new Error(`initialize has not been called.`)
    }

    // Unregister the handler - we're done now.
    signalR.unregisterHandler(this._eventHandler!)

    // Indicate this watcher is done.
    this._isComplete = true

    // Resolve the promise with the value provided.
    this._promiseResolver!(value!)
  }

  /** Called by the derived class when this watcher has completed unsuccessfully
   *   and unregisters the handler from SignalR. */
  protected operationFailed(err: any) {
    if (!this.isInitialized) {
      throw new Error(`initialize has not been called.`)
    }

    // Unregister the handler - we're done now.    
    signalR.unregisterHandler(this._eventHandler!)

    // Indicate this watcher completed with errors.
    this._isComplete = true
    this._isRejected = true

    // Reject the promise with the provided error/message.
    this._promiseRejector!(err)
  }

  private _promise: Promise<T> | undefined
  /** The promise that will resolve/reject when this watcher has achieved/failed its task. */
  public get promise(): Promise<T> {
    return this._promise!
  }

  /** Must be called to register the event handler to SignalR, and begin operations. */
  initialize(): Promise<T> {
    // We can't initialize more than once.
    if (this._isInitialized) {
      throw new Error(`initialize has already been called.`)
    }

    // Flag that we've initialized the class, so we can't do it again.
    this._isInitialized = true

    // Create a promise, and store the resolver and rejector for later.
    this._promise = new Promise<T>((res, rej) => {
      this._promiseResolver = res
      this._promiseRejector = rej
    })

    // Create a new SignalR event handler, using the
    //  derived implementation's handlerAction function.
    this._eventHandler = {
      eventName: this.eventName,
      action: this.handlerAction,
    }

    // Register our new handler.
    signalR.registerHandler(this._eventHandler)

    // Watch the API Promise (if there is one), and throw
    //  on rejection.  We won't wait for completion though,
    //  since this API call may be firing SignalR methods.
    if (this.apiPromise) {
      this.apiPromise.catch((err) => {
        this.operationFailed(err)
      })
    }

    // Return the promise for convenience.
    return this._promise
  }
}
