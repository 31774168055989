import { Route, Routes, useParams } from 'react-router-dom'
import { ClientGroupDetailsBanner } from './clientGroupDetailsBanner'
import { LandingPageBanner } from './landing-page-banner'
import { ParentChildFileBanner } from './parent-child-file-banner'
import { ProvisionContactEmailBanner } from './provisionalContactEmailBanner'
import { HelpPageBanner } from './helpPageBanner'
import { VerifyUserBanner } from './verifyUserBanner'
import { StateManagerBanner } from './stateManagerBanner'
import { entityGroupPartnershipStateAtom } from '../../state/atom'
import { useRecoilValue } from 'recoil'
import { getTermsOfUseRoute } from '../utility/route-creation'
import { TermsOfUseBanner } from './terms-of-use-banner'
import { InvestorElectionPageBanner } from './investor-elections-page-banner'
import { EntityListPageBanner } from './entityListPageBanner'
import { InvestorPageBanner } from './investor-page-banner'
import { ClientGroupsListBanner } from './client-groups-list-banner'
import { ManageExistingInvestorsBanner } from './manageExistingInvestorsBanner'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../error-boundary/ErrorFallback'
import { ErrorPage } from '../error-boundary/error-page'
export const Banner = () => {
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupIdNumber = parseInt(groupId)

  //Enabling StateManager Page with State Elections flag from recoil state
  const EntityClientGroup = useRecoilValue(entityGroupPartnershipStateAtom)
  const clientState = EntityClientGroup[groupIdNumber]
  const elections =
    clientState && clientState ? clientState.featureFlag?.stateElections : false

  return (
    <Routes>     
    <Route path='/admin' element={<ErrorBoundary FallbackComponent={ErrorFallback}><LandingPageBanner /> </ErrorBoundary>}></Route>
    <Route path='/investor' element={<ErrorBoundary FallbackComponent={ErrorFallback}><InvestorPageBanner /> </ErrorBoundary>}></Route>
    <Route
      path='/investor/investorlandingpage'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><LandingPageBanner /> </ErrorBoundary>}
    ></Route>
    <Route
      path='/admin/provisionuserbyemail'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><ProvisionContactEmailBanner /> </ErrorBoundary>}
    ></Route>
    <Route path='/admin/verifyuser' element={<ErrorBoundary FallbackComponent={ErrorFallback}><VerifyUserBanner /> </ErrorBoundary>}></Route>
    <Route
      path='admin/groups/:groupId'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><ClientGroupDetailsBanner></ClientGroupDetailsBanner> </ErrorBoundary>}
    ></Route>
    <Route
      path='admin/groups/:groupId/partnerships/:partnershipId'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}>
        <ParentChildFileBanner isParentBanner={true}></ParentChildFileBanner>
        </ErrorBoundary>}
    ></Route>
    <Route
      path='admin/groups/:groupId/partnerships/:partnershipId/*'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}>
        <ParentChildFileBanner isParentBanner={false}></ParentChildFileBanner></ErrorBoundary>
      }
    ></Route>
    <Route
      path='/admin/groups/:groupId/statemanager/:taxYear'
      element={ <ErrorBoundary FallbackComponent={ErrorFallback}> 
        <StateManagerBanner stateElections={elections!}></StateManagerBanner> </ErrorBoundary>
      }
    ></Route>
    <Route
      path='/admin/groups/:groupId/statemanager'
      element={ <ErrorBoundary FallbackComponent={ErrorFallback}> 
        <StateManagerBanner stateElections={elections!}></StateManagerBanner> </ErrorBoundary>
      }
    ></Route>
    <Route
      path='/admin/groups/:groupId/entitymanager/entities'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><EntityListPageBanner/> </ErrorBoundary>}
    >
    </Route>
    <Route path='help' element={<ErrorBoundary FallbackComponent={ErrorFallback}><HelpPageBanner /> </ErrorBoundary>}></Route>
    <Route
      path='investor/investorfiles'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><InvestorPageBanner /> </ErrorBoundary>}
    ></Route>
    <Route
      path='investor/investorfiles/:groupId/:partnerId'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><InvestorPageBanner /> </ErrorBoundary>}
    ></Route>
    <Route
      path='investor/groups/:groupId/stateelections/:partnerId'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><InvestorElectionPageBanner /> </ErrorBoundary>}
    ></Route>
    <Route
      path='investor/groups/:groupId/stateelections/:partnerId/:taxYear'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><InvestorElectionPageBanner /> </ErrorBoundary>}
    ></Route>
    <Route path={getTermsOfUseRoute()} element={<ErrorBoundary FallbackComponent={ErrorFallback}><TermsOfUseBanner /> </ErrorBoundary>}></Route>
    <Route
      path='admin/application/settings/clients'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><ClientGroupsListBanner /> </ErrorBoundary>}
    ></Route>
    <Route
      path='admin/existinginvestors'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><ManageExistingInvestorsBanner /> </ErrorBoundary>}
    ></Route>
     <Route
      path='/error'
      element={<ErrorBoundary FallbackComponent={ErrorFallback}><ErrorPage /> </ErrorBoundary>}
    ></Route>
     
  </Routes>

  )
}
