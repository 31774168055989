import { useTermsOfUse } from '../../hooks/use-terms-of-use'
import { useEffectSingleCalls } from '../../hooks/useEffectSingleCall'
import { REACT_APP_EULA_TERMS_OF_USE_ID } from '../../envVariables'
import './termsOfUse.scss'

/** This component we are using to show terms of use  */
export const TermsOfUse = () => {
  const { getTermsOfUse, requestStatus, termOfUse } = useTermsOfUse()
  const eulaTermsOfUseId = REACT_APP_EULA_TERMS_OF_USE_ID

  useEffectSingleCalls(() => {
    // Get the Terms of Use from Eula
    getTermsOfUse(eulaTermsOfUseId)
  }, [getTermsOfUse, eulaTermsOfUseId])

  /** Create HTML content to show in the page */
  const createContent = () => {
    return { __html: termOfUse as string }
  }

  return (
    <div className='terms-of-use'>
      {requestStatus === 'in-progress' && (
        <div className='text-message'>Loading...</div>
      )}
      {requestStatus === 'failed' && (
        <div className='text-message'>
          Sorry, we are unable to retrieve the EULA at this time. Please try
          again later.
        </div>
      )}
      {requestStatus === 'success' && (
        <div>
          <div dangerouslySetInnerHTML={createContent()} />
        </div>
      )}
    </div>
  )
}
