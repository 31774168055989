import { Column } from 'devextreme-react/data-grid'
import {
  CellPreparedEvent,
  Column as ColumnInfo,
} from 'devextreme/ui/data_grid'

interface ColumnInfoExtension {
  /** When set to false, prevents tool tips from being generated, using the
   *   addToolTipOnCellPrepared method. */
  'data-includeTooltip'?: boolean
  /** Provides an alternate method of generating tooltip content for the
   *   addToolTipOnCellPrepared method. */
  'data-tooltipValueGeneration'?: (
    cellInfo: CellPreparedEventExtended
  ) => string
}

/** Used on addToolTipOnCellPrepared, adding the typeings needed for the
 *   event to include the extra properties for the column.  The column.['data-includeTooltip']
 *   property being set to false will not create a tooltip on the cell when
 *   the addToolTipOnCellPrepared method is used. */
export type CellPreparedEventExtended = CellPreparedEvent & {
  column: ColumnInfo<any, any> & ColumnInfoExtension
}

/** Generic method that adds tool tips to grid cells.  When the grid cell
 *   has an attribute 'data-includeToolTip': false, this function
 *   ignores the cell. */
export const addToolTipOnCellPrepared = (e: CellPreparedEventExtended) => {
  if (
    e.rowType === 'data' &&
    e.column &&
    e.cellElement &&
    e.column['data-includeTooltip'] !== false
  ) {
    if (e.column['data-tooltipValueGeneration']) {
      // Set the title/tooltip to the generated value, defined by the attribute.
      e.cellElement.title = e.column['data-tooltipValueGeneration'](e)
    } else {
      // Set the title/tooltip to the cell's value.
      e.cellElement.title = e.value ?? ''
    }
  }
}

/** Helper method to get the value used in filtering dates on the data grid. */
export function getFilterDateValue<T extends object>(data: T | string | Date, dateField: keyof T) {
  if (typeof data === 'string') {
    return data
  } else if (data instanceof Date) {
    return data.toLocaleDateString()
  } else {
    if (data[dateField]) {
      return new Date(data[dateField] as any).toLocaleDateString()
    }
    return 'never'
  }
}

export function getDateFilterFunction<T extends object>(propertyName: keyof T) {
  return (target: T | string | Date) => getFilterDateValue(target, propertyName)
}

export function getCalculatedFilterExpression<T extends object = any>(propertyName: keyof T) {
  const filterFunction = getDateFilterFunction<T>(propertyName)

  return function calculateFilterExpression2(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    // If this is the headerFilter, then we want to modify it.
    if (target === 'headerFilter') {
      return [filterFunction, '=', filterFunction(value)]
    }

    // Anything else, we should return as-is.
    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }
}

//filter value for investor mismatch 'Investor Name and Linked Investor do not match'
export const MISMATCH_FILTER_TEXT = 'Investor Name and Linked Investor do not match'

//general type to track cem action (reset password & resend activation mail)
export type IdmActionState = 'not-called' | 'in-progress' | 'called' | 'error'