import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { useFileClientApi } from '../../../hooks/use-file-api'
import { clientAndPartnershipStateAtom, parentFileListAtom } from '../../../state/atom'
import { getChildFileListRoute } from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { NavWidgetOption } from '../nav-widget-models'
import { PARENT_FILE_LIST_LEVEL } from '../widget-levels'

export const useParentFilesNavWidget = () => {
  const parentFileListState = useRecoilValue(parentFileListAtom)
  const clientAndPartnerships = useRecoilValue(clientAndPartnershipStateAtom)
  const clientApi = useFileClientApi()
  // Update parent file data in store if its empty (browser refresh) or any change in client Id
  if (
    clientAndPartnerships.clientGroupId != null &&
    clientAndPartnerships.partnershipId != null &&
    parentFileListState.partnershipId !== clientAndPartnerships.partnershipId &&
    !parentFileListState.isLoading
  ) {
    clientApi.getParentFiles(
      clientAndPartnerships.clientGroupId,
      clientAndPartnerships.partnershipId,
      ''
    )
  }
  const navItems: NavWidgetOption[] = useMemo(() => {
    if (
      !clientAndPartnerships.clientGroupId ||
      !clientAndPartnerships.partnershipId
    ) {
      return []
    }

    return parentFileListState.files.map((g) => ({
        label: g.externalFileName!,
        level: PARENT_FILE_LIST_LEVEL,
        visibilityPaths: [
          '/admin/groups/:groupId/partnerships/:partnershipId/*',
          '/admin/groups/:groupId/partnerships/:partnershipId',
        ],
        navigationPath: getChildFileListRoute(
          clientAndPartnerships.clientGroupId!,
          clientAndPartnerships.partnershipId!,
          g.id!
        ),
        selectionPath: getChildFileListRoute(
          clientAndPartnerships.clientGroupId!,
          clientAndPartnerships.partnershipId!,
          g.id!
        )!,
        createdDate: g.createdDate!,
      }))
      .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1))
  }, [
    parentFileListState,
    clientAndPartnerships.clientGroupId,
    clientAndPartnerships.partnershipId,
  ])
  useNavWidgetPageItems(navItems)
}
