import { BannerHeader } from './bannerHeader'

export interface StatisticsItem {
  name: string
  value: string | number
}
interface StatisticsProps {
  items: StatisticsItem[]
}
export const Statistics = (props: StatisticsProps) => {
  return (
    <>
      {props.items.map((item) => (
        <div key={'sta-header-' + item.name}>
          <BannerHeader>{item.value}</BannerHeader>
          <label>{item.name}</label>
        </div>
      ))}
    </>
  )
}
