import Chart, {
  ArgumentAxis,
  CommonAxisSettings,
  HoverStyle,
  Series,
  ValueAxis,
} from 'devextreme-react/chart'
import { CommonSeriesSettings } from 'devextreme-react/chart'
import { Legend } from 'devextreme-react/chart'
import { useMemo } from 'react'
import './barchart-display.scss'
import { Grid } from '@mui/material'

interface BarChartDisplayProps {
  visible: boolean
  markedPercent: number
  displayValue?: string
}

/** BarChart Component */
export const BarChartDisplay = ({
  visible,
  markedPercent,
  displayValue,
}: BarChartDisplayProps) => {
  const unMarkedBarPercent: number = 100 - markedPercent

  // Create dataSource with the calculated percentages
  const dataSource = useMemo(
    () => [
      {
        state: '',
        markedBar: markedPercent,
        unMarkedBar: unMarkedBarPercent,
      },
    ],
    [markedPercent, unMarkedBarPercent]
  )

  return (
    <>
      {visible && (
        <div className='chart-container' style={{ display: 'flex' }}>
          <Grid container alignItems='flex-end' className='bar-chart-container'>
            <Grid item xs={3} className='before-bar'>
              <span data-testid='text' className='textdisplay'>
                {markedPercent === 100 ? 'Completed' : 'In Progress'}
              </span>
            </Grid>
            <Grid item xs={6} className='bar-chart'>
              <Chart
                className='bar-chart'
                dataSource={dataSource}
                rotated={true}
                autoHidePointMarkers={true}
                aria-label='Bar Chart'
              >
                <Legend visible={false} />
                <ValueAxis
                  visible={false}
                  axisDivisionFactor={200}
                  position='top'
                  minorTickCount={3}
                  wholeRange={{ length: 100 }}
                  tick={{ visible: true, opacity: 0.6, length: 9 }}
                  minorTick={{ visible: true, opacity: 0.6, length: 5 }}
                  label={{ visible: false }}
                />
                <ArgumentAxis visible={false} label={{ visible: false }} />

                <CommonAxisSettings grid={{ visible: false, opacity: 0 }} />

                <CommonSeriesSettings
                  argumentField='state'
                  type='stackedBar'
                  border={{ visible: true, color: '#33afe4', width: 1 }}
                >
                  <HoverStyle
                    border={{ visible: true, color: '#33afe4', width: 1 }}
                  />
                </CommonSeriesSettings>
                <Series
                  valueField='markedBar'
                  color={markedPercent === 100 ? '#3F9C35' : '#33afe4'}
                  barPadding={5}
                />
                <Series
                  valueField='unMarkedBar'
                  color='#ffffff'
                  barPadding={5}
                />
              </Chart>
            </Grid>
            <Grid item xs={3} className='after-chart'>
              <span data-testid='valuedisplay' className='valuedisplay'>
                {displayValue}
              </span>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}
