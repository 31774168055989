import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import CustomStore from "devextreme/data/custom_store"
import { useStateManagerApi } from "./use-state-manager-api"
import { EntityOptionsModel, InvestorSearchResult, InvestorsSearchRequest, SMParentTypes, SortOrderTypes } from "../api-client/investor-portal-client"

/** Hook to load & perform data investor selection operation  */
export const useInvestorSelection = () => {
    const stateManagerApi = useStateManagerApi()
    const [isLoading, setIsLoading] = useState(false)
    const [sortType, setSortType] = useState<SortOrderTypes>(SortOrderTypes.Asc)
    const [selectionType, setSelectionType] = useState<boolean>()
    const sessionRef = useRef<number>(0)
    const [investorPromise, setInvestorPromise] = useState<Promise<any>>()
    const [parentId, setParentId] = useState<number>(0)
    const [parentType, setParentType] = useState<SMParentTypes>(SMParentTypes.Global)
    const [checkboxChange, setCheckboxChange] = useState<string>('')
    const [useGlobalSettings, setUseGlobalSettings] = useState<boolean>()
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false)
    const [isSelectionChanged, setIsSelectionChanged] = useState<boolean>(false)

    /** clean the investor selection session based on the session Id 
     * if user not saved the changes and navigate to other page
    */
    useEffect(() => {
        return () => {
            if (sessionRef.current > 0) {
                // delete investor selection session records based on the session id 
                stateManagerApi.cancelInvestorSelectionSession(sessionRef.current)
            }
        }
    }, [])

    /** create investor selection session id and set in session */
    const createInvestorSelectionSession = async () => {
        const globalSettings = useGlobalSettings === undefined ? true : useGlobalSettings
        // API call to copy the investor selection records to investor selection session table
        const result = await stateManagerApi.createInvestorSelectionSession(parentId, parentType, globalSettings)
        // set session id
        sessionRef.current = result
    }

    /*** update select all or select none option */
    const updateAllInvestorSelections = async (isAllSelected: boolean) => {
        if (sessionRef.current <= 0) {
            await createInvestorSelectionSession()
            // API call to update all investor selections to SelectAll or SelectNone based on the session id
            await stateManagerApi.updateAllInvestorSelections(
                sessionRef.current,
                isAllSelected
            )
        }
        else {
            // API call to update all investor selections to SelectAll or SelectNone based on the session id
            await stateManagerApi.updateAllInvestorSelections(
                sessionRef.current,
                isAllSelected
            )
        }
        setIsSelectionChanged(true)
        return true
    }

    /** create investor selection session id & update investor selection */
    const updateInvestor = async (key: number, isSelected: boolean) => {
        await createInvestorSelectionSession()
        // API call to update investor selection based on the investor id
        await stateManagerApi.updateInvestorSelection(sessionRef.current, key, isSelected)
    }

    /** custom store update handler to update data in DB */
    const updateHandler = async (key: number, values: InvestorSearchResult) => {
        if (sessionRef.current <= 0) {
            var createPromise = updateInvestor(key, values.isSelected!)
            // set promise to state to display spinner modal
            setInvestorPromise(createPromise)
            await createPromise
        }
        else {
            /** API call to update investor selection based on the investor id & 
             * Set random string to trigger the function in investor selection component to set Select All option
             */
            stateManagerApi.updateInvestorSelection(sessionRef.current, key, values.isSelected!)
                .then(() => setCheckboxChange(Math.floor(Math.random() * Date.now()).toString(36)))

        }
        setIsSelectionChanged(true)
        return Promise.resolve(values)
    }

    /** call API and Save investor selection */
    const saveInvestorSelecionApi = async (allocatingEntityId?: number, parent?: number, entityOptionId?: number) => {
        let optionsModel: EntityOptionsModel = {}
        if (allocatingEntityId) {
            optionsModel = {
                allocatingEntityId: allocatingEntityId,
                parentId: parent,
                propertyName: 'UseGlobalEntities',
                propertyValue: useGlobalSettings,
            }
        }
        /** Update/insert investor selection table from investor selection session record table 
         * based on the session id and
         * delete the entries in investor selection session & investor selection session record table
         */
        if (parentType === SMParentTypes.Global) {
            await stateManagerApi.saveInvestorSelectionSession(sessionRef.current)
        }
        /** User toggele between useGlobalEntities option ie., true or false and 
         * finally set useGlobalEntities to true and click on Save or Save All
         * Delete custom investor selection records based on the entity options id & parent id from investor session record tables
         * Update the useGlobalEntities to true in SMEntityoptions table
        */
        else if (parentType === SMParentTypes.AllocatingEntity && useGlobalSettings) {
            await stateManagerApi.insertorUpdateSMEntityOptions(optionsModel, useGlobalSettings)
            await stateManagerApi.clearCustomEntitySelections(entityOptionId!, parentType)
        }
        /** Update the useGlobalEntities to true in SMEntityoptions table 
         * Update/insert investor selection table from investor selection session record table 
         * based on the session id and
         * delete the entries in investor selection session & investor selection session record table
        */
        else if (parentType === SMParentTypes.AllocatingEntity && !useGlobalSettings) {
            await stateManagerApi.saveInvestorSelectionSession(sessionRef.current)
            await stateManagerApi.insertorUpdateSMEntityOptions(optionsModel, !useGlobalSettings)
        }
        // reset investor selection  session Id
        sessionRef.current = 0
        setIsSelectionChanged(false)
    }

    /** custom store insert handler to pull data from DB */
    const loadInvestorHandler = useCallback(async (loadOptions: any) => {
        setIsLoading(true)
        const searchRequest: InvestorsSearchRequest = {
            parentId: parentId,
            parentType: parentType,
            sessionId: sessionRef.current,
            sortDirection: sortType,
            selectionType: selectionType,
            useGlobalSettings: useGlobalSettings,
            searchText: loadOptions.filter ? loadOptions.filter[2] : '',   // Apply search filter conditions to API
            pageSize: loadOptions['take'],
            skipCount: loadOptions['skip'],
        }
        setIsFilterApplied(selectionType !== undefined)
        // if session id is available then load data from investor session record table
        if (sessionRef.current > 0 && useGlobalSettings !== true) {
            return stateManagerApi
                .getInvestorSelections(searchRequest)
                .then((response) => {
                    setIsLoading(false)
                    return {
                        data: response.result,
                        totalCount: response.totalCount,
                    }
                })
        } else {
            setIsSelectionChanged(false)
            // if session id is not available then load data from investor selection table
            return stateManagerApi
                .getInvestors(searchRequest)
                .then((response) => {
                    setIsLoading(false)
                    return {
                        data: response.result,
                        totalCount: response.totalCount,
                    }
                })
        }
    }, [parentId, parentType, sortType, selectionType, useGlobalSettings])

    /** custor store */
    const dataStore = useMemo(() => {
        return new CustomStore({
            key: 'investorId',
            load: loadInvestorHandler,
            update: updateHandler,
        })
    }, [parentId, parentType, sortType, selectionType, useGlobalSettings])

    /** hook to  load investor selection component */
    const returnVal = useMemo(
        () => ({
            parentId: parentId,
            setParentId: setParentId,
            parentType: parentType,
            setParentType: setParentType,
            dataSource: dataStore,
            isLoading: isLoading,
            sortType: setSortType,
            selectionType: setSelectionType,
            sessionRef: sessionRef,
            isGlobalSettings: useGlobalSettings,
            setGlobalSettings: setUseGlobalSettings,
            investorSelectionChange: checkboxChange,
            isFilterSet: isFilterApplied,
            isSelectionChanged: isSelectionChanged,
            promise: investorPromise,
            setPromise: setInvestorPromise,
            createSession: createInvestorSelectionSession,
            updateAllInvestors: updateAllInvestorSelections,
            saveInvestor: saveInvestorSelecionApi,
        }),
        [dataStore, isLoading, sortType, selectionType, investorPromise, setParentId, setParentType, checkboxChange, setUseGlobalSettings, setInvestorPromise, isFilterApplied, isSelectionChanged]
    )
    return returnVal
}