import React, { useState, ReactNode, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import './navChip.scss'
import { useNavigate } from '../../hooks/useNavigationGuard'

interface NavChipProps {
  label: string
  items: NavChipMenuItem[]
  navLocation?: string
}

interface NavChipMenuItem {
  text: string
  data?: string | (() => void)
}

/** In order to facilitate the context menu properly, for each item in the breadcrumbs, we need
 *   the nav chip to be a React component. */
export const NavChip = ({ label, navLocation, items }: NavChipProps) => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const labelRef = useRef<any>()
  const location = useLocation()

  // Determine if the label requires a context menu.
  const requireContextMenu = (items?.length ?? 0) > 1

  /** Called when the label (created below) is clicked. */
  const onLabelClicked = useCallback(() => {
    // Navigate to this path if it's not already the current path.
    if (
      navLocation &&
      location.pathname.toLocaleLowerCase() !== navLocation.toLocaleLowerCase()
    ) {
      navigate(navLocation)
    }
  }, [location, navLocation, navigate])

  // We need a label object.  Depending on whether
  //  or not we have an item list, we need to show
  //  an expander icon.
  let labelContent: ReactNode
  if (requireContextMenu) {
    labelContent = (
      <div
        ref={labelRef}
        className='nav-widget-chip clickable-chip'
        onClick={() => setIsMenuOpen(true)}
      >
        {label} <ExpandMoreIcon></ExpandMoreIcon>
      </div>
    )
  } else {
    const classNames = ['nav-widget-chip']

    if (navLocation !== location.pathname) {
      classNames.push('clickable-chip')
    }

    labelContent = (
      <div
        onClick={onLabelClicked}
        ref={labelRef}
        className={classNames.join(' ')}
      >
        {label}
      </div>
    )
  }

  // Method to close the menu.
  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  /** Handles when a menu item is clicked. */
  const onMenuItemClicked = useCallback(
    (navItem: NavChipMenuItem) => {
      if (typeof navItem.data === 'function') {
        // If the nav item has a function for its data, then execute it.
        navItem.data()
      } else if (navItem.data) {
        // The nav item has string data.  This must be a URL path,
        //  and we need to navigate to it.
        navigate(navItem.data)
      }

      // Either way, we need to close the menu.
      closeMenu()
    },
    [navigate]
  )

  return (
    <>
      {labelContent}

      {requireContextMenu && (
        <Menu
          anchorEl={labelRef.current}
          onClose={closeMenu}
          PaperProps={{
            style: {
              maxHeight: '50vh',
            },
          }}
          open={isMenuOpen}
        >
          {items.map((m) => (
            <MenuItem
              key={m.text}
              onClick={(e) => onMenuItemClicked(m)}
              className='nav-menu-font'
            >
              {m.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
